import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import Members from "./Members";
import useStyles from "../../../hooks/useStyles";
import { User } from "../../../models/User";
import { Task } from "../../../models/Task";
import { SettingCategory } from "../../../models/Setting";
import { taskSettingTabIndex } from "../../../constants";

type Props = {
  user: User;
  members: User[];
  task: Task;
  endAssigning: (
    task: Task,
    assignee: User | null,
    category?: SettingCategory
  ) => Promise<void>;
};

const Assigning: React.FC<Props> = React.memo(
  ({ user, members, task, endAssigning }) => {
    // ref
    const elm = useRef<HTMLDivElement>(null);
    const membersWithoutSelf = members.filter(
      (m) => m.userId !== (user ? user.userId : "")
    );
    // hooks
    const { t: a } = useTranslation("assignee");
    const { fontSize } = useStyles();
    // states
    const [left, setLeft] = useState(task.left);
    const [editingAssignee, setEditingAssignee] = useState<User | null>(null);

    useEffect(() => {
      if (elm.current) {
        const margin =
          elm.current.offsetLeft +
          elm.current.offsetWidth / 2 -
          task.horizontalCenter;
        setLeft(left - margin);
      }
    }, [task.horizontalCenter]);

    useEffect(() => {
      if (task.isAssigning) {
        setEditingAssignee(task.assignee);
        focus();
      }
    }, [task.isAssigning]);

    const focus = () => {
      elm.current && elm.current.focus();
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      switch (event.key) {
        case "ArrowRight":
          event.preventDefault();
          moveToRightMember();
          break;
        case "ArrowLeft":
          event.preventDefault();
          moveToLeftMember();
          break;
        case "Enter":
          event.preventDefault();
          end();
          return;
        case "a":
          event.preventDefault();
          return;
        case "d":
          event.preventDefault();
          await endAssigning(task, editingAssignee, "DueDate");
          return;
        case "l":
          event.preventDefault();
          await endAssigning(task, editingAssignee, "Label");
          return;
        case " ":
          // 画面がスクロールされるのを防ぐ
          event.preventDefault();
          return;
        case "Tab":
          // フォーカスが移動するのを防ぐ
          event.preventDefault();
          return;
        default:
          break;
      }
    };

    const moveToRightMember = () => {
      if (!user) return;

      if (editingAssignee) {
        if (editingAssignee.userId === user.userId) {
          if (membersWithoutSelf.length) {
            // 「自分」で他のメンバーがいる場合は最初のメンバー
            onChangeAssignee(membersWithoutSelf[0]);
          } else {
            // 「自分」で自分しかいない場合は「なし」
            onChangeAssignee(null);
          }
        } else {
          const currentIndex = membersWithoutSelf.findIndex(
            (m) => m.userId === editingAssignee.userId
          );
          if (currentIndex === membersWithoutSelf.length - 1) {
            // 最後なら「なし」
            onChangeAssignee(null);
          } else {
            // 次のメンバー
            onChangeAssignee(membersWithoutSelf[currentIndex + 1]);
          }
        }
      } else {
        // 「なし」なら「自分」
        onChangeAssignee(user);
      }
    };

    const moveToLeftMember = () => {
      if (!user) return;

      if (editingAssignee) {
        if (editingAssignee.userId === user.userId) {
          // 「自分」なら「なし」
          onChangeAssignee(null);
        } else {
          const currentIndex = membersWithoutSelf.findIndex(
            (m) => m.userId === editingAssignee.userId
          );
          if (currentIndex === 0) {
            // 最初なら「自分」
            onChangeAssignee(user);
          } else {
            // 前のメンバー
            onChangeAssignee(membersWithoutSelf[currentIndex - 1]);
          }
        }
      } else {
        // 「なし」なら最後のメンバー
        if (membersWithoutSelf.length) {
          // 「なし」で他のメンバーがいる場合は最後のメンバー
          onChangeAssignee(membersWithoutSelf[membersWithoutSelf.length - 1]);
        } else {
          // 「なし」で自分しかいない場合は「自分」
          onChangeAssignee(user);
        }
      }
    };

    const onChangeAssignee = (assignee: User | null) => {
      setEditingAssignee(assignee);
    };

    const end = () => {
      elm.current && elm.current.blur();
    };

    const onBlur = async () => {
      await endAssigning(task, editingAssignee);
    };

    if (user) {
      return (
        <Container
          ref={elm}
          tabIndex={taskSettingTabIndex}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        >
          <Title fontSize={fontSize}>{a("assignee")}</Title>
          <Members
            focused={true}
            user={user}
            assigneeId={editingAssignee ? editingAssignee.userId : ""}
            members={membersWithoutSelf}
            onChangeAssignee={onChangeAssignee}
          />
        </Container>
      );
    } else {
      return <></>;
    }
  }
);
Assigning.displayName = "Assigning";

const Container = styled(FlexColumnCenter)`
  max-width: 400px;
  margin-top: 8px;
  user-select: none;
  :focus {
    outline: none;
  }
`;

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize + 2}px;
  font-weight: bold;
`;

export default Assigning;
