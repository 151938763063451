import styled from "styled-components";

const SigninButton = styled.button<{ color: string; hoverColor: string }>`
  font-size: 20px;
  font-weight: bold;
  color: ${({ color }) => color};
  background-color: transparent;

  padding: 4px 12px;
  border: none;

  cursor: pointer;
  user-select: none;

  transition: color 100ms ease-out;

  :hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export default SigninButton;
