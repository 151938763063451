import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useStyles from "../hooks/useStyles";
import {
  baseBorderRadius,
  taskVerticalPadding,
  taskHorizontalPadding,
  contextMenuZIndex,
} from "../constants";

type Props = {
  borderColor: string;
  top: number;
  left: number;
  onClickContextMenuOpenSettings: () => void;
  onClickContextMenuAddChild: () => Promise<void>;
  onClickContextMenuAddTaskBelow: () => Promise<void>;
  onClickContextMenuAddTaskAbove: () => Promise<void>;
};

const ContextMenu: React.FC<Props> = React.memo(
  ({
    borderColor,
    top,
    left,
    onClickContextMenuOpenSettings,
    onClickContextMenuAddChild,
    onClickContextMenuAddTaskBelow,
    onClickContextMenuAddTaskAbove,
  }) => {
    const { color, node, icon, helpBgColor } = useStyles();
    const { t: c } = useTranslation("command");
    const boxShadow = node.boxShadow ? node.boxShadow : `0 0 10px ${color}`;

    const onClickModal = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      // stop onClickBackground
      event.stopPropagation();
    };

    const onMouseDown = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation();
    };

    return (
      <Modal
        top={top}
        left={left}
        color={color}
        bgColor={helpBgColor}
        borderColor={borderColor}
        boxShadow={boxShadow}
        onClick={onClickModal}
      >
        <ContextMenuItem
          color={icon.color}
          hoverColor={icon.hoverColor}
          onClick={onClickContextMenuOpenSettings}
          onMouseDown={onMouseDown}
        >
          {c("openTotalSettings")}
        </ContextMenuItem>
        <ContextMenuItem
          color={icon.color}
          hoverColor={icon.hoverColor}
          onClick={onClickContextMenuAddChild}
          onMouseDown={onMouseDown}
        >
          {c("addChild")}
        </ContextMenuItem>
        <ContextMenuItem
          color={icon.color}
          hoverColor={icon.hoverColor}
          onClick={onClickContextMenuAddTaskBelow}
          onMouseDown={onMouseDown}
        >
          {c("addBelow")}
        </ContextMenuItem>
        <ContextMenuItem
          color={icon.color}
          hoverColor={icon.hoverColor}
          onClick={onClickContextMenuAddTaskAbove}
          onMouseDown={onMouseDown}
        >
          {c("addAbove")}
        </ContextMenuItem>
      </Modal>
    );
  }
);
ContextMenu.displayName = "ContextMenu";

type ModalProps = {
  top: number;
  left: number;
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
};

const Modal = styled.div<ModalProps>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: ${taskVerticalPadding}px ${taskHorizontalPadding}px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  box-shadow: ${({ boxShadow }) => boxShadow};

  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: ${contextMenuZIndex};
`;

const ContextMenuItem = styled.div<{ color: string; hoverColor: string }>`
  color: ${({ color }) => color};
  padding: 4px 8px;
  text-align: center;
  cursor: pointer;
  :hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export default ContextMenu;
