import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Typography from "../../components/Typography";
import Table from "../../components/Table";
import Icon from "../../components/Button/Icon";
import { FlexColumnCenter } from "../../components/StyledComponents";
import Context from "../../context";
import MapContext from "./context";
import useStyles from "../../hooks/useStyles";
import {
  windowPadding,
  headerFullHeight,
  sideMenuFullWidth,
  toolboxIconSize,
  helpZIndex,
} from "../../constants";

const Help: React.FC = React.memo(() => {
  // hooks
  const { t: h } = useTranslation("help");
  const { t: c } = useTranslation("command");
  const { t: w } = useTranslation("windows");
  const { t: m } = useTranslation("mac");
  const { t: s } = useTranslation("safari");
  const { color, helpBgColor } = useStyles();

  const { isMac, isSafari } = useContext(Context);
  const command = isSafari ? s : isMac ? m : w;
  const { isHelpOpen, setIsHelpOpen } = useContext(MapContext);
  const closeHelp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsHelpOpen(false);
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const onMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  if (isHelpOpen) {
    return (
      <Container
        scrollColor={color}
        bgColor={helpBgColor}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <Content>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {c("move")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("move")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("addTask")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("addChild")}</td>
                  <td>:</td>
                  <td>{h("child")}</td>
                </tr>
                <tr>
                  <td>{command("addBelow")}</td>
                  <td>:</td>
                  <td>{h("below")}</td>
                </tr>
                <tr>
                  <td>{command("addAbove")}</td>
                  <td>:</td>
                  <td>{h("above")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {c("edit")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("editWithMouse")}</td>
                  <td>:</td>
                  <td>{h("edit")}</td>
                </tr>
                <tr>
                  <td>Enter</td>
                  <td>:</td>
                  <td>{h("finish")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {c("doneUndone")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>Space</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("changeOrder")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("moveUp")}</td>
                  <td>:</td>
                  <td>{c("moveUp")}</td>
                </tr>
                <tr>
                  <td>{command("moveDown")}</td>
                  <td>:</td>
                  <td>{c("moveDown")}</td>
                </tr>
                <tr>
                  <td>{command("moveToTop")}</td>
                  <td>:</td>
                  <td>{c("moveToTop")}</td>
                </tr>
                <tr>
                  <td>{command("moveToBottom")}</td>
                  <td>:</td>
                  <td>{c("moveToBottom")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("selectMultipleTasks")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("shiftSelect")}</td>
                </tr>
                <tr>
                  <td>{command("ctrlClick")}</td>
                </tr>
                <tr>
                  <td>{command("shiftClick")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("deleteTask")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("delete")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("copyCutPasteTasks")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("copy")}</td>
                  <td>:</td>
                  <td>{c("copy")}</td>
                </tr>
                <tr>
                  <td>{command("cut")}</td>
                  <td>:</td>
                  <td>{c("cut")}</td>
                </tr>
                <tr>
                  <td>{command("paste")}</td>
                  <td>:</td>
                  <td>{c("paste")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("openTaskSettings")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("openAssigning")}</td>
                  <td>:</td>
                  <td>{c("openAssigning")}</td>
                </tr>
                <tr>
                  <td>{command("openSettingDueDate")}</td>
                  <td>:</td>
                  <td>{c("openSettingDueDate")}</td>
                </tr>
                <tr>
                  <td>{command("openLabeling")}</td>
                  <td>:</td>
                  <td>{c("openLabeling")}</td>
                </tr>
                <tr>
                  <td>{command("rightClick")}</td>
                  <td>:</td>
                  <td>{c("openTotalSettings")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {c("openFilter")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("openFilter")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {c("onlyText")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("onlyText")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
          <Rows>
            <Typography variant="medium" bold margin="0px 0px 4px 0px">
              {h("changeFontSize")}
            </Typography>
            <Table>
              <tbody>
                <tr>
                  <td>{command("incrementFontSize")}</td>
                  <td>:</td>
                  <td>{h("incrementFontSize")}</td>
                </tr>
                <tr>
                  <td>{command("decrementFontSize")}</td>
                  <td>:</td>
                  <td>{h("decrementFontSize")}</td>
                </tr>
              </tbody>
            </Table>
          </Rows>
        </Content>
        <IconWrapper>
          <Icon
            icon="clear"
            size={toolboxIconSize}
            selected={false}
            onClick={closeHelp}
          />
        </IconWrapper>
      </Container>
    );
  } else {
    return <></>;
  }
});
Help.displayName = "Help";

const Container = styled.div<{ scrollColor: string; bgColor: string }>`
  height: calc(100vh - ${(windowPadding + headerFullHeight) * 2}px);
  width: 100vw;
  background-color: ${({ bgColor }) => bgColor};
  padding: ${windowPadding + headerFullHeight}px 0px;
  position: fixed;
  top: 0;
  left: 0;
  cursor: default;
  overflow: auto;
  z-index: ${helpZIndex};

  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ scrollColor }) =>
      scrollColor}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
`;

const Content = styled(FlexColumnCenter)`
  > div {
    margin-bottom: 20px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

const Rows = styled(FlexColumnCenter)``;

const IconWrapper = styled.div`
  position: fixed;
  top: ${windowPadding + headerFullHeight}px;
  right: ${windowPadding + sideMenuFullWidth + 50}px;
`;

export default Help;
