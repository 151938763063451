import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexRowCenter } from "../../../components/StyledComponents";
import { User } from "../../../models/User";
import useStyles from "../../../hooks/useStyles";

type Props = {
  focused: boolean;
  user: User;
  assigneeId: string;
  members: User[];
  onChangeAssignee: (assignee: User | null) => void;
};

const Members: React.FC<Props> = React.memo(
  ({ focused, user, assigneeId, members, onChangeAssignee }) => {
    // hooks
    const { t: c } = useTranslation("common");
    const { t: a } = useTranslation("assignee");
    const { filter } = useStyles();
    const boxShadow = filter.item.boxShadow
      ? filter.item.boxShadow
      : `0 0 10px ${filter.item.borderColor}`;

    const onClickNoOne = () => {
      onChangeAssignee(null);
    };
    const onClickMe = () => {
      onChangeAssignee(user);
    };
    const onClickMember = (member: User) => {
      onChangeAssignee(member);
    };

    return (
      <Container>
        <Member
          focused={focused}
          selected={assigneeId === ""}
          color={filter.item.color}
          selectedColor={filter.item.selected.color}
          bgColor={filter.item.bgColor}
          selectedBgColor={filter.item.selected.bgColor}
          borderColor={filter.item.borderColor}
          boxShadow={boxShadow}
          onClick={onClickNoOne}
        >
          {c("none")}
        </Member>
        <Member
          focused={focused}
          selected={assigneeId === user.userId}
          color={filter.item.color}
          selectedColor={filter.item.selected.color}
          bgColor={filter.item.bgColor}
          selectedBgColor={filter.item.selected.bgColor}
          borderColor={filter.item.borderColor}
          boxShadow={boxShadow}
          onClick={onClickMe}
        >
          {a("me")}
        </Member>
        {members.map((m) => (
          <Member
            key={m.userId}
            focused={focused}
            selected={m.userId === assigneeId}
            color={filter.item.color}
            selectedColor={filter.item.selected.color}
            bgColor={filter.item.bgColor}
            selectedBgColor={filter.item.selected.bgColor}
            borderColor={filter.item.borderColor}
            boxShadow={boxShadow}
            onClick={() => onClickMember(m)}
          >
            {m.name}
          </Member>
        ))}
      </Container>
    );
  }
);
Members.displayName = "Members";

const Container = styled.div`
  width: 100%;

  padding: 4px 8px;
  margin: 4px 0px 2px 0px;

  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  user-select: none;

  > div {
    margin-right: 6px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

type ContainerProps = {
  focused: boolean;
  selected: boolean;
  color: string;
  selectedColor: string;
  bgColor: string;
  selectedBgColor: string;
  borderColor: string;
  boxShadow: string;
};
const Member = styled(FlexRowCenter)<ContainerProps>`
  color: ${({ selected, color, selectedColor }) =>
    selected ? selectedColor : color};
  background-color: ${({ selected, bgColor, selectedBgColor }) =>
    selected ? selectedBgColor : bgColor};
  border: solid 1px ${({ borderColor }) => borderColor};
  border-radius: 8px;
  box-shadow: ${({ focused, selected, boxShadow }) =>
    focused && selected ? boxShadow : ""};
  padding: 4px 8px;
  cursor: pointer;

  transition: color 0.1s ease-out, background-color 0.1s ease-out,
    border 0.1s ease-out, box-shadow 0.1s ease-out;

  :hover {
    color: ${({ selectedColor }) => selectedColor};
    background-color: ${({ selectedBgColor }) => selectedBgColor};
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

export default Members;
