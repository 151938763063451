import { User } from "../../models/User";
import { ITask, Task } from "../../models/Task";

export const blSetTasks = (
  tasks: Task[],
  updatedTasks: ITask[],
  members: User[]
): Task[] => {
  /**
   * 共同編集時にバグる可能性のあるパターン
   * (思いつく限り)
   *
   * 1. ほぼ同時にノードを追加して sortNumber が被る
   *    これは
   *    - blAddSibling
   *    - blMoveToEdge
   *    - blSwitchTasks
   *    の中で吸収していくしかない
   * 2. 子供を追加・編集しているうちに先祖が消される
   *    これは blSetTasks の中で
   *    存在できなくなったタスクを消すしかない
   */
  let tempSettingTasks = tasks.slice();
  // updatedTasks: ITask[] -> Task[] slice で切り出す
  const updatingTasks = updatedTasks.slice();
  // 更新が古い順にソート
  updatingTasks.sort((a, b) => {
    if (a.updatedAt && b.updatedAt && a.updatedAt < b.updatedAt) {
      return -1;
    } else {
      return 1;
    }
  });
  for (const updatingTask of updatingTasks) {
    switch (updatingTask.changeType) {
      case "added": {
        const addedTask = tempSettingTasks.find(
          (t) => t.id === updatingTask.id
        );
        if (addedTask) {
          // 念のため存在確認してあればマージする
          addedTask.level = updatingTask.level;
          addedTask.sortNumber = updatingTask.sortNumber;
          // parent, children, assignee は後で設定する
          addedTask.text = updatingTask.text;
          addedTask.done = updatingTask.done;
          addedTask.doneBy = updatingTask.doneBy;
          addedTask.doneAt = updatingTask.doneAt;
          addedTask.dueDate = updatingTask.dueDate;
          addedTask.labels = updatingTask.labels;
        } else {
          Array.prototype.push.apply(tempSettingTasks, [
            iTaskToTask(updatingTask, tempSettingTasks, members),
          ]);
        }
        break;
      }
      case "modified": {
        const modifiedTask = tempSettingTasks.find(
          (t) => t.id === updatingTask.id
        );
        if (modifiedTask) {
          modifiedTask.level = updatingTask.level;
          modifiedTask.sortNumber = updatingTask.sortNumber;
          // parent, children, assignee は後で設定する
          modifiedTask.text = updatingTask.text;
          modifiedTask.done = updatingTask.done;
          modifiedTask.doneBy = updatingTask.doneBy;
          modifiedTask.doneAt = updatingTask.doneAt;
          modifiedTask.dueDate = updatingTask.dueDate;
          modifiedTask.labels = updatingTask.labels;
        } else {
          // 修正にもかかわらずタスクがない場合
        }
        break;
      }
      case "removed":
        tempSettingTasks = tempSettingTasks.filter(
          (t) => t.id !== updatingTask.id
        );
        break;
      default:
        break;
    }
  }
  /**
   * updatingTasks に対して
   * 1. parent
   * 2. children
   * 3. assignee
   * をセットしていく
   */
  for (const updatingTask of updatingTasks) {
    if (updatingTask.changeType === "removed") continue;

    const cTask = tempSettingTasks.find((t) => t.id === updatingTask.id);
    if (!cTask) continue;

    // parent
    const newParent = tempSettingTasks.find(
      (t) => t.id === updatingTask.parentId
    );
    if (newParent) {
      cTask.parent = newParent;
    } else {
      // parent がいない
      // おそらく追加・編集中に先祖が削除されたパターン
      // 後で削除できるのでここでは何もしない
    }
    // children
    const newChildren: Task[] = [];
    for (const childId of updatingTask.childrenIds) {
      const newChild = tempSettingTasks.find((t) => t.id === childId);
      if (newChild) {
        newChildren.push(newChild);
      } else {
        // 子供が見つからない場合は特にバグなどは引き起こさない
        // children に加えなければ更新時に自動的に見つからなかった子供が削除される
      }
    }
    cTask.children = newChildren;
    // assignee
    if (updatingTask.assigneeId) {
      const assginee = members.find(
        (m) => m.userId === updatingTask.assigneeId
      );
      if (assginee) {
        cTask.assignee = assginee;
      } else {
        // assignee が見つからない場合
        // assignee に加えなければ更新時に自動的に反映されるのでそのまま
      }
    }
  }
  /**
   * tempSettingTasks を level === 1 から昇順に
   * 親が見つからないものを除いていく
   * そうすることで、
   * 1. 追加中
   * 2. 編集中
   * に先祖が削除されたものを除く
   * level 1 から昇順なのは、複数のレベルをまたいで削除されると
   * 親の children にも子供の parent にも問題ないタスクが存在しうるから
   */
  const maxLevel = Math.max(...tempSettingTasks.map((t) => t.level));
  const settingTasks: Task[] = [];
  // level 1
  const level1Task = tempSettingTasks.find((t) => t.level === 1);
  level1Task && settingTasks.push(level1Task);
  // level 2 以降
  for (let level = 2; level <= maxLevel; level++) {
    const levelTasks = tempSettingTasks.filter((t) => t.level === level);
    for (const levelTask of levelTasks) {
      const parent = settingTasks.find((t) => t.id === levelTask.parentId);
      if (parent) {
        if (parent.childrenIds.includes(levelTask.id)) {
          settingTasks.push(levelTask);
        } else {
          // 親が子供を持っていない
        }
      } else {
        // 親が削除されている
      }
    }
  }

  // 不整合が起きて選択状態のタスクが亡くなってしまった場合の対応
  if (!settingTasks.some((t) => t.selected) && level1Task) {
    level1Task.selected = true;
  }

  return settingTasks;
};

const iTaskToTask = (iTask: ITask, tasks: Task[], members: User[]): Task => {
  const cTask: Task = new Task(
    iTask.id,
    iTask.level,
    iTask.sortNumber,
    null,
    [],
    iTask.text,
    iTask.done,
    iTask.doneBy,
    iTask.doneAt,
    null,
    iTask.dueDate,
    iTask.labels
  );
  if (iTask.parentId) {
    const parent = tasks.find((t) => t.id === iTask.parentId);
    cTask.parent = parent ?? null;
  }
  for (const childId of iTask.childrenIds) {
    const child = tasks.find((t) => t.id === childId);
    child && cTask.children.push(child);
  }
  if (iTask.assigneeId) {
    const assignee = members.find((m) => m.userId === iTask.assigneeId);
    assignee && (cTask.assignee = assignee);
  }
  return cTask;
};
