import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SettingContainer from "../components/SettingContainer";
import Typography from "../components/Typography";
import {
  freePlanMaxProjectCount,
  freePlanMaxTaskCount,
  freePlanMaxLabelCount,
  freePlanMaxMemberCount,
} from "../constants";

const Home: React.FC = () => {
  // hooks
  const { t: h } = useTranslation("news");
  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {h("news")}
      </Typography>
      <Typography variant="medium" bold margin="12px 0px 16px 0px">
        {new Date(2021, 5, 16).toLocaleDateString()}
      </Typography>
      <Typography variant="small" margin="0px 0px 8px 0px">
        {h("message1")}
      </Typography>
      <Typography variant="small" margin="0px 0px 0px 0px">
        {h("message2")}
      </Typography>
      <Typography variant="medium" bold margin="16px 0px 16px 0px">
        {h("freePlanLimit")}
      </Typography>
      <Table>
        <tbody>
          <tr>
            <th>{h("projects")}</th>
            <td>{freePlanMaxProjectCount}</td>
          </tr>
          <tr>
            <th>{h("tasks")}</th>
            <td>
              {freePlanMaxTaskCount} / {h("project")}
            </td>
          </tr>
          <tr>
            <th>{h("labels")}</th>
            <td>
              {freePlanMaxLabelCount} / {h("project")}
            </td>
          </tr>
          <tr>
            <th>{h("collaborationMembers")}</th>
            <td>
              {freePlanMaxMemberCount} / {h("project")}
            </td>
          </tr>
        </tbody>
      </Table>
    </SettingContainer>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  th,
  td {
    padding: 8px 16px;
    border: solid 1px;
    text-align: center;
  }
`;

export default Home;
