import { Language } from "./models/Language";
import { Visibility } from "./models/Project";

export const logoChars = ["T", "o", "D", "o", "M", "a", "p"];
export const firebase401Message = "Missing or insufficient permissions.";
export const firebase404Message = "Requested entity was not found.";

// plan
export const freePlanMaxProjectCount = 10;
export const freePlanMaxTaskCount = 300;
export const freePlanMaxLabelCount = 10;
export const freePlanMaxMemberCount = 3;
export const stripeKey =
  "pk_test_51Ije6tHDZqH2uDVxEy7BB12s43GbaXphsflHTeucv8HpHsRy4N96L9wrSyJdw3WTGzfW7YAvXH1nbvYEo3dN1bOy00FqFubqdW";

// font-siee
export const defaultFontSize = 14;

// mode
export const baseBorderRadius = 12;

// visibility
export const defaultVisibility: Visibility = {
  assignee: true,
  dueDate: true,
  label: true,
  filterHelp: true,
};

// language
export const defaultLanguage = "en" as Language;
export const languages = ["en", "ja", "ko"] as Language[];

// device
export const windowPadding = 16;

// header
export const logoTop = windowPadding;
export const headerHeihgt = 50;
const headerButtonMarginRight = 10;
export const logoLeft = windowPadding + headerHeihgt + headerButtonMarginRight;
export const accountTop = windowPadding;
export const accountRight = windowPadding;
export const headerMarginBottom = windowPadding;
export const headerFullHeight = headerHeihgt + headerMarginBottom;

// Side menu
export const sideMenuFullWidth = 250;
export const sideMenuPadding = 8;
export const sideMenuWidth = sideMenuFullWidth - sideMenuPadding * 2;
export const sideMenuMargin = windowPadding;

// ProjectList
export const projectListTop = windowPadding + headerHeihgt + headerMarginBottom;
export const projectListLeft = windowPadding;

// SettingMenu
export const settingMenuTop = windowPadding + headerHeihgt + headerMarginBottom;
export const settingMenuRight = windowPadding;

// Toolbox
export const toolboxHeight = 40;
export const toolboxIconSize = toolboxHeight - 10; // padding + border
export const toolboxMarginTop = windowPadding;
const toolboxFullHeight = toolboxHeight + toolboxMarginTop;
export const toolboxBottom = windowPadding;
export const toolboxRight = windowPadding;

// ActiveMembers
export const activeMemberLabelHeight = 14;
export const activeMembersTop = windowPadding;
export const activeMembersRight = windowPadding + headerHeihgt + 20;

// Filter
export const filterBottom = windowPadding + toolboxFullHeight;
export const filterRight = windowPadding;

// FilterItems
export const labelItemsBottom = windowPadding;
export const labelItemsLeft = windowPadding;

// Snackbar
const snackbarMargin = 10;
export const snackbarTop = windowPadding + snackbarMargin;
export const snackbarRight = windowPadding + snackbarMargin;

// task
export const taskWidth = 200;
const checkboxWidth = 28;
export const taskFullWidth = taskWidth + checkboxWidth;
export const taskVerticalPadding = 5;
export const taskHorizontalPadding = 5;
export const taskMargin = 12;
export const regLink = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

// line
export const lineWidth = 50;
export const lineStrokeWidth = 2;

// calendar
export const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
export const defaultFirstDay = 1;

// z-index
export const lineZIndex = 1000;
export const taskZIndex = 1001;
export const activeMemberLabelZIndex = taskZIndex + 1;
export const editingZIndex = activeMemberLabelZIndex + 1;
export const filterItemsZIndex = editingZIndex + 1;
export const toolboxZIndex = filterItemsZIndex;
export const helpZIndex = toolboxZIndex + 1;
export const contextMenuZIndex = 1997;
// メニューが被っていると押せなくなるので floatItemZIndex より下
export const clickToFocusZIndex = 1998;
export const floatItemZIndex = 1999;
// フィルターが設定メニューに被る場合があるので floatItemZIndex より上
export const filterZIndex = 2000;
export const modalBackgroundZIndex = 2001;

// tabIndex
export const mindmapTabIndex = 1;
export const taskSettingTabIndex = mindmapTabIndex + 1;
export const filterTabIndex = taskSettingTabIndex + 1;

// label
export const defaultLabelColor = "#ffffff";

// settings
export const policyUpdatedAt = new Date(2021, 5, 16);
