import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Language as LanguageIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  FlexColumnCenter,
  FlexRowCenter,
} from "../../components/StyledComponents";
import { Language } from "../../models/Language";
import Context from "../../context";
import useStyles from "../../hooks/useStyles";
import { languages, baseBorderRadius } from "../../constants";

const SelectLanguage: React.FC = React.memo(() => {
  // context
  const { language, setLanguage } = useContext(Context);
  // hooks
  const { t: lng } = useTranslation("language");
  const { palette, top, menuItem, filter } = useStyles();
  // states
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnter = () => {
    setIsOpen(true);
  };
  const onMouseLeave = () => {
    setIsOpen(false);
  };

  const onChangeValue = (
    event: React.MouseEvent<HTMLDivElement>,
    value: Language
  ) => {
    event.stopPropagation();
    setLanguage(value);
    setIsOpen(false);
  };

  return (
    <Container>
      <Select isOpen={isOpen} color={top.color} onMouseEnter={onMouseEnter}>
        <LanguageIcon style={{ marginRight: 2 }} />
        {lng(language)}
      </Select>
      {isOpen && (
        <Options
          bgColor={filter.bgColor}
          borderColor={filter.borderColor}
          onMouseLeave={onMouseLeave}
        >
          {languages.map((l, i) => (
            <Option
              key={l}
              color={menuItem.color}
              bgColor="transparent"
              hoverColor={menuItem.selected.color}
              hoverBgColor={
                menuItem.selected.mode === "palette"
                  ? palette[i % palette.length]
                  : menuItem.selected.bgColor
              }
              onClick={(e) => onChangeValue(e, l)}
            >
              {lng(l)}
            </Option>
          ))}
        </Options>
      )}
    </Container>
  );
});
SelectLanguage.displayName = "SelectLanguage";

const Container = styled.div`
  width: 125px;
  position: relative;

  display: flex;
  align-items: center;

  transition: display 200ms ease-out;
`;

type SelectProps = {
  isOpen: boolean;
  color: string;
};
const Select = styled(FlexRowCenter)<SelectProps>`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ isOpen, color }) => (isOpen ? "transparent" : color)};
  padding: 4px 12px;
  margin-left: 10px;
  cursor: pointer;
`;

type OptionsProps = {
  bgColor: string;
  borderColor: string;
};
const Options = styled(FlexColumnCenter)<OptionsProps>`
  width: 125px;
  background-color: ${({ bgColor }) => bgColor};
  padding: 6px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  position: absolute;
  bottom: 0px;
  left: 10px;
  align-items: stretch;

  > div {
    margin-bottom: 6px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

type OptionProps = {
  color: string;
  bgColor: string;
  hoverColor: string;
  hoverBgColor: string;
};
const Option = styled.div<OptionProps>`
  text-align: center;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};

  padding: 4px 12px;
  border-radius: ${baseBorderRadius}px;
  cursor: pointer;

  :hover {
    color: ${({ hoverColor }) => hoverColor};
    background-color: ${({ hoverBgColor }) => hoverBgColor};
  }
`;

export default SelectLanguage;
