import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ComponentTask from "./Task";
import ComponentLine from "../../projects/Line";
import ComponentActiveMembersLabel from "../../projects/ActiveMembersLabel";
import FilteringItems from "../../../components/FilteringItems";
import Filter from "../../../components/Filter";
import ContextMenu from "../../../components/ContextMenu";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import ActiveMembersComponent from "../../projects/ActiveMembers";
import {
  setTaskTop,
  setTaskTopWithFilter,
  createLines,
} from "../../../bl/organize";
import { blSetTasks } from "../../../bl/task/set";
import { setTaskLeft } from "../bl";
import { blAddChild, blAddSibling } from "../../../bl/task/add";
import { blDeleteTask } from "../../../bl/task/delete";
import { blMoveToEdge, blSwitchTasks } from "../../../bl/task/move";
import { blPasteCopiedTask } from "../../../bl/task/copy";
import { blPasteCutTask, blOutdentTasks } from "../../../bl/task/cut";
import {
  getClosestAboveTask,
  getClosestBelowTask,
  getClosestRightTask,
  getClosestLeftTask,
  getElderTask,
  getYoungerTask,
} from "../../../bl/task/select";
import {
  setPassedFilter,
  getSelectingTaskIdInFilteredTasks,
} from "../../../bl/task/filter";
import { User } from "../../../models/User";
import { ActiveMember } from "../../../models/ActiveMember";
import { ActiveMembersLabel } from "../../../models/ActiveMembersLabel";
import { ITask, UpdateTask, Task } from "../../../models/Task";
import { Line } from "../../../models/Line";
import { Label, LabelItem, TaskLabel } from "../../../models/Label";
import { FilterDueDate, FilterStatus } from "../../../models/Filter";
import { SettingCategory } from "../../../models/Setting";
import Context from "../../../context";
import useStyles from "../../../hooks/useStyles";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  defaultVisibility,
  activeMemberLabelHeight,
  clickToFocusZIndex,
} from "../../../constants";
import {
  firstDay,
  mindmapTopPadding,
  mindmapBottomPadding,
  mindmapHorizontalPadding,
  recMargin,
  acitionItemsMargin,
} from "../constants";

type Props = {
  isLeft: boolean;
  focusTrigger: number;
  isGrabbingMindmap: boolean;
  user: User | null;
  members: User[];
  activeMembers: ActiveMember[];
  labels: Label[];
  updatedTasks: ITask[];
  resetUpdatedTasks: () => void;
  batchEditTasks: (
    userId: string,
    tempUserId: string,
    addingTasks: Task[],
    updatingTasks: Task[],
    deletingTasks: Task[],
    currentTaskId: string
  ) => void;
  updateCurrentTask: (userId: string, currentTaskId: string) => void;
  onMindmapMouseDown: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onMindmapMouseMove: (pageX: number, pageY: number) => void;
  onMindmapMouseUp: () => void;
  onMindmapMouseOut: () => void;
  adjustScroll: (target: Task) => void;
};

const Mindmap: React.FC<Props> = ({
  isLeft,
  focusTrigger,
  isGrabbingMindmap,
  user,
  members,
  activeMembers,
  labels,
  updatedTasks,
  resetUpdatedTasks,
  batchEditTasks,
  updateCurrentTask,
  onMindmapMouseDown,
  onMindmapMouseMove,
  onMindmapMouseUp,
  onMindmapMouseOut,
  adjustScroll,
}) => {
  // context
  const { language } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { palette, color, node } = useStyles();
  const {
    setAddedInvisibleTaskMessage,
    setDifferentParentMessage,
    setCannotDeleteMessage,
  } = useSnackbar();
  // ref
  const mindmapElm = useRef<HTMLDivElement>(null);

  // states
  const [activeMembersLabels, setActiveMembersLabels] = useState<
    ActiveMembersLabel[]
  >([]);
  const [tasks, setOriginalTasks] = useState<Task[]>([]);
  const [displayTasks, setDisplayTasks] = useState<Task[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const [shiftMoveStartTask, setShiftMoveStartTask] = useState<Task | null>(
    null
  );
  const [
    nextTaskIdAfterDeletingNewTask,
    setNextTaskIdAfterDeletingNewTask,
  ] = useState("");
  const [isSettingsVisible, setIsSettingsVisible] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [focusFilterCounter, setForceFocusFilter] = useState(0);
  const [filterMember, setFilterMember] = useState<User | null>(null);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>("all");
  const [filterDueDate, setFilterDueDate] = useState<FilterDueDate>("none");
  const [filterLabelItems, setFilterLabelItems] = useState<LabelItem[]>([]);
  const [forceOrganizeCounter, setForceOrganizeCounter] = useState(0);
  // Context menu
  // コンテキストメニューの isOpen も兼ねている
  const [contextMenuTask, setContextMenuTask] = useState<Task | null>(null);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);
  // computed
  const tempUserId = user ? user.userId : "";
  const isFiltering =
    filterMember ||
    filterStatus !== "all" ||
    filterDueDate !== "none" ||
    filterLabelItems.length
      ? true
      : false;

  // set tasks / task
  const setTasks = (newTasks: Task[]) => {
    if (updatedTasks.length) {
      const updatingTasks = updatedTasks.filter(
        (t) => t.updatedBy !== tempUserId
      );
      if (updatingTasks.length) {
        const settingTasks = blSetTasks(newTasks, updatedTasks, members);
        setOriginalTasks(settingTasks);
      } else {
        setOriginalTasks(newTasks);
      }
      resetUpdatedTasks();
    } else {
      setOriginalTasks(newTasks);
    }
  };
  const setTask = (task: Task): void => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  // Filter
  const openOrFocusFilter = () => {
    setIsFilterOpen(true);
    setForceFocusFilter(focusFilterCounter + 1);
  };
  const closeFilter = () => {
    setIsFilterOpen(false);
    focusMindmap();
  };
  const changeFilterMember = (member: User | null) => {
    setFilterMember(member);
  };
  const changeFilterStatus = (status: FilterStatus) => {
    setFilterStatus(status);
  };
  const changeFilterDueDate = (dueDate: FilterDueDate) => {
    setFilterDueDate(dueDate);
  };
  const toggleFilterLabelItem = (item: LabelItem) => {
    if (filterLabelItems.some((i) => i.labelItemId === item.labelItemId)) {
      setFilterLabelItems(
        filterLabelItems.filter((i) => i.labelItemId !== item.labelItemId)
      );
    } else {
      setFilterLabelItems([...filterLabelItems, item]);
    }
  };
  const resetFilter = () => {
    setFilterMember(null);
    setFilterStatus("all");
    setFilterDueDate("none");
    setFilterLabelItems([]);
  };

  // Force Organize
  const organize = () => {
    setForceOrganizeCounter(forceOrganizeCounter + 1);
  };

  // Ref & Focus
  const focusMindmap = () => {
    mindmapElm.current && mindmapElm.current.focus();
  };
  const isClickToFocusDisabled =
    isFilterOpen ||
    tasks.some(
      (t) =>
        t.isEditing ||
        t.isSetting ||
        t.isAssigning ||
        t.isSettingDueDate ||
        t.isLabeling
    );

  useEffect(() => {
    if (isLeft) {
      focusMindmap();
    }
  }, [focusTrigger]);

  useEffect(() => {
    setOriginalTasks([]);
    setDisplayTasks([]);
    setLines([]);
    setActiveMembersLabels([]);
    resetFilter();
    setIsSettingsVisible(true);
    closeFilter();
  }, [language]);

  useEffect(() => {
    if (!updatedTasks.length) return;
    setTasks(tasks);
  }, [updatedTasks]);

  const [renewActiveMembersCounter, setRenewActiveMembersCounter] = useState(0);

  useEffect(() => {
    if (!user) return;
    const otherMembers = activeMembers.filter((m) => m.userId !== user.userId);
    const amLabels: ActiveMembersLabel[] = [];
    for (const otherMember of otherMembers) {
      if (!otherMember.currentTaskId) continue;
      const task = displayTasks.find((t) => t.id === otherMember.currentTaskId);
      if (!task) continue;
      const label = amLabels.find((l) => l.taskId === task.id);
      if (label) {
        label.activeMembers.push(otherMember);
      } else {
        const label: ActiveMembersLabel = {
          taskId: task.id,
          activeMembers: [otherMember],
          top: task.top - activeMemberLabelHeight + 1,
          left: task.left + 2,
        };
        amLabels.push(label);
      }
    }
    setActiveMembersLabels(amLabels);
  }, [activeMembers, renewActiveMembersCounter]);

  const renewActiveMembers = () => {
    setRenewActiveMembersCounter(renewActiveMembersCounter + 1);
  };

  const updateSelectedTaskId = async (taskId: string) => {
    if (!user || !taskId) return;
    updateCurrentTask(user.userId, taskId);
  };

  useEffect(() => {
    filterTasks();
  }, [tasks, filterMember, filterStatus, filterDueDate, filterLabelItems]);

  const filterTasks = () => {
    if (isFiltering) {
      setPassedFilter(
        tasks,
        filterMember,
        filterStatus,
        filterDueDate,
        firstDay,
        filterLabelItems
      );
      const newFilteredTasks = tasks.filter((t) => t.passedFilterWithFamily);
      const selectedTask = tasks.find((t) => t.selected);
      if (selectedTask && selectedTask.passedFilterWithFamily) {
        // 選択されていたノードが表示されているなら何もしない
      } else {
        // 選択されていたノードが表示されない場合
        const level1Task = newFilteredTasks.find((t) => t.level === 1);
        if (level1Task) {
          if (level1Task.filteredChildren.length) {
            // Level1が表示される子供を持っているなら座標上で一番上のノードを選択状態にする
            const selectingId = getSelectingTaskIdInFilteredTasks(level1Task);
            if (selectingId) {
              // tasks 全体で管理しないと選択状態のノードが複数出てきてしまう
              for (const task of tasks) {
                task.selected = task.id === selectingId;
              }
              // 直前の useEffect で tasks を引数に入れてないので問題ないはず
              setTasks(tasks);
            }
          } else {
            // 表示するタスクがないので、Level1 のタスクを選択状態にする
            for (const task of tasks) {
              task.selected = task.level === 1;
            }
            setTasks(tasks);
          }
        }
      }
      setDisplayTasks(newFilteredTasks);
    } else {
      setDisplayTasks(tasks);
    }
    organize();
  };

  useEffect(() => {
    const level1Task = tasks.find((t) => t.level === 1);
    if (!level1Task) return;
    if (isFiltering) {
      setTaskTopWithFilter(mindmapTopPadding, level1Task);
    } else {
      setTaskTop(mindmapTopPadding, level1Task);
    }
    setTaskLeft(tasks);
    setLines(createLines(displayTasks));

    // 順序はどうでもいいので最後
    renewActiveMembers();
    setMindmapSize();
  }, [forceOrganizeCounter]);

  const setMindmapSize = () => {
    const height =
      Math.max(...displayTasks.map((t) => t.bottom)) + mindmapBottomPadding;
    const width =
      Math.max(...displayTasks.map((t) => t.right)) + mindmapHorizontalPadding;
    if (mindmapElm.current) {
      mindmapElm.current.style.height = height + "px";
      mindmapElm.current.style.width = width + "px";
    }
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onMindmapMouseMove(event.pageX, event.pageY);
  };

  // Update
  const updateTask = (task: Task, updatingTasks: Task[]): void => {
    if (!user || !tempUserId) return;

    batchEditTasks(user.userId, tempUserId, [], updatingTasks, [], task.id);
    // 更新が確認できてからローカルを更新
    setTask(task);
  };
  const updateTasks = (
    newTasks: Task[],
    addingTasks: Task[],
    updatingTasks: Task[],
    selectingTaskId: string
  ): void => {
    if (!user || !tempUserId) return;

    batchEditTasks(
      user.userId,
      tempUserId,
      addingTasks,
      updatingTasks,
      [],
      selectingTaskId
    );
    // 更新が確認できてからローカルを更新
    setTasks(newTasks);
  };

  const toCTasks = (allTasks: Task[], updatingTasks: UpdateTask[]) => {
    const cTasks: Task[] = [];
    for (const updatingTask of updatingTasks) {
      const cTask = allTasks.find((t) => t.id === updatingTask.id);
      if (!cTask) continue;
      const {
        level,
        sortNumber,
        parentId,
        childrenIds,
        text,
        done,
        assigneeId,
        dueDate,
        labels,
      } = updatingTask.params;
      if (level !== undefined) {
        cTask.level = level;
      }
      if (sortNumber !== undefined) {
        cTask.sortNumber = sortNumber;
      }
      if (parentId !== undefined) {
        const parent = allTasks.find((t) => t.id === parentId);
        parent && (cTask.parent = parent);
      }
      if (childrenIds !== undefined) {
        const children: Task[] = [];
        for (const childId of childrenIds) {
          const child = allTasks.find((t) => t.id === childId);
          child && children.push(child);
        }
        cTask.children = children;
      }
      if (text !== undefined) {
        cTask.text = text;
      }
      if (done !== undefined) {
        cTask.done = done;
      }
      if (assigneeId !== undefined) {
        const assignee = members.find((t) => t.userId === assigneeId);
        assignee && (cTask.assignee = assignee);
      }
      if (dueDate !== undefined) {
        cTask.dueDate = dueDate;
      }
      if (labels !== undefined) {
        cTask.labels = labels;
      }
      cTasks.push(cTask);
    }
    return cTasks;
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const task = tasks.find((t) => t.selected);
    if (!task) return;

    if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
      onKeyDownWithCtrlAndShift(task, event);
    } else if (event.ctrlKey || event.metaKey) {
      onKeyDownWithCtrl(task, event);
    } else if (event.shiftKey) {
      onKeyDownWithShift(task, event);
    } else {
      onKeyDownPlain(task, event);
    }
  };
  const onKeyDownWithCtrlAndShift = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        moveToEdge(task, "TOP");
        break;
      case "ArrowDown":
        event.preventDefault();
        moveToEdge(task, "BOTTOM");
        break;
      default:
        break;
    }
  };
  const onKeyDownWithShift = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        addSibling(task, "ABOVE");
        break;
      case "ArrowUp":
        event.preventDefault();
        shiftMove(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        shiftMove(task, "DOWN");
        break;
      default:
        break;
    }
  };
  const onKeyDownWithCtrl = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Backspace":
        event.preventDefault();
        deleteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Enter":
        event.preventDefault();
        startEditing(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "ArrowUp":
        event.preventDefault();
        switchTasks(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        switchTasks(task, "DOWN");
        break;
      case "[":
        event.preventDefault();
        outdentTasks(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "a":
        event.preventDefault();
        startAssigning(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "d":
        event.preventDefault();
        startSettingDueDate(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "l":
        event.preventDefault();
        startLabeling(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "c":
        event.preventDefault();
        copyTask();
        break;
      case "x":
        event.preventDefault();
        cutTask();
        break;
      case "v":
        event.preventDefault();
        pasteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "f":
        event.preventDefault();
        openOrFocusFilter();
        break;
      case "h":
        event.preventDefault();
        toggleIsSettingsVisible();
        break;
      default:
        break;
    }
  };
  const onKeyDownPlain = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        addSibling(task, "BELOW");
        break;
      case "F2":
        event.preventDefault();
        startEditing(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Delete":
        event.preventDefault();
        deleteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Tab":
        event.preventDefault();
        addNewChild(task);
        break;
      case " ":
        event.preventDefault();
        toggleDone(task);
        break;
      case "ArrowUp":
        event.preventDefault();
        move(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        move(task, "DOWN");
        break;
      case "ArrowLeft":
        event.preventDefault();
        move(task, "LEFT");
        break;
      case "ArrowRight":
        event.preventDefault();
        move(task, "RIGHT");
        break;
      default:
        break;
    }
  };

  // ノード追加
  const addNewChild = (task: Task) => {
    if (isFiltering && !task.passedFilter && !task.passedFilterWithAncestors) {
      // 子供の追加は自分自身か先祖がフィルター表示対象の場合のみ
      setAddedInvisibleTaskMessage();
      return;
    }

    const { newTasks } = blAddChild(tasks, task);
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
  };
  const addSibling = (task: Task, direction: "ABOVE" | "BELOW") => {
    if (isFiltering && !task.passedFilterWithAncestors) {
      setAddedInvisibleTaskMessage();
      return;
    }
    if (!task.parent) return;

    const { newTasks } = blAddSibling(tasks, task, task.parent, direction);
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);

    // 空で確定してタスクが削除された場合の戻り先
    setNextTaskIdAfterDeletingNewTask(task.id);
  };

  // ノード削除
  const deleteLocalTask = (task: Task): void => {
    if (task.level === 1 || !user || !tempUserId || !task.parent) return;

    const targetTasks = [task]
      .concat(tasks.filter((t) => t.multiSelected))
      // multiSelected と selected がダブった時のために重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTasks = targetTasks
      // 自分自身と子孫全てを含んだ配列にする
      .map((t) => [t].concat(t.allChildren))
      // 1つの配列にする
      .flat()
      // 重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTaskIds = deletingTasks.map((t) => t.id);

    if (
      activeMembers.some(
        (m) =>
          m.userId !== user.userId && deletingTaskIds.includes(m.currentTaskId)
      )
    ) {
      setCannotDeleteMessage();
      return;
    }
    const { newTasks } = blDeleteTask(
      tasks,
      targetTasks,
      deletingTaskIds,
      task.parent,
      nextTaskIdAfterDeletingNewTask,
      tempUserId
    );
    setTasks(newTasks);
  };
  const deleteTask = (task: Task): void => {
    if (task.level === 1 || !user || !tempUserId || !task.parent) return;

    const targetTasks = [task]
      .concat(tasks.filter((t) => t.multiSelected))
      // multiSelected と selected がダブった時のために重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTasks = targetTasks
      // 自分自身と子孫全てを含んだ配列にする
      .map((t) => [t].concat(t.allChildren))
      // 1つの配列にする
      .flat()
      // 重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTaskIds = deletingTasks.map((t) => t.id);

    if (
      activeMembers.some(
        (m) =>
          m.userId !== user.userId && deletingTaskIds.includes(m.currentTaskId)
      )
    ) {
      setCannotDeleteMessage();
      return;
    }

    const { newTasks, updatingTasks, selectingTaskId } = blDeleteTask(
      tasks,
      targetTasks,
      deletingTaskIds,
      task.parent,
      nextTaskIdAfterDeletingNewTask,
      tempUserId
    );
    setTasks(newTasks);

    const updatingCTasks = toCTasks(tasks, updatingTasks);
    batchEditTasks(
      user.userId,
      tempUserId,
      [],
      updatingCTasks,
      deletingTasks,
      selectingTaskId
    );
  };

  // ノード移動
  const switchTasks = (task: Task, direction: "UP" | "DOWN") => {
    if (!task.parent) return;

    const parent = task.parent;
    // フィルターによって処理が変わるのはここだけ
    const children = isFiltering ? parent.filteredChildren : parent.children;
    // フィルターの有無関係なく処理するために index で入れ替える
    const sourceIndex = children.findIndex((c) => c.id === task.id);
    if (direction === "UP" && sourceIndex === 0) return;
    if (direction === "DOWN" && sourceIndex === children.length - 1) return;

    const target =
      direction === "UP"
        ? children[sourceIndex - 1]
        : children[sourceIndex + 1];
    if (!target) return;

    const { newTasks, updatingTasks } = blSwitchTasks(
      tasks,
      task,
      target,
      parent,
      tempUserId
    );
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
    updateTasks(newTasks, [], toCTasks(tasks, updatingTasks), task.id);
  };
  const moveToEdge = (task: Task, direction: "TOP" | "BOTTOM") => {
    if (!task.parent || task.parent.children.length < 2) return;

    const parent = task.parent;
    // フィルターの有無関係なく処理するために index で入れ替える
    const sourceIndex = parent.children.findIndex((c) => c.id === task.id);
    if (direction === "TOP" && sourceIndex === 0) return;
    if (direction === "BOTTOM" && sourceIndex === parent.children.length - 1)
      return;

    const { newTasks, updatingTasks } = blMoveToEdge(
      tasks,
      task,
      parent,
      direction,
      tempUserId
    );
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
    updateTasks(newTasks, [], toCTasks(tasks, updatingTasks), task.id);
  };

  // Copy, Cut, Paste, Outdent
  const copyTask = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = t.selected || t.multiSelected;
        t.cut = false;
        return t;
      })
    );
  };
  const cutTask = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = false;
        t.cut = t.selected || t.multiSelected;
        return t;
      })
    );
  };
  const resetClipboard = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  const pasteTask = (task: Task) => {
    const copiedTasks = tasks.filter((t) => t.copied);
    const cutTasks = tasks.filter((t) => t.cut);
    if (copiedTasks.length) {
      pasteCopiedTask(task, copiedTasks);
    } else if (cutTasks.length) {
      pasteCutTask(task, cutTasks);
    } else {
      resetClipboard();
    }
  };
  const pasteCopiedTask = (target: Task, copiedTasks: Task[]) => {
    const {
      newTasks,
      addingTasks,
      updatingTasks,
      selectingTaskId,
    } = blPasteCopiedTask(tasks, target, copiedTasks, tempUserId);
    updateTasks(
      newTasks,
      addingTasks,
      toCTasks(tasks.concat(addingTasks), updatingTasks),
      selectingTaskId
    );
  };
  const pasteCutTask = (target: Task, cutTasks: Task[]) => {
    if (cutTasks.some((c) => c.id === target.id)) {
      resetClipboard();
      return;
    }
    const formerParent = cutTasks[0].parent;
    if (!formerParent) return;

    const { newTasks, updatingTasks, selectingTaskId } = blPasteCutTask(
      tasks,
      cutTasks,
      formerParent,
      target,
      tempUserId
    );
    updateTasks(newTasks, [], toCTasks(tasks, updatingTasks), selectingTaskId);
  };
  const outdentTasks = (task: Task) => {
    if (isFiltering) {
      // 子供の追加は自分自身か先祖がフィルター表示対象の場合のみ
      setAddedInvisibleTaskMessage();
      return;
    }
    const formerParent = task.parent;
    if (!formerParent || formerParent.level === 1) return;

    const nextParent = formerParent.parent;
    if (!nextParent) return;

    const targetChildren = formerParent.children.filter(
      (c) => c.selected || c.multiSelected
    );
    if (!targetChildren.length) return;

    const { newTasks, updatingTasks, selectingTaskId } = blOutdentTasks(
      tasks,
      task,
      targetChildren,
      formerParent,
      nextParent,
      tempUserId
    );
    updateTasks(newTasks, [], toCTasks(tasks, updatingTasks), selectingTaskId);
  };

  // done
  const toggleDone = (task: Task) => {
    if (!user || task.children.length) return;

    task.done = !task.done;
    if (task.done) {
      task.doneBy = "";
      task.doneAt = null;
    } else {
      task.doneBy = user.name;
      task.doneAt = new Date();
    }
    const updatingTasks = [task];
    updateTask(task, updatingTasks);
  };

  // edit
  const startEditing = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = t.id === task.id;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  const endEditingText = (task: Task, text: string) => {
    if (!user) return;
    // 先にフォーカスを移さないと ClikcToFocus が一瞬表示されてしまう
    focusMindmap();

    if (text.trim() || task.children.length) {
      // text があるか、子供がいる場合は削除できないので更新処理
      task.text = text.trim();
      task.selected = true;
      task.isEditing = false;
      if (task.isNew) {
        task.isNew = false;
        // isNew の場合はまだ 新規追加したタスクの親や兄弟をDBに反映してないのでここで更新
        const addingTasks: Task[] = [task];
        const updatingTasks: Task[] = [];
        if (task.parent) {
          // 親も更新
          updatingTasks.push(task.parent);
          // addSibling の場合は途中に追加されることもあるので、自分以降の兄弟も更新
          const youngerSiblings = task.parent.children.filter(
            (c) => c.sortNumber > task.sortNumber
          );
          for (const sibling of youngerSiblings) {
            updatingTasks.push(sibling);
          }
        }
        batchEditTasks(
          user.userId,
          tempUserId,
          addingTasks,
          updatingTasks,
          [],
          // 新規追加の場合は選択タスクを移動させていないので、ここで更新
          task.id
        );
        // 更新が確認できてからローカルを更新
        setTask(task);
      } else {
        // 既にあった場合は自分だけを更新
        const updatingTasks = [task];
        updateTask(task, updatingTasks);
      }
    } else if (!task.children.length) {
      // 子供がない場合は削除
      if (task.isNew) {
        // 新規の場合はローカルだけを削除
        deleteLocalTask(task);
      } else {
        // 新規でない場合はDBも更新
        deleteTask(task);
      }
    }
    // 編集後に戻り先のタスクをリセットしておかないと
    // 別の場所で削除した時に引き継がれてしまう
    setNextTaskIdAfterDeletingNewTask("");
  };

  // settings
  const startAssigning = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = t.id === task.id;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endAssigning = async (
    task: Task,
    assignee: User | null,
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.assignee = assignee;
    task.isAssigning = false;
    if (nextCategory === "DueDate") {
      task.isSettingDueDate = true;
    } else if (nextCategory === "Label") {
      task.isLabeling = true;
    }
    const updatingTasks = [task];
    updateTask(task, updatingTasks);
  };
  const startSettingDueDate = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = t.id === task.id;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endSettingDueDate = async (
    task: Task,
    dueDate: Date | null,
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.dueDate = dueDate;
    task.isSettingDueDate = false;
    if (nextCategory === "Assignee") {
      task.isAssigning = true;
    } else if (nextCategory === "Label") {
      task.isLabeling = true;
    }
    const updatingTasks = [task];
    updateTask(task, updatingTasks);
  };
  const startLabeling = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = t.id === task.id;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endLabeling = async (
    task: Task,
    labels: TaskLabel[],
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.labels = labels;
    task.isLabeling = false;
    if (nextCategory === "Assignee") {
      task.isAssigning = true;
    } else if (nextCategory === "DueDate") {
      task.isSettingDueDate = true;
    }
    const updatingTasks = [task];
    updateTask(task, updatingTasks);
  };

  const toggleIsSettingsVisible = () => {
    setIsSettingsVisible(!isSettingsVisible);
    organize();
  };

  // Select
  const move = (task: Task, direction: "UP" | "DOWN" | "RIGHT" | "LEFT") => {
    const nextTask =
      direction === "UP"
        ? getClosestAboveTask(displayTasks, task)
        : direction === "DOWN"
        ? getClosestBelowTask(displayTasks, task)
        : direction === "RIGHT"
        ? getClosestRightTask(displayTasks, task)
        : // LEFT
          getClosestLeftTask(task);
    if (nextTask) {
      selectTask(nextTask);
      setShiftMoveStartTask(null);
      setContextMenuTask(null);
      adjustScroll(nextTask);
    }
  };
  const shiftMove = (task: Task, direction: "UP" | "DOWN") => {
    if (!task.parent) return;

    const nextTask =
      direction === "UP"
        ? getElderTask(task, task.parent, isFiltering)
        : getYoungerTask(task, task.parent, isFiltering);
    if (!nextTask) {
      setDifferentParentMessage();
      return;
    }

    if (shiftMoveStartTask) {
      shiftSelectTask(shiftMoveStartTask, nextTask);
    } else {
      // 最初のノード
      setShiftMoveStartTask(task);
      shiftSelectTask(task, nextTask);
    }
    setContextMenuTask(null);
  };
  const shiftSelectTask = (startTask: Task, endTask: Task) => {
    setTasks(
      tasks.map((t) => {
        t.selected = t.id === endTask.id;
        if (
          t.parentId === startTask.parentId &&
          // フィルタリング中でないか、フィルタリング中なら表示されていることが条件
          (!isFiltering || t.passedFilterWithFamily) &&
          ((t.sortNumber >= startTask.sortNumber &&
            t.sortNumber <= endTask.sortNumber) ||
            (t.sortNumber <= startTask.sortNumber &&
              t.sortNumber >= endTask.sortNumber))
        ) {
          t.multiSelected = true;
        } else {
          t.multiSelected = false;
        }
        return t;
      })
    );
    updateSelectedTaskId(endTask.id);
  };
  const selectTask = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.isSetting = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        return t;
      })
    );
    updateSelectedTaskId(task.id);
  };
  const onClickTask = (event: React.MouseEvent<HTMLDivElement>, task: Task) => {
    if (event.shiftKey) {
      if (shiftMoveStartTask) {
        if (shiftMoveStartTask.parentId === task.parentId) {
          // Shift移動開始したタスクと同じ親を持つ場合にだけ可能
          shiftSelectTask(shiftMoveStartTask, task);
        } else {
          setDifferentParentMessage();
        }
      } else {
        // Shift移動をまだ開始してない場合
        const selectedTask = tasks.find((t) => t.selected);
        if (selectedTask && selectedTask.parentId === task.parentId) {
          // 選択されているタスクと同じ親を持つ場合にだけ可能
          shiftSelectTask(selectedTask, task);
          // Shift移動開始タスクも同時にセットしておく
          setShiftMoveStartTask(selectedTask);
        } else {
          setDifferentParentMessage();
        }
      }
    } else if (event.ctrlKey || event.metaKey) {
      ctrlSelectTask(task);
      setShiftMoveStartTask(null);
    } else {
      selectTask(task);
      setShiftMoveStartTask(null);
    }
    setContextMenuTask(null);
  };
  const ctrlSelectTask = (task: Task) => {
    if (task.selected) {
      // 選択状態のタスクの場合
      const multiSelectedTask = tasks.find((t) => t.multiSelected);
      if (multiSelectedTask) {
        // 他に複数選択状態のタスクがあれば適当に選択状態を移動
        multiSelectedTask.selected = true;
        task.selected = false;
        setTasks(
          tasks.map((t) => {
            if (t.id === task.id) return task;
            if (t.id === multiSelectedTask.id) return multiSelectedTask;
            return t;
          })
        );
        updateSelectedTaskId(multiSelectedTask.id);
      } else {
        // 複数選択状態のタスクがない場合は選択状態のタスクがなくなるとまずいので何もしない
      }
    } else {
      const selectedTask = tasks.find((t) => t.selected);
      if (!selectedTask) return;
      if (selectedTask.parentId !== task.parentId) {
        setDifferentParentMessage();
        return;
      }
      // selected を移動させる
      selectedTask.selected = false;
      selectedTask.multiSelected = true;
      task.selected = true;
      setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
      updateSelectedTaskId(task.id);
    }
  };

  // Context Menu
  const onContextMenu = (
    event: React.MouseEvent<HTMLDivElement>,
    task: Task
  ) => {
    event.preventDefault();
    const bounds = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    setContextMenuTop(task.top + y - 3); // translateY分の3
    setContextMenuLeft(task.left + x);
    setContextMenuTask(task);
    selectTask(task);
  };
  const onClickContextMenuOpenSettings = () => {
    if (!contextMenuTask) return;
    startSettings(contextMenuTask);
    setContextMenuTask(null);
  };
  const startSettings = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = t.id === task.id;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endSettings = async (
    task: Task,
    assignee: User | null,
    dueDate: Date | null,
    labels: TaskLabel[]
  ) => {
    focusMindmap();
    task.assignee = assignee;
    task.dueDate = dueDate;
    task.labels = labels;
    task.isSetting = false;

    const updatingTasks = [task];
    updateTask(task, updatingTasks);
  };
  const onClickContextMenuAddChild = async () => {
    if (!contextMenuTask) return;
    addNewChild(contextMenuTask);
  };
  const onClickContextMenuAddTaskBelow = async () => {
    if (!contextMenuTask) return;
    addSibling(contextMenuTask, "BELOW");
  };
  const onClickContextMenuAddTaskAbove = async () => {
    if (!contextMenuTask) return;
    addSibling(contextMenuTask, "ABOVE");
  };

  const onMindmapMouseDownLocal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    contextMenuTask && setContextMenuTask(null);
    onMindmapMouseDown(event);
  };

  return (
    <Container
      ref={mindmapElm}
      tabIndex={0}
      isGrabbing={isGrabbingMindmap}
      isClickToFocusDisabled={isClickToFocusDisabled}
      color={color}
      onKeyDown={onKeyDown}
      onMouseDown={onMindmapMouseDownLocal}
      onMouseOut={onMindmapMouseOut}
      onMouseMove={onMouseMove}
      onMouseUp={onMindmapMouseUp}
    >
      {displayTasks.map((t) => (
        <ComponentTask
          key={t.id}
          user={user}
          members={members}
          labels={labels}
          task={t}
          onClick={onClickTask}
          onContextMenu={onContextMenu}
          isSettingsVisible={isSettingsVisible}
          toggleDone={toggleDone}
          endEditingText={endEditingText}
          setTask={setTask}
          endSettings={endSettings}
          endAssigning={endAssigning}
          endSettingDueDate={endSettingDueDate}
          endLabeling={endLabeling}
          adjustScroll={adjustScroll}
        />
      ))}
      {contextMenuTask ? (
        <ContextMenu
          borderColor={
            palette[(contextMenuTask.topSortNumber - 1) % palette.length]
          }
          top={contextMenuTop}
          left={contextMenuLeft}
          onClickContextMenuOpenSettings={onClickContextMenuOpenSettings}
          onClickContextMenuAddChild={onClickContextMenuAddChild}
          onClickContextMenuAddTaskBelow={onClickContextMenuAddTaskBelow}
          onClickContextMenuAddTaskAbove={onClickContextMenuAddTaskAbove}
        />
      ) : (
        false
      )}
      {lines.map((l) => (
        <ComponentLine
          key={l.startTask.id + l.endTask.id}
          startX={l.startX}
          startY={l.startY}
          endX={l.endX}
          endY={l.endY}
          lineStrokeColor={
            l.startTask.isCutWithAncestors
              ? node.cut.borderColor
              : palette[(l.endTask.topSortNumber - 1) % palette.length]
          }
        />
      ))}
      {activeMembersLabels.map((l) => (
        <ComponentActiveMembersLabel key={"label-" + l.taskId} label={l} />
      ))}
      <ActiveMembersComponent />
      <Filter
        isOpen={isFilterOpen}
        bottom={recMargin + acitionItemsMargin}
        right={
          isLeft
            ? recMargin +
              (mindmapElm.current ? mindmapElm.current.offsetWidth : 0) +
              acitionItemsMargin
            : recMargin + acitionItemsMargin
        }
        labels={labels}
        visibility={defaultVisibility}
        isCoediting={true}
        members={members}
        openOrFocusFilter={openOrFocusFilter}
        closeFilter={closeFilter}
        focusFilterCounter={focusFilterCounter}
        filterMember={filterMember}
        changeFilterMember={changeFilterMember}
        filterStatus={filterStatus}
        changeFilterStatus={changeFilterStatus}
        filterDueDate={filterDueDate}
        changeFilterDueDate={changeFilterDueDate}
        filterLabelItems={filterLabelItems}
        toggleFilterLabelItem={toggleFilterLabelItem}
      />
      <FilteringItems
        bottom={recMargin + acitionItemsMargin}
        left={
          isLeft
            ? recMargin + acitionItemsMargin
            : recMargin +
              (mindmapElm.current ? mindmapElm.current.offsetWidth : 0) +
              acitionItemsMargin
        }
        isSettingsVisible={isSettingsVisible}
        toggleIsSettingsVisible={toggleIsSettingsVisible}
        filterMember={filterMember}
        changeFilterMember={changeFilterMember}
        filterStatus={filterStatus}
        changeFilterStatus={changeFilterStatus}
        filterDueDate={filterDueDate}
        changeFilterDueDate={changeFilterDueDate}
        filterLabelItems={filterLabelItems}
        toggleFilterLabelItem={toggleFilterLabelItem}
        resetFilter={resetFilter}
      />
      <ClickToFocus className="click-to-focus" isLeft={isLeft}>
        {c("clickToFocus")}
      </ClickToFocus>
    </Container>
  );
};

type ContainerProps = {
  isGrabbing: boolean;
  isClickToFocusDisabled: boolean;
  color: string;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: 100%;
  color: ${({ color }) => color};

  position: relative;
  cursor: ${({ isGrabbing }) => (isGrabbing ? "grabbing" : "grab")};
  outline: none;

  /* focus してない時は編集時、設定時なら none */
  div.click-to-focus {
    display: ${({ isClickToFocusDisabled }) =>
      isClickToFocusDisabled ? "none" : ""};
  }
  /* focus 時は常に none */
  :focus {
    div.click-to-focus {
      display: none;
    }
  }
`;

const ClickToFocus = styled(FlexColumnCenter)<{ isLeft: boolean }>`
  font-size: 30px;
  font-weight: bold;
  position: fixed;
  top: calc(50vh - 50px);
  left: ${({ isLeft }) => (isLeft ? 0 : "")};
  right: ${({ isLeft }) => (isLeft ? "" : 0)};
  height: 100px;
  width: 50%;
  z-index: ${clickToFocusZIndex};
`;

export default Mindmap;
