import React from "react";
import SettingContainer from "../../components/SettingContainer";
import PrivacyPolicyComponent from "../../components/PrivacyPolicy";

const PrivacyPolicy: React.FC = () => {
  return (
    <SettingContainer>
      <PrivacyPolicyComponent />
    </SettingContainer>
  );
};

export default PrivacyPolicy;
