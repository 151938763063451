import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../../components/SettingContainer";
import Typography from "../../../components/Typography";
import Text from "../../../components/Input/Text";
import Buttons from "./Buttons";
import useSnackbar from "../../../hooks/useSnackbar";
import useAnalytics from "../../../hooks/useAnalytics";
import { updateProjectName } from "../../../db/projects";
import { Project } from "../../../models/Project";
import Context from "../../../context";
import { firebase401Message } from "../../../constants";

const ProjectBasicSetting: React.FC = () => {
  const match = useRouteMatch<{ projectId: string }>({
    path: "/projects/:projectId/settings/basic",
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params && match.params.projectId;

  // context
  const { projects, updateLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: p } = useTranslation("project");
  const history = useHistory();
  const { setMessage, setUnauthorizedMessage } = useSnackbar();
  const { sendProjectLog } = useAnalytics();
  // states
  const [project, setProject] = useState<Project | null>(null);
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!projectId || !projects.length) return;
    const currentProject = projects.find((p) => p.projectId === projectId);
    if (currentProject) {
      setProject(currentProject);
      setProjectName(currentProject.name);
    } else {
      setProjectName("");
    }
  }, [projects, projectId]);

  const onChangeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const onClickSave = async () => {
    if (!projectId) return;

    setLoading(true);
    try {
      await updateProjectName(projectId, projectName);
      await updateLocalProject(projectId);
      setMessage({
        level: "success",
        text: c("successfullyUpdated"),
      });
      sendProjectLog("update_project_name");
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = () => {
    projectId && history.push(`/projects/${projectId}`);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {project ? project.name : ""}
      </Typography>
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {p("projectName")}
      </Typography>
      <Text
        value={projectName}
        placeholder={p("enterProjectName")}
        autoComplete="off"
        margin="0px 0px 16px 0px"
        onChange={onChangeProjectName}
      />
      <Buttons
        loading={loading}
        disabled={!projectName}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
    </SettingContainer>
  );
};

export default ProjectBasicSetting;
