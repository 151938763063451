import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDiL70dQVqiw6Xb3bzU00WtPiZW8D5sjFg",
  authDomain: "castle-in-the-sky.firebaseapp.com",
  projectId: "castle-in-the-sky",
  storageBucket: "castle-in-the-sky.appspot.com",
  messagingSenderId: "1037355942643",
  appId: "1:1037355942643:web:737dfe67acfd49a9aaad86",
  measurementId: "G-MFXRZXQ6FP",
};

firebase.initializeApp(firebaseConfig);
// firebase.functions().useEmulator("localhost", 5000);
export default firebase;

export const db = firebase.firestore();
// export const functions = firebase.functions();
export const functions = firebase.app().functions("asia-northeast2");
