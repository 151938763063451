import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  FlexRowCenter,
  FlexColumnCenter,
} from "../../../components/StyledComponents";
import { Label, LabelItem, TaskLabel } from "../../../models/Label";
import useStyles from "../../../hooks/useStyles";

type Props = {
  focused: boolean;
  selected: boolean;
  label: Label;
  taskLabel: TaskLabel | undefined;
  onClickLabelItem: (labelId: string, labelItem: LabelItem) => void;
  onClickNone: (labelId: string) => void;
};

const ComponentLabel: React.FC<Props> = React.memo(
  ({ focused, selected, label, taskLabel, onClickLabelItem, onClickNone }) => {
    // hooks
    const { t: c } = useTranslation("common");
    const { label: LabelTheme } = useStyles();
    // computed
    const isLabelItemSelected = (labelItem: LabelItem): boolean => {
      return taskLabel && taskLabel.labelItemId === labelItem.labelItemId
        ? true
        : false;
    };

    return (
      <Container>
        <LabelName>{label.name}</LabelName>
        <FilterItems>
          <LabelItemContainer
            focused={focused}
            labelSelected={selected}
            selected={taskLabel === undefined}
            color={LabelTheme.item.color}
            bgColor={LabelTheme.item.bgColor}
            selectedColor={LabelTheme.item.selected.color}
            selectedBgColor={LabelTheme.item.selected.bgColor}
            borderColor={LabelTheme.item.borderColor}
            onClick={() => onClickNone(label.labelId)}
          >
            {c("none")}
          </LabelItemContainer>
          {label.labelItems.map((i) => (
            <LabelItemContainer
              key={i.labelItemId}
              focused={focused}
              labelSelected={selected}
              selected={isLabelItemSelected(i)}
              color={i.backgroundColor}
              selectedColor={i.color}
              bgColor="transparent"
              selectedBgColor={i.backgroundColor}
              borderColor={i.backgroundColor}
              onClick={() => onClickLabelItem(label.labelId, i)}
            >
              {i.name}
            </LabelItemContainer>
          ))}
        </FilterItems>
      </Container>
    );
  }
);
ComponentLabel.displayName = "Label";

const Container = styled(FlexColumnCenter)`
  padding: 0px 8px 0px 8px;
  flex-wrap: wrap;
`;

const LabelName = styled.div`
  margin-bottom: 6px;
`;

const FilterItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  > div {
    margin-right: 4px;
    /* Container の padding-bottom 分をここで埋める */
    margin-bottom: 4px;
  }
  div:last-child {
    margin-right: 0px;
  }
`;

type LabelItemProps = {
  focused: boolean;
  labelSelected: boolean;
  selected: boolean;
  color: string;
  bgColor: string;
  selectedColor: string;
  selectedBgColor: string;
  borderColor: string;
};
const LabelItemContainer = styled(FlexRowCenter)<LabelItemProps>`
  color: ${({ selected, color, selectedColor }) =>
    selected ? selectedColor : color};
  background-color: ${({ selected, bgColor, selectedBgColor }) =>
    selected ? selectedBgColor : bgColor};
  border: solid 1px ${({ borderColor }) => borderColor};
  border-radius: 8px;
  box-shadow: ${({ focused, labelSelected, selected, borderColor }) =>
    focused && labelSelected && selected ? `0 0 10px ${borderColor}` : ""};
  padding: 4px 8px;
  cursor: pointer;

  transition: color 0.1s ease-out, background-color 0.1s ease-out,
    border 0.1s ease-out, box-shadow 0.1s ease-out;

  :hover {
    color: ${({ selectedColor }) => selectedColor};
    background-color: ${({ selectedBgColor }) => selectedBgColor};
    box-shadow: 0 0 10px ${({ borderColor }) => borderColor};
  }
`;

export default ComponentLabel;
