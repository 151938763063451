import { User } from "../../models/User";
import { Task } from "../../models/Task";
import { Label, LabelItem, DisplayTaskLabel } from "../../models/Label";
import { FilterDueDate, FilterStatus } from "../../models/Filter";
import { getOclockDate } from "../common";

export const setPassedFilter = (
  tasks: Task[],
  filterMember: User | null,
  filterStatus: FilterStatus,
  filterDueDate: FilterDueDate,
  firstDay: number,
  filterLabelItems: LabelItem[]
): void => {
  const nowStart = getOclockDate();
  for (const task of tasks) {
    if (task.level === 1) {
      task.passedMemberFilter = true;
      task.passedMemberFilterWithAncestors = true;
      task.passedMemberFilterWithDescendants = true;
      task.passedStatusFilter = true;
      task.passedDueDateFilter = true;
      task.passedDueDateFilterWithAncestors = true;
      task.passedDueDateFilterWithDescendants = true;
      task.passedLabelFilter = true;
      task.passedLabelFilterWithAncestors = true;
      task.passedLabelFilterWithDescendants = true;
      continue;
    }
    // member
    if (filterMember) {
      task.passedMemberFilter = hasAssignee(task, filterMember);
      task.passedMemberFilterWithAncestors = hasAssigneeConsideringAncestors(
        task,
        filterMember,
        true
      );
      task.passedMemberFilterWithDescendants = hasAssigneeConsideringDescendants(
        task,
        filterMember
      );
    } else {
      task.passedMemberFilter = true;
      task.passedMemberFilterWithAncestors = true;
      task.passedMemberFilterWithDescendants = true;
    }
    // status
    if (filterStatus === "all") {
      task.passedStatusFilter = true;
    } else {
      // done は全てにおいて子供を考慮しないといけない
      task.passedStatusFilter = !task.doneConsideringDescendants;
    }
    // due date
    if (filterDueDate === "none") {
      task.passedDueDateFilter = true;
      task.passedDueDateFilterWithAncestors = true;
      task.passedDueDateFilterWithDescendants = true;
    } else {
      task.passedDueDateFilter = isInFilterDueDateSpan(
        task,
        filterDueDate,
        firstDay,
        nowStart
      );
      task.passedDueDateFilterWithAncestors = isInFilterDueDateSpanConsideringAncestors(
        task,
        filterDueDate,
        firstDay,
        nowStart,
        true
      );
      task.passedDueDateFilterWithDescendants = isInFilterDueDateSpanConsideringDescendants(
        task,
        filterDueDate,
        firstDay,
        nowStart
      );
    }
    // label
    if (filterLabelItems.length) {
      task.passedLabelFilter = hasLabelItems(task, filterLabelItems);
      task.passedLabelFilterWithAncestors = hasLabelItemsConsideringAncestors(
        task,
        filterLabelItems
      );
      task.passedLabelFilterWithDescendants = hasLabelItemsConsideringDescendants(
        task,
        filterLabelItems
      );
    } else {
      task.passedLabelFilter = true;
      task.passedLabelFilterWithAncestors = true;
      task.passedLabelFilterWithDescendants = true;
    }
  }
};

const hasAssignee = (
  task: Task,
  filterMember: User // 入ってる前提
): boolean => {
  if (!task.assignee) return false;
  return task.assigneeId === filterMember.userId;
};

const hasAssigneeConsideringAncestors = (
  task: Task,
  filterMember: User,
  skip: boolean
): boolean => {
  // 自分が対象
  if (!skip && hasAssignee(task, filterMember)) {
    return true;
  }
  if (!task.parent) return false;
  return hasAssigneeConsideringAncestors(task.parent, filterMember, false);
};

const hasAssigneeConsideringDescendants = (
  task: Task,
  filterMember: User
): boolean => {
  /**
   * 再起の考え方
   * true だったらそのまま表示すればいいが
   * false だったら子孫に true がないかを調べないといけない
   */
  if (hasAssignee(task, filterMember)) {
    // 自分自身が入ってたら true
    return true;
  }
  // 自分自身が入ってない場合は子孫を考慮する
  return task.children.some((c) =>
    hasAssigneeConsideringDescendants(c, filterMember)
  );
};

const hasLabelItems = (task: Task, labelItems: LabelItem[]): boolean => {
  for (const labelItem of labelItems) {
    if (task.labels.some((l) => l.labelItemId === labelItem.labelItemId)) {
      // 自分が持ってる
      return true;
    }
  }
  return false;
};

const hasLabelItemsConsideringAncestors = (
  task: Task,
  labelItems: LabelItem[]
): boolean => {
  for (const labelItem of labelItems) {
    if (hasLabelItemConsideringAncestors(task, labelItem.labelItemId, true)) {
      return true;
    }
  }
  return false;
};

const hasLabelItemConsideringAncestors = (
  task: Task,
  labelItemId: string,
  skip: boolean
): boolean => {
  /**
   * skip は最初だけ
   * この直後の処理を入れないと正しく動かないが、
   * skip も入れておかないと、自分自身が含まれてしまう
   */
  if (!skip && task.labels.some((l) => l.labelItemId === labelItemId))
    return true;
  if (!task.parent) return false;
  return hasLabelItemConsideringAncestors(task.parent, labelItemId, false);
};

const hasLabelItemsConsideringDescendants = (
  task: Task,
  labelItems: LabelItem[]
): boolean => {
  for (const labelItem of labelItems) {
    if (hasLabelItemConsideringDescendants(task, labelItem.labelItemId)) {
      return true;
    }
  }
  return false;
};

const hasLabelItemConsideringDescendants = (
  task: Task,
  labelItemId: string
): boolean => {
  if (!task.children.length) {
    // 子供がいない
    return false;
  }
  if (
    task.children.some((c) =>
      c.labels.some((l) => l.labelItemId === labelItemId)
    )
  ) {
    // 子供が持ってる
    return true;
  } else {
    for (const child of task.children) {
      if (hasLabelItemConsideringDescendants(child, labelItemId)) {
        // 子孫が持ってる
        return true;
      }
    }
  }
  return false;
};

const isInFilterDueDateSpan = (
  task: Task,
  filterDueDate: FilterDueDate, // 入ってる前提
  firstDay: number,
  nowStart: Date // 時刻は 00:00:00
): boolean => {
  if (!task.dueDate) return false;

  switch (filterDueDate) {
    case "overdue":
      return task.dueDate < nowStart;
    case "today":
      return (
        nowStart.getFullYear() === task.dueDate.getFullYear() &&
        nowStart.getMonth() === task.dueDate.getMonth() &&
        nowStart.getDate() === task.dueDate.getDate()
      );
    case "thisweek": {
      const today = nowStart.getDay();
      const adjustedLastDay = today < firstDay ? today + 7 : today;
      const margin = 6 + firstDay - adjustedLastDay;
      return (
        nowStart <= task.dueDate &&
        task.dueDate <=
          new Date(
            nowStart.getFullYear(),
            nowStart.getMonth(),
            nowStart.getDate() + margin,
            23,
            59,
            59
          )
      );
    }
    case "thismonth":
      return (
        nowStart <= task.dueDate &&
        task.dueDate <=
          new Date(
            nowStart.getFullYear(),
            nowStart.getMonth() + 1,
            0,
            23,
            59,
            59
          )
      );
    default:
      return false;
  }
};

const isInFilterDueDateSpanConsideringAncestors = (
  task: Task,
  filterDueDate: FilterDueDate,
  firstDay: number,
  nowStart: Date, // 時刻は 00:00:00
  skip: boolean
): boolean => {
  // 自分が対象
  if (!skip && isInFilterDueDateSpan(task, filterDueDate, firstDay, nowStart)) {
    return true;
  }
  if (!task.parent) return false;
  return isInFilterDueDateSpanConsideringAncestors(
    task.parent,
    filterDueDate,
    firstDay,
    nowStart,
    false
  );
};

const isInFilterDueDateSpanConsideringDescendants = (
  task: Task,
  filterDueDate: FilterDueDate,
  firstDay: number,
  nowStart: Date // 時刻は 00:00:00
): boolean => {
  /**
   * 再起の考え方
   * true だったらそのまま表示すればいいが
   * false だったら子孫に true がないかを調べないといけない
   */
  if (isInFilterDueDateSpan(task, filterDueDate, firstDay, nowStart)) {
    // 自分自身が入ってたら true
    return true;
  }
  // 自分自身が入ってない場合は子孫を考慮する
  return task.children.some((c) =>
    isInFilterDueDateSpanConsideringDescendants(
      c,
      filterDueDate,
      firstDay,
      nowStart
    )
  );
};

export const getTaskLabelsConsideringAncestors = (
  task: Task,
  labels: Label[]
): DisplayTaskLabel[] => {
  const taskLabels: DisplayTaskLabel[] = [];
  for (const label of labels) {
    const taskLabel = task.labels.find((l) => l.labelId === label.labelId);
    if (taskLabel) {
      const labelItem = label.labelItems.find(
        (i) => i.labelItemId === taskLabel.labelItemId
      );
      if (labelItem) {
        taskLabels.push({
          labelItemId: labelItem.labelItemId,
          name: labelItem.name,
          isParentLabel: false,
          color: labelItem.color,
          backgroundColor: labelItem.backgroundColor,
        });
      }
      continue;
    }
    for (const labelItem of label.labelItems) {
      if (
        hasLabelItemConsideringAncestors(task, labelItem.labelItemId, false)
      ) {
        taskLabels.push({
          labelItemId: labelItem.labelItemId,
          name: labelItem.name,
          isParentLabel: !task.labels.some(
            (l) => l.labelItemId === labelItem.labelItemId
          ),
          color: labelItem.color,
          backgroundColor: labelItem.backgroundColor,
        });
        break;
      }
    }
  }
  return taskLabels;
};

/**
 * 表示されているタスクの中で座標的に一番上にあるタスクのIDを返す
 */
export const getSelectingTaskIdInFilteredTasks = (topTask: Task): string => {
  let children = topTask.filteredChildren;
  let selectingId = "";
  while (children.length) {
    selectingId = children[0].id;
    children = children[0].filteredChildren;
  }
  return selectingId;
};
