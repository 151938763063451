import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Forward } from "@material-ui/icons";
import {
  FlexColumnCenter,
  FlexRowCenter,
} from "../../components/StyledComponents";
import { Command } from "../../models/Command";
import Context from "../../context";
import useStyles from "../../hooks/useStyles";
import { baseBorderRadius } from "../../constants";
import { recMargin, acitionItemsMargin, mindmapTopPadding } from "./constants";

type Props = {
  todoCommands: Command[];
  doneCommands: Command[];
};

const TodoTable: React.FC<Props> = React.memo(
  ({ todoCommands, doneCommands }) => {
    // context
    const { isMac, isSafari } = useContext(Context);
    // ref
    const elm = useRef<HTMLDivElement>(null);
    // hooks
    const { t: c } = useTranslation("command");
    const { t: w } = useTranslation("windows");
    const { t: m } = useTranslation("mac");
    const { t: s } = useTranslation("safari");
    const command = isSafari ? s : isMac ? m : w;
    const { color, node, menuItem } = useStyles();
    // states
    const [width, setWidth] = useState(0);

    useEffect(() => {
      if (elm.current) {
        setWidth(elm.current.offsetWidth);
      }
    }, [elm.current, c]);

    return (
      <Container ref={elm} color={color}>
        {todoCommands.map((cmd) => (
          <Item
            key={cmd}
            done={doneCommands.includes(cmd)}
            color={color}
            selectedColor={node.done.color}
            bgColor={node.bgColor}
            selectedBgColor={node.done.bgColor}
            borderColor={menuItem.borderColor}
          >
            {!doneCommands.includes(cmd) && (
              <ArrowWrapper right={width}>
                <Enter>{c("enter")}</Enter>
                <Forward style={{ height: 30, width: 30 }} />
              </ArrowWrapper>
            )}
            <Explanation>{c(cmd)}</Explanation>
            <CommandText>{command(cmd)}</CommandText>
          </Item>
        ))}
      </Container>
    );
  }
);
TodoTable.displayName = "TodoTable";

const Container = styled.div<{ color: string }>`
  color: ${({ color }) => color};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  position: fixed;
  top: ${recMargin + mindmapTopPadding}px;
  right: ${recMargin + acitionItemsMargin}px;
  z-index: 1999;

  > div {
    margin-bottom: 8px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

type ItemProps = {
  done: boolean;
  color: string;
  selectedColor: string;
  bgColor: string;
  selectedBgColor: string;
  borderColor: string;
};
const Item = styled(FlexColumnCenter)<ItemProps>`
  color: ${({ done, color, selectedColor }) => (done ? selectedColor : color)};
  background-color: ${({ done, bgColor, selectedBgColor }) =>
    done ? selectedBgColor : bgColor};
  padding: 5px 12px;
  border: solid 2px ${({ color }) => color};
  border-radius: ${baseBorderRadius}px;

  position: relative;
  cursor: default;
`;

const Explanation = styled.div`
  line-height: 16px;
  font-size: 14px;
  font-weight: 300;
`;

const CommandText = styled.div`
  line-height: 20px;
  font-size: 15px;
`;

const Enter = styled.div`
  font-size: 14px;
`;

const ArrowWrapper = styled(FlexRowCenter)<{ right: number }>`
  width: max-content;
  position: absolute;
  top: 9px;
  right: ${({ right }) => right + 8}px;
  animation: shaking 1s ease-in-out infinite;
`;

export default TodoTable;
