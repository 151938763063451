import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Icon from "../components/Button/Icon";
import BaseSignInButton from "../components/Button/BaseSignInButton";
import Context from "../context";
import useStyles from "../hooks/useStyles";
import {
  logoChars,
  windowPadding,
  logoTop,
  logoLeft,
  accountTop,
  accountRight,
  headerHeihgt,
  floatItemZIndex,
} from "../constants";

const Header: React.FC = React.memo(() => {
  const {
    user,
    isProjectListOpen,
    setIsProjectListOpen,
    isSettingMenuOpen,
    setIsSettingMenuOpen,
    focusMindmapFromOutside,
  } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const { palette, color, logo, menuItem, icon } = useStyles();
  // states
  const [isLogoHover, setIsLogoHover] = useState(false);
  // computed
  const boxShadow = menuItem.boxShadow
    ? menuItem.boxShadow
    : `0 0 10px ${menuItem.borderColor}`;
  const isListSelected =
    isProjectListOpen || location.pathname.includes("settings");

  // ProjectList
  const onListMouseEnter = () => {
    setIsProjectListOpen(true);
  };
  const onClickList = () => {
    if (isProjectListOpen) {
      focusMindmapFromOutside();
      setIsProjectListOpen(false);
    } else {
      setIsProjectListOpen(true);
    }
  };

  // Logo
  const onMouseEnter = () => {
    setIsLogoHover(true);
  };
  const onMouseLeave = () => {
    setIsLogoHover(false);
  };
  const onClickLogo = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    history.push("/news");
  };
  const onClickSignIn = () => {
    history.push("/signin");
  };

  // Account
  const account = () => {
    if (user) {
      return (
        <Account
          color={color}
          boxShadow={boxShadow}
          onMouseEnter={onAccountMouseEnter}
        >
          {user.image ? (
            <Picture
              borderColor={menuItem.borderColor}
              alt={user.name}
              src={user.image ? user.image : ""}
              onClick={onClickAccount}
            />
          ) : (
            <Icon icon="person" size={40} selected={isSettingMenuOpen} />
          )}
        </Account>
      );
    } else {
      return (
        <SigninButton
          color={icon.color}
          hoverColor={icon.hoverColor}
          onClick={onClickSignIn}
        >
          {c("signin")}
        </SigninButton>
      );
    }
  };

  // SettingMenu
  const onAccountMouseEnter = () => {
    setIsSettingMenuOpen(true);
  };
  const onClickAccount = () => {
    if (isSettingMenuOpen) {
      focusMindmapFromOutside();
      setIsSettingMenuOpen(false);
    } else {
      setIsSettingMenuOpen(true);
    }
  };

  return (
    <>
      <HeaderButtonWrapper onMouseEnter={onListMouseEnter}>
        <Icon
          icon="list"
          size={40}
          selected={isListSelected}
          onClick={onClickList}
        />
      </HeaderButtonWrapper>
      <Logo
        href="/news"
        color={logo.color}
        onClick={onClickLogo}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {logoChars.map((c, i) => (
          <LogoChar
            key={c + i}
            isLogoHover={isLogoHover}
            strokeColor={palette[i % palette.length]}
          >
            {c}
          </LogoChar>
        ))}
      </Logo>
      {account()}
    </>
  );
});
Header.displayName = "Header";

const HeaderButtonWrapper = styled.div`
  position: fixed;
  top: ${windowPadding}px;
  left: ${windowPadding}px;
  z-index: ${floatItemZIndex};
`;

const Logo = styled.a<{ color: string }>`
  font-family: "Orbitron", sans-serif;
  font-size: 45px;

  color: ${({ color }) => color};

  text-decoration: none;

  position: fixed;
  top: ${logoTop}px;
  left: ${logoLeft}px;
  z-index: ${floatItemZIndex};

  user-select: none;
  cursor: pointer;
`;

const LogoChar = styled.span<{ isLogoHover: boolean; strokeColor: string }>`
  -webkit-text-stroke: 2px ${({ strokeColor }) => strokeColor};
  letter-spacing: 2px;
  text-shadow: ${({ isLogoHover, strokeColor }) =>
    isLogoHover ? `0 0 20px ${strokeColor}` : ""};
`;

const Account = styled.div<{ boxShadow: string }>`
  height: ${headerHeihgt}px;
  width: ${headerHeihgt}px;
  border-radius: ${headerHeihgt / 2}px;

  display: flex;
  align-items: center;

  position: fixed;
  top: ${accountTop}px;
  right: ${accountRight}px;
  z-index: ${floatItemZIndex};

  cursor: pointer;
  user-select: none;

  transition: box-shadow 100ms ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

const Picture = styled.img<{ borderColor: string }>`
  height: ${headerHeihgt - 4}px;
  width: ${headerHeihgt - 4}px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${headerHeihgt / 2}px;
`;

const SigninButton = styled(BaseSignInButton)`
  position: fixed;
  top: ${accountTop}px;
  right: ${accountRight}px;
  z-index: ${floatItemZIndex};
`;

export default Header;
