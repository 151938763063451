import React from "react";
import styled from "styled-components";
import useStyles from "../../hooks/useStyles";
import {
  FormatListBulleted,
  Help,
  Search,
  Clear,
  Person,
} from "@material-ui/icons";

type Props = {
  icon: "list" | "help" | "search" | "clear" | "person";
  size: number;
  selected: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: () => void;
};

const Icon: React.FC<Props> = React.memo(
  ({ icon, size, selected, onClick, onMouseEnter }) => {
    const { menuItem } = useStyles();
    const boxShadow = menuItem.boxShadow
      ? menuItem.boxShadow
      : `0 0 10px ${menuItem.borderColor}`;

    const getIcon = () => {
      switch (icon) {
        case "list":
          return <FormatListBulleted style={{ fontSize: size }} />;
        case "help":
          return <Help style={{ fontSize: size }} />;
        case "clear":
          return <Clear style={{ fontSize: size }} />;
        case "person":
          return <Person style={{ fontSize: size }} />;
        case "search":
        default:
          return <Search style={{ fontSize: size }} />;
      }
    };

    return (
      <Container
        size={size}
        selected={selected}
        color={menuItem.color}
        bgColor={menuItem.bgColor}
        selectedColor={menuItem.selected.color}
        selectedBgColor={menuItem.selected.bgColor}
        borderColor={menuItem.borderColor}
        boxShadow={boxShadow}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {getIcon()}
      </Container>
    );
  }
);
Icon.displayName = "Icon";

type ContainerProps = {
  size: number;
  selected: boolean;
  color: string;
  bgColor: string;
  selectedColor: string;
  selectedBgColor: string;
  borderColor: string;
  boxShadow: string;
};
const padding = 3;
const borderWidth = 2;
const Container = styled.div<ContainerProps>`
  color: ${({ selected, color, selectedColor }) =>
    selected ? selectedColor : color};
  background-color: ${({ selected, bgColor, selectedBgColor }) =>
    selected ? selectedBgColor : bgColor};
  padding: ${padding}px;
  border: solid ${borderWidth}px ${({ borderColor }) => borderColor};
  border-radius: ${({ size }) => size / 2 + padding + borderWidth}px;

  display: flex;
  align-items: center;

  cursor: pointer;

  transition: color 100ms ease-out, background-color 100ms ease-out,
    box-shadow 100ms ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

export default Icon;
