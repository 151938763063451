import React from "react";
import styled from "styled-components";
import { DisplayTaskLabel } from "../../../models/Label";

type Props = {
  taskLabels: DisplayTaskLabel[];
};

const TaskLabels: React.FC<Props> = React.memo(({ taskLabels }) => {
  const showParentLabels = false;
  const displayTaskLabels = taskLabels.filter((l) => {
    if (showParentLabels) {
      return true;
    }
    return !l.isParentLabel;
  });

  return displayTaskLabels.length ? (
    <Container>
      {displayTaskLabels.map((i) => (
        <TaskLabelItem
          key={i.labelItemId}
          isParentLabel={i.isParentLabel}
          color={i.color}
          backgroundColor={i.backgroundColor}
        >
          {i.name}
        </TaskLabelItem>
      ))}
    </Container>
  ) : (
    <></>
  );
});
TaskLabels.displayName = "TaskLabels";

const Container = styled.div`
  width: 100%;

  margin: 4px 0px 2px 0px;

  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  user-select: none;

  > div {
    margin-right: 6px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

type TaskLabelItemProps = {
  isParentLabel: boolean;
  backgroundColor: string;
  color: string;
};
const TaskLabelItem = styled.div<TaskLabelItemProps>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};

  border-radius: 6px;
  padding: 1px 6px;
  display: flex;
  align-items: center;
`;

export default TaskLabels;
