import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  FlexColumnCenter,
  FlexRowCenter,
} from "../../../../components/StyledComponents";
import LabelItemRow from "./LabelItem";
import IconButton from "../../../../components/Button/IconButton";
import Text from "../../../../components/Input/Text";
import useStyles from "../../../../hooks/useStyles";
import { issueId } from "../../../../bl/common";
import { Label, LabelItem } from "../../../../models/Label";
import { baseBorderRadius, defaultLabelColor } from "../../../../constants";

type Props = {
  label: Label;
  canMoveUpward: boolean;
  moveLabelUpward: (displayOrder: number) => void;
  canMoveDownward: boolean;
  moveLabelDownward: (displayOrder: number) => void;
  deleteLabel: (displayOrder: number) => void;
  onChangeLabelName: (labelId: string, name: string) => void;
  onChangeLabelItems: (labelId: string, labelItems: LabelItem[]) => void;
};

const LabelSettingPanel: React.FC<Props> = React.memo(
  ({
    label,
    canMoveUpward,
    moveLabelUpward,
    canMoveDownward,
    moveLabelDownward,
    deleteLabel,
    onChangeLabelName,
    onChangeLabelItems,
  }) => {
    // hooks
    const { t: l } = useTranslation("labels");
    const { palette, node } = useStyles();
    // const
    const labelId = label.labelId;
    const displayOrder = label.displayOrder;
    const labelItems = label.labelItems;

    const onClickMoveLabelUpward = () => {
      if (!canMoveUpward) return;
      moveLabelUpward(displayOrder);
    };

    const onClickMoveLabelDownward = () => {
      if (!canMoveDownward) return;
      moveLabelDownward(displayOrder);
    };

    const onClickDeleteLabel = () => {
      deleteLabel(displayOrder);
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeLabelName(labelId, event.target.value);
    };

    const onChangeLabelItem = (
      labelItemId: string,
      propertyName: "name" | "color" | "backgroundColor",
      value: string
    ) => {
      onChangeLabelItems(
        labelId,
        labelItems.map((l) => {
          if (l.labelItemId === labelItemId) {
            return {
              ...l,
              [propertyName]: value,
            };
          } else {
            return l;
          }
        })
      );
    };

    const assignSortNumber = (items: LabelItem[]) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        item.sortOrder = i + 1;
      }
    };
    const moveLabelItemUpward = (sortOrder: number) => {
      const index = sortOrder - 1;
      const newItems = labelItems.slice();
      newItems.splice(index - 1, 2, newItems[index], newItems[index - 1]);
      assignSortNumber(newItems);
      onChangeLabelItems(labelId, newItems);
    };
    const moveLabelItemDownward = (sortOrder: number) => {
      const index = sortOrder - 1;
      const newItems = labelItems.slice();
      newItems.splice(index, 2, newItems[index + 1], newItems[index]);
      assignSortNumber(newItems);
      onChangeLabelItems(labelId, newItems);
    };
    const deleteLabelItem = (sortOrder: number) => {
      const newItems = labelItems.filter((i) => i.sortOrder !== sortOrder);
      assignSortNumber(newItems);
      onChangeLabelItems(labelId, newItems);
    };

    const addLabelItem = () => {
      const newLabelItem: LabelItem = {
        labelItemId: issueId(),
        sortOrder: labelItems.length + 1,
        name: "",
        color: defaultLabelColor,
        backgroundColor: palette[labelItems.length % palette.length],
      };
      onChangeLabelItems(labelId, [...labelItems, newLabelItem]);
    };

    return (
      <Container bgColor={node.bgColor}>
        <Footer>
          <IconButton
            text={l("deleteLabel")}
            icon="delete"
            onClick={onClickDeleteLabel}
          />
          <IconButton
            text={l("moveUp")}
            icon="up"
            disabled={!canMoveUpward}
            onClick={onClickMoveLabelUpward}
          />
          <IconButton
            text={l("moveDown")}
            icon="down"
            disabled={!canMoveDownward}
            onClick={onClickMoveLabelDownward}
          />
        </Footer>
        <Body>
          <Title>{l("labelName")}</Title>
          <Text
            value={label.name}
            onChange={onChangeName}
            placeholder={l("enterLabelName")}
            autoComplete="off"
            margin="0px 0px 8px 0px"
            small
          />
          <Title>{l("items")}</Title>
          {label.labelItems.map((l, i) => (
            <LabelItemRow
              key={`${label.labelId}-${l.sortOrder}`}
              labelItem={l}
              canMoveUpward={i !== 0}
              moveUp={moveLabelItemUpward}
              canMoveDownward={i !== label.labelItems.length - 1}
              moveDown={moveLabelItemDownward}
              onChangeLabelItem={onChangeLabelItem}
              deleteLabelItem={deleteLabelItem}
            />
          ))}
          <IconButton text={l("addItem")} icon="add" onClick={addLabelItem} />
        </Body>
      </Container>
    );
  }
);
LabelSettingPanel.displayName = "LabelSettingPanel";

const Container = styled(FlexColumnCenter)<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border: solid 2px;
  border-radius: ${baseBorderRadius}px;
`;

const Body = styled(FlexColumnCenter)`
  padding: 12px 12px 8px 12px;
`;

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
  text-align: center;
  user-select: none;
`;

const Footer = styled(FlexRowCenter)`
  width: 100%;
  border-bottom: solid 2px #d8d8d8;
`;

export default LabelSettingPanel;
