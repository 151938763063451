import React from "react";
import styled from "styled-components";
import { FlexRowCenter } from "../StyledComponents";
import useStyles from "../../hooks/useStyles";

type Props = {
  focused: boolean;
  selected: boolean;
  onClick: () => void;
};

const FilterItem: React.FC<Props> = React.memo(
  ({ children, focused, selected, onClick }) => {
    // hooks
    const { filter } = useStyles();
    const boxShadow = filter.item.boxShadow
      ? filter.item.boxShadow
      : `0 0 10px ${filter.item.borderColor}`;

    return (
      <Container
        focused={focused}
        selected={selected}
        color={filter.item.color}
        selectedColor={filter.item.selected.color}
        bgColor={filter.item.bgColor}
        selectedBgColor={filter.item.selected.bgColor}
        borderColor={filter.item.borderColor}
        boxShadow={boxShadow}
        onClick={onClick}
      >
        {children}
      </Container>
    );
  }
);
FilterItem.displayName = "FilterItem";

type ContainerProps = {
  focused: boolean;
  selected: boolean;
  color: string;
  selectedColor: string;
  bgColor: string;
  selectedBgColor: string;
  borderColor: string;
  boxShadow: string;
};
const Container = styled(FlexRowCenter)<ContainerProps>`
  color: ${({ selected, color, selectedColor }) =>
    selected ? selectedColor : color};
  background-color: ${({ selected, bgColor, selectedBgColor }) =>
    selected ? selectedBgColor : bgColor};
  padding: 4px 8px;
  border: solid 1px ${({ borderColor }) => borderColor};
  border-radius: 8px;
  box-shadow: ${({ focused, selected, boxShadow }) =>
    focused && selected ? boxShadow : ""};
  cursor: pointer;

  transition: color 0.1s ease-out, background-color 0.1s ease-out,
    border 0.1s ease-out, box-shadow 0.1s ease-out;

  :hover {
    color: ${({ selectedColor }) => selectedColor};
    background-color: ${({ selectedBgColor }) => selectedBgColor};
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

export default FilterItem;
