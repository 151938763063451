import { asignChildrenSortNumber, reasignChildrenSortNumber } from "./common";
import { UpdateTask, Task } from "../../models/Task";

export const blSwitchTasks = (
  tasks: Task[],
  source: Task,
  target: Task,
  parent: Task,
  updatedBy: string
): {
  newTasks: Task[];
  updatingTasks: UpdateTask[];
} => {
  const sourceSortNumber = source.sortNumber;
  const targetSortNumber = target.sortNumber;
  source.sortNumber = targetSortNumber;
  target.sortNumber = sourceSortNumber;
  reasignChildrenSortNumber(parent);

  // updatingTasks: [source, target, parent],
  // 本来は↑だけでいいのだが、共同編集で順番がおかしくなる可能性があるので
  // sortNumber を振り直して、子供全てを更新する
  const updatingTasks: UpdateTask[] = [];
  const updatedAt = new Date();
  updatingTasks.push({
    id: parent.id,
    params: {
      childrenIds: parent.childrenIds,
      updatedBy,
      updatedAt,
    },
  });
  for (const child of parent.children) {
    updatingTasks.push({
      id: child.id,
      params: {
        sortNumber: child.sortNumber,
        updatedBy,
        updatedAt,
      },
    });
  }

  // newTasks
  const newTasks = tasks.map((t) => {
    if (t.id === parent.id) {
      parent.multiSelected = false;
      return parent;
    }
    if (t.id === source.id) {
      source.multiSelected = false;
      return source;
    }
    if (t.id === target.id) {
      target.multiSelected = false;
      return target;
    }
    t.multiSelected = false;
    t.copied = false;
    t.cut = false;
    return t;
  });

  return {
    newTasks,
    updatingTasks,
  };
};

export const blMoveToEdge = (
  tasks: Task[],
  task: Task,
  parent: Task,
  direction: "TOP" | "BOTTOM",
  updatedBy: string
): {
  newTasks: Task[];
  updatingTasks: UpdateTask[];
} => {
  // まず動かすノードを抜く
  const newChildren = parent.children.filter((c) => c.id !== task.id);
  // 動かすノードを追加
  if (direction === "TOP") {
    newChildren.unshift(task);
  } else {
    // BOTTOM
    newChildren.push(task);
  }
  // sortNumber 振り直す
  parent.children = newChildren;
  asignChildrenSortNumber(parent);

  const updatingTasks: UpdateTask[] = [];
  const updatedAt = new Date();
  updatingTasks.push({
    id: parent.id,
    params: {
      childrenIds: parent.childrenIds,
      updatedBy,
      updatedAt,
    },
  });
  for (const child of parent.children) {
    updatingTasks.push({
      id: child.id,
      params: {
        sortNumber: child.sortNumber,
        updatedBy,
        updatedAt,
      },
    });
  }

  const newTasks = tasks.map((t) => {
    if (t.id === parent.id) {
      parent.multiSelected = false;
      return parent;
    }
    for (const newChild of newChildren) {
      if (t.id === newChild.id) {
        newChild.multiSelected = false;
        return newChild;
      }
    }
    t.multiSelected = false;
    t.copied = false;
    t.cut = false;
    return t;
  });
  return {
    newTasks,
    updatingTasks,
  };
};
