import React from "react";
import { Add, Delete, ArrowUpward, ArrowDownward } from "@material-ui/icons";
import styled from "styled-components";
import useStyles from "../../hooks/useStyles";

type Props = {
  text: string;
  icon: "add" | "delete" | "up" | "down";
  disabled?: boolean;
  onClick?: () => void;
};

const IconButton: React.FC<Props> = React.memo(
  ({ text, icon, disabled, onClick }) => {
    const { icon: iconTheme } = useStyles();

    const getIcon = () => {
      switch (icon) {
        case "add":
          return <Add />;
        case "delete":
          return <Delete />;
        case "up":
          return <ArrowUpward />;
        case "down":
          return <ArrowDownward />;
        default:
          return <></>;
      }
    };

    return (
      <Container
        color={iconTheme.color}
        hoverColor={iconTheme.hoverColor}
        disabledColor="transparent"
        disabled={disabled}
        onClick={onClick}
      >
        {getIcon()}
        {text}
      </Container>
    );
  }
);
IconButton.displayName = "IconButton";

type ContainerProps = {
  color: string;
  hoverColor: string;
  disabledColor: string;
  disabled?: boolean;
};
const Container = styled.button<ContainerProps>`
  font-size: 16px;
  line-height: 40px;
  color: ${({ color, disabledColor, disabled }) =>
    disabled ? disabledColor : color};
  background-color: transparent;
  padding: 0px 12px;
  border: none;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  display: flex;
  align-items: center;

  transition: box-shadow 0.1s ease-out;

  :hover {
    color: ${({ hoverColor, disabledColor, disabled }) =>
      disabled ? disabledColor : hoverColor};
  }
  :focus {
    outline: none;
  }
`;

export default IconButton;
