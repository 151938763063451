import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ComponentTask from "./Task";
import ComponentLine from "../../projects/Line";
import ScrollToNext from "../ScrollToNext";
import ClickToFocus from "../../../components/ClickToFocus";
import { setTaskTop, createLines } from "../../../bl/organize";
import { setTaskLeft, createSampleDate } from "../bl";
import { blAddChild, blAddSibling } from "../../../bl/task/add";
import { blDeleteTask } from "../../../bl/task/delete";
import {
  getClosestAboveTask,
  getClosestBelowTask,
  getClosestRightTask,
  getClosestLeftTask,
} from "../../../bl/task/select";
import { Task } from "../../../models/Task";
import { Line } from "../../../models/Line";
import { Command } from "../../../models/Command";
import useStyles from "../../../hooks/useStyles";
import {
  mindmapTopPadding,
  mindmapBottomPadding,
  mindmapHorizontalPadding,
} from "../constants";

type Props = {
  focusTrigger: number;
  isGrabbingMindmap: boolean;
  onMindmapMouseDown: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onMindmapMouseMove: (pageX: number, pageY: number) => void;
  onMindmapMouseUp: () => void;
  onMindmapMouseOut: () => void;
  adjustScroll: (target: Task) => void;
  addCommand: (command: Command) => void;
  allDone: boolean;
};

const Mindmap: React.FC<Props> = ({
  focusTrigger,
  isGrabbingMindmap,
  onMindmapMouseDown,
  onMindmapMouseMove,
  onMindmapMouseUp,
  onMindmapMouseOut,
  adjustScroll,
  addCommand,
  allDone,
}) => {
  // hooks
  const { t: c } = useTranslation("common");
  const { t } = useTranslation("top");
  const { t: s } = useTranslation("sample");
  const { t: p } = useTranslation("priority");
  const { palette } = useStyles();
  // ref
  const mindmapElm = useRef<HTMLDivElement>(null);
  // states
  const [tasks, setTasks] = useState<Task[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const [
    nextTaskIdAfterDeletingNewTask,
    setNextTaskIdAfterDeletingNewTask,
  ] = useState("");
  const [forceOrganizeCounter, setForceOrganizeCounter] = useState(0);
  const setTask = (task: Task): void => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  // Force Organize
  const organize = () => {
    setForceOrganizeCounter(forceOrganizeCounter + 1);
  };

  // Ref & Focus
  const focusMindmap = () => {
    mindmapElm.current && mindmapElm.current.focus();
  };
  const isClickToFocusDisabled = tasks.some((t) => t.isEditing);

  // projects & project
  useEffect(() => {
    setTasks([]);
    setLines([]);
  }, []);

  useEffect(() => {
    loadData();
  }, [t]);

  useEffect(() => {
    focusMindmap();
  }, [focusTrigger]);

  const loadData = () => {
    const { newTasks } = createSampleDate(
      s("userName"),
      s("member1Name"),
      s("member2Name"),
      p("priority"),
      p("high"),
      p("medium"),
      p("low"),
      s("create"),
      s("design"),
      s("logo"),
      s("map"),
      s("ui"),
      s("keyboardShortcuts"),
      s("mouseActions"),
      s("policies")
    );
    setTasks(newTasks);
  };

  useEffect(() => {
    organize();
  }, [tasks]);

  useEffect(() => {
    const level1Task = tasks.find((t) => t.level === 1);
    if (!level1Task) return;
    setTaskTop(mindmapTopPadding, level1Task);
    setTaskLeft(tasks);
    setLines(createLines(tasks));
    setMindmapSize();
  }, [forceOrganizeCounter]);

  const setMindmapSize = () => {
    const height =
      Math.max(...tasks.map((t) => t.bottom)) + mindmapBottomPadding;
    const width =
      Math.max(...tasks.map((t) => t.right)) + mindmapHorizontalPadding;
    if (mindmapElm.current) {
      mindmapElm.current.style.height = height + "px";
      mindmapElm.current.style.width = width + "px";
    }
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onMindmapMouseMove(event.pageX, event.pageY);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const task = tasks.find((t) => t.selected);
    if (!task) return;

    if (event.ctrlKey || event.metaKey) {
      onKeyDownWithCtrl(task, event);
    } else if (event.shiftKey) {
      onKeyDownWithShift(task, event);
    } else {
      onKeyDownPlain(task, event);
    }
  };
  const onKeyDownWithShift = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        addSibling(task, "ABOVE");
        addCommand("addAbove");
        break;
      default:
        break;
    }
  };
  const onKeyDownWithCtrl = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Backspace":
        event.preventDefault();
        deleteTask(task);
        addCommand("delete");
        break;
      case "Enter":
        event.preventDefault();
        startEditing(task);
        addCommand("edit");
        break;
      default:
        break;
    }
  };
  const onKeyDownPlain = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        addSibling(task, "BELOW");
        addCommand("addBelow");
        break;
      case "F2":
        event.preventDefault();
        startEditing(task);
        addCommand("edit");
        break;
      case "Delete":
        event.preventDefault();
        deleteTask(task);
        addCommand("delete");
        break;
      case "Tab":
        event.preventDefault();
        addNewChild(task);
        addCommand("addChild");
        break;
      case " ":
        event.preventDefault();
        toggleDone(task);
        addCommand("doneUndone");
        break;
      case "ArrowUp":
        event.preventDefault();
        move(task, "UP");
        addCommand("move");
        break;
      case "ArrowDown":
        event.preventDefault();
        move(task, "DOWN");
        addCommand("move");
        break;
      case "ArrowLeft":
        event.preventDefault();
        move(task, "LEFT");
        addCommand("move");
        break;
      case "ArrowRight":
        event.preventDefault();
        move(task, "RIGHT");
        addCommand("move");
        break;
      default:
        break;
    }
  };

  // ノード追加
  const addNewChild = (task: Task) => {
    const { newTasks } = blAddChild(tasks, task);
    setTasks(newTasks);
  };
  const addSibling = (task: Task, direction: "ABOVE" | "BELOW") => {
    if (!task.parent) return;

    const { newTasks } = blAddSibling(tasks, task, task.parent, direction);
    setTasks(newTasks);

    // 空で確定してタスクが削除された場合の戻り先
    setNextTaskIdAfterDeletingNewTask(task.id);
  };

  // ノード削除
  const deleteTask = (task: Task): void => {
    if (task.level === 1 || !task.parent) return;

    const targetTasks = [task]
      .concat(tasks.filter((t) => t.multiSelected))
      // multiSelected と selected がダブった時のために重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTasks = targetTasks
      // 自分自身と子孫全てを含んだ配列にする
      .map((t) => [t].concat(t.allChildren))
      // 1つの配列にする
      .flat()
      // 重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTaskIds = deletingTasks.map((t) => t.id);

    const { newTasks } = blDeleteTask(
      tasks,
      targetTasks,
      deletingTaskIds,
      task.parent,
      nextTaskIdAfterDeletingNewTask,
      ""
    );
    setTasks(newTasks);
  };

  // done
  const toggleDone = (task: Task) => {
    if (task.children.length) return;
    task.done = !task.done;
    setTask(task);
  };

  // edit
  const startEditing = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = t.id === task.id;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  const endEditingText = (task: Task, text: string) => {
    // 先にフォーカスを移さないと ClikcToFocus が一瞬表示されてしまう
    focusMindmap();

    if (text.trim() || task.children.length) {
      // text があるか、子供がいる場合は削除できないので更新処理
      task.text = text.trim();
      task.selected = true;
      task.isEditing = false;
      task.isNew = false;
      setTask(task);
    } else if (!task.children.length) {
      // 子供がない場合は削除
      deleteTask(task);
    }
    // 編集後に戻り先のタスクをリセットしておかないと
    // 別の場所で削除した時に引き継がれてしまう
    setNextTaskIdAfterDeletingNewTask("");
  };

  const move = (task: Task, direction: "UP" | "DOWN" | "RIGHT" | "LEFT") => {
    const nextTask =
      direction === "UP"
        ? getClosestAboveTask(tasks, task)
        : direction === "DOWN"
        ? getClosestBelowTask(tasks, task)
        : direction === "RIGHT"
        ? getClosestRightTask(tasks, task)
        : // LEFT
          getClosestLeftTask(task);
    if (nextTask) {
      selectTask(nextTask);
      adjustScroll(nextTask);
    }
  };
  const selectTask = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.isSetting = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        return t;
      })
    );
  };
  const onClickTask = (event: React.MouseEvent<HTMLDivElement>, task: Task) => {
    selectTask(task);
  };

  return (
    <Container
      ref={mindmapElm}
      tabIndex={0}
      isGrabbing={isGrabbingMindmap}
      isClickToFocusDisabled={isClickToFocusDisabled}
      onKeyDown={onKeyDown}
      onMouseDown={onMindmapMouseDown}
      onMouseOut={onMindmapMouseOut}
      onMouseMove={onMouseMove}
      onMouseUp={onMindmapMouseUp}
    >
      {tasks.map((t) => (
        <ComponentTask
          key={t.id}
          task={t}
          onClick={onClickTask}
          toggleDone={toggleDone}
          endEditingText={endEditingText}
          setTask={setTask}
          adjustScroll={adjustScroll}
        />
      ))}
      {lines.map((l) => (
        <ComponentLine
          key={l.startTask.id + l.endTask.id}
          startX={l.startX}
          startY={l.startY}
          endX={l.endX}
          endY={l.endY}
          lineStrokeColor={
            palette[(l.endTask.topSortNumber - 1) % palette.length]
          }
        />
      ))}
      {allDone && <ScrollToNext />}
      <ClickToFocus className="click-to-focus">
        {c("clickToFocus")}
      </ClickToFocus>
    </Container>
  );
};

type ContainerProps = {
  isGrabbing: boolean;
  isClickToFocusDisabled: boolean;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: 100%;
  position: relative;
  cursor: ${({ isGrabbing }) => (isGrabbing ? "grabbing" : "grab")};
  outline: none;

  /* focus してない時は編集時、設定時なら none */
  div.click-to-focus {
    display: ${({ isClickToFocusDisabled }) =>
      isClickToFocusDisabled ? "none" : ""};
  }
  /* focus 時は常に none */
  :focus {
    div.click-to-focus {
      display: none;
    }
  }
`;

export default Mindmap;
