import React from "react";
import { useTranslation } from "react-i18next";
import Block from "./Block";
import Typography from "../Typography";
import { User } from "../../models/User";

type Props = {
  owner: User | null;
};

const Owner: React.FC<Props> = React.memo(({ owner }) => {
  // hooks
  const { t: o } = useTranslation("owner");
  return (
    <Block>
      <Typography variant="medium" bold>
        {o("owner")}
      </Typography>
      {owner && <Typography margin="4px 0px 0px 0px">{owner.name}</Typography>}
    </Block>
  );
});
Owner.displayName = "Owner";

export default Owner;
