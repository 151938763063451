export type Command =
  | "move"
  | "addChild"
  | "addBelow"
  | "addAbove"
  | "edit"
  | "doneUndone"
  | "moveUp"
  | "moveDown"
  | "moveToTop"
  | "moveToBottom"
  | "delete"
  | "copy"
  | "cut"
  | "paste"
  | "outdent"
  | "openAssigning"
  | "assign"
  | "openSettingDueDate"
  | "setDueDate"
  | "openLabeling"
  | "setLabel"
  | "openTotalSettings"
  | "openFilter"
  | "setStatusFilter"
  | "setAssigneeFilter"
  | "setDueDateFilter"
  | "setLabelFilter"
  | "closeFilter"
  | "onlyText";

export const tutorial1Commands: Command[] = [
  "move",
  "addChild",
  "addBelow",
  "addAbove",
  "edit",
  "doneUndone",
  "delete",
];

export const tutorial2Commands: Command[] = [
  "moveUp",
  "moveDown",
  "moveToTop",
  "moveToBottom",
  "outdent",
  "copy",
  "cut",
  "paste",
];

export const tutorial3Commands: Command[] = [
  "openAssigning",
  "assign",
  "openSettingDueDate",
  "setDueDate",
  "openLabeling",
  "setLabel",
  "openTotalSettings",
];

export const tutorial4Commands: Command[] = [
  "openFilter",
  "setStatusFilter",
  "setAssigneeFilter",
  "setDueDateFilter",
  "setLabelFilter",
  "closeFilter",
  "onlyText",
];
