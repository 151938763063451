import React, { useState, useRef } from "react";
import styled from "styled-components";
import Mindmap from "./Mindmap";
import { FlexRowCenter } from "../../../components/StyledComponents";
import TodoTable from "../TodoTable";
import { Task } from "../../../models/Task";
import { Command, tutorial4Commands } from "../../../models/Command";
import useAnalytics from "../../../hooks/useAnalytics";
import { taskMargin, lineWidth } from "../../../constants";
import { recMargin } from "../constants";

type Props = {
  focusTrigger: number;
  addScrollTop: (value: number) => void;
};

const FilterTutorial: React.FC<Props> = ({ focusTrigger, addScrollTop }) => {
  // refs
  const screenElm = useRef<HTMLDivElement>(null);
  // hooks
  const { sendTutorialCommandLog, sendTutorialCompletedLog } = useAnalytics();
  // drag
  const [isGrabbingMindmap, setIsGrabbingMindmap] = useState(false);
  const [grabbedPageX, setGrabbedPageX] = useState(0);
  const [grabbedScrollLeft, setGrabbedScrollLeft] = useState(0);
  const [grabbedPageY, setGrabbedPageY] = useState(0);
  const [grabbedScrollTop, setGrabbedScrollTop] = useState(0);
  // todo
  const [doneCommands, setDoneCommands] = useState<Command[]>([]);
  const addCommand = (command: Command) => {
    if (!doneCommands.includes(command)) {
      const newCommands = doneCommands.slice();
      newCommands.push(command);
      setDoneCommands(newCommands);
      sendTutorialCommandLog(command);
      if (newCommands.length >= tutorial4Commands.length) {
        sendTutorialCompletedLog("Filter");
      }
    }
  };

  const onMindmapMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsGrabbingMindmap(true);
    setGrabbedPageX(event.pageX);
    setGrabbedPageY(event.pageY);
    if (screenElm.current) {
      setGrabbedScrollTop(screenElm.current.scrollTop);
      setGrabbedScrollLeft(screenElm.current.scrollLeft);
    }
  };

  const onMindmapMouseMove = (pageX: number, pageY: number) => {
    if (isGrabbingMindmap) {
      if (!screenElm.current) return;
      screenElm.current.scrollTop = grabbedScrollTop - (pageY - grabbedPageY);
      screenElm.current.scrollLeft = grabbedScrollLeft - (pageX - grabbedPageX);
    }
  };

  const onMindmapMouseUp = () => {
    if (isGrabbingMindmap) {
      setIsGrabbingMindmap(false);
    }
  };

  const onMindmapMouseOut = () => {
    setIsGrabbingMindmap(false);
  };

  // ノード移動後のスクロール移動
  const adjustScroll = (target: Task) => {
    if (!screenElm.current) return;
    // top
    if (screenElm.current.scrollTop + taskMargin > target.top) {
      const margin = screenElm.current.scrollTop - target.top;
      screenElm.current.scrollTop =
        screenElm.current.scrollTop - margin - taskMargin;
    }
    // bottom
    if (
      screenElm.current.scrollTop +
        screenElm.current.clientHeight -
        taskMargin <
      target.bottom
    ) {
      const margin =
        target.bottom -
        screenElm.current.scrollTop -
        screenElm.current.clientHeight;
      screenElm.current.scrollTop =
        screenElm.current.scrollTop + margin + taskMargin + 20;
    }
    // right
    // target.right + lineWidth が目標
    const rightMargin =
      target.right +
      lineWidth -
      screenElm.current.scrollLeft -
      screenElm.current.clientWidth;
    if (rightMargin > 0) {
      screenElm.current.scrollLeft = screenElm.current.scrollLeft + rightMargin;
    }
    // left
    // targetTask.right + lineWidth が目標
    const leftMargin = screenElm.current.scrollLeft + lineWidth - target.left;
    if (leftMargin > 0) {
      screenElm.current.scrollLeft = screenElm.current.scrollLeft - leftMargin;
    }
  };

  const onWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight ===
        event.currentTarget.scrollTop &&
      event.deltaY > 0
    ) {
      // スクロールが一番下かつ下にスクロール
      addScrollTop(event.deltaY);
    } else if (event.currentTarget.scrollTop === 0 && event.deltaY < 0) {
      // スクロールが一番上かつ上にスクロール
      addScrollTop(event.deltaY);
    }
  };

  return (
    <Container>
      <TodoTable todoCommands={tutorial4Commands} doneCommands={doneCommands} />
      <Screen ref={screenElm} className="scroll-none" onWheel={onWheel}>
        <Mindmap
          focusTrigger={focusTrigger}
          isGrabbingMindmap={isGrabbingMindmap}
          onMindmapMouseDown={onMindmapMouseDown}
          onMindmapMouseMove={onMindmapMouseMove}
          onMindmapMouseUp={onMindmapMouseUp}
          onMindmapMouseOut={onMindmapMouseOut}
          adjustScroll={adjustScroll}
          addCommand={addCommand}
          allDone={doneCommands.length === tutorial4Commands.length}
        />
      </Screen>
    </Container>
  );
};

const Container = styled(FlexRowCenter)``;

const Screen = styled.div`
  font-family: initial;
  height: calc(100vh - ${recMargin * 2}px);
  width: calc(100vw - ${recMargin * 2}px);
  overflow: auto;
  pointer-events: initial;
`;

export default FilterTutorial;
