import React, { useContext } from "react";
import styled from "styled-components";
import { FlexRowCenter } from "../../components/StyledComponents";
import Icon from "../../components/Button/Icon";
import MapContext from "./context";
import {
  toolboxIconSize,
  toolboxMarginTop,
  toolboxBottom,
  toolboxRight,
  toolboxZIndex,
} from "../../constants";

const Toolbox: React.FC = React.memo(() => {
  // context
  const { setIsHelpOpen, openOrFocusFilter } = useContext(MapContext);

  const onClickHelp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsHelpOpen(true);
  };
  const onFilterMouseEnter = () => {
    openOrFocusFilter();
  };
  const onClickFilter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openOrFocusFilter();
  };
  return (
    <Container marginTop={toolboxMarginTop}>
      <Icon
        icon="help"
        size={toolboxIconSize}
        selected={false}
        onClick={onClickHelp}
      />
      <Icon
        icon="search"
        size={toolboxIconSize}
        selected={false}
        onClick={onClickFilter}
        onMouseEnter={onFilterMouseEnter}
      />
    </Container>
  );
});
Toolbox.displayName = "Toolbox";

const marginLeft = 6;

const Container = styled(FlexRowCenter)<{ marginTop: number }>`
  margin-top: ${({ marginTop }) => marginTop};

  position: fixed;
  bottom: ${toolboxBottom}px;
  right: ${toolboxRight}px;
  z-index: ${toolboxZIndex};

  > div {
    margin-left: ${marginLeft}px;
  }
`;

export default Toolbox;
