import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Forward } from "@material-ui/icons";
import { FlexRowCenter } from "../../components/StyledComponents";

type Props = {
  taskHeight: number;
  left: number;
  color: string;
};

const YouAreHere: React.FC<Props> = React.memo(
  ({ taskHeight, left, color }) => {
    const { t: s } = useTranslation("sample");

    const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    };

    return (
      <Container
        taskHeight={taskHeight}
        left={left}
        color={color}
        onContextMenu={onContextMenu}
      >
        <Forward
          style={{ height: 30, width: 30, transform: "rotate(180deg)" }}
        />
        <Text style={{ width: 100 }}>{s("youAreHere")}</Text>
      </Container>
    );
  }
);
YouAreHere.displayName = "YouAreHere";

type ContainerProps = {
  taskHeight: number;
  left: number;
  color: string;
};
const Container = styled(FlexRowCenter)<ContainerProps>`
  color: ${({ color }) => color};
  position: absolute;
  top: ${({ taskHeight }) => taskHeight / 2 - 17}px;
  left: ${({ left }) => left}px;
  animation: shaking 1s ease-in-out infinite;
`;

const Text = styled.div`
  font-weight: bold;
`;

export default YouAreHere;
