import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ComponentTask from "./Task";
import ComponentLine from "../../projects/Line";
import ContextMenu from "../../../components/ContextMenu";
import ScrollToNext from "../ScrollToNext";
import ClickToFocus from "../../../components/ClickToFocus";
import { setTaskTop, createLines } from "../../../bl/organize";
import { setTaskLeft, createSampleDate } from "../bl";
import { blAddChild, blAddSibling } from "../../../bl/task/add";
import { blDeleteTask } from "../../../bl/task/delete";
import { blMoveToEdge, blSwitchTasks } from "../../../bl/task/move";
import { blPasteCopiedTask } from "../../../bl/task/copy";
import { blPasteCutTask, blOutdentTasks } from "../../../bl/task/cut";
import {
  getClosestAboveTask,
  getClosestBelowTask,
  getClosestRightTask,
  getClosestLeftTask,
  getElderTask,
  getYoungerTask,
} from "../../../bl/task/select";
import { User } from "../../../models/User";
import { Task } from "../../../models/Task";
import { Line } from "../../../models/Line";
import { Command } from "../../../models/Command";
import { Label, TaskLabel } from "../../../models/Label";
import { SettingCategory } from "../../../models/Setting";
import useStyles from "../../../hooks/useStyles";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  mindmapTopPadding,
  mindmapBottomPadding,
  mindmapHorizontalPadding,
} from "../constants";

type Props = {
  focusTrigger: number;
  isGrabbingMindmap: boolean;
  onMindmapMouseDown: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onMindmapMouseMove: (pageX: number, pageY: number) => void;
  onMindmapMouseUp: () => void;
  onMindmapMouseOut: () => void;
  adjustScroll: (target: Task) => void;
  addCommand: (command: Command) => void;
  allDone: boolean;
};

const Mindmap: React.FC<Props> = ({
  focusTrigger,
  isGrabbingMindmap,
  onMindmapMouseDown,
  onMindmapMouseMove,
  onMindmapMouseUp,
  onMindmapMouseOut,
  adjustScroll,
  addCommand,
  allDone,
}) => {
  // hooks
  const { t: c } = useTranslation("common");
  const { t } = useTranslation("top");
  const { t: s } = useTranslation("sample");
  const { t: p } = useTranslation("priority");
  const { palette, color, node } = useStyles();
  const { setDifferentParentMessage } = useSnackbar();
  // ref
  const mindmapElm = useRef<HTMLDivElement>(null);
  // states
  const [tasks, setTasks] = useState<Task[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const [shiftMoveStartTask, setShiftMoveStartTask] = useState<Task | null>(
    null
  );
  const [
    nextTaskIdAfterDeletingNewTask,
    setNextTaskIdAfterDeletingNewTask,
  ] = useState("");
  const [forceOrganizeCounter, setForceOrganizeCounter] = useState(0);
  const [labels, setLabels] = useState<Label[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [members, setMembers] = useState<User[]>([]);
  const setTask = (task: Task): void => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };
  // Context menu
  const [contextMenuTask, setContextMenuTask] = useState<Task | null>(null);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  // Force Organize
  const organize = () => {
    setForceOrganizeCounter(forceOrganizeCounter + 1);
  };

  // Ref & Focus
  const focusMindmap = () => {
    mindmapElm.current && mindmapElm.current.focus();
  };
  const isClickToFocusDisabled = tasks.some(
    (t) =>
      t.isEditing ||
      t.isSetting ||
      t.isAssigning ||
      t.isSettingDueDate ||
      t.isLabeling
  );

  // projects & project
  useEffect(() => {
    setTasks([]);
    setLines([]);
  }, []);

  useEffect(() => {
    loadData();
  }, [t]);

  useEffect(() => {
    focusMindmap();
  }, [focusTrigger]);

  const loadData = () => {
    const { user, members, labels, newTasks } = createSampleDate(
      s("userName"),
      s("member1Name"),
      s("member2Name"),
      p("priority"),
      p("high"),
      p("medium"),
      p("low"),
      s("create"),
      s("design"),
      s("logo"),
      s("map"),
      s("ui"),
      s("keyboardShortcuts"),
      s("mouseActions"),
      s("policies")
    );
    setUser(user);
    setMembers(members);
    setLabels(labels);
    setTasks(newTasks);
  };

  useEffect(() => {
    organize();
  }, [tasks]);

  useEffect(() => {
    const level1Task = tasks.find((t) => t.level === 1);
    if (!level1Task) return;
    setTaskTop(mindmapTopPadding, level1Task);
    setTaskLeft(tasks);
    setLines(createLines(tasks));
    setMindmapSize();
  }, [forceOrganizeCounter]);

  const setMindmapSize = () => {
    const height =
      Math.max(...tasks.map((t) => t.bottom)) + mindmapBottomPadding;
    const width =
      Math.max(...tasks.map((t) => t.right)) + mindmapHorizontalPadding;
    if (mindmapElm.current) {
      mindmapElm.current.style.height = height + "px";
      mindmapElm.current.style.width = width + "px";
    }
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onMindmapMouseMove(event.pageX, event.pageY);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const task = tasks.find((t) => t.selected);
    if (!task) return;

    if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
      onKeyDownWithCtrlAndShift(task, event);
    } else if (event.ctrlKey || event.metaKey) {
      onKeyDownWithCtrl(task, event);
    } else if (event.shiftKey) {
      onKeyDownWithShift(task, event);
    } else {
      onKeyDownPlain(task, event);
    }
  };
  const onKeyDownWithCtrlAndShift = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        moveToEdge(task, "TOP");
        break;
      case "ArrowDown":
        event.preventDefault();
        moveToEdge(task, "BOTTOM");
        break;
      default:
        break;
    }
  };
  const onKeyDownWithShift = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        addSibling(task, "ABOVE");
        break;
      case "ArrowUp":
        event.preventDefault();
        shiftMove(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        shiftMove(task, "DOWN");
        break;
      default:
        break;
    }
  };
  const onKeyDownWithCtrl = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Backspace":
        event.preventDefault();
        deleteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Enter":
        event.preventDefault();
        startEditing(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "ArrowUp":
        event.preventDefault();
        switchTasks(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        switchTasks(task, "DOWN");
        break;
      case "[":
        event.preventDefault();
        outdentTasks(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "a":
        event.preventDefault();
        startAssigning(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        addCommand("openAssigning");
        break;
      case "d":
        event.preventDefault();
        startSettingDueDate(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        addCommand("openSettingDueDate");
        break;
      case "l":
        event.preventDefault();
        startLabeling(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        addCommand("openLabeling");
        break;
      case "c":
        event.preventDefault();
        copyTask();
        break;
      case "x":
        event.preventDefault();
        cutTask();
        break;
      case "v":
        event.preventDefault();
        pasteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      default:
        break;
    }
  };
  const onKeyDownPlain = (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        addSibling(task, "BELOW");
        break;
      case "F2":
        event.preventDefault();
        startEditing(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Delete":
        event.preventDefault();
        deleteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Tab":
        event.preventDefault();
        addNewChild(task);
        break;
      case " ":
        event.preventDefault();
        toggleDone(task);
        break;
      case "ArrowUp":
        event.preventDefault();
        move(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        move(task, "DOWN");
        break;
      case "ArrowLeft":
        event.preventDefault();
        move(task, "LEFT");
        break;
      case "ArrowRight":
        event.preventDefault();
        move(task, "RIGHT");
        break;
      default:
        break;
    }
  };

  // ノード追加
  const addNewChild = (task: Task) => {
    const { newTasks } = blAddChild(tasks, task);
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
  };
  const addSibling = (task: Task, direction: "ABOVE" | "BELOW") => {
    if (!task.parent) return;

    const { newTasks } = blAddSibling(tasks, task, task.parent, direction);
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);

    // 空で確定してタスクが削除された場合の戻り先
    setNextTaskIdAfterDeletingNewTask(task.id);
  };

  // ノード削除
  const deleteTask = (task: Task): void => {
    if (task.level === 1 || !task.parent) return;

    const targetTasks = [task]
      .concat(tasks.filter((t) => t.multiSelected))
      // multiSelected と selected がダブった時のために重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTasks = targetTasks
      // 自分自身と子孫全てを含んだ配列にする
      .map((t) => [t].concat(t.allChildren))
      // 1つの配列にする
      .flat()
      // 重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTaskIds = deletingTasks.map((t) => t.id);

    const { newTasks } = blDeleteTask(
      tasks,
      targetTasks,
      deletingTaskIds,
      task.parent,
      nextTaskIdAfterDeletingNewTask,
      ""
    );
    setTasks(newTasks);
  };

  // ノード移動
  const switchTasks = (task: Task, direction: "UP" | "DOWN") => {
    if (!task.parent) return;

    const parent = task.parent;
    // フィルターによって処理が変わるのはここだけ
    const children = parent.children;
    // フィルターの有無関係なく処理するために index で入れ替える
    const sourceIndex = children.findIndex((c) => c.id === task.id);
    if (direction === "UP" && sourceIndex === 0) return;
    if (direction === "DOWN" && sourceIndex === children.length - 1) return;

    const target =
      direction === "UP"
        ? children[sourceIndex - 1]
        : children[sourceIndex + 1];
    if (!target) return;

    const { newTasks } = blSwitchTasks(tasks, task, target, parent, "");
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
  };
  const moveToEdge = (task: Task, direction: "TOP" | "BOTTOM") => {
    if (!task.parent || task.parent.children.length < 2) return;

    const parent = task.parent;
    // フィルターの有無関係なく処理するために index で入れ替える
    const sourceIndex = parent.children.findIndex((c) => c.id === task.id);
    if (direction === "TOP" && sourceIndex === 0) return;
    if (direction === "BOTTOM" && sourceIndex === parent.children.length - 1)
      return;

    const { newTasks } = blMoveToEdge(tasks, task, task.parent, direction, "");
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
    setTasks(newTasks);
  };

  // Copy, Cut, Paste, Outdent
  const copyTask = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = t.selected || t.multiSelected;
        t.cut = false;
        return t;
      })
    );
  };
  const cutTask = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = false;
        t.cut = t.selected || t.multiSelected;
        return t;
      })
    );
  };
  const resetClipboard = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  const pasteTask = (task: Task) => {
    const copiedTasks = tasks.filter((t) => t.copied);
    const cutTasks = tasks.filter((t) => t.cut);
    if (copiedTasks.length) {
      pasteCopiedTask(task, copiedTasks);
    } else if (cutTasks.length) {
      pasteCutTask(task, cutTasks);
    } else {
      resetClipboard();
    }
  };
  const pasteCopiedTask = (target: Task, copiedTasks: Task[]) => {
    const { newTasks } = blPasteCopiedTask(tasks, target, copiedTasks, "");
    setTasks(newTasks);
  };
  const pasteCutTask = (target: Task, cutTasks: Task[]) => {
    if (cutTasks.some((c) => c.id === target.id)) {
      resetClipboard();
      return;
    }
    const formerParent = cutTasks[0].parent;
    if (!formerParent) return;

    const { newTasks } = blPasteCutTask(
      tasks,
      cutTasks,
      formerParent,
      target,
      ""
    );
    setTasks(newTasks);
  };
  const outdentTasks = (task: Task) => {
    const formerParent = task.parent;
    if (!formerParent || formerParent.level === 1) return;

    const nextParent = formerParent.parent;
    if (!nextParent) return;

    const targetChildren = formerParent.children.filter(
      (c) => c.selected || c.multiSelected
    );
    if (!targetChildren.length) return;

    const { newTasks } = blOutdentTasks(
      tasks,
      task,
      targetChildren,
      formerParent,
      nextParent,
      ""
    );
    setTasks(newTasks);
  };

  // done
  const toggleDone = (task: Task) => {
    if (task.children.length) return;
    task.done = !task.done;
    setTask(task);
  };

  // edit
  const startEditing = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = t.id === task.id;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  const endEditingText = (task: Task, text: string) => {
    // 先にフォーカスを移さないと ClikcToFocus が一瞬表示されてしまう
    focusMindmap();

    if (text.trim() || task.children.length) {
      // text があるか、子供がいる場合は削除できないので更新処理
      task.text = text.trim();
      task.selected = true;
      task.isEditing = false;
      task.isNew = false;
      setTask(task);
    } else if (!task.children.length) {
      // 子供がない場合は削除
      deleteTask(task);
    }
    // 編集後に戻り先のタスクをリセットしておかないと
    // 別の場所で削除した時に引き継がれてしまう
    setNextTaskIdAfterDeletingNewTask("");
  };

  // settings
  const startAssigning = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = t.id === task.id;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endAssigning = async (
    task: Task,
    assignee: User | null,
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.assignee = assignee;
    task.isAssigning = false;
    if (nextCategory === "DueDate") {
      task.isSettingDueDate = true;
    } else if (nextCategory === "Label") {
      task.isLabeling = true;
    }
    setTask(task);
    addCommand("assign");
  };
  const startSettingDueDate = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = t.id === task.id;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endSettingDueDate = async (
    task: Task,
    dueDate: Date | null,
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.dueDate = dueDate;
    task.isSettingDueDate = false;
    if (nextCategory === "Assignee") {
      task.isAssigning = true;
    } else if (nextCategory === "Label") {
      task.isLabeling = true;
    }
    setTask(task);
    addCommand("setDueDate");
  };
  const startLabeling = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = t.id === task.id;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
  };
  const endLabeling = async (
    task: Task,
    labels: TaskLabel[],
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.labels = labels;
    task.isLabeling = false;
    if (nextCategory === "Assignee") {
      task.isAssigning = true;
    } else if (nextCategory === "DueDate") {
      task.isSettingDueDate = true;
    }
    setTask(task);
    addCommand("setLabel");
  };

  const move = (task: Task, direction: "UP" | "DOWN" | "RIGHT" | "LEFT") => {
    const nextTask =
      direction === "UP"
        ? getClosestAboveTask(tasks, task)
        : direction === "DOWN"
        ? getClosestBelowTask(tasks, task)
        : direction === "RIGHT"
        ? getClosestRightTask(tasks, task)
        : // LEFT
          getClosestLeftTask(task);
    if (nextTask) {
      selectTask(nextTask);
      setShiftMoveStartTask(null);
      setContextMenuTask(null);
      adjustScroll(nextTask);
    }
  };
  const shiftMove = (task: Task, direction: "UP" | "DOWN") => {
    if (!task.parent) return;

    const nextTask =
      direction === "UP"
        ? getElderTask(task, task.parent, false)
        : getYoungerTask(task, task.parent, false);
    if (!nextTask) {
      setDifferentParentMessage();
      return;
    }

    if (shiftMoveStartTask) {
      shiftSelectTask(shiftMoveStartTask, nextTask);
    } else {
      // 最初のノード
      setShiftMoveStartTask(task);
      shiftSelectTask(task, nextTask);
    }
    setContextMenuTask(null);
  };
  const shiftSelectTask = (startTask: Task, endTask: Task) => {
    setTasks(
      tasks.map((t) => {
        t.selected = t.id === endTask.id;
        if (
          t.parentId === startTask.parentId &&
          ((t.sortNumber >= startTask.sortNumber &&
            t.sortNumber <= endTask.sortNumber) ||
            (t.sortNumber <= startTask.sortNumber &&
              t.sortNumber >= endTask.sortNumber))
        ) {
          t.multiSelected = true;
        } else {
          t.multiSelected = false;
        }
        return t;
      })
    );
  };
  const selectTask = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.isSetting = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        return t;
      })
    );
  };
  const onClickTask = (event: React.MouseEvent<HTMLDivElement>, task: Task) => {
    if (event.shiftKey) {
      if (shiftMoveStartTask) {
        if (shiftMoveStartTask.parentId === task.parentId) {
          // Shift移動開始したタスクと同じ親を持つ場合にだけ可能
          shiftSelectTask(shiftMoveStartTask, task);
        } else {
          setDifferentParentMessage();
        }
      } else {
        // Shift移動をまだ開始してない場合
        const selectedTask = tasks.find((t) => t.selected);
        if (selectedTask && selectedTask.parentId === task.parentId) {
          // 選択されているタスクと同じ親を持つ場合にだけ可能
          shiftSelectTask(selectedTask, task);
          // Shift移動開始タスクも同時にセットしておく
          setShiftMoveStartTask(selectedTask);
        } else {
          setDifferentParentMessage();
        }
      }
    } else if (event.ctrlKey || event.metaKey) {
      ctrlSelectTask(task);
      setShiftMoveStartTask(null);
    } else {
      selectTask(task);
      setShiftMoveStartTask(null);
    }
    setContextMenuTask(null);
  };
  const ctrlSelectTask = (task: Task) => {
    if (task.selected) {
      // 選択状態のタスクの場合
      const multiSelectedTask = tasks.find((t) => t.multiSelected);
      if (multiSelectedTask) {
        // 他に複数選択状態のタスクがあれば適当に選択状態を移動
        multiSelectedTask.selected = true;
        task.selected = false;
        setTasks(
          tasks.map((t) => {
            if (t.id === task.id) return task;
            if (t.id === multiSelectedTask.id) return multiSelectedTask;
            return t;
          })
        );
      } else {
        // 複数選択状態のタスクがない場合は選択状態のタスクがなくなるとまずいので何もしない
      }
    } else {
      const selectedTask = tasks.find((t) => t.selected);
      if (!selectedTask) return;
      if (selectedTask.parentId !== task.parentId) {
        setDifferentParentMessage();
        return;
      }
      // selected を移動させる
      selectedTask.selected = false;
      selectedTask.multiSelected = true;
      task.selected = true;
      setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    }
  };

  // Context Menu
  const onContextMenu = (
    event: React.MouseEvent<HTMLDivElement>,
    task: Task
  ) => {
    event.preventDefault();
    const bounds = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    setContextMenuTop(task.top + y - 3); // translateY分の3
    setContextMenuLeft(task.left + x);
    setContextMenuTask(task);
    selectTask(task);
  };
  const onClickContextMenuOpenSettings = () => {
    if (!contextMenuTask) return;
    startSettings(contextMenuTask);
    setContextMenuTask(null);
  };
  const startSettings = async (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = t.id === task.id;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
    addCommand("openTotalSettings");
  };
  const endSettings = async (
    task: Task,
    assignee: User | null,
    dueDate: Date | null,
    labels: TaskLabel[]
  ) => {
    focusMindmap();
    task.assignee = assignee;
    task.dueDate = dueDate;
    task.labels = labels;
    task.isSetting = false;
    setTask(task);
  };
  const onClickContextMenuAddChild = async () => {
    if (!contextMenuTask) return;
    addNewChild(contextMenuTask);
  };
  const onClickContextMenuAddTaskBelow = async () => {
    if (!contextMenuTask) return;
    addSibling(contextMenuTask, "BELOW");
  };
  const onClickContextMenuAddTaskAbove = async () => {
    if (!contextMenuTask) return;
    addSibling(contextMenuTask, "ABOVE");
  };

  const onMindmapMouseDownLocal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    contextMenuTask && setContextMenuTask(null);
    onMindmapMouseDown(event);
  };

  return (
    <Container
      ref={mindmapElm}
      tabIndex={0}
      isGrabbing={isGrabbingMindmap}
      isClickToFocusDisabled={isClickToFocusDisabled}
      color={color}
      onKeyDown={onKeyDown}
      onMouseDown={onMindmapMouseDownLocal}
      onMouseOut={onMindmapMouseOut}
      onMouseMove={onMouseMove}
      onMouseUp={onMindmapMouseUp}
    >
      {tasks.map((t) => (
        <ComponentTask
          key={t.id}
          user={user}
          members={members}
          labels={labels}
          task={t}
          onClick={onClickTask}
          onContextMenu={onContextMenu}
          toggleDone={toggleDone}
          endEditingText={endEditingText}
          setTask={setTask}
          endSettings={endSettings}
          endAssigning={endAssigning}
          endSettingDueDate={endSettingDueDate}
          endLabeling={endLabeling}
          adjustScroll={adjustScroll}
        />
      ))}
      {contextMenuTask ? (
        <ContextMenu
          borderColor={
            palette[(contextMenuTask.topSortNumber - 1) % palette.length]
          }
          top={contextMenuTop}
          left={contextMenuLeft}
          onClickContextMenuOpenSettings={onClickContextMenuOpenSettings}
          onClickContextMenuAddChild={onClickContextMenuAddChild}
          onClickContextMenuAddTaskBelow={onClickContextMenuAddTaskBelow}
          onClickContextMenuAddTaskAbove={onClickContextMenuAddTaskAbove}
        />
      ) : (
        false
      )}
      {lines.map((l) => (
        <ComponentLine
          key={l.startTask.id + l.endTask.id}
          startX={l.startX}
          startY={l.startY}
          endX={l.endX}
          endY={l.endY}
          lineStrokeColor={
            l.startTask.isCutWithAncestors
              ? node.cut.borderColor
              : palette[(l.endTask.topSortNumber - 1) % palette.length]
          }
        />
      ))}
      {allDone && <ScrollToNext />}
      <ClickToFocus className="click-to-focus">
        {c("clickToFocus")}
      </ClickToFocus>
    </Container>
  );
};

type ContainerProps = {
  isGrabbing: boolean;
  isClickToFocusDisabled: boolean;
  color: string;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: 100%;
  color: ${({ color }) => color};

  position: relative;
  cursor: ${({ isGrabbing }) => (isGrabbing ? "grabbing" : "grab")};
  outline: none;

  /* focus してない時は編集時、設定時なら none */
  div.click-to-focus {
    display: ${({ isClickToFocusDisabled }) =>
      isClickToFocusDisabled ? "none" : ""};
  }
  /* focus 時は常に none */
  :focus {
    div.click-to-focus {
      display: none;
    }
  }
`;

export default Mindmap;
