import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import firebase from "./firebase";
import "./i18n";
import { useTranslation } from "react-i18next";

// context
import Context from "./context";

// bl
import { issueId } from "./bl/common";

// db
import { getUser, getUserById, updateUserLanguage } from "./db/users";
import { getProjects, getProject } from "./db/projects";
import { getTeams, getTeam } from "./db/teams";

// hooks
import useLocalStorage from "./hooks/useLocalStorage";
import useAnalytics from "./hooks/useAnalytics";

// views
import Device from "./views";

// components
import MessageSnackbar from "./components/MessageSnackbar";

// models
import { User } from "./models/User";
import { Language } from "./models/Language";
import { Theme } from "./models/Theme";
import { Project } from "./models/Project";
import { Team } from "./models/Team";
import { SnackbarMessage } from "./models/Snackbar";

// constants
import { defaultLanguage } from "./constants";

const App: React.FC = () => {
  // hooks
  const {
    getLanguage,
    saveLanguage,
    getTheme,
    getFontSize,
    cleanProjectSettings,
  } = useLocalStorage();
  const { i18n } = useTranslation();
  const { setLanguageProperty, sendLoginLog } = useAnalytics();

  // states
  const [isCheckingUser, setIsCheckingUser] = useState(false);
  const [isMac, setIsMac] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const initialTempUserId = useMemo(() => {
    return issueId();
  }, []);
  const [tempUserId] = useState(initialTempUserId);
  const [user, setUser] = useState<User | null>(null);
  const initialTheme = useMemo(() => {
    return getTheme();
  }, []);
  const [theme, setTheme] = useState<Theme>(initialTheme);
  const initialFontSize = useMemo(() => {
    return getFontSize();
  }, []);
  const [fontSize, setFontSize] = useState(initialFontSize);
  const initialLanguage = useMemo(() => {
    return getLanguage();
  }, []);
  const [language, setLanguage] = useState<Language>(initialLanguage);
  const [message, setMessage] = useState<SnackbarMessage | null>(null);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isProjectListOpen, setIsProjectListOpen] = useState(false);
  const [
    projectListClosingTimer,
    setProjectListClosingTimer,
  ] = useState<NodeJS.Timer | null>(null);
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false);
  const [
    settingMenuClosingTimer,
    setSettingMenuClosingTimer,
  ] = useState<NodeJS.Timer | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [focusMindmapCounter, setFocusMindmapCounter] = useState(0);

  // computed
  const computedIsSettingMenuOpen = user && isSettingMenuOpen ? true : false;

  const focusMindmapFromOutside = () => {
    setFocusMindmapCounter(focusMindmapCounter + 1);
  };

  const signOut = () => {
    firebase.auth().signOut();
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        if (!user) {
          const user = await getUser(
            fbUser.uid,
            fbUser.displayName || "",
            fbUser.email || "",
            fbUser.photoURL || ""
          );
          setLanguage(user.language ?? defaultLanguage);
          setUser(user);
          sendLoginLog();
        }
      } else {
        // sign out
        setUser(null);
        setProjects([]);
      }
      setIsCheckingUser(false);
    });
  }, []);

  // isMac, isSafari
  useEffect(() => {
    if (navigator && navigator.platform) {
      setIsMac(navigator.platform.toUpperCase().includes("MAC"));
    }
    // eslint-disable-next-line
    setIsSafari((window as any).safari ? true : false);
  }, []);

  useEffect(() => {
    if (message) {
      setIsMessageOpen(true);
    }
  }, [message]);

  useEffect(() => {
    (async () => {
      const promises = [loadTeams(), loadProjects()];
      await Promise.all(promises);
    })();
  }, [user]);

  useEffect(() => {
    if (i18n.language !== language) {
      // 不要なテキスト再レンダリングを起こさない
      i18n.changeLanguage(language);
    }
    saveLanguage(language);
    (async () => {
      if (!user) return;
      await updateUserLanguage(user.userId, language);
    })();
    setLanguageProperty(language);
  }, [language]);

  // ProjectList
  useEffect(() => {
    if (isProjectListOpen) {
      projectListClosingTimer && clearTimeout(projectListClosingTimer);
      const timer = setTimeout(() => {
        setIsProjectListOpen(false);
      }, 10000);
      setProjectListClosingTimer(timer);
    }
  }, [isProjectListOpen]);
  // SettingMenu
  useEffect(() => {
    if (isSettingMenuOpen) {
      settingMenuClosingTimer && clearTimeout(settingMenuClosingTimer);
      const timer = setTimeout(() => {
        setIsSettingMenuOpen(false);
      }, 10000);
      setSettingMenuClosingTimer(timer);
    }
  }, [isSettingMenuOpen]);

  const updateLocalUser = async () => {
    if (!user) return;
    setUser(await getUserById(user.userId));
  };

  const loadProjects = async (): Promise<void> => {
    if (!user) return;
    const newProjects = await getProjects(user.userId);
    setProjects(newProjects);
    // 不要になった設定を削除
    cleanProjectSettings(newProjects);
  };

  const addLocalProject = async (projectId: string): Promise<void> => {
    const newProject = await getProject(projectId);
    if (newProject) {
      const newProjects = projects.slice();
      newProjects.push(newProject);
      setProjects(newProjects);
    }
  };

  const updateLocalProject = async (projectId: string): Promise<void> => {
    const newProject = await getProject(projectId);
    if (newProject) {
      setProjects(
        projects.map((p) =>
          p.projectId === newProject.projectId ? newProject : p
        )
      );
    }
  };

  const deleteLocalProject = (projectId: string): void => {
    setProjects(projects.filter((p) => p.projectId !== projectId));
  };

  const loadTeams = async () => {
    if (!user) return;
    setTeams(await getTeams(user.userId));
  };

  const addLocalTeam = async (teamId: string): Promise<void> => {
    const newTeam = await getTeam(teamId);
    if (newTeam) {
      const newTeams = teams.slice();
      newTeams.push(newTeam);
      setTeams(newTeams);
    }
  };

  const updateLocalTeam = async (teamId: string): Promise<void> => {
    const newTeam = await getTeam(teamId);
    if (newTeam) {
      setTeams(teams.map((o) => (o.teamId === newTeam.teamId ? newTeam : o)));
    }
  };

  return (
    <Context.Provider
      value={{
        isMac,
        isSafari,
        isCheckingUser,
        tempUserId,
        user,
        setUser,
        language,
        setLanguage,
        updateLocalUser,
        signOut,
        theme,
        setTheme,
        fontSize,
        setFontSize,
        setMessage,
        isProjectListOpen,
        setIsProjectListOpen,
        isSettingMenuOpen: computedIsSettingMenuOpen,
        setIsSettingMenuOpen,
        projects,
        addLocalProject,
        updateLocalProject,
        deleteLocalProject,
        teams,
        addLocalTeam,
        updateLocalTeam,
        focusMindmapCounter,
        focusMindmapFromOutside,
      }}
    >
      <Device />
      {message ? (
        <MessageSnackbar
          message={message}
          isOpen={isMessageOpen}
          setIsOpen={setIsMessageOpen}
        />
      ) : (
        false
      )}
    </Context.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
