import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Check } from "@material-ui/icons";
import Typography from "../../../../components/Typography";
import {
  FlexRowCenter,
  FlexColumnCenter,
} from "../../../../components/StyledComponents";
import useStyles from "../../../../hooks/useStyles";
import { LabelItem } from "../../../../models/Label";
import { baseBorderRadius, modalBackgroundZIndex } from "../../../../constants";
import colors from "../../../../json/colors.json";

const textColors = [
  {
    name: "black",
    value: "#000000",
    iconColor: "#ffffff",
  },
  {
    name: "white",
    value: "#ffffff",
    iconColor: "#000000",
  },
];

type Props = {
  anchorEl: HTMLButtonElement | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  labelItem: LabelItem;
  onChangeLabelItem: (
    labelItemId: string,
    propertyName: "name" | "color" | "backgroundColor",
    value: string
  ) => void;
};

const ColorPicker: React.FC<Props> = React.memo(
  ({ anchorEl, isOpen, setIsOpen, labelItem, onChangeLabelItem }) => {
    // hooks
    const { t: l } = useTranslation("labels");
    const { palette, color, node, helpBgColor } = useStyles();
    const boxShadow = node.boxShadow ? node.boxShadow : `0 0 10px ${color}`;

    const onClickBackground = () => {
      setIsOpen(false);
    };

    const onClickModal = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      // stop onClickBackground
      event.stopPropagation();
    };

    const onChangeColor = (color: string) => {
      onChangeLabelItem(labelItem.labelItemId, "color", color);
    };

    const onClickBackgroundColor = (color: string) => {
      onChangeLabelItem(labelItem.labelItemId, "backgroundColor", color);
    };

    if (anchorEl && isOpen) {
      const rec = anchorEl.getBoundingClientRect();
      const top = rec.top;
      const left = rec.left + rec.width;

      return (
        <Container onClick={onClickBackground}>
          <Modal
            color={color}
            bgColor={helpBgColor}
            boxShadow={boxShadow}
            style={{ top, left }}
            onClick={onClickModal}
          >
            <Typography margin="0px 0px 4px 0px">{l("textColor")}</Typography>
            <PaletteRows>
              {textColors.map((c) => (
                <TextColorItemWrapper
                  key={c.name}
                  color={c.iconColor}
                  backgroundColor={l(c.value)}
                  onClick={() => onChangeColor(c.value)}
                >
                  {labelItem.color === c.value ? <Check /> : false}
                </TextColorItemWrapper>
              ))}
            </PaletteRows>
            <Typography margin="4px 0px 8px 0px">
              {l("backgroundColor")}
            </Typography>
            <ThemePalette>
              {palette.map((p) => (
                <ColorItemWrapper
                  key={p}
                  color="white"
                  backgroundColor={p}
                  onClick={() => onClickBackgroundColor(p)}
                >
                  {labelItem.backgroundColor === p ? <Check /> : false}
                </ColorItemWrapper>
              ))}
            </ThemePalette>
            <PaletteRows>
              {colors.map((c) => (
                <ColorItemColumns key={c.name}>
                  {c.palette.map((p) => (
                    <ColorItemWrapper
                      key={p}
                      color="white"
                      backgroundColor={p}
                      onClick={() => onClickBackgroundColor(p)}
                    >
                      {labelItem.backgroundColor === p ? <Check /> : false}
                    </ColorItemWrapper>
                  ))}
                </ColorItemColumns>
              ))}
            </PaletteRows>
          </Modal>
        </Container>
      );
    } else {
      return <></>;
    }
  }
);
ColorPicker.displayName = "ColorPicker";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${modalBackgroundZIndex};
`;

type ModalProps = {
  color: string;
  bgColor: string;
  boxShadow: string;
};
const Modal = styled(FlexColumnCenter)<ModalProps>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  border: solid 2px ${({ color }) => color};
  border-radius: ${baseBorderRadius}px;
  padding: 8px;
  box-shadow: ${({ boxShadow }) => boxShadow};
  position: fixed;
`;

const ThemePalette = styled(FlexRowCenter)`
  margin-bottom: 4px;
  > div {
    margin-right: 4px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

const PaletteRows = styled(FlexRowCenter)`
  > div {
    margin-right: 4px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

const ColorItemColumns = styled(FlexColumnCenter)`
  > div {
    margin-bottom: 4px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

type TextColorItemWrapperProps = {
  color: string;
  backgroundColor: string;
};
const TextColorItemWrapper = styled.div<TextColorItemWrapperProps>`
  width: 22px;
  height: 22px;
  border: solid 2px ${({ color }) => color};
  border-radius: 50%;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;

  :hover {
    box-shadow: 0 0 8px ${({ backgroundColor }) => backgroundColor};
  }
`;

type ColorItemWrapper = { color: string; backgroundColor: string };
const ColorItemWrapper = styled.div<ColorItemWrapper>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;

  :hover {
    box-shadow: 0 0 8px ${({ backgroundColor }) => backgroundColor};
  }
`;

export default ColorPicker;
