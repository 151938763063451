import { Task } from "./Task";

export class Line {
  id: string;
  // ここにCTaskを直接入れるとドラッグ時についてきてしまう
  startTask: Task;
  startX: number;
  startY: number;
  endTask: Task;
  endX: number;
  endY: number;

  constructor(
    id: string,
    startTask: Task,
    startX: number,
    startY: number,
    endTask: Task,
    endX: number,
    endY: number
  ) {
    this.id = id;
    this.startTask = startTask;
    this.startX = startX;
    this.startY = startY;
    this.endTask = endTask;
    this.endX = endX;
    this.endY = endY;
  }
}
