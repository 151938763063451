import React, { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import firebase from "../firebase";
import { StyledLink } from "../components/StyledComponents";
import ListItem from "../components/MenuItem";
import Context from "../context";
import {
  sideMenuFullWidth,
  sideMenuWidth,
  sideMenuPadding,
  sideMenuMargin,
  settingMenuTop,
  settingMenuRight,
  floatItemZIndex,
} from "../constants";

const menuItems = [
  {
    to: "/settings/account",
    key: "account",
  },
  // {
  //   to: "/settings/teams",
  //   key: "teams"
  // },
  // {
  //   to: "/settings/subscriptions",
  //   key: "subscriptions"
  // },
  {
    to: "/settings/theme",
    key: "theme",
  },
  {
    to: "/settings/language",
    key: "language",
  },
  {
    to: "/settings/contact",
    key: "contact",
  },
  {
    to: "/settings/terms-of-service",
    key: "termsOfService",
  },
  {
    to: "/settings/privacy-policy",
    key: "privacyPolicy",
  },
  {
    to: "/settings/cookie-policy",
    key: "cookiePolicy",
  },
];

const SettingMenu: React.FC = React.memo(() => {
  const { user, isSettingMenuOpen, setIsSettingMenuOpen } = useContext(Context);
  // hooks
  const { t: s } = useTranslation("settings");
  const history = useHistory();
  const location = useLocation();

  // computed
  const isSettingPage = location.pathname.startsWith("/settings");
  const visible = user && (isSettingMenuOpen || isSettingPage) ? true : false;

  useEffect(() => {
    if (isSettingPage) {
      setIsSettingMenuOpen(true);
    }
  }, []);

  const onMouseLeave = () => {
    setIsSettingMenuOpen(true);
  };

  const onClickSignOut = () => {
    firebase.auth().signOut();
    history.push("/news");
  };

  return (
    <Container
      className="scroll-none"
      visible={visible}
      onMouseLeave={onMouseLeave}
    >
      {menuItems.map((item, i) => (
        <StyledLink key={item.key} to={item.to}>
          <MenuItem index={i} selected={location.pathname === item.to}>
            {s(item.key)}
          </MenuItem>
        </StyledLink>
      ))}
      <MenuItem
        index={menuItems.length}
        selected={false}
        onClick={onClickSignOut}
      >
        {s("signOut")}
      </MenuItem>
    </Container>
  );
});
SettingMenu.displayName = "SettingMenu";

const Container = styled.ul<{ visible: boolean }>`
  min-width: ${sideMenuWidth}px;
  max-width: ${sideMenuWidth}px;
  padding: ${sideMenuPadding}px;
  margin-left: ${sideMenuMargin}px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  position: fixed;
  top: ${settingMenuTop}px;
  right: ${settingMenuRight}px;
  z-index: ${floatItemZIndex};

  transition: transform ${({ visible }) => (visible ? 350 : 1000)}ms ease-out;
  transform: translateX(
    ${({ visible }) => (visible ? "0" : sideMenuMargin + sideMenuFullWidth)}px
  );

  :hover {
    transform: translateX(0px);
  }

  > a {
    margin-bottom: 12px;
  }
  > a:last-child {
    margin-bottom: 0px;
  }
`;

const MenuItem = styled(ListItem)`
  justify-content: center;
`;

export default SettingMenu;
