import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import command from "./locales/command.json";

// ここでロードしておかないと i18n に無駄に useEffect が走る
const language = localStorage.getItem("language");
i18n.use(initReactI18next).init({
  resources: {},
  lng: language ? language : "en",
  fallbackLng: "en",
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
i18n.addResources("en", "common", en.common);
i18n.addResources("en", "error", en.error);
i18n.addResources("en", "top", en.top);
i18n.addResources("en", "command", en.command);
i18n.addResources("en", "sample", en.sample);
i18n.addResources("en", "windows", command.windows);
i18n.addResources("en", "mac", command.mac);
i18n.addResources("en", "safari", command.safari);
i18n.addResources("en", "news", en.news);
i18n.addResources("en", "project", en.project);
i18n.addResources("en", "projectSubMenus", en.projectSubMenus);
i18n.addResources("en", "task", en.task);
i18n.addResources("en", "status", en.status);
i18n.addResources("en", "filterStatus", en.filterStatus);
i18n.addResources("en", "assignee", en.assignee);
i18n.addResources("en", "calendar", en.calendar);
i18n.addResources("en", "days", en.days);
i18n.addResources("en", "shortDays", en.shortDays);
i18n.addResources("en", "filterDueDate", en.filterDueDate);
i18n.addResources("en", "labels", en.labels);
i18n.addResources("en", "priority", en.priority);
i18n.addResources("en", "filter", en.filter);
i18n.addResources("en", "activeMember", en.activeMember);
i18n.addResources("en", "help", en.help);
i18n.addResources("en", "settings", en.settings);
i18n.addResources("en", "team", en.team);
i18n.addResources("en", "subscription", en.subscription);
i18n.addResources("en", "contact", en.contact);
i18n.addResources("en", "visibility", en.visibility);
i18n.addResources("en", "account", en.account);
i18n.addResources("en", "owner", en.owner);
i18n.addResources("en", "admin", en.admin);
i18n.addResources("en", "member", en.member);
i18n.addResources("en", "theme", en.theme);
i18n.addResources("en", "language", en.language);
i18n.addResources("ja", "common", ja.common);
i18n.addResources("ja", "error", ja.error);
i18n.addResources("ja", "top", ja.top);
i18n.addResources("ja", "command", ja.command);
i18n.addResources("ja", "sample", ja.sample);
i18n.addResources("ja", "windows", command.windows);
i18n.addResources("ja", "mac", command.mac);
i18n.addResources("ja", "safari", command.safari);
i18n.addResources("ja", "news", ja.news);
i18n.addResources("ja", "project", ja.project);
i18n.addResources("ja", "projectSubMenus", ja.projectSubMenus);
i18n.addResources("ja", "task", ja.task);
i18n.addResources("ja", "status", ja.status);
i18n.addResources("ja", "filterStatus", ja.filterStatus);
i18n.addResources("ja", "assignee", ja.assignee);
i18n.addResources("ja", "calendar", ja.calendar);
i18n.addResources("ja", "days", ja.days);
i18n.addResources("ja", "shortDays", ja.shortDays);
i18n.addResources("ja", "filterDueDate", ja.filterDueDate);
i18n.addResources("ja", "labels", ja.labels);
i18n.addResources("ja", "priority", ja.priority);
i18n.addResources("ja", "filter", ja.filter);
i18n.addResources("ja", "activeMember", ja.activeMember);
i18n.addResources("ja", "help", ja.help);
i18n.addResources("ja", "settings", ja.settings);
i18n.addResources("ja", "visibility", ja.visibility);
i18n.addResources("ja", "account", ja.account);
i18n.addResources("ja", "owner", ja.owner);
i18n.addResources("ja", "admin", ja.admin);
i18n.addResources("ja", "member", ja.member);
i18n.addResources("ja", "theme", ja.theme);
i18n.addResources("ja", "language", ja.language);
i18n.addResources("ja", "team", ja.team);
i18n.addResources("ja", "subscription", ja.subscription);
i18n.addResources("ja", "contact", ja.contact);
i18n.addResources("ko", "common", ko.common);
i18n.addResources("ko", "error", ko.error);
i18n.addResources("ko", "top", ko.top);
i18n.addResources("ko", "command", ko.command);
i18n.addResources("ko", "sample", ko.sample);
i18n.addResources("ko", "windows", command.windows);
i18n.addResources("ko", "mac", command.mac);
i18n.addResources("ko", "safari", command.safari);
i18n.addResources("ko", "news", ko.news);
i18n.addResources("ko", "project", ko.project);
i18n.addResources("ko", "projectSubMenus", ko.projectSubMenus);
i18n.addResources("ko", "task", ko.task);
i18n.addResources("ko", "status", ko.status);
i18n.addResources("ko", "filterStatus", ko.filterStatus);
i18n.addResources("ko", "assignee", ko.assignee);
i18n.addResources("ko", "calendar", ko.calendar);
i18n.addResources("ko", "days", ko.days);
i18n.addResources("ko", "shortDays", ko.shortDays);
i18n.addResources("ko", "filterDueDate", ko.filterDueDate);
i18n.addResources("ko", "labels", ko.labels);
i18n.addResources("ko", "priority", ko.priority);
i18n.addResources("ko", "filter", ko.filter);
i18n.addResources("ko", "activeMember", ko.activeMember);
i18n.addResources("ko", "help", ko.help);
i18n.addResources("ko", "settings", ko.settings);
i18n.addResources("ko", "visibility", ko.visibility);
i18n.addResources("ko", "account", ko.account);
i18n.addResources("ko", "owner", ko.owner);
i18n.addResources("ko", "admin", ko.admin);
i18n.addResources("ko", "member", ko.member);
i18n.addResources("ko", "theme", ko.theme);
i18n.addResources("ko", "language", ko.language);
i18n.addResources("ko", "team", ko.team);
i18n.addResources("ko", "subscription", ko.subscription);
i18n.addResources("ko", "contact", ko.contact);

export default i18n;
