import React from "react";
import styled from "styled-components";
import { FlexColumnCenter } from "../StyledComponents";
import ComponentLabelItem from "./LabelItem";
import { Label, LabelItem } from "../../models/Label";

type Props = {
  label: Label;
  focused: boolean;
  filterLabelItems: LabelItem[];
  toggleFilterLabelItem: (item: LabelItem) => void;
};

const ComponentLabel: React.FC<Props> = React.memo(
  ({ label, focused, filterLabelItems, toggleFilterLabelItem }) => {
    return (
      <Container>
        <LabelName>{label.name}</LabelName>
        <FilterItems>
          {label.labelItems.map((li) => (
            <ComponentLabelItem
              key={li.labelItemId}
              labelId={label.labelId}
              labelItem={li}
              focused={focused}
              filterLabelItems={filterLabelItems}
              toggleFilterLabelItem={toggleFilterLabelItem}
            />
          ))}
        </FilterItems>
      </Container>
    );
  }
);
ComponentLabel.displayName = "ComponentLabel";

const Container = styled(FlexColumnCenter)`
  padding: 4px 8px 0px 8px;
  transition: border 0.1s ease-out;
`;

const LabelName = styled.div`
  margin-bottom: 4px;
`;

const FilterItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  > div {
    margin-right: 8px;
  }
  div:last-child {
    margin-right: 0px;
  }
`;

export default ComponentLabel;
