import { issueId } from "../bl/common";
import { Task } from "../models/Task";
import { Line } from "../models/Line";
import { taskMargin, lineWidth } from "../constants";

const lowerChildrenTop = (task: Task, margin: number): void => {
  if (!task.children.length) return;

  for (const child of task.children) {
    child.setTop(child.top + margin);
    lowerChildrenTop(child, margin);
  }
};

export const setTaskTopWithFilter = (top: number, task: Task): void => {
  const children = task.filteredChildren;
  if (!children.length) {
    task.setTop(top);
    return;
  }

  let childTop = top;
  for (const child of children) {
    setTaskTopWithFilter(childTop, child);
    childTop = child.bottomWithDescendatnsWithFilter + taskMargin;
  }

  // 最後に自分自身の top
  const tempTop = task.topConsideringChildrenWithFilter;
  if (tempTop < top) {
    const margin = top - tempTop;
    lowerChildrenTop(task, margin);
    task.setTop(top);
  } else {
    task.setTop(tempTop);
  }
};

export const setTaskTop = (top: number, task: Task): void => {
  if (!task.children.length) {
    task.setTop(top);
    return;
  }

  let childTop = top;
  for (const child of task.children) {
    setTaskTop(childTop, child);
    childTop = child.bottomWithDescendatns + taskMargin;
  }

  // 最後に自分自身の top
  const tempTop = task.topConsideringChildren;
  if (tempTop < top) {
    const margin = top - tempTop;
    lowerChildrenTop(task, margin);
    task.setTop(top);
  } else {
    task.setTop(tempTop);
  }
};

export const setTaskLeft = (tasks: Task[]): void => {
  const maxLevel = Math.max(...tasks.map((t) => t.level));
  const isToRight = true;
  const isFixedMargin = false;
  if (isToRight) {
    if (isFixedMargin) {
      let left = 0;
      for (let level = 1; level <= maxLevel; level++) {
        const levelTasks = tasks.filter((t) => t.level === level);
        for (const levelTask of levelTasks) {
          levelTask.setLeft(left);
        }
        const maxWidth = Math.max(...levelTasks.map((t) => t.offsetWidth));
        left += maxWidth + lineWidth;
      }
    } else {
      for (let level = 1; level <= maxLevel; level++) {
        const levelTasks = tasks.filter((t) => t.level === level);
        for (const levelTask of levelTasks) {
          if (level === 1) {
            levelTask.setLeft(0);
          }
          if (!levelTask.parent) continue;
          levelTask.setLeft(levelTask.parent.right + lineWidth);
        }
      }
    }
  } else {
    let left = 0;
    for (let level = maxLevel; level > 0; level--) {
      const levelTasks = tasks.filter((t) => t.level === level);
      for (const levelTask of levelTasks) {
        levelTask.setLeft(left);
      }
      const maxWidth = Math.max(...levelTasks.map((t) => t.offsetWidth));
      left += maxWidth + lineWidth;
    }
  }
};

export const createLines = (tasks: Task[]): Line[] => {
  const lines: Line[] = [];
  for (const task of tasks) {
    const parent = tasks.find((t) => t.id === task.parentId);
    if (!parent) continue;
    const line = new Line(
      issueId(),
      parent,
      parent.lineStartX,
      parent.lineStartY,
      task,
      task.lineEndX,
      task.lineEndY
    );
    lines.push(line);
  }
  return lines;
};
