import React from "react";
import styled from "styled-components";
import Checkbox from "./Input/Checkbox";
import useStyles from "../hooks/useStyles";
import useAnalytics from "../hooks/useAnalytics";
import { Theme } from "../models/Theme";
import { baseBorderRadius } from "../constants";

type Props = {
  themeItem: Theme;
};

const ThemeItem: React.FC<Props> = React.memo(({ themeItem }) => {
  // hooks
  const { theme, setTheme, menuItem } = useStyles();
  const { sendSettingLog } = useAnalytics();

  const onClickTheme = () => {
    setTheme(themeItem);
    sendSettingLog("update_theme");
  };

  const getNameChars = (name: string): string[] => {
    const chars = name.split("");
    const newChars: string[] = [];
    for (const char of chars) {
      if (char === " ") {
        newChars[newChars.length - 1] += " ";
      } else {
        newChars.push(char);
      }
    }
    return newChars;
  };

  return (
    <Container
      color={menuItem.borderColor}
      background={themeItem.background}
      onClick={onClickTheme}
    >
      <Checkbox
        checkedColor={themeItem.color}
        uncheckedColor={themeItem.color}
        checked={theme && theme.name === themeItem.name ? true : false}
      />
      <Name>
        {getNameChars(themeItem.name).map((c, i) => (
          <Char
            key={c + i}
            color={themeItem.palette[i % themeItem.palette.length]}
          >
            {c}
          </Char>
        ))}
      </Name>
    </Container>
  );
});
ThemeItem.displayName = "ThemeItem";

type ThemeItemProps = {
  color: string;
  background: string;
};
const Container = styled.div<ThemeItemProps>`
  height: 42px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 12px;
  border: solid 2px ${({ color }) => color};
  border-radius: ${baseBorderRadius}px;
  background: ${({ background }) => background};
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: box-shadow 0.1s ease-out;

  :hover {
    box-shadow: 0 0 10px ${({ color }) => color};
  }
`;

const Name = styled.div`
  flex: 1;
`;

const Char = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export default ThemeItem;
