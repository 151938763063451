import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SettingContainer from "../../../components/SettingContainer";
import Typography from "../../../components/Typography";
import TaskLikeButton from "../../../components/Button/NodeLikeButton";
import LabelSettingsPanel from "./LabelSettingsPanel";
import Buttons from "./Buttons";
import Context from "../../../context";
import useStyles from "../../../hooks/useStyles";
import useSnackbar from "../../../hooks/useSnackbar";
import useAnalytics from "../../../hooks/useAnalytics";
import { issueId } from "../../../bl/common";
import { updateProjectLabels } from "../../../db/projects";
import { Project } from "../../../models/Project";
import { Label, LabelItem } from "../../../models/Label";
import { freePlanMaxLabelCount, defaultLabelColor } from "../../../constants";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import { firebase401Message } from "../../../constants";

const ProjectLabelSetting: React.FC = () => {
  const match = useRouteMatch<{ projectId: string }>({
    path: "/projects/:projectId/settings/labels",
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params && match.params.projectId;

  // context
  const { projects, updateLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: l } = useTranslation("labels");
  const history = useHistory();
  const { palette } = useStyles();
  const {
    setMessage,
    setUnauthorizedMessage,
    setLabelCountAlert,
  } = useSnackbar();
  const { sendProjectLog, sendAlertLog } = useAnalytics();
  // states
  const [project, setProject] = useState<Project | null>(null);
  const [labels, setLabels] = useState<Label[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!projectId || !projects.length) return;
    const currentProject = projects.find((p) => p.projectId === projectId);
    if (currentProject) {
      setProject(currentProject);
      setLabels(currentProject.labels);
    } else {
      setLabels([]);
    }
  }, [projects, projectId]);

  // Label
  const onChangeLabelName = (labelId: string, name: string) => {
    setLabels(
      labels.map((l) => {
        if (l.labelId === labelId) {
          return {
            ...l,
            name,
          };
        } else {
          return l;
        }
      })
    );
  };
  const onChangeLabelItems = (labelId: string, labelItems: LabelItem[]) => {
    setLabels(
      labels.map((l) => {
        if (l.labelId === labelId) {
          return {
            ...l,
            labelItems,
          };
        } else {
          return l;
        }
      })
    );
  };
  const onClickAddLabel = () => {
    if (labels.length >= freePlanMaxLabelCount) {
      setLabelCountAlert();
      sendAlertLog("label_count_limit");
      return;
    }
    const newLabelSet: Label = {
      labelId: issueId(),
      displayOrder: labels.length + 1,
      name: "",
      labelItems: [
        {
          labelItemId: issueId(),
          sortOrder: 1,
          name: "",
          color: defaultLabelColor,
          backgroundColor: palette[0],
        },
      ],
    };
    setLabels([...labels, newLabelSet]);
  };
  const assignDisplayOrder = (labels: Label[]) => {
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      label.displayOrder = i + 1;
    }
  };
  const moveLabelUpward = (displayOrder: number) => {
    const index = displayOrder - 1;
    const newLabels = labels.slice();
    newLabels.splice(index - 1, 2, newLabels[index], newLabels[index - 1]);
    assignDisplayOrder(newLabels);
    setLabels(newLabels);
  };
  const moveLabelDownward = (displayOrder: number) => {
    const index = displayOrder - 1;
    const newLabels = labels.slice();
    newLabels.splice(index, 2, newLabels[index + 1], newLabels[index]);
    assignDisplayOrder(newLabels);
    setLabels(newLabels);
  };
  const deleteLabel = (displayOrder: number) => {
    const newLabels = labels.filter((i) => i.displayOrder !== displayOrder);
    assignDisplayOrder(newLabels);
    setLabels(newLabels);
  };

  const onClickSave = async () => {
    if (!projectId) return;

    setLoading(true);
    try {
      await updateProjectLabels(projectId, labels);
      await updateLocalProject(projectId);
      setMessage({
        level: "success",
        text: c("successfullyUpdated"),
      });
      sendProjectLog("update_labels");
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = () => {
    projectId && history.push(`/projects/${projectId}`);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {project ? project.name : ""}
      </Typography>
      <Typography variant="large" bold margin="8px 0px 16px 0px">
        {l("labels")}
      </Typography>
      <Panels>
        {labels.map((l, i) => (
          <LabelSettingsPanel
            key={`label-sets-${l.labelId}`}
            label={l}
            canMoveUpward={i !== 0}
            moveLabelUpward={moveLabelUpward}
            canMoveDownward={i !== labels.length - 1}
            moveLabelDownward={moveLabelDownward}
            deleteLabel={deleteLabel}
            onChangeLabelName={onChangeLabelName}
            onChangeLabelItems={onChangeLabelItems}
          />
        ))}
      </Panels>
      <TaskLikeButton margin="0px 0px 16px 0px" onClick={onClickAddLabel}>
        {l("addLabel")}
      </TaskLikeButton>
      <Buttons
        loading={loading}
        disabled={false}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
    </SettingContainer>
  );
};

const Panels = styled(FlexColumnCenter)`
  > div {
    margin-bottom: 16px;
  }
`;

export default ProjectLabelSetting;
