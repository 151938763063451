import React from "react";
import TopPolicyContainer from "../components/TopPolicyContainer";
import CookiePolicyComponent from "../components/CookiePolicy";

const CookiePolicy: React.FC = () => {
  return (
    <TopPolicyContainer>
      <CookiePolicyComponent />
    </TopPolicyContainer>
  );
};

export default CookiePolicy;
