import { Task } from "../models/Task";
import { NodeTheme } from "../models/Theme";
import { taskZIndex, editingZIndex } from "../constants";

export const getTaskColor = (
  task: Task,
  color: string,
  theme: NodeTheme
): string => {
  if (task.isCutWithAncestors) {
    return theme.cut.color;
  } else if (task.isCopiedWithAncestors) {
    return theme.copied.color;
  } else if (task.doneConsideringDescendants) {
    return theme.done.color;
  } else {
    return color;
  }
};

export const getTaskBgColor = (
  task: Task,
  paletteColor: string,
  theme: NodeTheme
): string => {
  if (task.isCutWithAncestors) {
    return theme.cut.bgColor;
  } else if (task.isCopiedWithAncestors) {
    return paletteColor;
  } else if (task.doneConsideringDescendants) {
    return theme.done.bgColor;
  } else {
    return theme.bgColor;
  }
};

export const getTaskBorder = (
  task: Task,
  paletteColor: string,
  theme: NodeTheme
): string => {
  if (task.isCutWithAncestors) {
    return `solid 2px ${theme.cut.borderColor}`;
  } else {
    return task.topSortNumber <= 0 ? "" : `solid 2px ${paletteColor}`;
  }
};

export const getTaskBoxShadow = (
  task: Task,
  paletteColor: string,
  theme: NodeTheme
): string => {
  if (task.selected || task.multiSelected)
    return theme.boxShadow ? theme.boxShadow : `0 0 16px ${paletteColor}`;
  return "";
};

export const getTaskZIndex = (task: Task): number => {
  return task.selected ||
    task.isEditing ||
    task.isAssigning ||
    task.isSettingDueDate ||
    task.isLabeling ||
    task.isSetting
    ? editingZIndex
    : taskZIndex;
};

export const checkboxChedkedColor = (task: Task, theme: NodeTheme): string => {
  if (task.isCutWithAncestors) {
    return theme.cut.color;
  } else if (task.isCopiedWithAncestors) {
    return theme.copied.color;
  } else {
    return "";
  }
};

export const checkboxUnchedkedColor = (
  task: Task,
  paletteColor: string,
  theme: NodeTheme
): string => {
  if (task.isCutWithAncestors) {
    return theme.cut.color;
  } else if (task.isCopiedWithAncestors) {
    return theme.copied.color;
  } else {
    return paletteColor;
  }
};
