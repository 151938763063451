import { asignChildrenSortNumber, reasignChildrenSortNumber } from "./common";
import { UpdateTask, Task } from "../../models/Task";

export const blPasteCutTask = (
  tasks: Task[], // 全体
  children: Task[], // 移動するタスク
  formerParent: Task, // 移動元の親タスク
  nextParent: Task, // 移動先の親
  updatedBy: string
): {
  newTasks: Task[];
  updatingTasks: UpdateTask[];
  selectingTaskId: string;
} => {
  /**
   * blOutdentTasks とロジックがほとんど同じ！
   * どちらかを修正した場合はもう片方も確認すること
   */
  const newTasks = tasks.map((t) => {
    // reset flags
    t.selected = false;
    t.multiSelected = false;
    t.copied = false;
    t.cut = false;
    return t;
  });
  const updatingParents: Task[] = [];
  // 前の親の子供たちとペースト先のペースト対象以外の子供は sortNumber だけ更新
  const updatingSortNumberChildren: Task[] = [];
  // 移動する子供の子孫は level だけ更新
  const updatingLevelChildren: Task[] = [];
  // parent, sortNumber, level を更新
  const updatingChildren: Task[] = [];

  // sort しないと貼り付け順が変わる
  children.sort((a, b) => a.sortNumber - b.sortNumber);

  // 念のため先に移動先の親の子供たちの sortNumber を振り直しておく(ソートなし)
  asignChildrenSortNumber(nextParent);

  for (const child of children) {
    // 1. 前の親の children から child を除く
    formerParent.children = formerParent.children.filter(
      (c) => c.id !== child.id
    );
    // 2. child.parent を nextParent にする
    child.parent = nextParent;
    // 3. nextParent の children に加える
    nextParent.children.push(child);
    // 4. child.sortNumber を設定 (後でまとめて振り直す)
    child.sortNumber = nextParent.getNextSortNumber();
    // 5. sourceTask.level を調整
    const levelIncrementValue = nextParent.level + 1 - child.level;
    child.level += levelIncrementValue;
    incrementDescendantsLevel(child, levelIncrementValue);
    // 6. 追加
    updatingChildren.push(child);
    Array.prototype.push.apply(
      updatingLevelChildren,
      // incrementDescendantsLevel でレベルを変更したノード
      child.allChildren
    );
  }

  nextParent.children.sort((a, b) => a.sortNumber - b.sortNumber);
  asignChildrenSortNumber(nextParent);
  updatingParents.push(nextParent);
  formerParent.children.sort((a, b) => a.sortNumber - b.sortNumber);
  asignChildrenSortNumber(formerParent);
  updatingParents.push(formerParent);
  // 次の親のペーストするノード以外のノードは sortNumber のみ更新
  const childrenIds = children.map((c) => c.id);
  Array.prototype.push.apply(
    updatingSortNumberChildren,
    nextParent.children.filter((c) => !childrenIds.includes(c.id))
  );
  // 前の親の children に対して sortNumber を振り直す
  reasignChildrenSortNumber(formerParent);
  Array.prototype.push.apply(updatingSortNumberChildren, formerParent.children);

  // selected
  // ペーストした子供たちの一番下
  const selectingTask = nextParent.children[nextParent.children.length - 1];
  selectingTask.selected = true;

  const updatingTasks: UpdateTask[] = [];
  const updatedAt = new Date();
  // 全て重複を除いてから追加する
  for (const parent of updatingParents.filter(
    (a, i, arr) => arr.findIndex((b) => a.id === b.id) === i
  )) {
    updatingTasks.push({
      id: parent.id,
      params: {
        childrenIds: parent.childrenIds,
        updatedBy,
        updatedAt,
      },
    });
  }
  for (const child of updatingSortNumberChildren.filter(
    (a, i, arr) => arr.findIndex((b) => a.id === b.id) === i
  )) {
    updatingTasks.push({
      id: child.id,
      params: {
        sortNumber: child.sortNumber,
        updatedBy,
        updatedAt,
      },
    });
  }
  for (const child of updatingLevelChildren.filter(
    (a, i, arr) => arr.findIndex((b) => a.id === b.id) === i
  )) {
    updatingTasks.push({
      id: child.id,
      params: {
        level: child.level,
        updatedBy,
        updatedAt,
      },
    });
  }
  for (const child of children) {
    updatingTasks.push({
      id: child.id,
      params: {
        level: child.level,
        sortNumber: child.sortNumber,
        parentId: child.parentId,
        updatedBy,
        updatedAt,
      },
    });
  }

  return {
    newTasks,
    updatingTasks,
    selectingTaskId: selectingTask.id,
  };
};

export const blOutdentTasks = (
  tasks: Task[], // 全体
  task: Task, // 選択中のタスク
  children: Task[], // 移動するタスク
  formerParent: Task, // 移動元の親タスク
  nextParent: Task, // 移動先の親タスク
  updatedBy: string
): {
  newTasks: Task[];
  updatingTasks: UpdateTask[];
  selectingTaskId: string;
} => {
  /**
   * blPasteCutTask とロジックがほとんど同じ！
   * どちらかを修正した場合はもう片方も確認すること
   */
  const newTasks = tasks.map((t) => {
    // reset flags
    // selected だけそのままにする
    t.multiSelected = false;
    t.copied = false;
    t.cut = false;
    return t;
  });

  const updatingParents: Task[] = [];
  // 前の親の子供たちとペースト先のペースト対象以外の子供は sortNumber だけ更新
  const updatingSortNumberChildren: Task[] = [];
  // 移動する子供の子孫は level だけ更新
  const updatingLevelChildren: Task[] = [];
  // parent, sortNumber, level を更新
  const updatingChildren: Task[] = [];

  // sort しないと貼り付け順が変わる
  // ただし親の後ろにINSERTしていく形になるので降順にソートしておかないと順番が逆になる
  children.sort((a, b) => b.sortNumber - a.sortNumber);

  // 移動する子供達が全て親より高い場合は感覚的に親の上に移動させた方がしっくりくるのでそのためのフラグ
  const isAllAbove = children.every(
    (c) => c.verticalCenter < formerParent.verticalCenter
  );

  // 念のため先に移動先の親の子供たちの sortNumber を振り直しておく(ソートなし)
  asignChildrenSortNumber(nextParent);

  for (const child of children) {
    // 1. 前の親の children から child を除く
    formerParent.children = formerParent.children.filter(
      (c) => c.id !== child.id
    );
    // 2. child.parent を nextParent にする
    child.parent = nextParent;
    // 3. nextParent の children に加える
    nextParent.children.push(child);
    // 4. child.sortNumber を設定
    if (isAllAbove && child.verticalCenter < formerParent.verticalCenter) {
      // 移動するタスクが元の親より上にある場合は親の上に挿入
      for (const futureSibling of nextParent.children) {
        if (futureSibling.sortNumber >= formerParent.sortNumber) {
          // 親も含めて下に下げる
          futureSibling.sortNumber += 1;
        }
      }
      child.sortNumber = formerParent.sortNumber - 1;
    } else {
      // 移動するタスクが元の親より同じか下にある場合は親の下に挿入
      for (const futureSibling of nextParent.children) {
        if (futureSibling.sortNumber > formerParent.sortNumber) {
          // 親はそのまま
          futureSibling.sortNumber += 1;
        }
      }
      child.sortNumber = formerParent.sortNumber + 1;
    }
    // 5. sourceTask.level を調整
    const levelIncrementValue = -1;
    child.level += levelIncrementValue;
    incrementDescendantsLevel(child, levelIncrementValue);
    // 6. 追加
    updatingChildren.push(child);
    Array.prototype.push.apply(
      updatingLevelChildren,
      // incrementDescendantsLevel でレベルを変更したノード
      child.allChildren
    );
  }

  nextParent.children.sort((a, b) => a.sortNumber - b.sortNumber);
  asignChildrenSortNumber(nextParent);
  updatingParents.push(nextParent);
  formerParent.children.sort((a, b) => a.sortNumber - b.sortNumber);
  asignChildrenSortNumber(formerParent);
  updatingParents.push(formerParent);
  // 次の親のペーストするノード以外のノードは sortNumber のみ更新
  const childrenIds = children.map((c) => c.id);
  Array.prototype.push.apply(
    updatingSortNumberChildren,
    nextParent.children.filter((c) => !childrenIds.includes(c.id))
  );
  // 前の親の children に対して sortNumber を振り直す
  reasignChildrenSortNumber(formerParent);
  Array.prototype.push.apply(updatingSortNumberChildren, formerParent.children);

  // selected
  const selectingTaskId = task.id;

  const updatingTasks: UpdateTask[] = [];
  const updatedAt = new Date();
  // 全て重複を除いてから追加する
  for (const parent of updatingParents.filter(
    (a, i, arr) => arr.findIndex((b) => a.id === b.id) === i
  )) {
    updatingTasks.push({
      id: parent.id,
      params: {
        childrenIds: parent.childrenIds,
        updatedBy,
        updatedAt,
      },
    });
  }
  for (const child of updatingSortNumberChildren.filter(
    (a, i, arr) => arr.findIndex((b) => a.id === b.id) === i
  )) {
    updatingTasks.push({
      id: child.id,
      params: {
        sortNumber: child.sortNumber,
        updatedBy,
        updatedAt,
      },
    });
  }
  for (const child of updatingLevelChildren.filter(
    (a, i, arr) => arr.findIndex((b) => a.id === b.id) === i
  )) {
    updatingTasks.push({
      id: child.id,
      params: {
        level: child.level,
        updatedBy,
        updatedAt,
      },
    });
  }
  for (const child of children) {
    updatingTasks.push({
      id: child.id,
      params: {
        level: child.level,
        sortNumber: child.sortNumber,
        parentId: child.parentId,
        updatedBy,
        updatedAt,
      },
    });
  }

  return {
    newTasks,
    updatingTasks,
    selectingTaskId,
  };
};

/**
 * 再帰関数で子孫全てのレベルを value 分上げる
 */
const incrementDescendantsLevel = (task: Task, value: number): void => {
  if (!task.children.length) {
    return;
  }
  for (const child of task.children) {
    child.level += value;
    incrementDescendantsLevel(child, value);
  }
};
