import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SettingContainer from "../../../components/SettingContainer";
import Typography from "../../../components/Typography";
import Radio from "../../../components/Input/Radio";
import Buttons from "./Buttons";
import useSnackbar from "../../../hooks/useSnackbar";
import useAnalytics from "../../../hooks/useAnalytics";
import { updateProjectFirstDay } from "../../../db/projects";
import Context from "../../../context";
import { Project } from "../../../models/Project";
import { days, defaultFirstDay } from "../../../constants";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import { firebase401Message } from "../../../constants";

const ProjectCalendarSetting: React.FC = () => {
  const match = useRouteMatch<{ projectId: string }>({
    path: "/projects/:projectId/settings/calendar",
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params && match.params.projectId;

  // context
  const { projects, updateLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: cl } = useTranslation("calendar");
  const { t } = useTranslation("days");
  const history = useHistory();
  const { setMessage, setUnauthorizedMessage } = useSnackbar();
  const { sendProjectLog } = useAnalytics();
  // states
  const [project, setProject] = useState<Project | null>(null);
  const [firstDay, setFirstDay] = useState(defaultFirstDay);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!projectId || !projects.length) return;
    const currentProject = projects.find((p) => p.projectId === projectId);
    if (currentProject) {
      setProject(currentProject);
      setFirstDay(currentProject.firstDay);
    } else {
      setFirstDay(defaultFirstDay);
    }
  }, [projects, projectId]);

  const onChangeFirstDay = async (value: string) => {
    setFirstDay(parseInt(value));
  };

  const onClickSave = async () => {
    if (!projectId) return;

    setLoading(true);
    try {
      await updateProjectFirstDay(projectId, firstDay);
      await updateLocalProject(projectId);
      setMessage({
        level: "success",
        text: c("successfullyUpdated"),
      });
      sendProjectLog("update_calendar");
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = () => {
    projectId && history.push(`/projects/${projectId}`);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {project ? project.name : ""}
      </Typography>
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {cl("theFirstDayOfTheWeek")}
      </Typography>
      <Radios>
        {days.map((d, i) => (
          <Radio
            key={d}
            label={t(d)}
            value={i.toString()}
            checked={i === firstDay}
            onChange={onChangeFirstDay}
          />
        ))}
      </Radios>
      <Buttons
        loading={loading}
        disabled={false}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
    </SettingContainer>
  );
};

const Radios = styled(FlexColumnCenter)`
  margin-bottom: 8px;
`;

export default ProjectCalendarSetting;
