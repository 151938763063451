import React, { useContext } from "react";
import styled from "styled-components";
import { FlexRowCenter } from "../StyledComponents";
import Checkbox from "../Input/Checkbox";
import FilterContext from "./context";
import { LabelItem } from "../../models/Label";

type Props = {
  labelId: string;
  labelItem: LabelItem;
  focused: boolean;
  filterLabelItems: LabelItem[];
  toggleFilterLabelItem: (item: LabelItem) => void;
};

const ComponentLabelItem: React.FC<Props> = React.memo(
  ({
    labelId,
    labelItem,
    focused,
    filterLabelItems,
    toggleFilterLabelItem,
  }) => {
    // context
    const {
      selectedLabelId,
      setSelectedLabelId,
      selectedLabelItemId,
      setSelectedLabelItemId,
    } = useContext(FilterContext);

    const selected = labelItem.labelItemId === selectedLabelItemId;
    const checked = filterLabelItems.some(
      (i) => i.labelItemId === labelItem.labelItemId
    );

    const onClick = () => {
      setSelectedLabelId(labelId);
      setSelectedLabelItemId(labelItem.labelItemId);
      toggleFilterLabelItem(labelItem);
    };

    return (
      <Container
        labelSelected={labelId === selectedLabelId}
        selected={selected}
        checked={checked}
        focused={focused}
        color={labelItem.color}
        backgroundColor={labelItem.backgroundColor}
        onClick={onClick}
      >
        <Checkbox
          checkedColor={labelItem.color}
          uncheckedColor={checked ? labelItem.color : labelItem.backgroundColor}
          checked={checked}
        />
        {labelItem.name}
      </Container>
    );
  }
);
ComponentLabelItem.displayName = "ComponentLabelItem";

type LabelItemProps = {
  labelSelected: boolean;
  selected: boolean;
  checked: boolean;
  focused: boolean;
  backgroundColor: string;
  color: string;
};

const Container = styled(FlexRowCenter)<LabelItemProps>`
  color: ${({ checked, backgroundColor, color }) =>
    checked ? color : backgroundColor};
  background-color: ${({ checked, backgroundColor }) =>
    checked ? backgroundColor : ""};
  padding: 4px 8px;
  border: solid 1px ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  box-shadow: ${({ labelSelected, selected, focused, backgroundColor }) =>
    labelSelected && selected && focused ? `0 0 10px ${backgroundColor}` : ""};
  cursor: pointer;
  transition: color 0.1s ease-out, background-color 0.1s ease-out,
    box-shadow 0.1s ease-out;

  :hover {
    color: ${({ color }) => color};
    background-color: ${({ backgroundColor }) => backgroundColor};
    box-shadow: 0 0 10px ${({ backgroundColor }) => backgroundColor};

    > svg {
      color: ${({ color }) => color};
    }
  }
`;

export default ComponentLabelItem;
