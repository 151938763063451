import React, { useRef } from "react";
import styled from "styled-components";
import useStyles from "../../hooks/useStyles";
import { baseBorderRadius } from "../../constants";

type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  margin?: string;
};

const Textarea: React.FC<Props> = React.memo(({ value, onChange, margin }) => {
  const textareaElm = useRef<HTMLTextAreaElement>(null);
  const { color, menuItem, input } = useStyles();

  const boxShadow = menuItem.boxShadow
    ? menuItem.boxShadow
    : `0 0 10px ${menuItem.borderColor}`;

  const onInput = () => {
    if (textareaElm.current) {
      textareaElm.current.style.height = "auto";
      textareaElm.current.style.height =
        textareaElm.current.scrollHeight + "px";
    }
  };

  return (
    <Container
      ref={textareaElm}
      value={value}
      onInput={onInput}
      onChange={onChange}
      color={color}
      placeholderColor={input.text.placeholderColor}
      bgColor={input.text.bgColor}
      borderColor={menuItem.borderColor}
      boxShadow={boxShadow}
      margin={margin}
    />
  );
});
Textarea.displayName = "Textarea";

type ContainerProps = {
  color: string;
  placeholderColor: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  margin?: string;
};
const Container = styled.textarea<ContainerProps>`
  width: 350px;

  font-size: 16px;
  color: ${({ color }) => color};

  background-color: ${({ bgColor }) => bgColor};

  padding: 10px 15px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  margin: ${({ margin }) => margin};

  outline: none;
  resize: none;

  transition: box-shadow 0.1s ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
  :focus {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`;

export default Textarea;
