import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../components/SettingContainer";
import Typography from "../../components/Typography";
import Text from "../../components/Input/Text";
import Textarea from "../../components/Input/Textarea";
import FilledButton from "../../components/Button/FilledButton";
import { addInquiry } from "../../db/inquiries";
import { Inquiry } from "../../models/Inquiry";
import useSnackbar from "../../hooks/useSnackbar";
import Context from "../../context";

const Contact: React.FC = () => {
  // context
  const { user } = useContext(Context);
  // hooks
  const { t: cm } = useTranslation("common");
  const { t: c } = useTranslation("contact");
  const { setMessage } = useSnackbar();
  // states
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setTitle("");
      setBody("");
    }
  }, [user]);

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const onChangeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBody(event.target.value);
  };

  const save = async () => {
    if (!user || !title || !body) return;

    setLoading(true);
    const inquiry: Inquiry = {
      userId: user.userId,
      title,
      body,
      createdAt: new Date(),
    };
    await addInquiry(inquiry);
    setMessage({
      level: "success",
      text: cm("successfullyUpdated"),
    });
    setTitle("");
    setBody("");
    setLoading(false);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="8px 0px 16px 0px">
        {c("contact")}
      </Typography>
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {c("title")}
      </Typography>
      <Text value={title} onChange={onChangeTitle} margin="0px 0px 16px 0px" />
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {c("body")}
      </Typography>
      <Textarea
        value={body}
        onChange={onChangeBody}
        margin="0px 0px 16px 0px"
      />
      <FilledButton
        color="primary"
        disabled={!title || !body}
        loading={loading}
        onClick={save}
      >
        {cm("send")}
      </FilledButton>
    </SettingContainer>
  );
};

export default Contact;
