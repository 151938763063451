import React from "react";
import {
  Add,
  Edit,
  Remove,
  Delete,
  Clear,
  ArrowUpward,
  ArrowDownward,
  Refresh,
  Autorenew,
} from "@material-ui/icons";
import styled from "styled-components";
import { ButtonTheme } from "../../models/Theme";
import useStyles from "../../hooks/useStyles";
import { baseBorderRadius } from "../../constants";

type Color = "primary" | "secondary" | "default";

const getStyles = (
  color: Color | undefined,
  button: ButtonTheme,
  disabled: boolean | undefined
): {
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  hoverColor: string;
  hoverBgColor: string;
} => {
  if (disabled) {
    const theme = button.disabled;
    return {
      color: theme.color,
      bgColor: theme.bgColor,
      borderColor: theme.borderColor,
      boxShadow: theme.boxShadow
        ? theme.boxShadow
        : `0 0 10px ${theme.borderColor}`,
      hoverColor: theme.hover.color,
      hoverBgColor: theme.hover.bgColor,
    };
  }
  switch (color) {
    case "primary": {
      const theme = button.primary;
      return {
        color: theme.color,
        bgColor: theme.bgColor,
        borderColor: theme.borderColor,
        boxShadow: theme.boxShadow
          ? theme.boxShadow
          : `0 0 10px ${theme.borderColor}`,
        hoverColor: theme.hover.color,
        hoverBgColor: theme.hover.bgColor,
      };
    }
    case "secondary": {
      const theme = button.secondary;
      return {
        color: theme.color,
        bgColor: theme.bgColor,
        borderColor: theme.borderColor,
        boxShadow: theme.boxShadow
          ? theme.boxShadow
          : `0 0 10px ${theme.borderColor}`,
        hoverColor: theme.hover.color,
        hoverBgColor: theme.hover.bgColor,
      };
    }
    case "default":
    default: {
      const theme = button.default;
      return {
        color: theme.color,
        bgColor: theme.bgColor,
        borderColor: theme.borderColor,
        boxShadow: theme.boxShadow
          ? theme.boxShadow
          : `0 0 10px ${theme.borderColor}`,
        hoverColor: theme.hover.color,
        hoverBgColor: theme.hover.bgColor,
      };
    }
  }
};

type Props = {
  icon?:
    | "add"
    | "edit"
    | "remove"
    | "delete"
    | "clear"
    | "up"
    | "down"
    | "refresh";
  color?: Color;
  small?: boolean;
  width?: number;
  margin?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const FilledButton: React.FC<Props> = React.memo(
  ({
    children,
    icon,
    color,
    small,
    width,
    margin,
    disabled,
    loading,
    onClick,
  }) => {
    const { button } = useStyles();
    const {
      color: textColor,
      bgColor,
      borderColor,
      boxShadow,
      hoverColor,
      hoverBgColor,
    } = getStyles(color, button, disabled);

    const getIcon = () => {
      if (loading) {
        return <Loading />;
      }
      switch (icon) {
        case "add":
          return <Add />;
        case "edit":
          return <Edit />;
        case "delete":
          return <Delete />;
        case "remove":
          return <Remove />;
        case "clear":
          return <Clear />;
        case "up":
          return <ArrowUpward />;
        case "down":
          return <ArrowDownward />;
        case "refresh":
          return <Refresh />;
        default:
          return <></>;
      }
    };

    return (
      <Container
        color={textColor}
        bgColor={bgColor}
        borderColor={borderColor}
        boxShadow={boxShadow}
        hoverColor={hoverColor}
        hoverBgColor={hoverBgColor}
        small={small}
        width={width}
        margin={margin}
        disabled={disabled}
        onClick={onClick}
      >
        {getIcon()}
        {children}
      </Container>
    );
  }
);
FilledButton.displayName = "FilledButton";

type ContainerProps = {
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  hoverColor: string;
  hoverBgColor: string;
  small?: boolean;
  width?: number;
  margin?: string;
  disabled?: boolean;
};
const Container = styled.button<ContainerProps>`
  line-height: 26px;
  width: ${({ width }) => (width ? width + "px" : "")};
  font-size: ${({ small }) => (small ? 15 : 16)}px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ small }) => (small ? "4px 8px" : "6px 12px")};
  margin: ${({ margin }) => margin};
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  /* bg と shadow に加えて disabled で color & border */
  transition: color 100ms ease-out, background-color 100ms ease-out,
    border 100ms ease-out, box-shadow 100ms ease-out;

  > svg {
    margin-right: 3px;
  }

  :hover {
    color: ${({ hoverColor }) => hoverColor};
    background-color: ${({ hoverBgColor }) => hoverBgColor};
    box-shadow: ${({ boxShadow, disabled }) => (disabled ? "" : boxShadow)};
  }
  :focus {
    outline: none;
  }
`;

const Loading = styled(Autorenew)`
  animation: 3s linear infinite loading;
`;

export default FilledButton;
