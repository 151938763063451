import React from "react";
import { Add } from "@material-ui/icons";
import styled from "styled-components";
import useStyles from "../../hooks/useStyles";
import { baseBorderRadius } from "../../constants";

type Props = {
  margin?: string;
  onClick?: () => void;
};

const NodeLikeButton: React.FC<Props> = React.memo(
  ({ children, margin, onClick }) => {
    const { color, node } = useStyles();
    const boxShadow = node.boxShadow ? node.boxShadow : `0 0 10px ${color}`;
    return (
      <Container
        color={color}
        bgColor={node.bgColor}
        borderColor={color}
        boxShadow={boxShadow}
        margin={margin}
        onClick={onClick}
      >
        <Add />
        {children}
      </Container>
    );
  }
);
NodeLikeButton.displayName = "NodeLikeButton";

type ContainerProps = {
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  margin?: string;
};
const Container = styled.button<ContainerProps>`
  font-size: 16px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  line-height: 40px;
  padding: 0px 12px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  margin: ${({ margin }) => margin};

  user-select: none;
  cursor: pointer;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: box-shadow 0.1s ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

export default NodeLikeButton;
