import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../components/SettingContainer";
import ThemeItem from "../../components/ThemeItem";
import Typography from "../../components/Typography";
import useStyles from "../../hooks/useStyles";

const ThemeSetting: React.FC = () => {
  // hooks
  const { t } = useTranslation("theme");
  const { themes } = useStyles();

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="8px 0px 16px 0px">
        {t("theme")}
      </Typography>
      <Themes>
        {themes.map((t) => (
          <ThemeItem key={t.name} themeItem={t} />
        ))}
      </Themes>
    </SettingContainer>
  );
};

const Themes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div {
    margin-bottom: 12px;
  }
`;

export default ThemeSetting;
