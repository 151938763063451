import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ItemsContainer } from "./StyledComponents";
import FilterItem from "./FilterItem";
import { FilterStatus } from "../../models/Filter";

const filterStatuses = ["all", "undone"] as FilterStatus[];

type Props = {
  focused: boolean;
  filterStatus: FilterStatus;
  setFilterStatus: (filterStatus: FilterStatus) => void;
  onClick: () => void;
};

const StatusFilter: React.FC<Props> = React.memo(
  ({ focused, filterStatus, setFilterStatus, onClick }) => {
    // hooks
    const { t } = useTranslation("filterStatus");

    const onClickFilterStatus = (selectedFilterStatus: FilterStatus) => {
      setFilterStatus(selectedFilterStatus);
      onClick();
    };

    return (
      <Container>
        <ItemsContainer>
          {filterStatuses.map((s) => (
            <FilterItem
              key={s}
              focused={focused}
              selected={s === filterStatus}
              onClick={() => onClickFilterStatus(s)}
            >
              {t(s)}
            </FilterItem>
          ))}
        </ItemsContainer>
      </Container>
    );
  }
);
StatusFilter.displayName = "StatusFilter";

export default StatusFilter;
