import styled from "styled-components";
import { FlexColumnCenter } from "./StyledComponents";
import { clickToFocusZIndex } from "../constants";

const ClickToFocus = styled(FlexColumnCenter)`
  font-size: 50px;
  font-weight: bold;
  position: fixed;
  top: calc(50vh - 50px);
  left: 0;
  height: 100px;
  width: 100vw;
  z-index: ${clickToFocusZIndex};
`;

export default ClickToFocus;
