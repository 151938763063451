import React from "react";
import styled from "styled-components";
import { FlexRowCenter } from "../StyledComponents";
import useStyles from "../../hooks/useStyles";

type Props = {
  color: string;
  bgColor: string;
  onClick: () => void;
};

const FilteringItem: React.FC<Props> = React.memo(
  ({ children, color, bgColor, onClick }) => {
    // hooks
    const { filter } = useStyles();
    const boxShadow = filter.item.boxShadow
      ? filter.item.boxShadow
      : `0 0 10px ${filter.item.borderColor}`;

    return (
      <LabelItemContainer
        color={color}
        bgColor={bgColor}
        boxShadow={boxShadow}
        onClick={onClick}
      >
        {children}
      </LabelItemContainer>
    );
  }
);
FilteringItem.displayName = "FilteringItem";

type LabelItemProps = {
  color: string;
  bgColor: string;
  boxShadow: string;
};
const LabelItemContainer = styled(FlexRowCenter)<LabelItemProps>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  user-select: none;

  transition: box-shadow 0.1s ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

export default FilteringItem;
