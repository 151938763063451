import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexRowCenter } from "../StyledComponents";
import DialogWrapper from "./DialogWrapper";
import FilledButton from "../Button/FilledButton";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  color: "primary" | "secondary";
  title?: string;
  message: string;
  onClickYes: () => Promise<void>;
  onClickNo: () => void;
};

const YesNoDialog: React.FC<Props> = React.memo(
  ({ isOpen, setIsOpen, color, title, message, onClickYes, onClickNo }) => {
    // hooks
    const { t: c } = useTranslation("common");
    const yes = async () => {
      await onClickYes();
    };
    const no = () => {
      onClickNo();
    };
    const onClose = () => {
      setIsOpen(false);
    };
    if (isOpen) {
      return (
        <DialogWrapper isOpen={isOpen} onClose={onClose}>
          <Title>{title ?? ""}</Title>
          <Message>{message}</Message>
          <Buttons>
            <FilledButton small onClick={no}>
              {c("no")}
            </FilledButton>
            <FilledButton color={color} small onClick={yes}>
              {c("yes")}
            </FilledButton>
          </Buttons>
        </DialogWrapper>
      );
    } else {
      return <></>;
    }
  }
);
YesNoDialog.displayName = "YesNoDialog";

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Message = styled.div`
  white-space: pre-wrap;
  margin: 8px 0px;
`;

const Buttons = styled(FlexRowCenter)`
  > button {
    margin: 0px 4px;
  }
`;

export default YesNoDialog;
