import React from "react";
import TopPolicyContainer from "../components/TopPolicyContainer";
import PrivacyPolicyComponent from "../components/PrivacyPolicy";

const PrivacyPolicy: React.FC = () => {
  return (
    <TopPolicyContainer>
      <PrivacyPolicyComponent />
    </TopPolicyContainer>
  );
};

export default PrivacyPolicy;
