import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Add } from "@material-ui/icons";
import ListItem from "../../components/MenuItem";
import ProjectListItem from "./ProjectListItem";
import { Project } from "../../models/Project";
import Context from "../../context";
import useSnackbar from "../../hooks/useSnackbar";
import useAnalytics from "../../hooks/useAnalytics";
import {
  freePlanMaxProjectCount,
  sideMenuFullWidth,
  sideMenuWidth,
  sideMenuPadding,
  sideMenuMargin,
  projectListTop,
  projectListLeft,
  floatItemZIndex,
} from "../../constants";

type Props = {
  onClickDeleteProject: (project: Project) => void;
};

const ProjectList: React.FC<Props> = React.memo(({ onClickDeleteProject }) => {
  // context
  const {
    user,
    isProjectListOpen,
    setIsProjectListOpen,
    projects,
  } = useContext(Context);
  // hooks
  const { t } = useTranslation("project");
  const { setProjectCountAlert } = useSnackbar();
  const { sendAlertLog } = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  // computed
  const visible =
    user &&
    location.pathname !== "/signin" &&
    (isProjectListOpen ||
      location.pathname.includes("settings") ||
      location.pathname.includes("add") ||
      location.pathname === "/news")
      ? true
      : false;

  const selected = (projectId: string): boolean => {
    return location.pathname.includes(projectId);
  };

  const onMouseLeave = () => {
    setIsProjectListOpen(true);
  };

  const onClickAddProject = async () => {
    if (!user) {
      history.push("/signin");
    } else if (projects.length >= freePlanMaxProjectCount) {
      setProjectCountAlert();
      sendAlertLog("project_count_limit");
    } else {
      history.push("/projects/add");
    }
  };

  return (
    <Container
      className="scroll-none"
      visible={visible}
      onMouseLeave={onMouseLeave}
    >
      {projects.map((p, i) => (
        <ProjectListItem
          key={p.projectId}
          index={i}
          project={p}
          selected={selected(p.projectId)}
          onClickDeleteProject={onClickDeleteProject}
        />
      ))}
      <AddProject
        index={projects.length}
        selected={selected("add")}
        onClick={onClickAddProject}
      >
        <Add />
        <div>{t("addProject")}</div>
      </AddProject>
    </Container>
  );
});
ProjectList.displayName = "ProjectList";

const Container = styled.ul<{ visible: boolean }>`
  max-height: calc(100vh - ${projectListTop + sideMenuMargin}px);
  width: ${sideMenuWidth}px;
  padding: ${sideMenuPadding}px;
  margin-right: ${sideMenuMargin}px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  position: fixed;
  top: ${projectListTop}px;
  left: ${projectListLeft}px;

  overflow: auto;

  z-index: ${floatItemZIndex};

  transition: transform ${({ visible }) => (visible ? 350 : 1000)}ms ease-out;
  transform: translateX(
    -${({ visible }) => (visible ? "0" : sideMenuMargin + sideMenuFullWidth)}px
  );

  :hover {
    transform: translateX(0px);
  }

  > li {
    margin-bottom: 12px;
  }
  > li:last-child {
    margin-bottom: 0px;
  }
`;

const AddProject = styled(ListItem)`
  justify-content: flex-start;
`;

export default ProjectList;
