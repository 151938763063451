import { createContext } from "react";
import { User } from "./models/User";
import { Language } from "./models/Language";
import { SnackbarMessage } from "./models/Snackbar";
import { Project } from "./models/Project";
import { Team } from "./models/Team";
import { Theme } from "./models/Theme";
import jsonThemes from "./json/themes.json";
import { defaultLanguage } from "./constants";

const Context = createContext({
  isMac: false,
  isSafari: false,
  isCheckingUser: false,
  tempUserId: "",
  user: null as User | null,
  setUser: (user: User) => {},
  language: defaultLanguage as Language,
  setLanguage: (language: Language) => {},
  updateLocalUser: async () => {},
  signOut: () => {},
  theme: jsonThemes[0] as Theme,
  setTheme: (theme: Theme, projectId?: string) => {},
  fontSize: 16,
  setFontSize: (fontSize: number) => {},
  setMessage: (message: SnackbarMessage) => {},
  isProjectListOpen: false,
  setIsProjectListOpen: (isProjectListOpen: boolean) => {},
  isSettingMenuOpen: false,
  setIsSettingMenuOpen: (isSettingMenuOpen: boolean) => {},
  projects: [] as Project[],
  addLocalProject: async (projectId: string) => {},
  updateLocalProject: async (projectId: string) => {},
  deleteLocalProject: (projectId: string) => {},
  teams: [] as Team[],
  addLocalTeam: async (teamId: string) => {},
  updateLocalTeam: async (teamId: string) => {},
  // focus mindmap
  focusMindmapCounter: 0,
  focusMindmapFromOutside: () => {},
});
export default Context;
