import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FilledButton from "../../../components/Button/FilledButton";
import { FlexRowCenter } from "../../../components/StyledComponents";

type Props = {
  loading: boolean;
  disabled: boolean;
  onClickSave: () => Promise<void>;
  onClickCancel: () => void;
};

const Buttons: React.FC<Props> = React.memo(
  ({ loading, disabled, onClickSave, onClickCancel }) => {
    const { t: c } = useTranslation("common");
    const { t: p } = useTranslation("project");

    return (
      <Container>
        <FilledButton color="default" onClick={onClickCancel}>
          {p("backToProject")}
        </FilledButton>
        <FilledButton
          color="primary"
          loading={loading}
          disabled={disabled}
          onClick={onClickSave}
        >
          {c("save")}
        </FilledButton>
      </Container>
    );
  }
);
Buttons.displayName = "Buttons";

const Container = styled(FlexRowCenter)`
  > button {
    margin: 0px 4px;
  }
`;

export default Buttons;
