import React from "react";
import { useTranslation } from "react-i18next";
import Block from "./Block";
import Typography from "../Typography";
import Table from "./Table";
import FilledButton from "../Button/FilledButton";
import { User } from "../../models/User";
import { Auth } from "../../models/Auth";

type Props = {
  admins: User[];
  canEditMembers: boolean;
  changeAuth: (member: User, from: Auth, to: Auth) => Promise<void>;
};

const Admins: React.FC<Props> = React.memo(
  ({ admins, canEditMembers, changeAuth }) => {
    // hooks
    const { t: c } = useTranslation("common");
    const { t } = useTranslation("admin");
    return (
      <Block>
        <Typography variant="medium" bold>
          {t("admins")}
        </Typography>
        {admins.length ? (
          <Table>
            <tbody>
              {admins.map((a) => (
                <tr key={a.userId}>
                  <td>{a.name}</td>
                  {canEditMembers && (
                    <>
                      <td>
                        <FilledButton
                          icon="down"
                          small
                          onClick={() => changeAuth(a, "admin", "member")}
                        >
                          {t("moveToMember")}
                        </FilledButton>
                      </td>
                      <td>
                        <FilledButton
                          icon="clear"
                          color="secondary"
                          small
                          onClick={() => changeAuth(a, "admin", "notmember")}
                        >
                          {c("remove")}
                        </FilledButton>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Typography margin="4px 0px 0px 0px">{t("noAdmin")}</Typography>
        )}
      </Block>
    );
  }
);
Admins.displayName = "Admins";

export default Admins;
