import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../components/SettingContainer";
import Typography from "../../components/Typography";
import Text from "../../components/Input/Text";
import FilledButton from "../../components/Button/FilledButton";
import Form from "../../components/Input/Form";
import { createProject } from "../../db/projects";
import Context from "../../context";
import useSnackbar from "../../hooks/useSnackbar";
import useAnalytics from "../../hooks/useAnalytics";
import { freePlanMaxProjectCount } from "../../constants";

const ProjectList: React.FC = () => {
  // context
  const { user, projects, addLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: p } = useTranslation("project");
  const { t: pr } = useTranslation("priority");
  const history = useHistory();
  const { setProjectCountAlert } = useSnackbar();
  const { sendAlertLog, sendProjectLog } = useAnalytics();
  // states
  const [name, setName] = useState("");

  // Add project
  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onSubmitAddProject = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    await save();
  };
  const save = async () => {
    if (projects.length >= freePlanMaxProjectCount) {
      setProjectCountAlert();
      sendAlertLog("project_count_limit");
      return;
    }
    if (!user) return;
    const projectId = await createProject(name, user.userId, pr);
    await addLocalProject(projectId);
    history.push(`/projects/${projectId}`);
    setName("");
    sendProjectLog("add");
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {p("newProject")}
      </Typography>
      <Form onSubmit={onSubmitAddProject}>
        <Typography variant="medium" bold margin="0px 0px 12px 0px">
          {p("projectName")}
        </Typography>
        <Text
          value={name}
          placeholder={p("enterProjectName")}
          onChange={onChangeName}
        />
      </Form>
      <FilledButton
        color="primary"
        margin="12px 0px 0px 0px"
        disabled={!name}
        onClick={save}
      >
        {c("add")}
      </FilledButton>
    </SettingContainer>
  );
};

export default ProjectList;
