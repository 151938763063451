import firebase from "../firebase";
import { User } from "../models/User";
import { ActiveMember } from "../models/ActiveMember";

const db = firebase.firestore();

export const activeMemberConverter: firebase.firestore.FirestoreDataConverter<ActiveMember> = {
  toFirestore: (activeMember: ActiveMember) => {
    return {
      name: activeMember.name,
      image: activeMember.image,
      currentTaskId: activeMember.currentTaskId,
      updatedAt: firebase.firestore.Timestamp.fromDate(activeMember.updatedAt),
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): ActiveMember => {
    const data = snapshot.data(options);
    const project: ActiveMember = {
      userId: snapshot.id,
      name: data.name,
      image: data.image,
      currentTaskId: data.currentTaskId,
      updatedAt: (data.updatedAt as firebase.firestore.Timestamp).toDate(),
    };
    return project;
  },
};

export const createActiveMember = async (
  user: User,
  projectId: string,
  currentTaskId: string
): Promise<void> => {
  const activeMember: ActiveMember = {
    userId: user.userId,
    name: user.name,
    image: user.image,
    currentTaskId,
    updatedAt: new Date(),
  };
  await db
    .collection("projects")
    .doc(projectId)
    .collection("activeMembers")
    .doc(user.userId)
    .withConverter(activeMemberConverter)
    .set(activeMember);
};

export const updateCurrentTask = async (
  userId: string,
  projectId: string,
  currentTaskId: string
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .collection("activeMembers")
    .doc(userId)
    .set(
      {
        currentTaskId,
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      },
      { merge: true }
    );
};
