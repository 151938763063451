import firebase from "../firebase";
import { Inquiry } from "../models/Inquiry";

const db = firebase.firestore();

export const inquiryConverter: firebase.firestore.FirestoreDataConverter<Inquiry> = {
  toFirestore: (inquiry: Inquiry) => {
    return {
      userId: inquiry.userId,
      title: inquiry.title,
      body: inquiry.body,
      createdAt: firebase.firestore.Timestamp.fromDate(inquiry.createdAt),
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Inquiry => {
    const data = snapshot.data(options);
    const inquiry: Inquiry = {
      userId: data.userId,
      title: data.title,
      body: data.body,
      createdAt: (data.createdAt as firebase.firestore.Timestamp).toDate(),
    };
    return inquiry;
  },
};

export const addInquiry = async (inquiry: Inquiry): Promise<void> => {
  await db.collection("inquiries").withConverter(inquiryConverter).add(inquiry);
};
