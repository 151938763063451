import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Context from "../context";
import { SnackbarMessage } from "../models/Snackbar";

const useSnackbar = (): {
  setMessage: (message: SnackbarMessage) => void;
  setProjectCountAlert: () => void;
  setTaskCountAlert: () => void;
  setLabelCountAlert: () => void;
  setNetworkErrorMessage: () => void;
  setUnauthorizedMessage: () => void;
  setAddedInvisibleTaskMessage: () => void;
  setDifferentParentMessage: () => void;
  setTaskDeletedMessage: () => void;
  setCannotDeleteMessage: () => void;
  setNotCollaborationProjectWarning: () => void;
  setAssigningInvisibleWarning: () => void;
  setDueDateInvisibleWarning: () => void;
  setNoLabelWarning: () => void;
  setLabelInvisibleWarning: () => void;
} => {
  const { setMessage } = useContext(Context);
  const { t: e } = useTranslation("error");

  const setProjectCountAlert = () => {
    setMessage({
      level: "error",
      text: e("projectCountLimit"),
    });
  };

  const setTaskCountAlert = () => {
    setMessage({
      level: "error",
      text: e("taskCountLimit"),
    });
  };

  const setLabelCountAlert = () => {
    setMessage({
      level: "error",
      text: e("labelCountLimit"),
    });
  };

  const setNetworkErrorMessage = () => {
    setMessage({
      level: "error",
      text: e("networkError"),
    });
  };

  const setUnauthorizedMessage = () => {
    setMessage({
      level: "error",
      text: e("unauthorized"),
    });
  };

  const setAddedInvisibleTaskMessage = () => {
    setMessage({
      level: "warning",
      text: e("invisibleTask"),
    });
  };

  const setDifferentParentMessage = () => {
    setMessage({
      level: "warning",
      text: e("differentParent"),
    });
  };

  const setTaskDeletedMessage = () => {
    setMessage({
      level: "error",
      text: e("taskDeleted"),
    });
  };

  const setCannotDeleteMessage = () => {
    setMessage({
      level: "error",
      text: e("cannotDelete"),
    });
  };

  // settings
  const setNotCollaborationProjectWarning = () => {
    setMessage({
      level: "warning",
      text: e("notCollaborationProject"),
    });
  };
  const setAssigningInvisibleWarning = () => {
    setMessage({
      level: "warning",
      text: e("assigningInvisible"),
    });
  };
  const setDueDateInvisibleWarning = () => {
    setMessage({
      level: "warning",
      text: e("dueDateInvisible"),
    });
  };
  const setNoLabelWarning = () => {
    setMessage({
      level: "warning",
      text: e("noLabel"),
    });
  };
  const setLabelInvisibleWarning = () => {
    setMessage({
      level: "warning",
      text: e("labelInvisible"),
    });
  };

  return {
    setProjectCountAlert,
    setTaskCountAlert,
    setLabelCountAlert,
    setNetworkErrorMessage,
    setMessage,
    setUnauthorizedMessage,
    setAddedInvisibleTaskMessage,
    setDifferentParentMessage,
    setTaskDeletedMessage,
    setCannotDeleteMessage,
    setNotCollaborationProjectWarning,
    setAssigningInvisibleWarning,
    setDueDateInvisibleWarning,
    setNoLabelWarning,
    setLabelInvisibleWarning,
  };
};

export default useSnackbar;
