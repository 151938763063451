import React from "react";
import styled from "styled-components";
import { FlexColumnCenter } from "../StyledComponents";
import { baseBorderRadius } from "../../constants";
import useStyles from "../../hooks/useStyles";

const Block: React.FC = React.memo(({ children }) => {
  // hooks
  const { node } = useStyles();

  return <Container backgroundColor={node.bgColor}>{children}</Container>;
});
Block.displayName = "Block";

type ContainerProps = {
  backgroundColor: string;
};
const Container = styled(FlexColumnCenter)<ContainerProps>`
  min-width: 250px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 8px 16px;
  margin-bottom: 16px;
  border: solid 2px;
  border-radius: ${baseBorderRadius}px;
`;

export default Block;
