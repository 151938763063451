import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ItemsContainer } from "./StyledComponents";
import FilterItem from "./FilterItem";
import { User } from "../../models/User";

type Props = {
  focused: boolean;
  members: User[];
  filterMember: User | null;
  changeFilterMember: (member: User | null) => void;
  onClick: () => void;
};

const MemberFilter: React.FC<Props> = React.memo(
  ({ focused, members, filterMember, changeFilterMember, onClick }) => {
    // hooks
    const { t: c } = useTranslation("common");

    const onClickMember = (member: User) => {
      changeFilterMember(member);
      onClick();
    };
    const onClickReset = () => {
      changeFilterMember(null);
    };

    return (
      <Container>
        <ItemsContainer>
          <FilterItem
            focused={focused}
            selected={filterMember === null}
            onClick={onClickReset}
          >
            {c("none")}
          </FilterItem>
          {members.map((m) => (
            <FilterItem
              key={m.userId}
              focused={focused}
              selected={
                filterMember && m.userId === filterMember.userId ? true : false
              }
              onClick={() => onClickMember(m)}
            >
              {m.name}
            </FilterItem>
          ))}
        </ItemsContainer>
      </Container>
    );
  }
);
MemberFilter.displayName = "MemberFilter";

export default MemberFilter;
