import styled from "styled-components";

const Table = styled.table`
  td {
    padding: 2px;
    text-align: center;
  }
`;

export default Table;
