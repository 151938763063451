import styled from "styled-components";
import { FlexColumnCenter } from "../StyledComponents";

export const Container = styled(FlexColumnCenter)`
  padding: 4px 8px;
  border-radius: 10px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  > div {
    margin-right: 8px;
  }
  div:last-child {
    margin-right: 0px;
  }
`;
