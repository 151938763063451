export const firstDay = 0;

export const tutorialTitleHeight = 70;
export const tutorialTitlePaddingTop = 10;

export const mindmapTopPadding = 80;
export const mindmapBottomPadding = 20;
export const mindmapHorizontalPadding = 30;

export const tutorialFontSize = 12;
export const tutorialTaskVerticalPadding = 5;
export const tutorialTaskHorizontalPadding = 7;

export const recMargin = 70;
export const acitionItemsMargin = 15;
