import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ItemsContainer } from "./StyledComponents";
import FilterItem from "./FilterItem";
import { FilterDueDate } from "../../models/Filter";

const dueDates = [
  "none",
  "overdue",
  "today",
  "thisweek",
  "thismonth",
] as FilterDueDate[];

type Props = {
  focused: boolean;
  filterDueDate: FilterDueDate;
  setFilterDueDate: (filterDueDate: FilterDueDate) => void;
  onClick: () => void;
};

const DueDateFilter: React.FC<Props> = React.memo(
  ({ focused, filterDueDate, setFilterDueDate, onClick }) => {
    // hooks
    const { t } = useTranslation("filterDueDate");

    const onClickFilterDueDate = (selectedFilterDueDate: FilterDueDate) => {
      setFilterDueDate(selectedFilterDueDate);
      onClick();
    };

    return (
      <Container>
        <ItemsContainer>
          {dueDates.map((d) => (
            <FilterItem
              key={d}
              focused={focused}
              selected={d === filterDueDate}
              onClick={() => onClickFilterDueDate(d)}
            >
              {t(d)}
            </FilterItem>
          ))}
        </ItemsContainer>
      </Container>
    );
  }
);
DueDateFilter.displayName = "DueDateFilter";

export default DueDateFilter;
