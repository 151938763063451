import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import {
  FlexColumnCenter,
  FlexRowCenter,
} from "../../../components/StyledComponents";
import OutlinedButton from "../../../components/Button/OutlinedButton";
import MapContext from "../context";
import useStyles from "../../../hooks/useStyles";
import { Calendar, Day } from "../../../models/Calendar";
import { days } from "../../../constants";

// const
const hoverBGColor = "#afafaf";

const getCalender = (
  year: number,
  month: number,
  firstDay: number
): Calendar => {
  const lastDay = firstDay === 0 ? 6 : firstDay - 1;
  const lastDate = new Date(year, month, 0);
  const days: Date[] = [];
  for (let day = 1; day <= lastDate.getDate(); day++) {
    days.push(new Date(year, month - 1, day));
  }
  // 1日が月曜日でない場合に前月の日付で埋める
  const firstDayOfMonth = days[0];
  if (firstDayOfMonth.getDay() !== firstDay) {
    for (let i = 0; i < firstDayOfMonth.getDay() - firstDay; i++) {
      days.unshift(
        new Date(
          firstDayOfMonth.getFullYear(),
          firstDayOfMonth.getMonth(),
          -1 * i
        )
      );
    }
  }
  // 最終日が最終の曜日でない場合に次月の日付で埋める
  const lastDateDay = lastDate.getDay();
  if (lastDateDay !== lastDay) {
    const adjustedLastDay =
      lastDateDay < firstDay ? lastDateDay + 7 : lastDateDay;
    const limit = 6 + firstDay - adjustedLastDay;
    for (let day = 1; day <= limit; day++) {
      days.push(
        new Date(
          firstDayOfMonth.getFullYear(),
          firstDayOfMonth.getMonth() + 1,
          day
        )
      );
    }
  }

  const objMonth: Calendar = {
    year,
    month,
    weeks: [],
  };
  const weekCount = days.length / 7;
  for (let i = 0; i < weekCount; i++) {
    objMonth.weeks.push({
      no: i + 1,
      days: days.slice(i * 7, i * 7 + 7).map((d) => {
        return {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
        };
      }),
    });
  }
  return objMonth;
};

const getDays = (firstDay: number): string[] => {
  const doubleDays = days.concat(days);
  return doubleDays.slice(firstDay, firstDay + 7);
};

type Props = {
  selectedDate: Date | null;
  onChange: (selectedDate: Date | null) => void;
  forceUpdateCounter: number;
  color: string;
};

const DatePicker: React.FC<Props> = React.memo(
  ({ selectedDate, onChange, forceUpdateCounter, color }) => {
    // context
    const { firstDay } = useContext(MapContext);
    // hooks
    const { t: c } = useTranslation("common");
    const { t: cl } = useTranslation("calendar");
    const { t: sd } = useTranslation("shortDays");
    const { button } = useStyles();
    // const
    const now = new Date();
    const days = useMemo(() => {
      return getDays(firstDay);
    }, [firstDay]);
    // states
    const [calendar, setCalendar] = useState(
      selectedDate
        ? getCalender(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            firstDay
          )
        : getCalender(now.getFullYear(), now.getMonth() + 1, firstDay)
    );

    useEffect(() => {
      if (selectedDate && calendar.month !== selectedDate.getMonth() + 1) {
        setCalendar(
          getCalender(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            firstDay
          )
        );
      }
    }, [forceUpdateCounter]);

    const goToPreviousMonth = () => {
      if (calendar.month === 1) {
        setCalendar(getCalender(calendar.year - 1, 12, firstDay));
      } else {
        setCalendar(getCalender(calendar.year, calendar.month - 1, firstDay));
      }
    };

    const goToNextMonth = () => {
      if (calendar.month === 12) {
        setCalendar(getCalender(calendar.year + 1, 1, firstDay));
      } else {
        setCalendar(getCalender(calendar.year, calendar.month + 1, firstDay));
      }
    };

    const onClickDate = (event: React.MouseEvent<HTMLDivElement>, day: Day) => {
      event.stopPropagation();
      setNewDate(new Date(day.year, day.month - 1, day.day));
    };

    const setNewDate = (date: Date) => {
      onChange(date);
    };

    const setNull = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onChange(null);
    };
    const setToday = () => {
      setNewDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    };

    const isToday = (day: Day): boolean => {
      return (
        day.year === now.getFullYear() &&
        day.month === now.getMonth() + 1 &&
        day.day === now.getDate()
      );
    };

    const isTodayDate = (date: Date): boolean => {
      return (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
      );
    };

    const selected = (day: Day): boolean => {
      return selectedDate &&
        selectedDate.getFullYear() === day.year &&
        selectedDate.getMonth() + 1 === day.month &&
        selectedDate.getDate() === day.day
        ? true
        : false;
    };

    return (
      <Container>
        <Header>
          <OutlinedButton
            color="white"
            bgColor={button.default.borderColor}
            selected={!selectedDate}
            onClick={setNull}
          >
            {c("none")}
          </OutlinedButton>
          <FlexRowCenter>
            <PreviousMonth onClick={goToPreviousMonth} />
            <div>
              {cl("yearMonth")
                .replace("{year}", calendar.year.toString())
                .replace("{month}", calendar.month.toString())}
            </div>
            <NextMonth onClick={goToNextMonth} />
          </FlexRowCenter>
          <OutlinedButton
            color="white"
            bgColor={button.primary.borderColor}
            selected={selectedDate && isTodayDate(selectedDate) ? true : false}
            onClick={setToday}
          >
            {cl("today")}
          </OutlinedButton>
        </Header>
        <table>
          <thead>
            <tr>
              {days.map((d) => (
                <th key={d}>{sd(d)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {calendar.weeks.map((week) => (
              <tr key={"week" + week.no}>
                {week.days.map((day) => (
                  <td key={"day" + day.day} align="center">
                    <ComponentDay
                      isInThisMonth={day.month === calendar.month}
                      isToday={isToday(day)}
                      selected={selected(day)}
                      color={color}
                      todayColor="white"
                      todayBgColor={button.secondary.borderColor}
                      selectedColor="white"
                      selectedBgColor={button.primary.borderColor}
                      onClick={(e) => onClickDate(e, day)}
                    >
                      {day.day}
                    </ComponentDay>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    );
  }
);
DatePicker.displayName = "DatePicker";

const Container = styled(FlexColumnCenter)`
  width: 240px;
  background-color: transparent;
  border-radius: 10px;
  align-items: stretch;
`;

const Header = styled.div`
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PreviousMonth = styled(ChevronLeft)`
  cursor: pointer;
`;

const NextMonth = styled(ChevronRight)`
  cursor: pointer;
`;

type DayProps = {
  isInThisMonth: boolean;
  isToday: boolean;
  selected: boolean;
  color: string;
  todayColor: string;
  todayBgColor: string;
  selectedColor: string;
  selectedBgColor: string;
};
const ComponentDay = styled.div<DayProps>`
  color: ${({ isInThisMonth, isToday, selected, color, selectedColor }) =>
    isToday || selected ? selectedColor : isInThisMonth ? color : "gray"};
  background-color: ${({ isToday, selected, todayBgColor, selectedBgColor }) =>
    selected ? selectedBgColor : isToday ? todayBgColor : ""};
  border-radius: 5px;
  padding: 2px 4px;
  cursor: pointer;

  transition: color 0.1s ease-out, background-color 0.1s ease-out,
    border 0.1s ease-out;

  :hover {
    background-color: ${({
      isToday,
      selected,
      todayBgColor,
      selectedBgColor,
    }) => (selected ? selectedBgColor : isToday ? todayBgColor : hoverBGColor)};
  }
`;

export default DatePicker;
