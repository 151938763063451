import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { OpenInNew } from "@material-ui/icons";
import {
  FlexRowCenter,
  FlexColumnCenter,
} from "../../../components/StyledComponents";
import Checkbox from "../../../components/Input/Checkbox";
import YouAreHere from "../YouAreHere";
import { Task } from "../../../models/Task";
import {
  getTaskColor,
  getTaskBgColor,
  getTaskBorder,
  getTaskBoxShadow,
  getTaskZIndex,
  checkboxChedkedColor,
  checkboxUnchedkedColor,
} from "../../../bl/styles";
import Context from "../../../context";
import useStyles from "../../../hooks/useStyles";
import {
  taskWidth,
  taskFullWidth,
  baseBorderRadius,
  regLink,
} from "../../../constants";
import {
  tutorialFontSize,
  tutorialTaskVerticalPadding,
  tutorialTaskHorizontalPadding,
} from "../constants";

type Props = {
  task: Task;
  onClick: (event: React.MouseEvent<HTMLDivElement>, task: Task) => void;
  toggleDone: (task: Task) => void;
  endEditingText: (task: Task, text: string) => void;
  setTask: (task: Task) => void;
  adjustScroll: (target: Task) => void;
};

const ComponentTask: React.FC<Props> = React.memo(
  ({ task, onClick, toggleDone, endEditingText, setTask, adjustScroll }) => {
    // context
    const { isSafari } = useContext(Context);
    // hooks
    const { t: tsk } = useTranslation("task");
    const { palette, color, node, input } = useStyles();
    const paletteColor = palette[(task.topSortNumber - 1) % palette.length];

    // states
    const [text, setText] = useState(task.text);

    // computed
    const nodeColor = getTaskColor(task, color, node);
    const backgroundColor = getTaskBgColor(task, paletteColor, node);
    const border = getTaskBorder(task, paletteColor, node);
    const boxShadow = getTaskBoxShadow(task, paletteColor, node);
    const zIndex = getTaskZIndex(task);
    const checkedColor = checkboxChedkedColor(task, node);
    const uncheckedColor = checkboxUnchedkedColor(task, paletteColor, node);
    const maxWidth = task.children.length ? taskWidth : taskFullWidth;

    // ref
    const elm = useRef<HTMLDivElement>(null);
    const textareaElm = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      // setState しなくても class 変数が更新される発明
      task.elm = elm.current;
    }, []);

    useEffect(() => {
      if (task.isEditing) {
        setText(task.text);
        focusTextarea();
        resizeTextarea();
        adjustScroll(task);
      }
    }, [task.isEditing]);

    const focusTextarea = () => {
      if (textareaElm.current) {
        textareaElm.current.focus();
        textareaElm.current.setSelectionRange(999, 999);
      }
    };

    const resizeTextarea = () => {
      if (textareaElm.current) {
        textareaElm.current.style.height = "auto";
        textareaElm.current.style.height =
          textareaElm.current.scrollHeight + "px";
      }
    };

    const onClickTask = (event: React.MouseEvent<HTMLDivElement>) => {
      if (task.isEditing) return;
      onClick(event, task);
    };

    const onDoubleClick = () => {
      if (task.isEditing) return;
      task.selected = true;
      task.isEditing = true;
      setTask(task);
    };

    const onTextareaMouseDown = (
      event: React.MouseEvent<HTMLTextAreaElement>
    ) => {
      event.stopPropagation(); // stop grab mindmap
    };

    const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(event.target.value.replace("\n", ""));
    };

    const onInput = () => {
      resizeTextarea();
    };

    const onClickTextarea = (event: React.MouseEvent<HTMLTextAreaElement>) => {
      event.stopPropagation();
    };
    const onTextareaContextMenu = (
      event: React.MouseEvent<HTMLTextAreaElement>
    ) => {
      event.stopPropagation();
    };
    const onTextareaBlur = () => {
      endEditingText(task, text);
    };

    const onTextareaKeyDown = (
      event: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      event.stopPropagation();
      switch (event.key) {
        case "Enter":
          if (isSafari) {
            if (event.keyCode === 13) {
              // Safari
              // https://qiita.com/ledsun/items/31e43a97413dd3c8e38e
              // https://qiita.com/darai0512/items/fac4f166c23bf2075deb
              end();
            }
          } else {
            // Chrome Firefox
            // eslint-disable-next-line
            if (!(event as any).isComposing) {
              end();
            }
          }
          break;
        case "Tab":
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    const end = () => {
      textareaElm.current && textareaElm.current.blur();
    };

    const onChangeDone = () => {
      toggleDone(task);
    };

    const displayText = useMemo(() => {
      if (/http/.test(task.text)) {
        const splitTexts = task.text.split(regLink);
        return (
          <>
            {splitTexts.map((t, i) =>
              /http/.test(t) ? (
                <FlexRowCenter key={task.id + i}>
                  <Link>{t}</Link>
                  <OpenLinkButton onClick={() => openLink(t)} />
                </FlexRowCenter>
              ) : (
                <span key={task.id + i}>{t}</span>
              )
            )}
          </>
        );
      } else {
        return task.text;
      }
    }, [task.text]);

    const openLink = (url: string) => {
      const newWindow = window.open(url, "_blank");
      newWindow && newWindow.focus();
    };

    return (
      <TaskArea
        ref={elm}
        maxWidth={maxWidth}
        selected={task.selected}
        isEditing={task.isEditing}
        paletteColor={paletteColor}
        onClick={onClickTask}
        onDoubleClick={onDoubleClick}
        style={{
          color: nodeColor,
          backgroundColor,
          border,
          boxShadow,
          zIndex,
          top: task.top,
          left: task.left,
        }}
      >
        <FlexRowCenter>
          {task.children.length === 0 && (
            <Checkbox
              checkedColor={checkedColor}
              uncheckedColor={uncheckedColor}
              checked={task.done}
              onChange={onChangeDone}
            />
          )}
          <TaskText>
            {task.isEditing ? (
              <Textarea
                ref={textareaElm}
                placeholder={tsk("enterTask")}
                placeholderColor={input.text.placeholderColor}
                value={text}
                onChange={onChangeText}
                onInput={onInput}
                onClick={onClickTextarea}
                onContextMenu={onTextareaContextMenu}
                onKeyDown={onTextareaKeyDown}
                onMouseDown={onTextareaMouseDown}
                onBlur={onTextareaBlur}
              />
            ) : (
              displayText
            )}
          </TaskText>
        </FlexRowCenter>
        <YouAreHere
          taskHeight={task.offsetHeight}
          left={task.offsetWidth}
          color={color}
        />
      </TaskArea>
    );
  }
);
ComponentTask.displayName = "ComponentTask";

type TaskAreaProps = {
  maxWidth: number;
  selected: boolean;
  isEditing: boolean;
  paletteColor: string;
};

const TaskArea = styled(FlexColumnCenter)<TaskAreaProps>`
  font-size: ${tutorialFontSize}px;
  max-width: ${({ maxWidth }) => maxWidth}px;

  padding: ${tutorialTaskVerticalPadding}px ${tutorialTaskHorizontalPadding}px;
  position: absolute;

  border-radius: ${baseBorderRadius}px;

  user-select: none;
  cursor: default;

  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out;

  :hover {
    transform: translateY(-3px);
  }

  > div:last-child {
    display: ${({ selected }) => (selected ? "" : "none")};
  }
`;

const TaskText = styled(FlexColumnCenter)`
  max-width: ${taskWidth}px;
`;

type TextareaProps = {
  placeholderColor: string;
};
const Textarea = styled.textarea<TextareaProps>`
  font-size: ${tutorialFontSize}px;
  color: inherit;
  background-color: inherit;
  padding: 0;
  outline: none;
  border: none;
  width: ${taskWidth}px;
  overflow: hidden;
  box-sizing: border-box;
  letter-spacing: inherit;
  resize: none;

  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`;

const openLinkButtonWidth = 20;
const OpenLinkButton = styled(OpenInNew)`
  height: ${openLinkButtonWidth}px !important;
  width: ${openLinkButtonWidth}px !important;
  cursor: pointer;
`;
const Link = styled.span`
  max-width: ${taskWidth - openLinkButtonWidth}px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ComponentTask;
