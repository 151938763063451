import { User } from "../../models/User";
import { ITask, Task } from "../../models/Task";

export const iTasksToTasks = (
  iTasks: ITask[],
  members: User[]
): {
  tasks: Task[];
  orphans: Task[];
} => {
  const cTasks: Task[] = [];
  for (const iTask of iTasks) {
    cTasks.push(
      new Task(
        iTask.id,
        iTask.level,
        iTask.sortNumber,
        null,
        [],
        iTask.text,
        iTask.done,
        iTask.doneBy,
        iTask.doneAt,
        null,
        iTask.dueDate,
        iTask.labels
      )
    );
  }
  /**
   * ここでデータの不整合を起こしているタスクを削除する
   */
  const orphans: Task[] = [];
  for (let i = 0; i < cTasks.length; i++) {
    const cTask = cTasks[i];
    const iTask = iTasks[i];
    if (iTask.parentId) {
      const parent = cTasks.find((t) => t.id === iTask.parentId);
      if (parent) {
        const iParent = iTasks.find((t) => t.id === parent.id);
        if (iParent) {
          if (iParent.childrenIds.includes(cTask.id)) {
            cTask.parent = parent;
          } else {
            // 子供はいるが、親の childrenIds に含まれない
            orphans.push(cTask);
          }
        } else {
          // 絶対にある前提
        }
      } else {
        // 親がいないパターン
        if (cTask.level !== 1) {
          orphans.push(cTask);
        }
      }
    } else {
      // そもそも parentId が入ってないパターン
      if (cTask.level !== 1) {
        orphans.push(cTask);
      }
    }
    const childrenIds = iTask.childrenIds;
    for (const childId of childrenIds) {
      const child = cTasks.find((t) => t.id === childId);
      if (child) {
        cTask.children.push(child);
      }
    }
    if (iTask.assigneeId) {
      const assignee = members.find((m) => m.userId === iTask.assigneeId);
      if (assignee) {
        cTask.assignee = assignee;
      }
    }
  }
  const orphanIds = orphans.map((o) => o.id);
  return {
    tasks: cTasks.filter((t) => !orphanIds.includes(t.id)),
    orphans,
  };
};
