import React from "react";
import styled from "styled-components";
import { RadioButtonUnchecked, CheckCircleOutline } from "@material-ui/icons";
import useStyles from "../../hooks/useStyles";

type Props = {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
};

const Radio: React.FC<Props> = React.memo(
  ({ label, value, checked, onChange }) => {
    const { color, input } = useStyles();
    const onClick = () => {
      onChange(value);
    };
    return (
      <Container
        checked={checked}
        color={color}
        uncheckedColor={input.checkbox.uncheckedColor}
        onClick={onClick}
      >
        {checked ? <CheckCircleOutline /> : <RadioButtonUnchecked />}
        <Label>{label}</Label>
      </Container>
    );
  }
);
Radio.displayName = "Radio";

type ContainerProps = {
  checked: boolean;
  color: string;
  uncheckedColor: string;
};
const Container = styled.button<ContainerProps>`
  line-height: 26px;

  font-size: 16px;
  color: ${({ checked, color, uncheckedColor }) =>
    checked ? color : uncheckedColor};

  background-color: transparent;

  border: none;
  margin-bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  cursor: pointer;

  transition: color 100ms ease-out;

  :hover {
    color: ${({ color }) => color};
  }
`;

const Label = styled.label`
  margin-left: 5px;
  cursor: pointer;
`;

export default Radio;
