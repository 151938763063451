import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  :hover {
    color: inherit;
  }
`;

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
