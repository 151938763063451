import { useContext } from "react";
import Context from "../context";
import useLocalStorage from "./useLocalStorage";
import {
  Theme,
  LogoTheme,
  TopTheme,
  NodeTheme,
  LabelTheme,
  MenuItemTheme,
  ButtonTheme,
  IconTheme,
  FilterTheme,
  InputTheme,
} from "../models/Theme";
import themes from "../json/themes.json";

const useStyles = (): {
  themes: Theme[];
  theme: Theme;
  setTheme: (theme: Theme) => void;
  fontSize: number;
  palette: string[];
  background: string;
  color: string;
  logo: LogoTheme;
  top: TopTheme;
  node: NodeTheme;
  label: LabelTheme;
  menuItem: MenuItemTheme;
  button: ButtonTheme;
  icon: IconTheme;
  filter: FilterTheme;
  helpBgColor: string;
  input: InputTheme;
  incrementFontSize: () => void;
  decrementFontSize: () => void;
} => {
  const { theme, setTheme, fontSize, setFontSize } = useContext(Context);
  const { saveTheme, saveFontSize } = useLocalStorage();

  const selectTheme = (selectedTheme: Theme) => {
    setTheme(selectedTheme);
    saveTheme(selectedTheme);
  };

  const incrementFontSize = () => {
    setFontSize(fontSize + 1);
    saveFontSize(fontSize + 1);
  };

  const decrementFontSize = () => {
    if (fontSize <= 10) return;
    setFontSize(fontSize - 1);
    saveFontSize(fontSize - 1);
  };

  return {
    themes,
    theme,
    setTheme: selectTheme,
    fontSize,
    palette: theme.palette,
    background: theme.background,
    color: theme.color,
    logo: theme.logo,
    top: theme.top,
    node: theme.node,
    label: theme.label,
    menuItem: theme.menuItem,
    button: theme.button,
    icon: theme.icon,
    filter: theme.filter,
    helpBgColor: theme.helpBgColor,
    input: theme.input,
    incrementFontSize,
    decrementFontSize,
  };
};

export default useStyles;
