import { createContext } from "react";
import { User } from "../../models/User";
import { Project } from "../../models/Project";
import { ActiveMember } from "../../models/ActiveMember";
import { Task } from "../../models/Task";
import { LabelItem } from "../../models/Label";
import { FilterStatus, FilterDueDate } from "../../models/Filter";
import { defaultFirstDay } from "../../constants";

const MapContext = createContext({
  focusMindmap: () => {},
  project: null as Project | null,
  isCoediting: false,
  isHelpOpen: false,
  setIsHelpOpen: (isHelpOpen: boolean) => {},
  firstDay: defaultFirstDay,
  members: [] as User[],
  activeMembers: [] as ActiveMember[],
  setActiveMembers: (activeMembers: ActiveMember[]) => {},
  setTask: (task: Task) => {},
  // Settings
  isSettingsVisible: true,
  toggleIsSettingsVisible: () => {},
  // Filter
  openOrFocusFilter: async () => {},
  closeFilter: () => {},
  focusFilterCounter: 0,
  filterMember: null as User | null,
  changeFilterMember: (member: User | null) => {},
  filterStatus: "" as FilterStatus,
  changeFilterStatus: (filterStatus: FilterStatus) => {},
  filterDueDate: "" as FilterDueDate,
  changeFilterDueDate: (filterDueDate: FilterDueDate) => {},
  filterLabelItems: [] as LabelItem[],
  toggleFilterLabelItem: (item: LabelItem) => {},
  resetFilter: (resetLocalStorage: boolean) => {},
  adjustScroll: (target: Task) => {},
});
export default MapContext;
