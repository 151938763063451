import firebase from "../firebase";
import { User } from "../models/User";
import { Language } from "../models/Language";
import { db } from "../firebase";
import { defaultLanguage } from "../constants";

const userConverter: firebase.firestore.FirestoreDataConverter<User> = {
  toFirestore: (user: User) => {
    return {
      userId: user.userId,
      language: user.language,
      name: user.name,
      image: user.image,
      email: user.email,
      organizaitonIds: user.organizaitonIds,
      allowEmailSearch: user.allowEmailSearch,
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): User => {
    const data = snapshot.data(options);
    return {
      userId: snapshot.id,
      language: data.language,
      name: data.name,
      image: data.image,
      email: data.email,
      organizaitonIds: data.organizaitonIds,
      allowEmailSearch: data.allowEmailSearch,
    } as User;
  },
};

export const getUser = async (
  userId: string,
  name: string,
  email: string,
  image: string
): Promise<User> => {
  const doc = await db
    .collection("users")
    .doc(userId)
    .withConverter(userConverter)
    .get();
  const data = doc.data();
  if (data) return data;

  // Create new user with plan = free
  const newUser: User = {
    userId,
    language: defaultLanguage,
    name,
    email,
    image,
    organizaitonIds: [],
    allowEmailSearch: false,
  };
  await db.collection("users").doc(userId).set(newUser);
  return newUser;
};

export const getUserById = async (userId: string): Promise<User | null> => {
  const doc = await db
    .collection("users")
    .doc(userId)
    .withConverter(userConverter)
    .get();
  const data = doc.data();
  if (data) {
    return data;
  } else {
    return null;
  }
};

export const getUserByEmail = async (email: string): Promise<User | null> => {
  const qs = await db
    .collection("users")
    .where("allowEmailSearch", "==", true)
    .where("email", "==", email)
    .withConverter(userConverter)
    .get();
  if (qs.docs.length) {
    return qs.docs[0].data();
  } else {
    return null;
  }
};

export const getUsers = async (userIds: string[]): Promise<User[]> => {
  const qs = await db
    .collection("users")
    .withConverter(userConverter)
    .where(firebase.firestore.FieldPath.documentId(), "in", userIds)
    .get();
  return qs.docs.map((d) => d.data());
};

export const updateUserNameEmailAllow = async (
  userId: string,
  name: string,
  email: string,
  allowEmailSearch: boolean
): Promise<void> => {
  await db.collection("users").doc(userId).update({
    name,
    email,
    allowEmailSearch,
  });
};

export const updateUserLanguage = async (
  userId: string,
  language: Language
): Promise<void> => {
  await db.collection("users").doc(userId).update({
    language,
  });
};
