import React from "react";
import styled from "styled-components";
import Typography from "./Typography";
import { PolicyContainer, Subtitle1, Subtitle2 } from "./PolicyComponents";
import { policyUpdatedAt } from "../constants";

const CookiePolicy: React.FC = () => {
  return (
    <PolicyContainer>
      <Typography variant="large" bold margin="8px 0px 16px 0px">
        Cookie ポリシー
      </Typography>
      <Subtitle1 marginBottom>1. Coookieとは</Subtitle1>
      <Subtitle2 marginBottom>
        Cookieとは、お客様がウェブサイトやサービスにアクセスした際の閲覧情報を、お客様のデバイス（PCやスマートフォン、タブレットなどの機器）に保存する機能のことです。Cookieからお客様の個人情報を特定することは出来ません。
      </Subtitle2>
      <Subtitle1 marginBottom>2. Cookieの用途</Subtitle1>
      <Subtitle2 marginBottom>
        当サービスでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
      </Subtitle2>
      <Subtitle2 marginBottom>
        この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細は
        <OpenInNewTab
          href="https://marketingplatform.google.com/about/analytics/terms/jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Googleアナリティクスサービス利用規約
        </OpenInNewTab>
        のページや
        <OpenInNewTab
          href="https://policies.google.com/technologies/ads?hl=ja"
          target="_blank"
          rel="noopener noreferrer"
        >
          Googleポリシー
        </OpenInNewTab>
        と規約ページをご覧ください。
      </Subtitle2>
      <Subtitle1 marginBottom>3. Cookieの拒否方法</Subtitle1>
      <Subtitle2 marginBottom>
        ブラウザの設定を変更することによりCookieを無効にすることが可能です。ただしCookieを無効にした場合、当サービスおよび当サービス以外のウェブサイトやサービスなどでも一部の機能が提供されない場合があることをご了承下さい。
      </Subtitle2>
      <Subtitle2>{policyUpdatedAt.toLocaleDateString()}</Subtitle2>
    </PolicyContainer>
  );
};

const OpenInNewTab = styled.a`
  color: inherit;
`;

export default CookiePolicy;
