import { TFunction } from "react-i18next";
import firebase from "../firebase";
import { issueId } from "../bl/common";
import { Project, Visibility } from "../models/Project";
import { ITask } from "../models/Task";
import { taskConverter } from "./tasks";
import { Label, LabelItem } from "../models/Label";
import { activeMemberConverter } from "./activeMembers";
import { defaultFirstDay, defaultVisibility } from "../constants";

const db = firebase.firestore();

export const projectConverter: firebase.firestore.FirestoreDataConverter<Project> = {
  toFirestore: (project: Project) => {
    return {
      name: project.name,
      ownerId: project.ownerId,
      adminIds: project.adminIds,
      memberIds: project.memberIds,
      labels: project.labels,
      firstDay: project.firstDay,
      visibility: project.visibility,
      createdAt: firebase.firestore.Timestamp.fromDate(project.createdAt),
      updatedAt: firebase.firestore.Timestamp.fromDate(project.updatedAt),
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Project => {
    const data = snapshot.data(options);
    const project: Project = {
      projectId: snapshot.id,
      name: data.name,
      ownerId: data.ownerId,
      adminIds: data.adminIds,
      memberIds: data.memberIds,
      labels: data.labels,
      firstDay: "firstDay" in data ? data.firstDay : defaultFirstDay,
      visibility: defaultVisibility,
      createdAt: (data.createdAt as firebase.firestore.Timestamp).toDate(),
      updatedAt: (data.updatedAt as firebase.firestore.Timestamp).toDate(),
    };
    const visibility: Visibility = {
      assignee:
        data.visibility && "assignee" in data.visibility
          ? data.visibility.assignee
          : true,
      dueDate:
        data.visibility && "dueDate" in data.visibility
          ? data.visibility.dueDate
          : true,
      label:
        data.visibility && "label" in data.visibility
          ? data.visibility.label
          : true,
      filterHelp:
        data.visibility && "filterHelp" in data.visibility
          ? data.visibility.filterHelp
          : true,
    };
    project.visibility = visibility;
    project.labels = project.labels.map((l) => {
      const label: Label = {
        labelId: l.labelId,
        displayOrder: l.displayOrder,
        name: l.name,
        labelItems: l.labelItems,
      };
      label.labelItems = label.labelItems.map((i) => {
        const labelItem: LabelItem = {
          labelItemId: i.labelItemId,
          sortOrder: i.sortOrder,
          name: i.name,
          color: i.color,
          backgroundColor: i.backgroundColor,
        };
        return labelItem;
      });
      return label;
    });
    return project;
  },
};

export const createProject = async (
  name: string,
  ownerId: string,
  t: TFunction<"priority">
): Promise<string> => {
  const newProject: Project = {
    projectId: "",
    name,
    ownerId,
    adminIds: [],
    memberIds: [],
    labels: createDefaultLabels(t),
    firstDay: defaultFirstDay,
    visibility: defaultVisibility,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  const doc = await db
    .collection("projects")
    .withConverter(projectConverter)
    .add(newProject);
  const projectId = doc.id;
  const firstTask: ITask = {
    id: issueId(),
    level: 1,
    sortNumber: 1,
    parentId: "",
    childrenIds: [],
    text: name,
    done: false,
    doneBy: "",
    doneAt: null,
    assigneeId: "",
    dueDate: null,
    labels: [],
    changeType: "",
  };
  await db
    .collection("projects")
    .doc(projectId)
    .collection("tasks")
    .withConverter(taskConverter)
    .doc(firstTask.id)
    .set(firstTask);
  return projectId;
};

const createDefaultLabels = (t: TFunction<"priority">): Label[] => {
  return [
    {
      labelId: "62c1e14622674a6d9e5b74fa92967be9",
      displayOrder: 1,
      name: t("priority"),
      labelItems: [
        {
          labelItemId: "3681d3bd4ffb4e6b94190160192770c4",
          sortOrder: 1,
          name: t("low"),
          color: "#ffffff",
          backgroundColor: "#ffc069",
        },
        {
          labelItemId: "5308fead90614d529b7e86df82886c4b",
          sortOrder: 2,
          name: t("medium"),
          color: "#ffffff",
          backgroundColor: "#ff9c6e",
        },
        {
          labelItemId: "53a85d698a0b4c118f28124918458430",
          sortOrder: 3,
          name: t("high"),
          color: "#ffffff",
          backgroundColor: "#ff7875",
        },
      ],
    },
  ];
};

export const getProjects = async (userId: string): Promise<Project[]> => {
  const projects: Project[] = [];
  const ownerProjects = await db
    .collection("projects")
    .where("ownerId", "==", userId)
    .withConverter(projectConverter)
    .get();
  Array.prototype.push.apply(
    projects,
    ownerProjects.docs.map((d) => d.data())
  );
  const adminProjects = await db
    .collection("projects")
    .where("adminIds", "array-contains", userId)
    .withConverter(projectConverter)
    .get();
  Array.prototype.push.apply(
    projects,
    adminProjects.docs.map((d) => d.data())
  );
  const memberProjects = await db
    .collection("projects")
    .where("memberIds", "array-contains", userId)
    .withConverter(projectConverter)
    .get();
  Array.prototype.push.apply(
    projects,
    memberProjects.docs.map((d) => d.data())
  );
  projects.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  return projects;
};

export const getProject = async (
  projectId: string
): Promise<Project | null> => {
  const doc = await db
    .collection("projects")
    .doc(projectId)
    .withConverter(projectConverter)
    .get();
  const data = doc.data();
  return data ?? null;
};

export const updateProjectName = async (
  projectId: string,
  name: string
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .update({
      name,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
};

export const updateProjectLabels = async (
  projectId: string,
  labels: Label[]
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .update({
      labels,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
};

export const updateProjectFirstDay = async (
  projectId: string,
  firstDay: number
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .update({
      firstDay,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
};

export const updateProjectVisibility = async (
  projectId: string,
  visibility: Visibility
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .update({
      visibility,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
};

export const switchAdminToMember = async (
  projectId: string,
  adminId: string
): Promise<void> => {
  const batch = db.batch();
  batch.update(db.collection("projects").doc(projectId), {
    adminIds: firebase.firestore.FieldValue.arrayRemove(adminId),
  });
  batch.update(db.collection("projects").doc(projectId), {
    memberIds: firebase.firestore.FieldValue.arrayUnion(adminId),
  });
  await batch.commit();
};

export const switchMemberToAdmin = async (
  projectId: string,
  memberId: string
): Promise<void> => {
  const batch = db.batch();
  batch.update(db.collection("projects").doc(projectId), {
    memberIds: firebase.firestore.FieldValue.arrayRemove(memberId),
  });
  batch.update(db.collection("projects").doc(projectId), {
    adminIds: firebase.firestore.FieldValue.arrayUnion(memberId),
  });
  await batch.commit();
};

export const addProjectAdmin = async (
  projectId: string,
  memberId: string
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .update({
      adminIds: firebase.firestore.FieldValue.arrayUnion(memberId),
    });
};

export const removeProjectAdmin = async (
  projectId: string,
  adminId: string
): Promise<void> => {
  const batch = db.batch();
  batch.update(db.collection("projects").doc(projectId), {
    adminIds: firebase.firestore.FieldValue.arrayRemove(adminId),
  });
  batch.delete(
    db
      .collection("projects")
      .doc(projectId)
      .collection("activeMembers")
      .doc(adminId)
  );
  await batch.commit();
};

export const addProjectMember = async (
  projectId: string,
  memberId: string
): Promise<void> => {
  await db
    .collection("projects")
    .doc(projectId)
    .update({
      memberIds: firebase.firestore.FieldValue.arrayUnion(memberId),
    });
};

export const removeProjectMember = async (
  projectId: string,
  memberId: string
): Promise<void> => {
  const batch = db.batch();
  batch.update(db.collection("projects").doc(projectId), {
    memberIds: firebase.firestore.FieldValue.arrayRemove(memberId),
  });
  batch.delete(
    db
      .collection("projects")
      .doc(projectId)
      .collection("activeMembers")
      .doc(memberId)
  );
  await batch.commit();
};

export const deleteProject = async (projectId: string): Promise<void> => {
  const tasks = await db
    .collection("projects")
    .doc(projectId)
    .collection("tasks")
    .withConverter(taskConverter)
    .get();
  const activeMembers = await db
    .collection("projects")
    .doc(projectId)
    .collection("activeMembers")
    .withConverter(activeMemberConverter)
    .get();
  const batch = db.batch();
  tasks.forEach((task) => {
    batch.delete(task.ref);
  });
  activeMembers.forEach((activeMember) => {
    batch.delete(activeMember.ref);
  });
  batch.delete(db.collection("projects").doc(projectId));

  await batch.commit();
};
