import React from "react";
import { RadioButtonUnchecked, CheckCircleOutline } from "@material-ui/icons";
import styled from "styled-components";

type Props = {
  checkedColor: string;
  uncheckedColor: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
};

const Checkbox: React.FC<Props> = React.memo(
  ({ checkedColor, uncheckedColor, checked, onChange }) => {
    const onChangeChecked = () => {
      onChange && onChange(!checked);
    };
    return checked ? (
      <ChekedIcon linecolor={checkedColor} onClick={onChangeChecked} />
    ) : (
      <UnchekedIcon linecolor={uncheckedColor} onClick={onChangeChecked} />
    );
  }
);
Checkbox.displayName = "Checkbox";

type IconProps = {
  linecolor: string;
};

const ChekedIcon = styled(CheckCircleOutline)<IconProps>`
  color: ${({ linecolor }) => linecolor};
  cursor: pointer;
  margin-right: 4px;
`;

const UnchekedIcon = styled(RadioButtonUnchecked)<IconProps>`
  color: ${({ linecolor }) => linecolor};
  cursor: pointer;
  margin-right: 4px;
`;

export default Checkbox;
