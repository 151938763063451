import firebase from "../firebase";
import { Team } from "../models/Team";

const db = firebase.firestore();

export const teamConverter: firebase.firestore.FirestoreDataConverter<Team> = {
  toFirestore: (team: Team) => {
    return {
      subscriptionId: team.subscriptionId,
      name: team.name,
      status: team.status,
      ownerId: team.ownerId,
      adminIds: team.adminIds,
      memberIds: team.memberIds,
      createdAt: firebase.firestore.Timestamp.fromDate(team.createdAt),
      updatedAt: firebase.firestore.Timestamp.fromDate(team.updatedAt),
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Team => {
    const data = snapshot.data(options);
    const team: Team = {
      teamId: snapshot.id,
      subscriptionId: data.subscriptionId,
      status: data.status,
      name: data.name,
      ownerId: data.ownerId,
      adminIds: data.adminIds,
      memberIds: data.memberIds,
      createdAt: (data.createdAt as firebase.firestore.Timestamp).toDate(),
      updatedAt: (data.updatedAt as firebase.firestore.Timestamp).toDate(),
    };
    return team;
  },
};

export const createTeam = async (
  name: string,
  ownerId: string
): Promise<string> => {
  const now = new Date();
  const team: Team = {
    teamId: "",
    subscriptionId: "",
    status: "new",
    name,
    ownerId,
    adminIds: [],
    memberIds: [],
    createdAt: now,
    updatedAt: now,
  };
  const doc = await db
    .collection("teams")
    .withConverter(teamConverter)
    .add(team);
  return doc.id;
};

export const getTeams = async (userId: string): Promise<Team[]> => {
  const teams: Team[] = [];
  const ownerTeams = await db
    .collection("teams")
    .where("ownerId", "==", userId)
    .withConverter(teamConverter)
    .get();
  Array.prototype.push.apply(
    teams,
    ownerTeams.docs.map((d) => d.data())
  );
  const adminTeams = await db
    .collection("teams")
    .where("adminIds", "array-contains", userId)
    .withConverter(teamConverter)
    .get();
  Array.prototype.push.apply(
    teams,
    adminTeams.docs.map((d) => d.data())
  );
  const memberTeams = await db
    .collection("teams")
    .where("memberIds", "array-contains", userId)
    .withConverter(teamConverter)
    .get();
  Array.prototype.push.apply(
    teams,
    memberTeams.docs.map((d) => d.data())
  );
  return teams;
};

export const getTeam = async (teamId: string): Promise<Team | null> => {
  const doc = await db
    .collection("teams")
    .doc(teamId)
    .withConverter(teamConverter)
    .get();
  const data = doc.data();
  return data ? data : null;
};

export const updateTeamName = async (
  teamId: string,
  name: string
): Promise<void> => {
  await db.collection("teams").doc(teamId).update({
    name,
  });
};

export const switchAdminToMember = async (
  teamId: string,
  adminId: string
): Promise<void> => {
  const batch = db.batch();
  batch.update(db.collection("teams").doc(teamId), {
    adminIds: firebase.firestore.FieldValue.arrayRemove(adminId),
  });
  batch.update(db.collection("teams").doc(teamId), {
    memberIds: firebase.firestore.FieldValue.arrayUnion(adminId),
  });
  await batch.commit();
};

export const switchMemberToAdmin = async (
  teamId: string,
  memberId: string
): Promise<void> => {
  const batch = db.batch();
  batch.update(db.collection("teams").doc(teamId), {
    memberIds: firebase.firestore.FieldValue.arrayRemove(memberId),
  });
  batch.update(db.collection("teams").doc(teamId), {
    adminIds: firebase.firestore.FieldValue.arrayUnion(memberId),
  });
  await batch.commit();
};

export const addMember = async (
  teamId: string,
  memberId: string
): Promise<void> => {
  await db
    .collection("teams")
    .doc(teamId)
    .update({
      memberIds: firebase.firestore.FieldValue.arrayUnion(memberId),
    });
};

export const removeAdmin = async (
  teamId: string,
  adminId: string
): Promise<void> => {
  await db
    .collection("teams")
    .doc(teamId)
    .update({
      adminIds: firebase.firestore.FieldValue.arrayRemove(adminId),
    });
};

export const removeMember = async (
  teamId: string,
  memberId: string
): Promise<void> => {
  await db
    .collection("teams")
    .doc(teamId)
    .update({
      memberIds: firebase.firestore.FieldValue.arrayRemove(memberId),
    });
};
