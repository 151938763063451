import React from "react";
import styled from "styled-components";
import { FlexColumnCenter } from "../StyledComponents";
import useStyles from "../../hooks/useStyles";
import { modalBackgroundZIndex, baseBorderRadius } from "../../constants";

type Props = {
  isOpen: boolean;
  height?: string;
  width?: string;
  onClose: () => void;
};

const DialogWrapper: React.FC<Props> = React.memo(
  ({ isOpen, height, width, onClose, children }) => {
    const { color, node, helpBgColor } = useStyles();
    const boxShadow = node.boxShadow ? node.boxShadow : `0 0 10px ${color}`;

    const onClickBackground = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation();
      onClose();
    };
    const onClickModal = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      // stop onClickBackground
      event.stopPropagation();
    };
    if (isOpen) {
      return (
        <Container bgColor={helpBgColor} onClick={onClickBackground}>
          <Modal
            height={height}
            width={width}
            color={color}
            bgColor={node.bgColor}
            boxShadow={boxShadow}
            onClick={onClickModal}
          >
            {children}
          </Modal>
        </Container>
      );
    } else {
      return <></>;
    }
  }
);
DialogWrapper.displayName = "DialogWrapper";

const Container = styled(FlexColumnCenter)<{ bgColor: string }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ bgColor }) => bgColor};
  z-index: ${modalBackgroundZIndex};
`;

type ModalProps = {
  height?: string;
  width?: string;
  color: string;
  bgColor: string;
  boxShadow: string;
};

const Modal = styled(FlexColumnCenter)<ModalProps>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: 10px 15px;
  border: solid 2px ${({ color }) => color};
  border-radius: ${baseBorderRadius}px;
  box-shadow: ${({ boxShadow }) => boxShadow};
`;

export default DialogWrapper;
