import React from "react";
import SettingContainer from "../../components/SettingContainer";
import CookiePolicyComponent from "../../components/CookiePolicy";

const CookiePolicy: React.FC = () => {
  return (
    <SettingContainer>
      <CookiePolicyComponent />
    </SettingContainer>
  );
};

export default CookiePolicy;
