import styled from "styled-components";

export const PolicyContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Subtitle1 = styled.div<{ marginBottom?: boolean }>`
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "0.35em" : "")};
`;

export const Subtitle2 = styled.div<{ marginBottom?: boolean }>`
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 0.00714em;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "0.35em" : "")};
`;
export const OrderedList = styled.ol`
  margin: 0px;
  padding-left: 20px;
`;
