import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexRowCenter } from "../../../../components/StyledComponents";
import Icon from "./Icon";
import ColorPicker from "./ColorPicker";
import { LabelItem } from "../../../../models/Label";

type Props = {
  labelItem: LabelItem;
  canMoveUpward: boolean;
  moveUp: (sortOrder: number) => void;
  canMoveDownward: boolean;
  moveDown: (sortOrder: number) => void;
  onChangeLabelItem: (
    labelItemId: string,
    propertyName: "name" | "color" | "backgroundColor",
    value: string
  ) => void;
  deleteLabelItem: (sortOrder: number) => void;
};

const ComponentLabelItem: React.FC<Props> = React.memo(
  ({
    labelItem,
    canMoveUpward,
    moveUp,
    canMoveDownward,
    moveDown,
    onChangeLabelItem,
    deleteLabelItem,
  }) => {
    // hooks
    const { t: l } = useTranslation("labels");
    // states
    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeLabelItem(labelItem.labelItemId, "name", event.target.value);
    };

    const onClickPalette = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!anchorEl) {
        setAnchorEl(event.currentTarget);
      }
      setIsOpen(true);
    };

    const onClickMoveUpward = () => {
      if (!canMoveUpward) return;
      moveUp(labelItem.sortOrder);
    };
    const onClickMoveDownward = () => {
      if (!canMoveDownward) return;
      moveDown(labelItem.sortOrder);
    };
    const onClickDelete = () => {
      deleteLabelItem(labelItem.sortOrder);
    };

    return (
      <Container>
        <LabelItemName
          type="text"
          value={labelItem.name}
          placeholder={l("enterItemName")}
          autoComplete="off"
          color={labelItem.color}
          backgroundColor={labelItem.backgroundColor}
          onChange={onChangeName}
        />
        <Buttons>
          <Icon icon="palette" onClick={onClickPalette} />
          <Icon
            icon="up"
            disabled={!canMoveUpward}
            onClick={onClickMoveUpward}
          />
          <Icon
            icon="down"
            disabled={!canMoveDownward}
            onClick={onClickMoveDownward}
          />
          <Icon icon="delete" onClick={onClickDelete} />
        </Buttons>
        <ColorPicker
          anchorEl={anchorEl}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          labelItem={labelItem}
          onChangeLabelItem={onChangeLabelItem}
        />
      </Container>
    );
  }
);
ComponentLabelItem.displayName = "ComponentLabelItem";

const Container = styled(FlexRowCenter)`
  margin-bottom: 8px;
`;

type LabelNameProps = {
  color: string;
  backgroundColor: string;
};

const LabelItemName = styled.input<LabelNameProps>`
  font-size: 15px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  outline: none;

  transition: box-shadow 0.1s ease-out;
  :hover {
    box-shadow: 0 0 5px ${({ backgroundColor }) => backgroundColor};
  }
  :focus {
    box-shadow: 0 0 10px ${({ backgroundColor }) => backgroundColor};
  }
  ::placeholder {
    color: #adadad;
  }
`;

const Buttons = styled(FlexRowCenter)`
  margin-left: 6px;
`;

export default ComponentLabelItem;
