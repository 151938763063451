import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ScrollToNext from "./ScrollToNext";
import TaskActionTutorial from "./TaskActionTutorial";
import MoveTaskTutorial from "./MoveTaskTutorial";
import SettingTutorial from "./SettingTutorial";
import FilterTutorial from "./FilterTutorial";
import CollaborationTutorial from "./CollaborationTutorial";
import SelectLanguage from "./SelectLanguage";
import SelectTheme from "./SelectTheme";
import BaseSignInButton from "../../components/Button/BaseSignInButton";
import SignInWithButton from "../../components/Button/SignInWithButton";
import {
  FlexRowCenter,
  FlexColumnCenter,
} from "../../components/StyledComponents";
import Context from "../../context";
import useStyles from "../../hooks/useStyles";
import useAnalytics from "../../hooks/useAnalytics";
import { baseBorderRadius, logoChars } from "../../constants";
import {
  tutorialTitleHeight,
  tutorialTitlePaddingTop,
  recMargin,
} from "./constants";

const initial = "100vh";
const visible = "0";
const out = "-100vh";
const scrollBodyZIndex = 0;
const displayZIndex = 1;
const footerZIndex = displayZIndex;

const IndexPage: React.FC = () => {
  // ref
  const elm = useRef<HTMLDivElement>(null);
  // context
  const { user } = useContext(Context);
  // hooks
  const { t } = useTranslation("top");
  const history = useHistory();
  const { palette, color, background, logo, top, menuItem } = useStyles();
  const { sendLandingLog } = useAnalytics();
  const boxShadow = menuItem.boxShadow
    ? menuItem.boxShadow
    : `0 0 10px ${menuItem.borderColor}`;
  // states
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const [blockHeight, setBlockHeight] = useState(0);
  const [recHeight, setRecHeight] = useState(0);
  const [recWidth, setRecWidth] = useState(0);
  const recHalfHeight = recHeight / 2;
  const recHalfWidth = recWidth / 2;
  const [logoTranslateY, setLogoTranslateY] = useState(visible);
  const [whyTranslateY, setWhyTranslateY] = useState(initial);
  const [howTranslateY, setHowTranslateY] = useState(initial);
  const [taskTranslateY, setTaskTranslateY] = useState(initial);
  const [moveTranslateY, setMoveTranslateY] = useState(initial);
  const [settingTranslateY, setSettingTranslateY] = useState(initial);
  const [filterTranslateY, setFilterTranslateY] = useState(initial);
  const [collaborationTranslateY, setCollaborationTranslateY] = useState(
    initial
  );
  const [weTranslateY, setWeTranslateY] = useState(initial);
  const [signupTranslateY, setSignupTranslateY] = useState(initial);
  // trigger
  const [focusActionTrigger, setFocusActionTrigger] = useState(0);
  const [focusMoveTrigger, setFocusMoveTrigger] = useState(0);
  const [focusSettingTrigger, setFocusSettiingTrigger] = useState(0);
  const [focusFilterTrigger, setFocusFilterTrigger] = useState(0);
  const [focusCollaborationTrigger, setFocusCollaborationTrigger] = useState(0);
  // line
  const [line1X2, setLine1X2] = useState(0);
  const [line2Y2, setLine2Y2] = useState(0);
  const [line3Y2, setLine3Y2] = useState(0);
  const [line4X2, setLine4X2] = useState(0);
  const [line5X2, setLine5X2] = useState(0);
  const [line6Y2, setLine6Y2] = useState(0);
  const [line7Y2, setLine7Y2] = useState(0);
  const [line8X2, setLine8X2] = useState(0);

  useEffect(() => {
    setSizes();
    sendLandingLog();

    window.onresize = () => {
      setSizes();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsScrollVisible(true);
    }, 3000);
  }, []);

  const setSizes = () => {
    const innerHeight = window.innerHeight;
    const innerWidth = window.innerWidth;
    setBlockHeight(innerHeight);

    const recH = innerHeight - recMargin * 2;
    setRecHeight(recH);
    const recW = innerWidth - recMargin * 2;
    setRecWidth(recW);

    setLine1X2(recW / 2);
    setLine2Y2(recH);
    setLine3Y2(recH / 2);
    setLine4X2(0);
    setLine5X2(recW / 2);
    setLine6Y2(0);
    setLine7Y2(recH / 2);
    setLine8X2(recW);
  };

  useEffect(() => {
    if (user) {
      window.onresize = () => {};
      history.push("/news");
    }
  }, [user]);

  const onScroll = () => {
    if (elm.current) {
      const scrollTop = elm.current.scrollTop;

      if (scrollTop < blockHeight * 1) {
        const ratio = (scrollTop - blockHeight * 0) / blockHeight;
        const length = recHalfWidth;
        setLine1X2(length - length * ratio);
        setLine2Y2(recHeight);
        setLine3Y2(recHalfHeight);
        setLine4X2(0);
        setLine5X2(recHalfWidth);
        setLine6Y2(0);
        setLine7Y2(recHalfHeight);
        setLine8X2(recWidth);
      } else if (blockHeight * 1 <= scrollTop && scrollTop < blockHeight * 2) {
        const ratio = (scrollTop - blockHeight * 1) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHeight - length * ratio);
        setLine3Y2(recHalfHeight);
        setLine4X2(0);
        setLine5X2(recHalfWidth);
        setLine6Y2(0);
        setLine7Y2(recHalfHeight);
        setLine8X2(recWidth);
      } else if (blockHeight * 2 <= scrollTop && scrollTop < blockHeight * 3) {
        const ratio = (scrollTop - blockHeight * 2) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(recHalfHeight - length * ratio);
        setLine4X2(0);
        setLine5X2(recHalfWidth);
        setLine6Y2(0);
        setLine7Y2(recHalfHeight);
        setLine8X2(recWidth);
      } else if (blockHeight * 3 <= scrollTop && scrollTop < blockHeight * 4) {
        const ratio = (scrollTop - blockHeight * 3) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(0);
        setLine4X2(length * ratio);
        setLine5X2(recHalfWidth);
        setLine6Y2(0);
        setLine7Y2(recHalfHeight);
        setLine8X2(recWidth);
      } else if (blockHeight * 4 <= scrollTop && scrollTop < blockHeight * 5) {
        const ratio = (scrollTop - blockHeight * 4) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(0);
        setLine4X2(recHalfWidth);
        setLine5X2(recHalfWidth + length * ratio);
        setLine6Y2(0);
        setLine7Y2(recHalfHeight);
        setLine8X2(recWidth);
      } else if (blockHeight * 5 <= scrollTop && scrollTop < blockHeight * 6) {
        const ratio = (scrollTop - blockHeight * 5) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(0);
        setLine4X2(recHalfWidth);
        setLine5X2(recWidth);
        setLine6Y2(length * ratio);
        setLine7Y2(recHalfHeight);
        setLine8X2(recWidth);
      } else if (blockHeight * 6 <= scrollTop && scrollTop < blockHeight * 7) {
        const ratio = (scrollTop - blockHeight * 6) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(0);
        setLine4X2(recHalfWidth);
        setLine5X2(recWidth);
        setLine6Y2(recHalfHeight);
        setLine7Y2(recHalfHeight + length * ratio);
        setLine8X2(recWidth);
      } else if (blockHeight * 7 <= scrollTop && scrollTop < blockHeight * 8) {
        const ratio = (scrollTop - blockHeight * 7) / blockHeight;
        const length = recHalfHeight;
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(0);
        setLine4X2(recHalfWidth);
        setLine5X2(recWidth);
        setLine6Y2(recHalfHeight);
        setLine7Y2(recHeight);
        setLine8X2(recWidth - length * ratio);
      } else {
        setLine1X2(0);
        setLine2Y2(recHalfHeight);
        setLine3Y2(0);
        setLine4X2(recHalfWidth);
        setLine5X2(recWidth);
        setLine6Y2(recHalfHeight);
        setLine7Y2(recHeight);
        setLine8X2(recHalfWidth);
      }

      if (scrollTop <= blockHeight) {
        setLogoTranslateY(visible);
        setWhyTranslateY(initial);
        setHowTranslateY(initial);
        setTaskTranslateY(initial);
        setMoveTranslateY(initial);
        setSettingTranslateY(initial);
        setFilterTranslateY(initial);
        setCollaborationTranslateY(initial);
        setWeTranslateY(initial);
      } else if (blockHeight <= scrollTop && scrollTop < blockHeight * 2) {
        setLogoTranslateY(out);
        setWhyTranslateY(visible);
        setHowTranslateY(initial);
        setTaskTranslateY(initial);
        setMoveTranslateY(initial);
        setSettingTranslateY(initial);
        setFilterTranslateY(initial);
        setCollaborationTranslateY(initial);
        setWeTranslateY(initial);
      } else if (blockHeight * 2 <= scrollTop && scrollTop < blockHeight * 3) {
        setLogoTranslateY(out);
        setWhyTranslateY(out);
        setHowTranslateY(visible);
        setTaskTranslateY(initial);
        setMoveTranslateY(initial);
        setSettingTranslateY(initial);
        setFilterTranslateY(initial);
        setCollaborationTranslateY(initial);
        setWeTranslateY(initial);
      } else if (blockHeight * 3 <= scrollTop && scrollTop < blockHeight * 4) {
        setLogoTranslateY(out);
        setWhyTranslateY(out);
        setHowTranslateY(out);
        setTaskTranslateY(visible);
        setFocusActionTrigger(focusActionTrigger + 1);
        setMoveTranslateY(initial);
        setSettingTranslateY(initial);
        setFilterTranslateY(initial);
        setCollaborationTranslateY(initial);
        setWeTranslateY(initial);
      } else if (blockHeight * 4 <= scrollTop && scrollTop < blockHeight * 5) {
        toTutorial2();
      } else if (blockHeight * 5 <= scrollTop && scrollTop < blockHeight * 6) {
        toTutorial3();
      } else if (blockHeight * 6 <= scrollTop && scrollTop < blockHeight * 7) {
        toTutorial4();
      } else if (blockHeight * 7 <= scrollTop && scrollTop < blockHeight * 8) {
        toTutorial5();
      } else if (blockHeight * 8 <= scrollTop && scrollTop < blockHeight * 9) {
        setLogoTranslateY(out);
        setWhyTranslateY(out);
        setHowTranslateY(out);
        setTaskTranslateY(out);
        setMoveTranslateY(out);
        setSettingTranslateY(out);
        setFilterTranslateY(out);
        setCollaborationTranslateY(out);
        setWeTranslateY(visible);
      }
      setSignupTranslateY(initial);
    }
  };

  const toTutorial2 = () => {
    // Add, Edit, Done, Delete
    setLogoTranslateY(out);
    setWhyTranslateY(out);
    setHowTranslateY(out);
    setTaskTranslateY(out);
    setMoveTranslateY(visible);
    setFocusMoveTrigger(focusMoveTrigger + 1);
    setSettingTranslateY(initial);
    setFilterTranslateY(initial);
    setCollaborationTranslateY(initial);
    setWeTranslateY(initial);
  };

  const toTutorial3 = () => {
    // Move, Copy, Cut, Paste
    setLogoTranslateY(out);
    setWhyTranslateY(out);
    setHowTranslateY(out);
    setTaskTranslateY(out);
    setMoveTranslateY(out);
    setSettingTranslateY(visible);
    setFocusSettiingTrigger(focusSettingTrigger + 1);
    setFilterTranslateY(initial);
    setCollaborationTranslateY(initial);
    setWeTranslateY(initial);
  };

  const toTutorial4 = () => {
    setLogoTranslateY(out);
    setWhyTranslateY(out);
    setHowTranslateY(out);
    setTaskTranslateY(out);
    setMoveTranslateY(out);
    setSettingTranslateY(out);
    setFilterTranslateY(visible);
    setFocusFilterTrigger(focusFilterTrigger + 1);
    setCollaborationTranslateY(initial);
    setWeTranslateY(initial);
  };

  const toTutorial5 = () => {
    setLogoTranslateY(out);
    setWhyTranslateY(out);
    setHowTranslateY(out);
    setTaskTranslateY(out);
    setMoveTranslateY(out);
    setSettingTranslateY(out);
    setFilterTranslateY(out);
    setCollaborationTranslateY(visible);
    setFocusCollaborationTrigger(focusCollaborationTrigger + 1);
    setWeTranslateY(initial);
  };

  const addScrollTop = (value: number) => {
    if (!elm.current) return;
    elm.current.scrollTop += value;
  };

  const onClickSignup = () => {
    setLogoTranslateY(out);
    setWhyTranslateY(out);
    setHowTranslateY(out);
    setTaskTranslateY(out);
    setMoveTranslateY(out);
    setSettingTranslateY(out);
    setFilterTranslateY(out);
    setCollaborationTranslateY(out);
    setWeTranslateY(out);
    setSignupTranslateY(visible);
  };

  return (
    <Container
      ref={elm}
      color={color}
      background={background}
      onScroll={onScroll}
      className="scroll-none"
    >
      <title>{"To Do Map | To-Do List & Mindmap"}</title>
      <Header>
        <Signin
          color={top.color}
          hoverColor={top.hoverColor}
          onClick={onClickSignup}
        >
          {t("signin")}
        </Signin>
      </Header>
      <ScrollBody>
        <ScrollBlock>
          <ScrollText>Logo</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>Why</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>How</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>Tutorial1</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>Tutorial2</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>Tutorial3</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>Tutorial4</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>Tutorial5</ScrollText>
        </ScrollBlock>
        <ScrollBlock>
          <ScrollText>We</ScrollText>
        </ScrollBlock>
      </ScrollBody>
      <DisplayBlock translateY={logoTranslateY}>
        <FlexColumnCenter>
          <Fastest>{t("fastest")}</Fastest>
          <Logo color={logo.color}>
            {logoChars.map((c, i) => (
              <LogoChar key={c + i} strokeColor={palette[i % palette.length]}>
                {c}
              </LogoChar>
            ))}
          </Logo>
          {isScrollVisible && <ScrollToNext />}
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={whyTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <SpeedComesFirst>{t("speed")}</SpeedComesFirst>
          ) : (
            <SpeedComesFirstOutlined
              color={logo.color}
              strokeColor={menuItem.borderColor}
            >
              {t("speed")}
            </SpeedComesFirstOutlined>
          )}
          <ForEveryone>{t("forEveryone")}</ForEveryone>
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={howTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <How> {t("how")}</How>
          ) : (
            <HowOutlined color={logo.color} strokeColor={menuItem.borderColor}>
              {t("how")}
            </HowOutlined>
          )}
          <List>
            <ListItem>
              <ListTitle>{t("how1Title")}</ListTitle>
              <ListText>{t("how1")}</ListText>
            </ListItem>
            <ListItem>
              <ListTitle>{t("how2Title")}</ListTitle>
              <ListText>{t("how2")}</ListText>
            </ListItem>
            <ListItem>
              <ListTitle>{t("how3Title")}</ListTitle>
              <ListText>{t("how3")}</ListText>
            </ListItem>
          </List>
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={taskTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <TutorialTitle>{t("tutorial1")}</TutorialTitle>
          ) : (
            <TutorialTitleOutlined
              color={logo.color}
              strokeColor={menuItem.borderColor}
            >
              {t("tutorial1")}
            </TutorialTitleOutlined>
          )}
          <TaskActionTutorial
            focusTrigger={focusActionTrigger}
            addScrollTop={addScrollTop}
          />
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={moveTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <TutorialTitle>{t("tutorial2")}</TutorialTitle>
          ) : (
            <TutorialTitleOutlined
              color={logo.color}
              strokeColor={menuItem.borderColor}
            >
              {t("tutorial2")}
            </TutorialTitleOutlined>
          )}
          <MoveTaskTutorial
            focusTrigger={focusMoveTrigger}
            addScrollTop={addScrollTop}
          />
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={settingTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <TutorialTitle>{t("tutorial3")}</TutorialTitle>
          ) : (
            <TutorialTitleOutlined
              color={logo.color}
              strokeColor={menuItem.borderColor}
            >
              {t("tutorial3")}
            </TutorialTitleOutlined>
          )}
          <SettingTutorial
            focusTrigger={focusSettingTrigger}
            addScrollTop={addScrollTop}
          />
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={filterTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <TutorialTitle>{t("tutorial4")}</TutorialTitle>
          ) : (
            <TutorialTitleOutlined
              color={logo.color}
              strokeColor={menuItem.borderColor}
            >
              {t("tutorial4")}
            </TutorialTitleOutlined>
          )}
          <FilterTutorial
            focusTrigger={focusFilterTrigger}
            addScrollTop={addScrollTop}
          />
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={collaborationTranslateY}>
        <FlexColumnCenter>
          {top.mode === "filled" ? (
            <TutorialTitle>{t("collaboration")}</TutorialTitle>
          ) : (
            <TutorialTitleOutlined
              color={logo.color}
              strokeColor={menuItem.borderColor}
            >
              {t("collaboration")}
            </TutorialTitleOutlined>
          )}
          <CollaborationTutorial
            focusTrigger={focusCollaborationTrigger}
            addScrollTop={addScrollTop}
          />
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={weTranslateY}>
        <FlexColumnCenter>
          <WeSupport>{t("we")}</WeSupport>
          {top.mode === "filled" ? (
            <Supportees>
              <Supportee>{t("individuals")}</Supportee>
              <Supportee>{t("smallTeams")}</Supportee>
              <Supportee>{t("startups")}</Supportee>
            </Supportees>
          ) : (
            <Supportees>
              <SupporteeOutlined
                color={logo.color}
                strokeColor={menuItem.borderColor}
              >
                {t("individuals")}
              </SupporteeOutlined>
              <SupporteeOutlined
                color={logo.color}
                strokeColor={menuItem.borderColor}
              >
                {t("smallTeams")}
              </SupporteeOutlined>
              <SupporteeOutlined
                color={logo.color}
                strokeColor={menuItem.borderColor}
              >
                {t("startups")}
              </SupporteeOutlined>
            </Supportees>
          )}
          <WeSupport>{t("support")}</WeSupport>
          <Start
            color={menuItem.color}
            bgColor={logo.color}
            borderColor={menuItem.borderColor}
            boxShadow={boxShadow}
            onClick={onClickSignup}
          >
            {t("start")}
          </Start>
        </FlexColumnCenter>
      </DisplayBlock>
      <DisplayBlock translateY={signupTranslateY}>
        <FlexColumnCenter>
          <ByClicking>
            {t("byClickingTop")}
            <OpenInNewTab
              href="/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("termsOfService")}
            </OpenInNewTab>
            <OpenInNewTab
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("privacyPolicy")}
            </OpenInNewTab>
            <OpenInNewTab
              href="/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("cookiePolicy")}
            </OpenInNewTab>
            {t("byClickingBottom")}
          </ByClicking>
          <SignInWithButton provider="Google" margin="16px 0px 0px 0px" />
          <SignInWithButton provider="Twitter" margin="16px 0px 0px 0px" />
          <SignInWithButton provider="Github" margin="16px 0px 0px 0px" />
        </FlexColumnCenter>
      </DisplayBlock>
      <Rectangle>
        <svg width={recWidth} height={recHeight}>
          <line
            x1={recHalfWidth}
            y1={recHeight}
            x2={line1X2}
            y2={recHeight}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={0}
            y1={recHeight}
            x2={0}
            y2={line2Y2}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={0}
            y1={recHalfHeight}
            x2={0}
            y2={line3Y2}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={0}
            y1={0}
            x2={line4X2}
            y2={0}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={recHalfWidth}
            y1={0}
            x2={line5X2}
            y2={0}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={recWidth}
            y1={0}
            x2={recWidth}
            y2={line6Y2}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={recWidth}
            y1={recHalfHeight}
            x2={recWidth}
            y2={line7Y2}
            stroke={color}
            strokeWidth="5"
          />
          <line
            x1={recWidth}
            y1={recHeight}
            x2={line8X2}
            y2={recHeight}
            stroke={color}
            strokeWidth="5"
          />
        </svg>
      </Rectangle>
      <Footer>
        <SelectLanguage />
        <Policies color={top.color} hoverColor={top.hoverColor}>
          <OpenInNewTab
            href="/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("termsOfService")}
          </OpenInNewTab>
          <OpenInNewTab
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("privacyPolicy")}
          </OpenInNewTab>
          <OpenInNewTab
            href="/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("cookiePolicy")}
          </OpenInNewTab>
        </Policies>
        <SelectTheme />
      </Footer>
    </Container>
  );
};

type ContainerProps = {
  color: string;
  background: string;
};
const Container = styled.main<ContainerProps>`
  height: 100vh;
  width: 100vw;
  color: ${({ color }) => color};

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  overflow: auto;

  background: ${({ background }) => background};
`;

const ScrollBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: ${scrollBodyZIndex};
`;

const ScrollBlock = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScrollText = styled.div`
  color: transparent;
`;

const DisplayBlock = styled.div<{ translateY: string }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(${({ translateY }) => translateY});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${displayZIndex};
  pointer-events: none;

  transition: transform 0.2s ease-out;
`;

const Fastest = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 2.125rem;
  font-weight: 600;
`;

const Logo = styled.div<{ color: string }>`
  font-family: "Orbitron", sans-serif;
  font-size: 3.75rem;
  font-weight: 900;
  color: ${({ color }) => color};
`;

const LogoChar = styled.span<{ strokeColor: string }>`
  -webkit-text-stroke: 2px ${({ strokeColor }) => strokeColor};
  letter-spacing: 2px;
`;

type TitleProps = {
  color: string;
  strokeColor: string;
};
const SpeedComesFirst = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 3rem;
  font-weight: 600;
`;
const SpeedComesFirstOutlined = styled(SpeedComesFirst)<TitleProps>`
  color: ${({ color }) => color};
  -webkit-text-stroke: 2px ${({ strokeColor }) => strokeColor};
`;

const ForEveryone = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 1.625rem;
  font-weight: 500;
`;

const How = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 16px;
`;

const HowOutlined = styled(How)<TitleProps>`
  color: ${({ color }) => color};
  -webkit-text-stroke: 2px ${({ strokeColor }) => strokeColor};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    margin-bottom: 12px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

const ListItem = styled.div`
  font-family: "Kanit", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ListTitle = styled.div`
  font-size: 1.875rem;
  font-weight: 600;
`;

const ListText = styled.div`
  font-size: 1.375rem;
  font-weight: 300;
`;

const TutorialTitle = styled.div`
  line-height: ${tutorialTitleHeight - tutorialTitlePaddingTop}px;

  font-family: "Kanit", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;

  padding-top: ${tutorialTitlePaddingTop}px;

  position: fixed;
  top: ${recMargin}px;
  z-index: 9999;
`;

const TutorialTitleOutlined = styled(TutorialTitle)<TitleProps>`
  color: ${({ color }) => color};
  -webkit-text-stroke: 2px ${({ strokeColor }) => strokeColor};
`;

const WeSupport = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
`;

const Supportees = styled(FlexColumnCenter)`
  font-family: "Kanit", sans-serif;
  margin: 8px 0px;
`;

const Supportee = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 2rem;
  font-weight: 600;
`;

const SupporteeOutlined = styled(Supportee)<TitleProps>`
  color: ${({ color }) => color};
  -webkit-text-stroke: 1px ${({ strokeColor }) => strokeColor};
`;

const ByClicking = styled(FlexColumnCenter)`
  font-family: "Kanit", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  pointer-events: initial;
`;

const Rectangle = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: ${displayZIndex};
`;

const Header = styled.header`
  width: 100vw;
  font-size: 20px;
  height: 60px;
  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: ${footerZIndex};
`;

const Signin = styled(BaseSignInButton)`
  font-family: "Kanit", sans-serif;
  margin-right: 10px;
`;

const Footer = styled.footer`
  width: 100vw;
  font-size: 20px;
  height: 60px;
  position: fixed;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${footerZIndex};
`;

const Policies = styled(FlexRowCenter)<{ color: string; hoverColor: string }>`
  > a {
    font-family: "Kanit", sans-serif;
    font-size: 1.125rem;
    color: ${({ color }) => color};
    padding: 4px 8px;
    text-decoration: none;
    cursor: pointer;

    transition: color 100ms ease-out;

    :hover {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

type StartProps = {
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  margin?: string;
};
const Start = styled.button<StartProps>`
  font-family: "Kanit", sans-serif;
  font-size: 1.375rem;

  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};

  padding: 4px 24px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  margin-top: 32px;

  outline: none;
  user-select: none;
  cursor: pointer;
  pointer-events: initial;

  transition: color 100ms ease-out, background-color 100ms ease-out,
    box-shadow 100ms ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

const OpenInNewTab = styled.a`
  color: inherit;
`;

export default IndexPage;
