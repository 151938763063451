import React from "react";
import styled from "styled-components";
import useStyles from "../hooks/useStyles";
import { windowPadding, headerFullHeight } from "../constants";

const SettingContainer: React.FC = React.memo(({ children }) => {
  const { color } = useStyles();

  return <Container color={color}>{children}</Container>;
});
SettingContainer.displayName = "SettingContainer";

const Container = styled.div<{ color: string }>`
  height: calc(100vh - ${(windowPadding + headerFullHeight) * 2}px);
  width: 100vw;
  color: ${({ color }) => color};
  padding: ${windowPadding + headerFullHeight}px 0px;

  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ color }) => color}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
`;

export default SettingContainer;
