import React from "react";
import TopPolicyContainer from "../components/TopPolicyContainer";
import TermsOfServiceComponent from "../components/TermsOfService";

const TermsOfService: React.FC = () => {
  return (
    <TopPolicyContainer>
      <TermsOfServiceComponent />
    </TopPolicyContainer>
  );
};

export default TermsOfService;
