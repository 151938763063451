import firebase, { db } from "../firebase";
import { sortChildren } from "../bl/task/common";
import { iTasksToTasks } from "../bl/task/get";
import { User } from "../models/User";
import { ITask, UpdateTask, Task } from "../models/Task";

export const taskConverter: firebase.firestore.FirestoreDataConverter<ITask> = {
  toFirestore: (task: ITask) => {
    return {
      level: task.level,
      sortNumber: task.sortNumber,
      parentId: task.parentId,
      childrenIds: task.childrenIds,
      text: task.text,
      assigneeId: task.assigneeId,
      done: "done" in task ? task.done : false,
      doneBy: task.doneBy ? task.doneBy : "",
      doneAt: task.doneAt
        ? firebase.firestore.Timestamp.fromDate(task.doneAt)
        : null,
      dueDate: task.dueDate
        ? firebase.firestore.Timestamp.fromDate(task.dueDate)
        : null,
      labels: task.labels ? task.labels : [],
      updatedBy: task.updatedBy ? task.updatedBy : "",
      updatedAt: task.updatedAt
        ? firebase.firestore.Timestamp.fromDate(task.updatedAt)
        : null,
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): ITask => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      level: data.level,
      sortNumber: data.sortNumber,
      parentId: data.parentId,
      childrenIds: data.childrenIds,
      text: data.text,
      done: "done" in data ? data.done : false,
      doneBy: data.doneBy ? data.doneBy : "",
      doneAt: data.doneAt
        ? (data.doneAt as firebase.firestore.Timestamp).toDate()
        : null,
      assigneeId: data.assigneeId,
      dueDate: data.dueDate
        ? (data.dueDate as firebase.firestore.Timestamp).toDate()
        : null,
      labels: data.labels ? data.labels : [],
      updatedBy: data.updatedBy ? data.updatedBy : "",
      updatedAt: data.updatedAt
        ? (data.updatedAt as firebase.firestore.Timestamp).toDate()
        : null,
    } as ITask;
  },
};

export const getTasks = async (
  user: User,
  projectId: string,
  members: User[]
): Promise<Task[]> => {
  const qs = await db
    .collection("projects")
    .doc(projectId)
    .collection("tasks")
    .withConverter(taskConverter)
    .get();
  const { tasks, orphans } = iTasksToTasks(
    qs.docs.map((d) => d.data()),
    members
  );
  sortChildren(tasks);

  if (orphans.length) {
    await deleteOrphans(projectId, orphans);
  }

  return tasks;
};

export const batchEditTasks = async (
  userId: string,
  tempUserId: string,
  projectId: string,
  addingTasks: Task[],
  updatingTasks: UpdateTask[],
  deletingTasks: Task[],
  currentTaskId?: string
): Promise<void> => {
  /**
   * Firestore のルール
   * 1. onSnapshot を使っている
   * 2. add, update が失敗する
   * と、
   * 最終的にはアトミックな結果になるが、なぜか
   * onSnapshot で add したドキュメントが removed で呼ばれる
   * これは add と update の batch に追加する順番を変えても同じだった
   *
   * このことから
   * 1. batch は結果はアトミックだがプロセスは onSnapshot に反映される
   * 2. batch の順序は Firestore 側で勝手に決められていて
   * 3. かつ、add は必ず update より先に実行される
   */
  const batch = db.batch();
  if (addingTasks.length) {
    for (const task of addingTasks) {
      // console.log("add", task.text);
      batch.set(
        db
          .collection("projects")
          .doc(projectId)
          .collection("tasks")
          .withConverter(taskConverter)
          .doc(task.id),
        task.toItem(tempUserId)
      );
    }
    batch.set(
      db.collection("users").doc(userId),
      {
        stats: {
          totalTaskCount: firebase.firestore.FieldValue.increment(
            addingTasks.length
          ),
        },
      },
      { merge: true }
    );
  }
  for (const task of updatingTasks) {
    batch.update(
      db.collection("projects").doc(projectId).collection("tasks").doc(task.id),
      task.params
    );
    // const logParams = { ...task.params } as any;
    // delete logParams.updatedBy;
    // delete logParams.updatedAt;
    // console.log("update", task.id, logParams);
  }
  for (const task of deletingTasks) {
    // console.log("delete", task.text);
    batch.delete(
      db.collection("projects").doc(projectId).collection("tasks").doc(task.id)
    );
  }
  if (currentTaskId) {
    batch.update(
      db
        .collection("projects")
        .doc(projectId)
        .collection("activeMembers")
        .doc(userId),
      {
        currentTaskId,
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      }
    );
  }
  await batch.commit();
};

export const deleteOrphans = async (
  projectId: string,
  orphans: Task[]
): Promise<void> => {
  const batch = db.batch();
  for (const orphan of orphans) {
    batch.delete(
      db
        .collection("projects")
        .doc(projectId)
        .collection("tasks")
        .doc(orphan.id)
    );
    batch.set(
      db
        .collection("projects")
        .doc(projectId)
        .collection("orphans")
        .doc(orphan.id),
      {
        level: orphan.level,
        sortNumber: orphan.sortNumber,
        text: orphan.text,
        parentId: orphan.parentId,
        lostedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      }
    );
  }
  await batch.commit();
};
