import { defaultFontSize, defaultLanguage } from "../constants";
import themes from "../json/themes.json";
import { Theme } from "../models/Theme";
import { Project } from "../models/Project";
import { LabelItem } from "../models/Label";
import { FilterStatus, FilterDueDate } from "../models/Filter";
import { Language } from "../models/Language";

interface ProjectSettings {
  projectId: string;
  selectedTaskId: string;
  filterMemberId: string;
  filterStatus: FilterStatus;
  filterDueDate: FilterDueDate;
  filterLabelItemIds: string[];
}

const useLocalStorage = (): {
  saveLanguage: (language: Language) => void;
  getLanguage: () => Language;
  saveTheme: (theme: Theme) => void;
  getTheme: () => Theme;
  saveFontSize: (fontSize: number) => void;
  getFontSize: () => number;
  cleanProjectSettings: (project: Project[]) => void;
  loadProjectSettings: (projectId: string) => ProjectSettings | null;
  setSelectedTaskId: (projectId: string, taskId: string) => void;
  saveFilterMember: (project: Project | null, filterMemberId: string) => void;
  saveFilterStatus: (
    project: Project | null,
    filterStatus: FilterStatus
  ) => void;
  saveFilterDueDate: (
    project: Project | null,
    filterDueDate: FilterDueDate
  ) => void;
  addFilterLabelItem: (project: Project | null, labelItem: LabelItem) => void;
  removeFilterLabelItem: (
    project: Project | null,
    labelItem: LabelItem
  ) => void;
  resetFilterLabelItems: (project: Project | null) => void;
} => {
  // Language
  const saveLanguage = (language: Language): void => {
    localStorage.setItem("language", language);
  };
  const getLanguage = (): Language => {
    const language = localStorage.getItem("language");
    if (language) {
      return language as Language;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    switch (timezone) {
      case "Asia/Tokyo":
        return "ja";
      case "Asia/Seoul":
        return "ko";
      default:
        return defaultLanguage;
    }
  };

  // Theme
  const saveTheme = (theme: Theme): void => {
    localStorage.setItem("theme", theme.name);
  };
  const getTheme = (): Theme => {
    const themeName = localStorage.getItem("theme");
    const theme = themes.find((t) => t.name === themeName);
    return (theme
      ? theme
      : themes[new Date().getTime() % themes.length]) as Theme;
  };

  // Font size
  const saveFontSize = (fontSize: number): void => {
    localStorage.setItem("fontSize", fontSize.toString());
  };
  const getFontSize = (): number => {
    const strFontSize = localStorage.getItem("fontSize");
    return strFontSize ? parseInt(strFontSize) : defaultFontSize;
  };

  // Project Settings
  const cleanProjectSettings = (projects: Project[]): void => {
    const projectIds = projects.map((p) => p.projectId);
    const projectSettings = getProjectSettings();
    const filteredProjectSettings = projectSettings.filter((s) =>
      projectIds.includes(s.projectId)
    );
    setProjectSettings(filteredProjectSettings);
  };
  // load
  const loadProjectSettings = (projectId: string): ProjectSettings | null => {
    const projectSettings = getProjectSettings();
    const project = projectSettings.find((p) => p.projectId === projectId);
    return project ? (project as ProjectSettings) : null;
  };
  const getProjectSettings = () => {
    const strProjects = localStorage.getItem("projectSettings");
    return strProjects ? (JSON.parse(strProjects) as ProjectSettings[]) : [];
  };
  const setProjectSettings = (projectSettings: ProjectSettings[]) => {
    localStorage.setItem("projectSettings", JSON.stringify(projectSettings));
  };

  // Selected Task
  const setSelectedTaskId = (projectId: string, taskId: string): void => {
    const projectSettings = getProjectSettings();
    const project = projectSettings.find((p) => p.projectId === projectId);
    if (project) {
      project.selectedTaskId = taskId;
    } else {
      projectSettings.push({
        projectId,
        selectedTaskId: taskId,
        filterMemberId: "",
        filterStatus: "all",
        filterDueDate: "none",
        filterLabelItemIds: [],
      });
    }
    setProjectSettings(projectSettings);
  };

  // filterStatus
  const saveFilterMember = (
    project: Project | null,
    filterMemberId: string
  ): void => {
    if (!project) return;
    const projectSettings = getProjectSettings();
    const projectSetting = projectSettings.find(
      (p) => p.projectId === project.projectId
    );
    if (projectSetting) {
      projectSetting.filterMemberId = filterMemberId;
    } else {
      projectSettings.push({
        projectId: project.projectId,
        selectedTaskId: "",
        filterMemberId,
        filterStatus: "all",
        filterDueDate: "none",
        filterLabelItemIds: [],
      });
    }
    setProjectSettings(projectSettings);
  };

  // filterStatus
  const saveFilterStatus = (
    project: Project | null,
    filterStatus: FilterStatus
  ): void => {
    if (!project) return;
    const projectSettings = getProjectSettings();
    const projectSetting = projectSettings.find(
      (p) => p.projectId === project.projectId
    );
    if (projectSetting) {
      projectSetting.filterStatus = filterStatus;
    } else {
      projectSettings.push({
        projectId: project.projectId,
        selectedTaskId: "",
        filterMemberId: "",
        filterStatus,
        filterDueDate: "none",
        filterLabelItemIds: [],
      });
    }
    setProjectSettings(projectSettings);
  };

  // filterDueDate
  const saveFilterDueDate = (
    project: Project | null,
    filterDueDate: FilterDueDate
  ): void => {
    if (!project) return;
    const projectSettings = getProjectSettings();
    const projectSetting = projectSettings.find(
      (p) => p.projectId === project.projectId
    );
    if (projectSetting) {
      projectSetting.filterDueDate = filterDueDate;
    } else {
      projectSettings.push({
        projectId: project.projectId,
        selectedTaskId: "",
        filterMemberId: "",
        filterStatus: "all",
        filterDueDate,
        filterLabelItemIds: [],
      });
    }
    setProjectSettings(projectSettings);
  };

  // filterLabelItems
  const addFilterLabelItem = (
    project: Project | null,
    labelItem: LabelItem
  ): void => {
    if (!project) return;
    const projectSettings = getProjectSettings();
    const projectSetting = projectSettings.find(
      (p) => p.projectId === project.projectId
    );
    if (projectSetting) {
      if (projectSetting.filterLabelItemIds) {
        projectSetting.filterLabelItemIds.push(labelItem.labelItemId);
        projectSetting.filterLabelItemIds = Array.from(
          new Set(projectSetting.filterLabelItemIds)
        );
      } else {
        projectSetting.filterLabelItemIds = [labelItem.labelItemId];
      }
    } else {
      projectSettings.push({
        projectId: project.projectId,
        selectedTaskId: "",
        filterMemberId: "",
        filterStatus: "all",
        filterDueDate: "none",
        filterLabelItemIds: [labelItem.labelItemId],
      });
    }
    setProjectSettings(projectSettings);
  };
  const removeFilterLabelItem = (
    project: Project | null,
    labelItem: LabelItem
  ): void => {
    if (!project) return;
    const projectSettings = getProjectSettings();
    const projectSetting = projectSettings.find(
      (p) => p.projectId === project.projectId
    );
    if (projectSetting && projectSetting.filterLabelItemIds) {
      projectSetting.filterLabelItemIds = projectSetting.filterLabelItemIds.filter(
        (id) => id !== labelItem.labelItemId
      );
      setProjectSettings(projectSettings);
    }
  };
  const resetFilterLabelItems = (project: Project | null): void => {
    if (!project) return;
    const projectSettings = getProjectSettings();
    const projectSetting = projectSettings.find(
      (p) => p.projectId === project.projectId
    );
    if (projectSetting) {
      projectSetting.filterLabelItemIds = [];
      setProjectSettings(projectSettings);
    }
  };

  return {
    saveLanguage,
    getLanguage,
    saveTheme,
    getTheme,
    saveFontSize,
    getFontSize,
    cleanProjectSettings,
    loadProjectSettings,
    setSelectedTaskId,
    saveFilterMember,
    saveFilterStatus,
    saveFilterDueDate,
    addFilterLabelItem,
    removeFilterLabelItem,
    resetFilterLabelItems,
  };
};

export default useLocalStorage;
