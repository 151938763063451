import React from "react";
import styled from "styled-components";
import { FlexRowCenter } from "../../components/StyledComponents";
import { ActiveMembersLabel } from "../../models/ActiveMembersLabel";
import useStyles from "../../hooks/useStyles";
import {
  activeMemberLabelHeight,
  activeMemberLabelZIndex,
} from "../../constants";

type Props = {
  label: ActiveMembersLabel;
};

const CActiveMembersLabel: React.FC<Props> = React.memo(({ label }) => {
  const { color } = useStyles();
  return (
    <Container style={{ top: label.top, left: label.left }}>
      {label.activeMembers.map((m) => (
        <Item key={"member-" + m.userId} backgroundColor={color}>
          {m.name}
        </Item>
      ))}
    </Container>
  );
});
CActiveMembersLabel.displayName = "ActiveMembersLabel";

const Container = styled(FlexRowCenter)`
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  z-index: ${activeMemberLabelZIndex};
`;

const Item = styled.div<{ backgroundColor: string }>`
  line-height: ${activeMemberLabelHeight}px;
  color: ${({ backgroundColor }) => backgroundColor};
  padding: 0px 4px;
  cursor: default;
`;

export default CActiveMembersLabel;
