import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DialogWrapper from "./DialogWrapper";
import FilledButton from "../Button/FilledButton";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title?: string;
  message: string;
};

const OKDialog: React.FC<Props> = React.memo(
  ({ isOpen, setIsOpen, title, message }) => {
    // hooks
    const { t: c } = useTranslation("common");
    const onClose = () => {
      setIsOpen(false);
    };
    if (isOpen) {
      return (
        <DialogWrapper isOpen={isOpen} onClose={onClose}>
          <Title>{title ?? ""}</Title>
          <Message>{message}</Message>
          <FilledButton small onClick={onClose}>
            {c("ok")}
          </FilledButton>
        </DialogWrapper>
      );
    } else {
      return <></>;
    }
  }
);
OKDialog.displayName = "OKDialog";

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Message = styled.div`
  white-space: pre-wrap;
  margin: 8px 0px;
`;

export default OKDialog;
