import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Block from "./Block";
import Typography from "../Typography";
import Table from "./Table";
import FilledButton from "../Button/FilledButton";
import Form from "../Input/Form";
import { FlexColumnCenter } from "../StyledComponents";
import Text from "../Input/Text";
import { User } from "../../models/User";
import { Auth } from "../../models/Auth";

type Props = {
  members: User[];
  changeAuth: (member: User, from: Auth, to: Auth) => Promise<void>;
  canAddMember: boolean;
  canEditMembers: boolean;
  email: string;
  onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAddMember: () => Promise<void>;
};

const Members: React.FC<Props> = React.memo(
  ({
    members,
    changeAuth,
    canAddMember,
    canEditMembers,
    email,
    onChangeEmail,
    onClickAddMember,
  }) => {
    // hooks
    const { t: c } = useTranslation("common");
    const { t: m } = useTranslation("member");

    const onSubmitAddMember = async (
      event: React.FormEvent<HTMLFormElement>
    ) => {
      event.preventDefault();
      await onClickAddMember();
    };

    return (
      <Block>
        <Typography variant="medium" bold>
          {m("members")}
        </Typography>
        {members.length ? (
          <Table>
            <tbody>
              {members.map((member) => (
                <tr key={member.userId}>
                  <td>{member.name}</td>
                  {canEditMembers && (
                    <>
                      <td>
                        <FilledButton
                          icon="up"
                          small
                          onClick={() => changeAuth(member, "member", "admin")}
                        >
                          {m("moveToAdmin")}
                        </FilledButton>
                      </td>
                      <td>
                        <FilledButton
                          icon="clear"
                          color="secondary"
                          small
                          onClick={() =>
                            changeAuth(member, "member", "notmember")
                          }
                        >
                          {c("remove")}
                        </FilledButton>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Typography margin="4px 0px 0px 0px">{m("noMember")}</Typography>
        )}
        {canEditMembers && canAddMember && (
          <AddMemberBlock>
            <Form onSubmit={onSubmitAddMember}>
              <Text
                placeholder={c("enterEmail")}
                value={email}
                onChange={onChangeEmail}
              />
            </Form>
            <FilledButton
              icon="add"
              color="primary"
              small
              margin="8px 0px 0px 0px"
              disabled={!email}
              onClick={onClickAddMember}
            >
              {m("addMember")}
            </FilledButton>
          </AddMemberBlock>
        )}
      </Block>
    );
  }
);
Members.displayName = "Members";

const AddMemberBlock = styled(FlexColumnCenter)`
  margin-top: 8px;
`;

export default Members;
