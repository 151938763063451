import React from "react";
import styled from "styled-components";
import useStyles from "../../hooks/useStyles";
import { baseBorderRadius } from "../../constants";

type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoComplete?: string;
  margin?: string;
  long?: boolean;
  small?: boolean;
};

const Text: React.FC<Props> = React.memo(
  ({ value, onChange, placeholder, autoComplete, margin, long, small }) => {
    const { color, menuItem, input } = useStyles();

    const boxShadow = menuItem.boxShadow
      ? menuItem.boxShadow
      : `0 0 10px ${menuItem.borderColor}`;

    return (
      <Container
        type="text"
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
        color={color}
        placeholder={placeholder}
        placeholderColor={input.text.placeholderColor}
        bgColor={input.text.bgColor}
        borderColor={menuItem.borderColor}
        boxShadow={boxShadow}
        margin={margin}
        long={long}
        small={small}
      />
    );
  }
);
Text.displayName = "Text";

type ContainerProps = {
  color: string;
  placeholderColor: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  margin?: string;
  long?: boolean;
  small?: boolean;
};
const Container = styled.input<ContainerProps>`
  height: ${({ small }) => (small ? 16 : 20)}px;
  width: ${({ long }) => (long ? 300 : 200)}px;

  font-size: ${({ small }) => (small ? 15 : 16)}px;
  color: ${({ color }) => color};

  background-color: ${({ bgColor }) => bgColor};

  padding: ${({ small }) => (small ? "8px 12px" : "10px 15px")};
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  margin: ${({ margin }) => margin};

  outline: none;

  transition: box-shadow 0.1s ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
  :focus {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`;

export default Text;
