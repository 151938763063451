import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SettingContainer from "../../../components/SettingContainer";
import {
  FlexColumnCenter,
  FlexRowCenter,
} from "../../../components/StyledComponents";
import Typography from "../../../components/Typography";
import Radio from "../../../components/Input/Radio";
import Buttons from "./Buttons";
import useSnackbar from "../../../hooks/useSnackbar";
import useAnalytics from "../../../hooks/useAnalytics";
import { updateProjectVisibility } from "../../../db/projects";
import { Project } from "../../../models/Project";
import Context from "../../../context";
import { firebase401Message } from "../../../constants";

const ProjectCalendarSetting: React.FC = () => {
  const match = useRouteMatch<{ projectId: string }>({
    path: "/projects/:projectId/settings/visibility",
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params && match.params.projectId;

  // context
  const { projects, updateLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: v } = useTranslation("visibility");
  const history = useHistory();
  const { setMessage, setUnauthorizedMessage } = useSnackbar();
  const { sendProjectLog } = useAnalytics();
  // states
  const [project, setProject] = useState<Project | null>(null);
  const [assignee, setAssignee] = useState(true);
  const [dueDate, setDueDate] = useState(true);
  const [label, setLabel] = useState(true);
  const [filterHelp, setFilterHelp] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!projectId || !projects.length) return;
    const currentProject = projects.find((p) => p.projectId === projectId);
    if (currentProject) {
      setProject(currentProject);
      setAssignee(currentProject.visibility.assignee);
      setDueDate(currentProject.visibility.dueDate);
      setLabel(currentProject.visibility.label);
      setFilterHelp(currentProject.visibility.filterHelp);
    } else {
      setAssignee(true);
      setDueDate(true);
      setLabel(true);
      setFilterHelp(true);
    }
  }, [projects, projectId]);

  const onChangeAssignee = async (value: string) => {
    setAssignee(value === "true");
  };
  const onChangeDueDate = async (value: string) => {
    setDueDate(value === "true");
  };
  const onChangeLabel = async (value: string) => {
    setLabel(value === "true");
  };
  const onChangeFilterHelp = async (value: string) => {
    setFilterHelp(value === "true");
  };

  const onClickSave = async () => {
    if (!projectId) return;

    setLoading(true);
    try {
      await updateProjectVisibility(projectId, {
        assignee,
        dueDate,
        label,
        filterHelp,
      });
      await updateLocalProject(projectId);
      setMessage({
        level: "success",
        text: c("successfullyUpdated"),
      });
      sendProjectLog("update_visibility");
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = () => {
    projectId && history.push(`/projects/${projectId}`);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {project ? project.name : ""}
      </Typography>
      <Typography variant="medium" bold margin="0px 0px 6px 0px">
        {v("visibility")}
      </Typography>
      <Typography variant="small" margin="0px 0px 6px 0px">
        {v("message")}
      </Typography>
      <Typography variant="small" bold margin="8px 0px 2px 0px">
        {v("assignee")}
      </Typography>
      <Rows>
        <Radios>
          <Radio
            label={v("show")}
            value="true"
            checked={assignee}
            onChange={onChangeAssignee}
          />
          <Radio
            label={v("hide")}
            value="false"
            checked={!assignee}
            onChange={onChangeAssignee}
          />
        </Radios>
        <Typography variant="small" bold margin="8px 0px 2px 0px">
          {v("dueDate")}
        </Typography>
        <Radios>
          <Radio
            label={v("show")}
            value="true"
            checked={dueDate}
            onChange={onChangeDueDate}
          />
          <Radio
            label={v("hide")}
            value="false"
            checked={!dueDate}
            onChange={onChangeDueDate}
          />
        </Radios>
        <Typography variant="small" bold margin="8px 0px 2px 0px">
          {v("labels")}
        </Typography>
        <Radios>
          <Radio
            label={v("show")}
            value="true"
            checked={label}
            onChange={onChangeLabel}
          />
          <Radio
            label={v("hide")}
            value="false"
            checked={!label}
            onChange={onChangeLabel}
          />
        </Radios>
        <Typography variant="small" bold margin="8px 0px 2px 0px">
          {v("filterHelp")}
        </Typography>
        <Radios>
          <Radio
            label={v("show")}
            value="true"
            checked={filterHelp}
            onChange={onChangeFilterHelp}
          />
          <Radio
            label={v("hide")}
            value="false"
            checked={!filterHelp}
            onChange={onChangeFilterHelp}
          />
        </Radios>
      </Rows>
      <Buttons
        loading={loading}
        disabled={false}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
    </SettingContainer>
  );
};

const Rows = styled(FlexColumnCenter)`
  margin-bottom: 16px;
`;

const Radios = styled(FlexRowCenter)`
  > button {
    margin: 4px 4px;
  }
`;

export default ProjectCalendarSetting;
