import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexColumnCenter } from "../../components/StyledComponents";
import MemberFilter from "./MemberFilter";
import StatusFilter from "./StatusFilter";
import DueDateFilter from "./DueDateFilter";
import LabelRow from "./Label";
import FilterContext from "./context";
import useStyles from "../../hooks/useStyles";
import { User } from "../../models/User";
import { Label, LabelItem } from "../../models/Label";
import { Visibility } from "../../models/Project";
import { FilterType, FilterStatus, FilterDueDate } from "../../models/Filter";
import {
  defaultFontSize,
  baseBorderRadius,
  taskVerticalPadding,
  taskHorizontalPadding,
  filterZIndex,
  filterTabIndex,
} from "../../constants";

type Props = {
  isOpen: boolean;
  bottom: number;
  right: number;
  labels: Label[];
  visibility: Visibility;
  isCoediting: boolean;
  members: User[];
  openOrFocusFilter: () => void;
  closeFilter: () => void;
  focusFilterCounter: number;
  filterMember: User | null;
  changeFilterMember: (member: User | null) => void;
  filterStatus: FilterStatus;
  changeFilterStatus: (filterStatus: FilterStatus) => void;
  filterDueDate: FilterDueDate;
  changeFilterDueDate: (filterDueDate: FilterDueDate) => void;
  filterLabelItems: LabelItem[];
  toggleFilterLabelItem: (item: LabelItem) => void;
};

const Filter: React.FC<Props> = React.memo(
  ({
    isOpen,
    bottom,
    right,
    labels,
    visibility,
    isCoediting,
    members,
    openOrFocusFilter,
    closeFilter,
    focusFilterCounter,
    filterMember,
    changeFilterMember,
    filterStatus,
    changeFilterStatus,
    filterDueDate,
    changeFilterDueDate,
    filterLabelItems,
    toggleFilterLabelItem,
  }) => {
    // ref
    const elm = useRef<HTMLDivElement>(null);
    // properties
    const {
      assignee: vAssignee,
      dueDate: isDueDateVisible,
      label: vLabel,
      filterHelp: isFilterHelpVisible,
    } = visibility;
    // hooks
    const { t: s } = useTranslation("status");
    const { t: a } = useTranslation("assignee");
    const { t: cl } = useTranslation("calendar");
    const { t: l } = useTranslation("labels");
    const { t: f } = useTranslation("filter");
    const { color, fontSize, filter } = useStyles();
    // states
    const [filterType, setFilterType] = useState<FilterType>("Status");
    const [selectedLabelId, setSelectedLabelId] = useState("");
    const [selectedLabelItemId, setSelectedLabelItemId] = useState("");
    // computed
    const isAssigneeVisible = vAssignee && isCoediting;
    const isLabelVisible = vLabel && labels.length ? true : false;
    const boxShadow = filter.boxShadow
      ? filter.boxShadow
      : `0 0 10px ${filter.borderColor}`;

    useEffect(() => {
      if (labels.length) {
        setSelectedLabelId(labels[0].labelId);
        if (labels[0].labelItems.length) {
          setSelectedLabelItemId(labels[0].labelItems[0].labelItemId);
        }
      }
    }, [labels]);

    useEffect(() => {
      focus();
    }, [focusFilterCounter]);

    const focus = () => {
      elm.current && elm.current.focus();
    };

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation(); // stop click
    };

    const onMouseDown = async (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation(); // stop grab

      // focusさせる
      await openOrFocusFilter();
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (event.ctrlKey || event.metaKey) {
        switch (event.key) {
          case "f":
            // ブラウザの検索窓を出さない
            event.preventDefault();
            break;
          default:
            break;
        }
      } else {
        switch (event.key) {
          case "ArrowUp":
            event.preventDefault();
            moveUp();
            break;
          case "ArrowDown":
            event.preventDefault();
            moveDown();
            break;
          case "ArrowRight":
            event.preventDefault();
            switch (filterType) {
              case "Assignee":
                moveToRightFilterMember();
                break;
              case "Status":
                switchFilterStatus();
                break;
              case "DueDate":
                moveToRightFilterDueDate();
                break;
              case "Label":
                moveToRightLabelItem();
                break;
              default:
                break;
            }
            break;
          case "ArrowLeft":
            event.preventDefault();
            switch (filterType) {
              case "Assignee":
                moveToLeftFilterMember();
                break;
              case "Status":
                switchFilterStatus();
                break;
              case "DueDate":
                moveToLeftFilterDueDate();
                break;
              case "Label":
                moveToLeftLabelItem();
                break;
              default:
                break;
            }
            break;
          case " ":
            event.preventDefault();
            onSpaceKeyDown();
            break;
          case "Enter":
          case "Escape":
            event.preventDefault();
            closeFilter();
            break;
          case "Tab":
            event.preventDefault();
            break;
          default:
            break;
        }
      }
    };

    const moveUp = () => {
      // 1. Status
      // 2. Assignee
      // 3. DueDate
      // 4. Label
      if (filterType === "Status") {
        if (isLabelVisible) {
          toLastLabel();
        } else if (isDueDateVisible) {
          setFilterType("DueDate");
        } else if (isAssigneeVisible) {
          setFilterType("Assignee");
        }
      } else if (filterType === "Assignee") {
        setFilterType("Status");
      } else if (filterType === "DueDate") {
        if (isAssigneeVisible) {
          setFilterType("Assignee");
        } else {
          setFilterType("Status");
        }
      } else if (filterType === "Label") {
        toPreviousLabel();
      }
    };
    const toLastLabel = () => {
      const currentIndex = labels.findIndex(
        (l) => l.labelId === selectedLabelId
      );
      const nextIndex = labels.length - 1;
      setLabelItem(currentIndex, nextIndex);
      const nextLabel = labels[nextIndex];
      setSelectedLabelId(nextLabel.labelId);
      setFilterType("Label");
    };
    const toPreviousLabel = () => {
      const currentIndex = labels.findIndex(
        (l) => l.labelId === selectedLabelId
      );
      if (currentIndex === 0) {
        // DueDate に移る
        if (isDueDateVisible) {
          setFilterType("DueDate");
        } else if (isAssigneeVisible) {
          setFilterType("Assignee");
        } else {
          setFilterType("Status");
        }
      } else {
        const nextIndex = currentIndex - 1;
        setLabelItem(currentIndex, nextIndex);
        const nextLabel = labels[nextIndex];
        setSelectedLabelId(nextLabel.labelId);
      }
    };

    const moveDown = () => {
      // 1. Status
      // 2. Assignee
      // 3. DueDate
      // 4. Label
      if (filterType === "Status") {
        if (isAssigneeVisible) {
          setFilterType("Assignee");
        } else if (isDueDateVisible) {
          setFilterType("DueDate");
        } else if (isLabelVisible) {
          toFirstLabel();
        }
      } else if (filterType === "Assignee") {
        if (isDueDateVisible) {
          setFilterType("DueDate");
        } else if (isLabelVisible) {
          toFirstLabel();
        } else {
          setFilterType("Status");
        }
      } else if (filterType === "DueDate") {
        if (isLabelVisible) {
          toFirstLabel();
        } else {
          setFilterType("Status");
        }
      } else if (filterType === "Label") {
        toNextLabel();
      }
    };
    const toFirstLabel = () => {
      const currentIndex = labels.findIndex(
        (l) => l.labelId === selectedLabelId
      );
      const nextIndex = 0;
      setLabelItem(currentIndex, nextIndex);
      const nextLabel = labels[nextIndex];
      setSelectedLabelId(nextLabel.labelId);
      setFilterType("Label");
    };
    const toNextLabel = () => {
      const currentIndex = labels.findIndex(
        (l) => l.labelId === selectedLabelId
      );
      if (currentIndex === labels.length - 1) {
        setFilterType("Status");
      } else {
        const nextIndex = currentIndex + 1;
        setLabelItem(currentIndex, nextIndex);
        const nextLabel = labels[nextIndex];
        setSelectedLabelId(nextLabel.labelId);
      }
    };

    const setLabelItem = (oldIndex: number, nextIndex: number) => {
      const nextLabel = labels[nextIndex];
      const currentItemIndex = labels[oldIndex].labelItems.findIndex(
        (i) => i.labelItemId === selectedLabelItemId
      );
      const nextItem =
        currentItemIndex > nextLabel.labelItems.length - 1
          ? nextLabel.labelItems[nextLabel.labelItems.length - 1]
          : nextLabel.labelItems[currentItemIndex];
      setSelectedLabelItemId(nextItem.labelItemId);
    };

    const moveToRightFilterMember = () => {
      if (filterMember) {
        const currentIndex = members.findIndex(
          (m) => m.userId === filterMember.userId
        );
        if (currentIndex === members.length - 1) {
          // なし
          changeFilterMember(null);
        } else {
          // 次のメンバー
          changeFilterMember(members[currentIndex + 1]);
        }
      } else {
        // 最初のメンバー
        changeFilterMember(members[0]);
      }
    };

    const moveToLeftFilterMember = () => {
      if (filterMember) {
        const currentIndex = members.findIndex(
          (m) => m.userId === filterMember.userId
        );
        if (currentIndex === 0) {
          // なし
          changeFilterMember(null);
        } else {
          // 前のメンバー
          changeFilterMember(members[currentIndex - 1]);
        }
      } else {
        // 最後のメンバー
        changeFilterMember(members[members.length - 1]);
      }
    };

    const moveToRightLabelItem = () => {
      const label = labels.find((l) => l.labelId === selectedLabelId);
      if (!label) return;
      const currentItemIndex = label.labelItems.findIndex(
        (i) => i.labelItemId === selectedLabelItemId
      );
      if (currentItemIndex === label.labelItems.length - 1) {
        setSelectedLabelItemId(label.labelItems[0].labelItemId);
      } else {
        setSelectedLabelItemId(
          label.labelItems[currentItemIndex + 1].labelItemId
        );
      }
    };

    const moveToLeftLabelItem = () => {
      const label = labels.find((l) => l.labelId === selectedLabelId);
      if (!label) return;
      const currentItemIndex = label.labelItems.findIndex(
        (i) => i.labelItemId === selectedLabelItemId
      );
      if (currentItemIndex === 0) {
        setSelectedLabelItemId(
          label.labelItems[label.labelItems.length - 1].labelItemId
        );
      } else {
        setSelectedLabelItemId(
          label.labelItems[currentItemIndex - 1].labelItemId
        );
      }
    };

    const switchFilterStatus = () => {
      switch (filterStatus) {
        case "all":
          changeFilterStatus("undone");
          break;
        case "undone":
          changeFilterStatus("all");
          break;
        default:
          break;
      }
    };

    const moveToRightFilterDueDate = () => {
      switch (filterDueDate) {
        case "none":
          changeFilterDueDate("overdue");
          break;
        case "overdue":
          changeFilterDueDate("today");
          break;
        case "today":
          changeFilterDueDate("thisweek");
          break;
        case "thisweek":
          changeFilterDueDate("thismonth");
          break;
        case "thismonth":
          changeFilterDueDate("none");
          break;
        default:
          break;
      }
    };

    const moveToLeftFilterDueDate = () => {
      switch (filterDueDate) {
        case "none":
          changeFilterDueDate("thismonth");
          break;
        case "overdue":
          changeFilterDueDate("none");
          break;
        case "today":
          changeFilterDueDate("overdue");
          break;
        case "thisweek":
          changeFilterDueDate("today");
          break;
        case "thismonth":
          changeFilterDueDate("thisweek");
          break;
        default:
          break;
      }
    };

    const onSpaceKeyDown = () => {
      const label = labels.find((l) => l.labelId === selectedLabelId);
      if (!label) return;
      const labelItem = label.labelItems.find(
        (i) => i.labelItemId === selectedLabelItemId
      );
      labelItem && toggleFilterLabelItem(labelItem);
    };

    const onBlur = () => {
      closeFilter();
    };

    const onClickAssigneeStatus = () => {
      setFilterType("Assignee");
    };

    const onClickFilterStatus = () => {
      setFilterType("Status");
    };

    const onClickFilterDueDate = () => {
      setFilterType("DueDate");
    };

    return (
      <FilterContext.Provider
        value={{
          selectedLabelId,
          setSelectedLabelId,
          selectedLabelItemId,
          setSelectedLabelItemId,
        }}
      >
        <Container
          ref={elm}
          tabIndex={filterTabIndex}
          isOpen={isOpen}
          bottom={bottom}
          right={right}
          fontSize={fontSize}
          color={color}
          bgColor={filter.bgColor}
          borderColor={filter.borderColor}
          boxShadow={boxShadow}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        >
          <Title fontSize={fontSize}>{s("status")}</Title>
          <StatusFilter
            focused={filterType === "Status"}
            filterStatus={filterStatus}
            setFilterStatus={changeFilterStatus}
            onClick={onClickFilterStatus}
          />
          {isAssigneeVisible && (
            <>
              <Title fontSize={fontSize}>{a("assignee")}</Title>
              <MemberFilter
                focused={filterType === "Assignee"}
                members={members}
                filterMember={filterMember}
                changeFilterMember={changeFilterMember}
                onClick={onClickAssigneeStatus}
              />
            </>
          )}
          {isDueDateVisible && (
            <>
              <Title fontSize={fontSize}>{cl("dueDate")}</Title>
              <DueDateFilter
                focused={filterType === "DueDate"}
                filterDueDate={filterDueDate}
                setFilterDueDate={changeFilterDueDate}
                onClick={onClickFilterDueDate}
              />
            </>
          )}
          {isLabelVisible && (
            <>
              <Title fontSize={fontSize}>{l("labels")}</Title>
              {labels.map((l) => (
                <LabelRow
                  key={l.labelId}
                  label={l}
                  focused={filterType === "Label"}
                  filterLabelItems={filterLabelItems}
                  toggleFilterLabelItem={toggleFilterLabelItem}
                />
              ))}
            </>
          )}
          {isFilterHelpVisible && (
            <Help>
              <table>
                <tbody>
                  <tr>
                    <td align="center">↑↓←→</td>
                    <td>:</td>
                    <td align="center">{f("move")}</td>
                  </tr>
                  <tr>
                    <td align="center">Space</td>
                    <td>:</td>
                    <td align="center">{f("toggleLabelItem")}</td>
                  </tr>
                  <tr>
                    <td align="center">Escape,Enter</td>
                    <td>:</td>
                    <td align="center">{f("close")}</td>
                  </tr>
                </tbody>
              </table>
            </Help>
          )}
        </Container>
      </FilterContext.Provider>
    );
  }
);
Filter.displayName = "Filter";

type ContainerProps = {
  isOpen: boolean;
  bottom: number;
  right: number;
  fontSize: number;
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
};

const Container = styled(FlexColumnCenter)<ContainerProps>`
  background-color: ${({ bgColor }) => bgColor};

  font-size: ${({ fontSize }) => (fontSize ? fontSize : defaultFontSize)}px;
  color: ${({ color }) => color};

  padding: ${taskVerticalPadding}px ${taskHorizontalPadding}px
    ${taskVerticalPadding + 4}px ${taskHorizontalPadding}px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  box-shadow: ${({ boxShadow }) => boxShadow};

  position: fixed;
  bottom: ${({ bottom }) => bottom}px;
  right: ${({ right }) => right}px;
  margin: auto;

  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};

  user-select: none;
  cursor: default;
  outline: none;
  z-index: ${filterZIndex};
`;

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : defaultFontSize) + 2}px;
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const Help = styled(FlexColumnCenter)`
  margin-top: 4px;
`;

export default Filter;
