import { Task } from "../../models/Task";

export const getClosestAboveTask = (
  tasks: Task[],
  startTask: Task
): Task | null => {
  // 1つ上の兄弟
  // tasks から探さないと非表示ノードに移動してしまう
  let nextTask = tasks.find(
    (t) =>
      t.parentId === startTask.parentId &&
      t.sortNumber === startTask.sortNumber - 1
  );
  if (!nextTask) {
    // 兄弟がない
    // 1. 親でない
    // 2. 子でもない
    // 3. 1と2を満たす直線距離が近いノード
    const childrenIds = startTask.childrenIds;
    const candidates = tasks.filter(
      (t) =>
        t.id !== t.parentId &&
        !childrenIds.includes(t.id) &&
        t.top < startTask.top
    );
    if (!candidates.length) return null;
    const x1 = startTask.horizontalCenter;
    const y1 = startTask.top;
    candidates.sort((a, b) => {
      const aBottomCenterDistance = getDistance(
        x1,
        y1,
        a.horizontalCenter,
        a.bottom
      );
      const bBottomCenterDistance = getDistance(
        x1,
        y1,
        b.horizontalCenter,
        b.bottom
      );
      const aBottomRightDistance = getDistance(x1, y1, a.right, a.bottom);
      const bBottomRightDistance = getDistance(x1, y1, b.right, b.bottom);
      const aBottomLeftDistance = getDistance(x1, y1, a.left, a.bottom);
      const bBottomLeftDistance = getDistance(x1, y1, b.left, b.bottom);
      const min = Math.min(
        ...[
          aBottomCenterDistance,
          bBottomCenterDistance,
          aBottomRightDistance,
          bBottomRightDistance,
          aBottomLeftDistance,
          bBottomLeftDistance,
        ]
      );
      if (
        min === aBottomCenterDistance ||
        min === aBottomRightDistance ||
        min === aBottomLeftDistance
      ) {
        return -1;
      } else {
        return 1;
      }
    });
    nextTask = candidates[0];
  }
  return nextTask;
};

export const getClosestBelowTask = (
  tasks: Task[],
  startTask: Task
): Task | null => {
  // 1つ下の兄弟
  // tasks から探さないと非表示ノードに移動してしまう
  let nextTask = tasks.find(
    (t) =>
      t.parentId === startTask.parentId &&
      t.sortNumber === startTask.sortNumber + 1
  );
  if (!nextTask) {
    // 兄弟がなければ直線距離が近いノード
    // 兄弟がない
    // 1. 親でない
    // 2. 子でもない
    // 3. 1と2を満たす直線距離が近いノード
    const childrenIds = startTask.childrenIds;
    const candidates = tasks.filter(
      (t) =>
        t.id !== t.parentId &&
        !childrenIds.includes(t.id) &&
        t.top > startTask.top
    );
    if (!candidates.length) return null;
    const x1 = startTask.horizontalCenter;
    const y1 = startTask.verticalCenter;
    candidates.sort((a, b) => {
      const aTopCenterDistance = getDistance(x1, y1, a.horizontalCenter, a.top);
      const bTopCenterDistance = getDistance(x1, y1, b.horizontalCenter, b.top);
      const aTopRightDistance = getDistance(x1, y1, a.right, a.bottom);
      const bTopRightDistance = getDistance(x1, y1, b.right, b.bottom);
      const aTopLeftDistance = getDistance(x1, y1, a.left, a.bottom);
      const bTopLeftDistance = getDistance(x1, y1, b.left, b.bottom);
      const min = Math.min(
        ...[
          aTopCenterDistance,
          bTopCenterDistance,
          aTopRightDistance,
          bTopRightDistance,
          aTopLeftDistance,
          bTopLeftDistance,
        ]
      );
      if (
        min === aTopCenterDistance ||
        min === aTopRightDistance ||
        min === aTopLeftDistance
      ) {
        return -1;
      } else {
        return 1;
      }
    });
    nextTask = candidates[0];
  }
  return nextTask;
};

const getDistance = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number => {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

export const getClosestRightTask = (
  tasks: Task[],
  startTask: Task
): Task | null => {
  if (startTask.children.length) {
    // sort するのでオリジナルの順番が変わらないように slice
    const children = startTask.children.slice();
    // 自分の子供の中で中心が自分の中心に近い方に移動
    const focusedTaskCenter = startTask.verticalCenter;
    children.sort((a, b) => {
      const aMargin =
        a.verticalCenter <= focusedTaskCenter
          ? focusedTaskCenter - a.verticalCenter
          : a.verticalCenter - focusedTaskCenter;
      const bMargin =
        b.verticalCenter <= focusedTaskCenter
          ? focusedTaskCenter - b.verticalCenter
          : b.verticalCenter - focusedTaskCenter;
      if (aMargin === bMargin) {
        return a.verticalCenter - b.verticalCenter;
      } else {
        return aMargin - bMargin;
      }
    });
    return children[0];
  } else {
    // 子供がいない
    // 1. 左がより左のタスク：これで兄弟は弾かれる
    // 2. 右がより右のタスク：これで直感に反するタスクに移動することがなくなる
    const candidates = tasks.filter(
      (t) => t.left > startTask.left && t.right > startTask.right
    );
    if (!candidates.length) return null;
    // 基準となる開始タスクの座標
    const x1 = startTask.horizontalCenter;
    const y1 = startTask.verticalCenter;
    candidates.sort((a, b) => {
      const aTopLeftDistance = getDistance(x1, y1, a.left, a.top);
      const bTopLeftDistance = getDistance(x1, y1, b.left, b.top);
      const aBottomLeftDistance = getDistance(x1, y1, a.left, a.bottom);
      const bBottomLeftDistance = getDistance(x1, y1, b.left, b.bottom);
      const min = Math.min(
        ...[
          aTopLeftDistance,
          bTopLeftDistance,
          aBottomLeftDistance,
          bBottomLeftDistance,
        ]
      );
      if (min === aTopLeftDistance || min === aBottomLeftDistance) {
        return -1;
      } else {
        return 1;
      }
    });
    return candidates[0];
  }
};

export const getClosestLeftTask = (startTask: Task): Task | null => {
  // 親に移動
  return startTask.parent ? startTask.parent : null;
};

/**
 * 同じ親を持つ1つ上のノード
 * shiftMove 用
 */
export const getElderTask = (
  startTask: Task,
  parent: Task,
  filtering: boolean
): Task | null => {
  const children = filtering ? parent.filteredChildren : parent.children;
  const currentIndex = children.findIndex((c) => c.id === startTask.id);
  if (currentIndex <= 0) return null; // 見つからない場合と一番上

  return children[currentIndex - 1];
};

/**
 * 同じ親を持つ1つ下のノード
 * shiftMove 用
 */
export const getYoungerTask = (
  startTask: Task,
  parent: Task,
  filtering: boolean
): Task | null => {
  const children = filtering ? parent.filteredChildren : parent.children;
  const currentIndex = children.findIndex((c) => c.id === startTask.id);
  if (currentIndex < 0) return null; // 見つからない場合
  if (currentIndex === children.length - 1) return null; // 一番下

  return children[currentIndex + 1];
};
