import { issueId } from "../../bl/common";
import { UpdateTask, Task } from "../../models/Task";

export const blPasteCopiedTask = (
  tasks: Task[],
  target: Task,
  copiedTasks: Task[],
  updatedBy: string
): {
  newTasks: Task[];
  addingTasks: Task[];
  updatingTasks: UpdateTask[];
  selectingTaskId: string;
} => {
  const newTasks = tasks.map((t) => {
    // reset flags
    t.selected = false;
    t.multiSelected = false;
    // copied は連続して貼り付ける可能性があるのでリセットしない
    t.cut = false;
    return t;
  });

  // sort しないと貼り付け順が変わる
  copiedTasks.sort((a, b) => a.sortNumber - b.sortNumber);

  const addingTasks: Task[] = [];
  for (const copiedTask of copiedTasks) {
    const levelMargin = target.level + 1 - copiedTask.level;
    // new
    const newCopiedTask = new Task(
      issueId(),
      copiedTask.level + levelMargin,
      target.getNextSortNumber(),
      target,
      [],
      copiedTask.text,
      false,
      "",
      null,
      null,
      copiedTask.dueDate,
      copiedTask.labels
    );

    // all children
    const idPairs: Array<[string, string]> = [
      [copiedTask.id, newCopiedTask.id],
    ];
    const newAllChildren = copiedTask.allChildren.map((c) => {
      const newId = issueId();
      idPairs.push([c.id, newId]);
      return new Task(
        newId,
        c.level + levelMargin,
        c.sortNumber,
        c.parent,
        c.children,
        c.text,
        false,
        "",
        null,
        null,
        c.dueDate,
        c.labels
      );
    });
    const tempAddingTasks = newAllChildren.concat([newCopiedTask]);
    newCopiedTask.children = copiedTask.children.map((c) => {
      const newChildIdPair = idPairs.find((p) => p[0] === c.id);
      if (newChildIdPair) {
        const newChild = tempAddingTasks.find(
          (c) => c.id === newChildIdPair[1]
        );
        return newChild ?? c;
      } else {
        return c;
      }
    });
    for (const child of newAllChildren) {
      const newParentIdPair = idPairs.find((p) => p[0] === child.parentId);
      if (newParentIdPair) {
        const newParent = tempAddingTasks.find(
          (t) => t.id === newParentIdPair[1]
        );
        child.parent = newParent ?? null;
      }
      child.children = child.children.map((c) => {
        const newChildIdPair = idPairs.find((p) => p[0] === c.id);
        if (newChildIdPair) {
          const newChild = tempAddingTasks.find(
            (c) => c.id === newChildIdPair[1]
          );
          return newChild ?? c;
        } else {
          return c;
        }
      });
    }

    // target
    // 最後に追加しないと copiedTask.allChildren に含まれてしまう
    target.children.push(newCopiedTask);

    Array.prototype.push.apply(newTasks, tempAddingTasks);
    Array.prototype.push.apply(addingTasks, tempAddingTasks);
  }

  // selected
  const selectingTask = target.children[target.children.length - 1];
  selectingTask.selected = true;

  const updatingTasks: UpdateTask[] = [];
  updatingTasks.push({
    id: target.id,
    params: {
      childrenIds: target.childrenIds,
      updatedBy,
      updatedAt: new Date(),
    },
  });

  return {
    newTasks,
    addingTasks,
    updatingTasks,
    selectingTaskId: selectingTask.id,
  };
};
