import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Forward } from "@material-ui/icons";
import { FlexColumnCenter } from "../../components/StyledComponents";
import { recMargin, acitionItemsMargin } from "./constants";

const ScrollToNext: React.FC = React.memo(() => {
  // hooks
  const { t } = useTranslation("top");

  return (
    <Container>
      <Forward style={{ height: 40, width: 40, transform: "rotate(90deg)" }} />
      <Text>{t("scrollToNext")}</Text>
    </Container>
  );
});
ScrollToNext.displayName = "ScrollToNext";

const Container = styled(FlexColumnCenter)`
  width: 360px;
  position: fixed;
  bottom: ${recMargin + acitionItemsMargin + 10}px;
  left: calc(50vw - 180px);
  animation: fadeIn 0.5s ease-out, waving 1s ease-in-out infinite;
`;

const Text = styled.div`
  line-height: 35px;
  font-size: 35px;
  font-weight: bold;
`;

export default ScrollToNext;
