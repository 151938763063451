import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Person, People, ExpandMore, ExpandLess } from "@material-ui/icons";
import { FlexRowCenter } from "../../components/StyledComponents";
import MenuItem from "../../components/MenuItem";
import { isCoediting } from "../../bl/project";
import Context from "../../context";
import useStyles from "../../hooks/useStyles";
import { Project } from "../../models/Project";

const subMenus = [
  {
    name: "basic",
    key: "projectName",
  },
  {
    name: "labels",
    key: "labels",
  },
  {
    name: "members",
    key: "members",
  },
  {
    name: "calendar",
    key: "calendar",
  },
  {
    name: "visibility",
    key: "visibility",
  },
];

type Props = {
  index: number;
  project: Project;
  selected: boolean;
  onClickDeleteProject: (project: Project) => void;
};

const ProjectListItem: React.FC<Props> = React.memo(
  ({ index, project, selected, onClickDeleteProject }) => {
    const match = useRouteMatch<{ projectId: string }>({
      path: ["/projects/:projectId", "/projects/:projectId/settings"],
      strict: true,
      sensitive: true,
    });
    const projectId = match && match.params && match.params.projectId;

    // context
    const { user } = useContext(Context);
    // hooks
    const { t: p } = useTranslation("project");
    const { t: m } = useTranslation("projectSubMenus");
    const history = useHistory();
    const location = useLocation();
    const { palette, menuItem, icon } = useStyles();
    // states
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // computed
    const isOwner = user && user.userId === project.ownerId ? true : false;
    const unhoverColor = selected ? icon.selected.color : icon.color;
    const hoverColor = selected ? icon.selected.hoverColor : icon.hoverColor;
    const boxShadow = menuItem.boxShadow
      ? menuItem.boxShadow
      : `0 0 10px ${menuItem.borderColor}`;

    const getIcon = () => {
      if (isCoediting(project)) {
        return <People />;
      } else {
        return <Person />;
      }
    };

    useEffect(() => {
      if (!location.pathname.includes(project.projectId)) {
        setIsMenuOpen(false);
      }
    }, [projectId]);

    const menuSelected = (path: string) => {
      return (
        location.pathname === `/projects/${project.projectId}/settings/${path}`
      );
    };

    const toProject = () => {
      const nextPath = `/projects/${project.projectId}`;
      if (location.pathname !== nextPath) {
        history.push(nextPath);
      }
    };
    const onClick = () => {
      if (isMenuOpen) {
        // メニューが開いてる時は名前を押した時しか反応させない
      } else {
        toProject();
      }
    };
    const onClickProject = () => {
      if (isMenuOpen) {
        toProject();
      } else {
        // メニューが開いてる時は onClickHeader で遷移させるので何もしない
      }
    };

    const onClickToggle = (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();
      setIsMenuOpen(!isMenuOpen);
    };

    const onClickMenu = (
      event: React.MouseEvent<HTMLLIElement>,
      menu: string
    ) => {
      event.stopPropagation();
      const nextPath = `/projects/${project.projectId}/settings/${menu}`;
      if (location.pathname !== nextPath) {
        history.push(nextPath);
      }
    };

    // Delete
    const onClickDelete = async () => {
      if (!isOwner) return;
      onClickDeleteProject(project);
    };

    return (
      <MenuItem
        index={index}
        selected={selected}
        flexDirection="column"
        justifyContent="space-between"
        cursor={isMenuOpen ? "default" : "pointer"}
        onClick={onClick}
      >
        <Header unhoverColor={unhoverColor} hoverColor={hoverColor}>
          <ProjectName onClick={onClickProject}>
            {getIcon()}
            <div>{project.name}</div>
          </ProjectName>
          {isMenuOpen ? (
            <ExpandLess style={{ cursor: "pointer" }} onClick={onClickToggle} />
          ) : (
            <ExpandMore onClick={onClickToggle} />
          )}
        </Header>
        <Menus visible={isMenuOpen}>
          {subMenus.map((item, i) => (
            <SubMenuItem
              key={item.key}
              selected={menuSelected(item.name)}
              color={menuItem.color}
              bgColor={menuItem.bgColor}
              selectedColor={menuItem.selected.color}
              selectedBgColor={
                menuItem.selected.mode === "designated"
                  ? menuItem.selected.bgColor
                  : palette[i % palette.length]
              }
              borderColor={menuItem.borderColor}
              boxShadow={boxShadow}
              onClick={(e) => onClickMenu(e, item.name)}
            >
              {m(item.key)}
            </SubMenuItem>
          ))}
          {isOwner && (
            <SubMenuItem
              selected={false}
              color={menuItem.color}
              bgColor={menuItem.bgColor}
              selectedColor={menuItem.selected.color}
              selectedBgColor={
                menuItem.selected.mode === "designated"
                  ? menuItem.selected.bgColor
                  : palette[subMenus.length % palette.length]
              }
              borderColor={menuItem.borderColor}
              boxShadow={boxShadow}
              onClick={onClickDelete}
            >
              {p("deleteProject")}
            </SubMenuItem>
          )}
        </Menus>
      </MenuItem>
    );
  }
);
ProjectListItem.displayName = "ProjectListItem";

type IconProps = {
  unhoverColor: string;
  hoverColor: string;
};
const Header = styled.div<IconProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  > svg {
    /* 設定ボタン */
    color: ${({ unhoverColor }) => unhoverColor};

    transition: color 100ms ease-out;

    :hover {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const ProjectName = styled(FlexRowCenter)`
  cursor: pointer;
  /* 左のアイコン */
  > svg {
    margin-right: 4px;
  }
`;

const Menus = styled.ul<{ visible: boolean }>`
  width: 100%;
  margin-top: 6px;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  align-items: stretch;

  > li {
    margin-bottom: 8px;
  }
  > li:last-child {
    margin-bottom: 5px;
  }
`;

type SubMenuItemProps = {
  selected: boolean;
  color: string;
  bgColor: string;
  selectedColor: string;
  selectedBgColor: string;
  borderColor: string;
  boxShadow: string;
};
const SubMenuItem = styled.li<SubMenuItemProps>`
  color: ${({ selected, color, selectedColor }) =>
    selected ? selectedColor : color};
  background-color: ${({ selected, bgColor, selectedBgColor }) =>
    selected ? selectedBgColor : bgColor};
  text-align: center;
  line-height: 32px;
  padding: 0px 12px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: 10px;
  list-style: none;

  user-select: none;
  cursor: pointer;

  transition: color 100ms ease-out, background-color 100ms ease-out,
    box-shadow 100ms ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

export default ProjectListItem;
