import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";
import ComponentTask from "./Task";
import ComponentLine from "./Line";
import ComponentActiveMembersLabel from "./ActiveMembersLabel";
import FilteringItems from "../../components/FilteringItems";
import Filter from "../../components/Filter";
import ContextMenu from "../../components/ContextMenu";
import Toolbox from "./Toolbox";
import ActiveMembersComponent from "./ActiveMembers";
import Help from "./Help";
import ClickToFocus from "../../components/ClickToFocus";
import useLocalStorage from "../../hooks/useLocalStorage";
import useStyles from "../../hooks/useStyles";
import useSnackbar from "../../hooks/useSnackbar";
import useAnalytics from "../../hooks/useAnalytics";
import Context from "../../context";
import MapContext from "./context";
import { db } from "../../firebase";
import {
  activeMemberConverter,
  createActiveMember,
  updateCurrentTask,
} from "../../db/activeMembers";
import { taskConverter } from "../../db/tasks";
import { isCoediting } from "../../bl/project";
import { setPassedFilter } from "../../bl/task/filter";
import {
  setTaskTop,
  setTaskTopWithFilter,
  setTaskLeft,
  createLines,
} from "../../bl/organize";
import { blSetTasks } from "../../bl/task/set";
import { blAddChild, blAddSibling } from "../../bl/task/add";
import { blDeleteTask } from "../../bl/task/delete";
import { blMoveToEdge, blSwitchTasks } from "../../bl/task/move";
import { blPasteCopiedTask } from "../../bl/task/copy";
import { blPasteCutTask, blOutdentTasks } from "../../bl/task/cut";
import {
  getClosestAboveTask,
  getClosestBelowTask,
  getClosestRightTask,
  getClosestLeftTask,
  getElderTask,
  getYoungerTask,
} from "../../bl/task/select";
import { getSelectingTaskIdInFilteredTasks } from "../../bl/task/filter";
import { getUsers } from "../../db/users";
import { getTasks, batchEditTasks } from "../../db/tasks";
import { User } from "../../models/User";
import { Project } from "../../models/Project";
import { ActiveMember } from "../../models/ActiveMember";
import { ActiveMembersLabel } from "../../models/ActiveMembersLabel";
import { ITask, UpdateTask, Task } from "../../models/Task";
import { LabelItem, TaskLabel } from "../../models/Label";
import { Line } from "../../models/Line";
import { FilterDueDate, FilterStatus } from "../../models/Filter";
import { SettingCategory } from "../../models/Setting";
import {
  firebase401Message,
  firebase404Message,
  freePlanMaxTaskCount,
  defaultFirstDay,
  activeMemberLabelHeight,
  mindmapTabIndex,
  defaultVisibility,
  filterBottom,
  filterRight,
  labelItemsBottom,
  labelItemsLeft,
} from "../../constants";

type Props = {
  setScroll: (top: number, left: number) => void;
  isGrabbingMindmap: boolean;
  onMindmapMouseDown: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onMindmapMouseMove: (pageX: number, pageY: number) => void;
  onMindmapMouseUp: () => void;
  onMindmapMouseOut: () => void;
  adjustScroll: (target: Task) => void;
};

const ComponentProject: React.FC<Props> = ({
  setScroll,
  isGrabbingMindmap,
  onMindmapMouseDown,
  onMindmapMouseMove,
  onMindmapMouseUp,
  onMindmapMouseOut,
  adjustScroll,
}) => {
  const match = useRouteMatch<{ projectId: string }>({
    path: "/projects/:projectId",
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params && match.params.projectId;

  // context
  const { user, tempUserId, projects, focusMindmapCounter } = useContext(
    Context
  );
  // hooks
  const { t: c } = useTranslation("common");
  const {
    loadProjectSettings,
    setSelectedTaskId,
    saveFilterMember,
    saveFilterStatus,
    saveFilterDueDate,
    addFilterLabelItem,
    removeFilterLabelItem,
    resetFilterLabelItems,
  } = useLocalStorage();
  const {
    palette,
    color,
    node,
    incrementFontSize,
    decrementFontSize,
  } = useStyles();
  const {
    setTaskCountAlert,
    setUnauthorizedMessage,
    setAddedInvisibleTaskMessage,
    setDifferentParentMessage,
    setTaskDeletedMessage,
    setCannotDeleteMessage,
    setNotCollaborationProjectWarning,
    setAssigningInvisibleWarning,
    setDueDateInvisibleWarning,
    setNoLabelWarning,
    setLabelInvisibleWarning,
  } = useSnackbar();
  const { sendTaskSettingLog, sendAlertLog, sendFilterLog } = useAnalytics();
  const history = useHistory();

  // ref
  const mindmapElm = useRef<HTMLDivElement>(null);

  // states
  const [project, setProject] = useState<Project | null>(null);
  const computedIsCoediting = project ? isCoediting(project) : false;
  const [activeMembers, setActiveMembers] = useState<ActiveMember[]>([]);
  const [activeMembersLabels, setActiveMembersLabels] = useState<
    ActiveMembersLabel[]
  >([]);
  const [firstDay, setFirstDay] = useState(defaultFirstDay);
  const [tempUpdatedTasks, setTempUpdatedTasks] = useState<ITask[]>([]);
  const [updatedTasks, setUpdatedTasks] = useState<ITask[]>([]);
  const [tasks, setOriginalTasks] = useState<Task[]>([]);
  const [displayTasks, setDisplayTasks] = useState<Task[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const [shiftMoveStartTask, setShiftMoveStartTask] = useState<Task | null>(
    null
  );
  const [
    nextTaskIdAfterDeletingNewTask,
    setNextTaskIdAfterDeletingNewTask,
  ] = useState("");
  const [members, setMembers] = useState<User[]>([]);
  const [isSettingsVisible, setIsSettingsVisible] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [focusFilterCounter, setForceFocusFilter] = useState(0);
  const [filterMember, setFilterMember] = useState<User | null>(null);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>("all");
  const [filterDueDate, setFilterDueDate] = useState<FilterDueDate>("none");
  const [filterLabelItems, setFilterLabelItems] = useState<LabelItem[]>([]);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [forceOrganizeCounter, setForceOrganizeCounter] = useState(0);
  const [resizeMap, setResizeMap] = useState(true);
  const [initialUnitHeight, setInitialUnitHeight] = useState(0);
  const [initialUnitWIdth, setInitialUnitWidth] = useState(0);
  const [organizeWithScroll, setOrganizeWithScroll] = useState(true);
  // Context menu
  // コンテキストメニューの isOpen も兼ねている
  const [contextMenuTask, setContextMenuTask] = useState<Task | null>(null);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);
  // Timer
  // 共同編集時に一定時間が経過すると強制的に updatedTasks を反映させるタイマー
  const [forceUpdateTimer, setForceUpdateTimer] = useState<NodeJS.Timer | null>(
    null
  );
  // ↑で setTasks(tasks) を強制的に発生させるトリガー
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);

  // computed
  const isFiltering =
    filterMember ||
    filterStatus !== "all" ||
    filterDueDate !== "none" ||
    filterLabelItems.length
      ? true
      : false;
  const {
    assignee: isAssigneeVisible,
    dueDate: isDueDateVisible,
    label: isLabelVisible,
  } = project ? project.visibility : defaultVisibility;

  // set tasks / task
  const setTasks = (newTasks: Task[]) => {
    if (forceUpdateTimer) {
      clearTimeout(forceUpdateTimer);
    }
    if (updatedTasks.length) {
      const settingTasks = blSetTasks(newTasks, updatedTasks, members);
      setOriginalTasks(settingTasks);
      // slice した分だけ、前から削って、setUpdatedTasks
      updatedTasks.splice(0, updatedTasks.length);
      setUpdatedTasks(updatedTasks);
    } else {
      setOriginalTasks(newTasks);
    }
  };
  const setTask = (task: Task): void => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  // Filter
  const openOrFocusFilter = async () => {
    setIsFilterOpen(true);
    setForceFocusFilter(focusFilterCounter + 1);
    sendFilterLog("open");
  };
  const closeFilter = () => {
    setIsFilterOpen(false);
    focusMindmap();
  };
  const changeFilterMember = (member: User | null) => {
    setFilterMember(member);
    saveFilterMember(project, member ? member.userId : "");
  };
  const changeFilterStatus = (status: FilterStatus) => {
    setFilterStatus(status);
    saveFilterStatus(project, status);
  };
  const changeFilterDueDate = (dueDate: FilterDueDate) => {
    setFilterDueDate(dueDate);
    saveFilterDueDate(project, dueDate);
  };
  const toggleFilterLabelItem = (item: LabelItem) => {
    if (filterLabelItems.some((i) => i.labelItemId === item.labelItemId)) {
      setFilterLabelItems(
        filterLabelItems.filter((i) => i.labelItemId !== item.labelItemId)
      );
      removeFilterLabelItem(project, item);
    } else {
      setFilterLabelItems([...filterLabelItems, item]);
      addFilterLabelItem(project, item);
    }
  };
  const resetFilter = (resetLocalStorage: boolean) => {
    setFilterMember(null);
    setFilterStatus("all");
    setFilterDueDate("none");
    setFilterLabelItems([]);
    if (resetLocalStorage) {
      saveFilterMember(project, "");
      saveFilterMember(project, "");
      saveFilterStatus(project, "all");
      saveFilterDueDate(project, "none");
      resetFilterLabelItems(project);
    }
  };

  // Force Organize
  const organize = () => {
    setForceOrganizeCounter(forceOrganizeCounter + 1);
  };

  // Ref & Focus
  const focusMindmap = () => {
    mindmapElm.current && mindmapElm.current.focus();
  };
  const isClickToFocusDisabled =
    isFilterOpen ||
    isHelpOpen ||
    tasks.some(
      (t) =>
        t.isEditing ||
        t.isSetting ||
        t.isAssigning ||
        t.isSettingDueDate ||
        t.isLabeling
    );

  useEffect(() => {
    focusMindmap();
  }, [focusMindmapCounter]);

  // projects & project
  useEffect(() => {
    if (!projectId) return;
    setMembers([]);
    setOriginalTasks([]);
    setDisplayTasks([]);
    setTempUpdatedTasks([]);
    setUpdatedTasks([]);
    setLines([]);
    setActiveMembers([]);
    setActiveMembersLabels([]);
    resetFilter(false);
    setIsSettingsVisible(true);
    closeFilter();
    const currentProject = projects.find((p) => p.projectId === projectId);
    if (currentProject) {
      setProject(currentProject);
      setFirstDay(currentProject.firstDay);
    }
  }, [projects, projectId]);

  useEffect(() => {
    (async () => {
      setResizeMap(true);
      setInitialUnitHeight(0);
      setInitialUnitWidth(0);
      setOrganizeWithScroll(true);
      await loadTasks();
      await subscribeActiveMembers();
      focusMindmap();
    })();
  }, [project]);

  const loadTasks = async () => {
    if (!user || !project) return;

    // Members
    const userIds = [project.ownerId]
      .concat(project.memberIds)
      .concat(project.adminIds);
    const tempMembers = await getUsers(userIds);
    setMembers(tempMembers);

    // Localstorage は members が取得できてからじゃないとダメ
    const projectSettings = loadProjectSettings(project.projectId);
    const selectedTaskId = projectSettings
      ? projectSettings.selectedTaskId
      : "";
    if (projectSettings) {
      if (projectSettings.filterMemberId) {
        const filterMember = tempMembers.find(
          (m) => m.userId === projectSettings.filterMemberId
        );
        filterMember && setFilterMember(filterMember);
      }
      setFilterStatus(projectSettings.filterStatus ?? "all");
      setFilterDueDate(projectSettings.filterDueDate ?? "none");

      const filterLabelItemIds = projectSettings.filterLabelItemIds ?? [];
      const items: LabelItem[] = [];
      for (const id of filterLabelItemIds) {
        for (const label of project.labels) {
          const labelItem = label.labelItems.find((i) => i.labelItemId === id);
          labelItem && items.push(labelItem);
        }
      }
      setFilterLabelItems(items);
    }

    try {
      // Tasks
      const newTasks = await getTasks(user, project.projectId, tempMembers);

      // 選択ノード & activeMembers
      const selectedTask = newTasks.find((t) => t.id === selectedTaskId);
      if (selectedTask) {
        selectedTask.selected = true;
        await createActiveMember(user, project.projectId, selectedTask.id);
      } else {
        const level1Task = newTasks.find((t) => t.level === 1);
        if (level1Task) {
          level1Task.selected = true;
          await createActiveMember(user, project.projectId, level1Task.id);
        }
      }
      setTasks(newTasks);

      if (computedIsCoediting) {
        // 共同編集時
        db.collection("projects")
          .doc(project.projectId)
          .collection("tasks")
          .withConverter(taskConverter)
          .onSnapshot((snapshot) => {
            let tempTasks = snapshot.docChanges().map((change) => {
              const data = change.doc.data();
              data.changeType = change.type;
              return data;
            });

            if (
              tempTasks.filter((t) => t.changeType === "added").length ===
              newTasks.length
            ) {
              return;
            }
            tempTasks = tempTasks.filter(
              // 削除時は updatedBy を更新できないので、
              // t.updatedBy !== tempUserId
              // だけだと removed が入ってこないパターンがある
              // 例えば、最後に自分が更新したけど、誰かが削除すると、updatedBy が自分になる
              // 処理的には削除は冪等性が保たれるので、removed は常に処理に加えるようにすればOK
              (t) => t.changeType === "removed" || t.updatedBy !== tempUserId
            );
            if (tempTasks.length) {
              setTempUpdatedTasks(tempTasks);
            }
          });
      }
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
        history.push("/news");
      }
    }
  };

  useEffect(() => {
    // 初回ロード時に走るのを防ぐ
    if (!tempUpdatedTasks.length) return;

    setUpdatedTasks(updatedTasks.concat(tempUpdatedTasks));

    // 一定時間経過後に強制アップデートするための処理
    if (forceUpdateTimer) {
      clearTimeout(forceUpdateTimer);
    }
    const timer = setTimeout(() => {
      setForceUpdateCounter(forceUpdateCounter + 1);
    }, 5000);
    setForceUpdateTimer(timer);
  }, [tempUpdatedTasks]);

  useEffect(() => {
    // 初回ロード時に走るのを防ぐ
    if (!tasks.length) return;

    // 強制アップデートに useEffect を使用する理由は
    // setTimeout の中に setTasks(tasks) を仕込むと
    // tasks がその時点での内容になってしまうため
    setTasks(tasks);
  }, [forceUpdateCounter]);

  // Co-edit
  const subscribeActiveMembers = async () => {
    if (!user || !project || !computedIsCoediting) return;

    try {
      db.collection("projects")
        .doc(project.projectId)
        .collection("activeMembers")
        .withConverter(activeMemberConverter)
        .onSnapshot(async (snapshot) => {
          const activeMembers = snapshot.docs.map((d) => d.data());
          const now = new Date();
          now.setMinutes(now.getMinutes() - 5);
          setActiveMembers(activeMembers.filter((m) => m.updatedAt > now));
        });
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
        history.push("/news");
      }
    }
  };

  const [renewActiveMembersCounter, setRenewActiveMembersCounter] = useState(0);

  useEffect(() => {
    if (!user) return;
    const otherMembers = activeMembers.filter((m) => m.userId !== user.userId);
    const labels: ActiveMembersLabel[] = [];
    for (const otherMember of otherMembers) {
      if (!otherMember.currentTaskId) continue;
      const task = displayTasks.find((t) => t.id === otherMember.currentTaskId);
      if (!task) continue;
      const label = labels.find((l) => l.taskId === task.id);
      if (label) {
        label.activeMembers.push(otherMember);
      } else {
        const label: ActiveMembersLabel = {
          taskId: task.id,
          activeMembers: [otherMember],
          top: task.top - activeMemberLabelHeight + 1,
          left: task.left + 2,
        };
        labels.push(label);
      }
    }
    setActiveMembersLabels(labels);
  }, [activeMembers, renewActiveMembersCounter]);

  const renewActiveMembers = () => {
    setRenewActiveMembersCounter(renewActiveMembersCounter + 1);
  };

  const updateSelectedTaskId = async (taskId: string) => {
    if (!user || !taskId) return;
    if (project) {
      setSelectedTaskId(project.projectId, taskId);
      if (computedIsCoediting) {
        try {
          await updateCurrentTask(user.userId, project.projectId, taskId);
        } catch (err) {
          if (err.message === firebase401Message) {
            setUnauthorizedMessage();
            history.push("/news");
          }
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      await filterTasks();
    })();
  }, [tasks]);

  useEffect(() => {
    // tasks がないときはスキップしておかないと
    // フィルターの値の初期値設定で organize まで無駄に動いてしまう
    if (!tasks.length) return;
    // 1. フィルターをかけた時
    // 2. フィルターを解除した時
    // に反応できるようにここでスクロールフラグをON
    setResizeMap(true);
    setOrganizeWithScroll(true);
    (async () => {
      await filterTasks();
    })();
  }, [filterMember, filterStatus, filterDueDate, filterLabelItems]);

  const filterTasks = async () => {
    if (isFiltering) {
      setPassedFilter(
        tasks,
        filterMember,
        filterStatus,
        filterDueDate,
        firstDay,
        filterLabelItems
      );
      const newFilteredTasks = tasks.filter((t) => t.passedFilterWithFamily);
      const selectedTask = tasks.find((t) => t.selected);
      if (selectedTask && selectedTask.passedFilterWithFamily) {
        // 選択されていたノードが表示されているなら何もしない
      } else {
        // 選択されていたノードが表示されない場合
        const level1Task = newFilteredTasks.find((t) => t.level === 1);
        if (level1Task) {
          if (level1Task.filteredChildren.length) {
            // Level1が表示される子供を持っているなら座標上で一番上のノードを選択状態にする
            const selectingId = getSelectingTaskIdInFilteredTasks(level1Task);
            if (selectingId) {
              // tasks 全体で管理しないと選択状態のノードが複数出てきてしまう
              for (const task of tasks) {
                task.selected = task.id === selectingId;
              }
              // localStorage
              await updateSelectedTaskId(selectingId);
              // 直前の useEffect で tasks を引数に入れてないので問題ないはず
              setTasks(tasks);
            }
          } else {
            // 表示するタスクがないので、Level1 のタスクを選択状態にする
            for (const task of tasks) {
              task.selected = task.level === 1;
            }
            await updateSelectedTaskId(level1Task.id);
            setTasks(tasks);
          }
        }
      }
      setDisplayTasks(newFilteredTasks);
    } else {
      setDisplayTasks(tasks);
    }
    organize();
  };

  useEffect(() => {
    /**
     * 1. organize を関数ではなく useEffect にしている理由
     *
     * setTaskTop
     * setTaskTopWithFilter
     * setTaskLeft
     * createLines
     * をする前にレンダリングが終わって、Task.offsetXXX を取得できる状態じゃないと
     * うまく位置を設定できない
     *
     * かつ
     *
     * setTaskTopWithFilter では filtetedChildren を使うので、
     * filterTasks で passedXXX を設定した後でなければならない
     *
     * 2. organize を forceOrganizeCounter にしている理由
     * displayTasks だけだと反応しないパターンがある
     */
    const level1Task = tasks.find((t) => t.level === 1);
    if (!level1Task) return;
    if (isFiltering) {
      setTaskTopWithFilter(0, level1Task);
    } else {
      setTaskTop(0, level1Task);
    }
    setTaskLeft(tasks);

    const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
    const mapHeight = Math.max(...displayTasks.map((t) => t.bottom));
    const mapWidth = Math.max(...displayTasks.map((t) => t.right));
    const unitHeight = mapHeight < windowHeight ? windowHeight : mapHeight;
    const unitWidth = mapWidth < windowWidth ? windowWidth : mapWidth;

    // Set mindmap size
    if (resizeMap) {
      if (mindmapElm.current) {
        mindmapElm.current.style.height = unitHeight * 3 + "px";
        mindmapElm.current.style.width = unitWidth * 3 + "px";
      }
      setResizeMap(false);
      setInitialUnitHeight(unitHeight);
      setInitialUnitWidth(unitWidth);
    }

    // マップとスクロールをずらす
    // 1. マップのサイズ確定後
    // 2. 左上基準に tasks の top, left を決めた状態からずらす
    const selectedTask = tasks.find((t) => t.selected);
    // マップをずらす値はマップのサイズを最初に固定してしまうので
    // その時の最初のマップのサイズを保存しておいて、毎回それだけ分ずらさないと
    // ノードが増えた時に画面がズレる
    const topMargin = resizeMap ? unitHeight : initialUnitHeight;
    const leftMargin = resizeMap ? unitWidth : initialUnitWIdth;
    if (organizeWithScroll) {
      if (selectedTask) {
        const topScrollMargin =
          windowHeight / 2 - selectedTask.top - selectedTask.offsetHeight / 2;
        const leftScrollMargin =
          windowWidth / 2 - selectedTask.left - selectedTask.offsetWidth / 2;
        // 先に上で margin を出しておかないと下でずらした後の値で計算してしまう
        for (const task of tasks) {
          task.setTop(task.top + topMargin);
          task.setLeft(task.left + leftMargin);
        }
        setScroll(unitHeight - topScrollMargin, unitWidth - leftScrollMargin);
      } else {
        const topScrollMargin =
          windowHeight / 2 - level1Task.top - level1Task.offsetHeight / 2;
        const leftScrollMargin =
          windowWidth / 2 - level1Task.left - level1Task.offsetWidth / 2;
        // 先に上で margin を出しておかないと下でずらした後の値で計算してしまう
        for (const task of tasks) {
          task.setTop(task.top + topMargin);
          task.setLeft(task.left + leftMargin);
        }
        setScroll(unitHeight - topScrollMargin, unitWidth - leftScrollMargin);
      }
    } else {
      // スクロールをずらさない
      // ずらす場合と全く同じ処理だが、ずらす場合はマージンの計算が入るため
      // まとめて上に持っていくことはできない
      for (const task of tasks) {
        task.setTop(task.top + topMargin);
        task.setLeft(task.left + leftMargin);
      }
    }

    // tasks の top, left をずらしてから生成しないとダメ
    setLines(createLines(displayTasks));

    // 順序はどうでもいいので最後
    renewActiveMembers();

    // デフォルトで false にしておいて必要な時だけ true にする
    setOrganizeWithScroll(false);
  }, [forceOrganizeCounter]);

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onMindmapMouseMove(event.pageX, event.pageY);
  };

  // Update
  const updateTask = async (
    task: Task,
    updatingTasks: UpdateTask[]
  ): Promise<void> => {
    if (!user || !tempUserId || !projectId) return;

    try {
      if (!computedIsCoediting) {
        // 更新が確認できてからローカルを更新
        setTask(task);
        // ローカルの選択タスクも更新
        setSelectedTaskId(projectId, task.id);
      }
      await batchEditTasks(
        user.userId,
        tempUserId,
        projectId,
        [],
        updatingTasks,
        [],
        task.id
      );
      if (computedIsCoediting) {
        // 更新が確認できてからローカルを更新
        setTask(task);
        // ローカルの選択タスクも更新
        setSelectedTaskId(projectId, task.id);
      }
    } catch (err) {
      // console.error(err);
      // 不整合が起きて更新できなかった場合
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
        history.push("/news");
      } else if (err.message === firebase404Message) {
        if (computedIsCoediting) {
          setTaskDeletedMessage();
          // 更新の場合は removed が飛んできているはずなので特に削除処理は必要ない
          // が、即座に際レンダリングしないとエラーが出てから時間差が生じる
          setTasks(tasks);
        }
      }
    }
  };
  const updateTasks = async (
    newTasks: Task[],
    addingTasks: Task[],
    updatingTasks: UpdateTask[],
    selectingTaskId: string
  ): Promise<void> => {
    if (!user || !tempUserId || !projectId) return;

    try {
      if (!computedIsCoediting) {
        // 更新が確認できてからローカルを更新
        setTasks(newTasks);
        // ローカルの選択タスクも更新
        setSelectedTaskId(projectId, selectingTaskId);
      }
      await batchEditTasks(
        user.userId,
        tempUserId,
        projectId,
        addingTasks,
        updatingTasks,
        [],
        selectingTaskId
      );
      if (computedIsCoediting) {
        // 更新が確認できてからローカルを更新
        setTasks(newTasks);
        // ローカルの選択タスクも更新
        setSelectedTaskId(projectId, selectingTaskId);
      }
    } catch (err) {
      // console.error(err);
      // 不整合が起きて更新できなかった場合
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
        history.push("/news");
      } else if (err.message === firebase404Message) {
        if (computedIsCoediting) {
          setTaskDeletedMessage();
          // 更新の場合は removed が飛んできているはずなので特に削除処理は必要ない
          // が、即座に際レンダリングしないとエラーが出てから時間差が生じる
          setTasks(tasks);
        }
      }
    }
  };

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    const task = tasks.find((t) => t.selected);
    if (!task) return;

    if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
      await onKeyDownWithCtrlAndShift(task, event);
    } else if (event.ctrlKey || event.metaKey) {
      await onKeyDownWithCtrl(task, event);
    } else if (event.shiftKey) {
      await onKeyDownWithShift(task, event);
    } else {
      await onKeyDownPlain(task, event);
    }
  };
  const onKeyDownWithCtrlAndShift = async (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        await moveToEdge(task, "TOP");
        break;
      case "ArrowDown":
        event.preventDefault();
        await moveToEdge(task, "BOTTOM");
        break;
      case ";":
        // Mac用
        event.preventDefault();
        incrementFontSize();
        organize();
        break;
      default:
        break;
    }
  };
  const onKeyDownWithShift = async (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        await addSibling(task, "ABOVE");
        break;
      case "ArrowUp":
        event.preventDefault();
        await shiftMove(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        await shiftMove(task, "DOWN");
        break;
      default:
        break;
    }
  };
  const onKeyDownWithCtrl = async (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Backspace":
        event.preventDefault();
        await deleteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Enter":
        event.preventDefault();
        startEditing(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "ArrowUp":
        event.preventDefault();
        await switchTasks(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        await switchTasks(task, "DOWN");
        break;
      case "[":
        event.preventDefault();
        await outdentTasks(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "a":
        event.preventDefault();
        await startAssigning(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "d":
        event.preventDefault();
        await startSettingDueDate(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "l":
        event.preventDefault();
        await startLabeling(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "c":
        event.preventDefault();
        copyTask();
        break;
      case "x":
        event.preventDefault();
        cutTask();
        break;
      case "v":
        event.preventDefault();
        await pasteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "f":
        event.preventDefault();
        await openOrFocusFilter();
        break;
      case "h":
        event.preventDefault();
        toggleIsSettingsVisible();
        break;
      case "+":
        event.preventDefault();
        incrementFontSize();
        organize();
        break;
      case "-":
        event.preventDefault();
        decrementFontSize();
        organize();
        break;
      default:
        break;
    }
  };
  const onKeyDownPlain = async (
    task: Task,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        await addSibling(task, "BELOW");
        break;
      case "F2":
        event.preventDefault();
        startEditing(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Delete":
        event.preventDefault();
        await deleteTask(task);
        setShiftMoveStartTask(null);
        setContextMenuTask(null);
        break;
      case "Tab":
        event.preventDefault();
        await addNewChild(task);
        break;
      case " ":
        event.preventDefault();
        toggleDone(task);
        break;
      case "ArrowUp":
        event.preventDefault();
        await move(task, "UP");
        break;
      case "ArrowDown":
        event.preventDefault();
        await move(task, "DOWN");
        break;
      case "ArrowLeft":
        event.preventDefault();
        await move(task, "LEFT");
        break;
      case "ArrowRight":
        event.preventDefault();
        await move(task, "RIGHT");
        break;
      default:
        break;
    }
  };

  // ノード追加
  const addNewChild = async (task: Task) => {
    if (tasks.length >= freePlanMaxTaskCount) {
      setTaskCountAlert();
      sendAlertLog("task_count_limit");
      return;
    }
    if (isFiltering && !task.passedFilter && !task.passedFilterWithAncestors) {
      // 子供の追加は自分自身か先祖がフィルター表示対象の場合のみ
      setAddedInvisibleTaskMessage();
      return;
    }

    const { newTasks } = blAddChild(tasks, task);
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
  };
  const addSibling = async (task: Task, direction: "ABOVE" | "BELOW") => {
    if (tasks.length >= freePlanMaxTaskCount) {
      setTaskCountAlert();
      sendAlertLog("task_count_limit");
      return;
    }
    if (isFiltering && !task.passedFilterWithAncestors) {
      setAddedInvisibleTaskMessage();
      return;
    }
    if (!task.parent) return;

    const { newTasks } = blAddSibling(tasks, task, task.parent, direction);
    setTasks(newTasks);
    setShiftMoveStartTask(null);
    setContextMenuTask(null);

    // 空で確定してタスクが削除された場合の戻り先
    setNextTaskIdAfterDeletingNewTask(task.id);
  };

  // ノード削除
  const deleteLocalTask = (task: Task): void => {
    if (task.level === 1 || !user || !tempUserId || !projectId || !task.parent)
      return;

    const targetTasks = [task]
      .concat(tasks.filter((t) => t.multiSelected))
      // multiSelected と selected がダブった時のために重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTasks = targetTasks
      // 自分自身と子孫全てを含んだ配列にする
      .map((t) => [t].concat(t.allChildren))
      // 1つの配列にする
      .flat()
      // 重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTaskIds = deletingTasks.map((t) => t.id);

    if (
      activeMembers.some(
        (m) =>
          m.userId !== user.userId && deletingTaskIds.includes(m.currentTaskId)
      )
    ) {
      setCannotDeleteMessage();
      return;
    }

    const { newTasks, selectingTaskId } = blDeleteTask(
      tasks,
      targetTasks,
      deletingTaskIds,
      task.parent,
      nextTaskIdAfterDeletingNewTask,
      tempUserId
    );
    setTasks(newTasks);
    setSelectedTaskId(projectId, selectingTaskId);
  };
  const deleteTask = async (task: Task): Promise<void> => {
    if (task.level === 1 || !user || !tempUserId || !projectId || !task.parent)
      return;

    const targetTasks = [task]
      .concat(tasks.filter((t) => t.multiSelected))
      // multiSelected と selected がダブった時のために重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTasks = targetTasks
      // 自分自身と子孫全てを含んだ配列にする
      .map((t) => [t].concat(t.allChildren))
      // 1つの配列にする
      .flat()
      // 重複を削除
      .filter((t, i, self) => self.findIndex((tt) => tt.id === t.id) === i);
    const deletingTaskIds = deletingTasks.map((t) => t.id);

    if (
      activeMembers.some(
        (m) =>
          m.userId !== user.userId && deletingTaskIds.includes(m.currentTaskId)
      )
    ) {
      setCannotDeleteMessage();
      return;
    }

    const { newTasks, updatingTasks, selectingTaskId } = blDeleteTask(
      tasks,
      targetTasks,
      deletingTaskIds,
      task.parent,
      nextTaskIdAfterDeletingNewTask,
      tempUserId
    );
    setTasks(newTasks);
    setSelectedTaskId(projectId, selectingTaskId);

    try {
      await batchEditTasks(
        user.userId,
        tempUserId,
        projectId,
        [],
        updatingTasks,
        deletingTasks,
        selectingTaskId
      );
    } catch (err) {
      // console.error(err);
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
        history.push("/news");
      }
    }
  };

  // ノード移動
  const switchTasks = async (task: Task, direction: "UP" | "DOWN") => {
    if (!task.parent) return;

    const parent = task.parent;
    // フィルターによって処理が変わるのはここだけ
    const children = isFiltering ? parent.filteredChildren : parent.children;
    // フィルターの有無関係なく処理するために index で入れ替える
    const sourceIndex = children.findIndex((c) => c.id === task.id);
    if (direction === "UP" && sourceIndex === 0) return;
    if (direction === "DOWN" && sourceIndex === children.length - 1) return;

    const target =
      direction === "UP"
        ? children[sourceIndex - 1]
        : children[sourceIndex + 1];
    if (!target) return;

    const { newTasks, updatingTasks } = blSwitchTasks(
      tasks,
      task,
      target,
      parent,
      tempUserId
    );
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
    await updateTasks(newTasks, [], updatingTasks, task.id);
  };
  const moveToEdge = async (task: Task, direction: "TOP" | "BOTTOM") => {
    if (!task.parent || task.parent.children.length < 2) return;

    const parent = task.parent;
    // フィルターの有無関係なく処理するために index で入れ替える
    const sourceIndex = parent.children.findIndex((c) => c.id === task.id);
    if (direction === "TOP" && sourceIndex === 0) return;
    if (direction === "BOTTOM" && sourceIndex === parent.children.length - 1)
      return;

    const { newTasks, updatingTasks } = blMoveToEdge(
      tasks,
      task,
      parent,
      direction,
      tempUserId
    );
    setShiftMoveStartTask(null);
    setContextMenuTask(null);
    await updateTasks(newTasks, [], updatingTasks, task.id);
  };

  // Copy, Cut, Paste, Outdent
  const copyTask = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = t.selected || t.multiSelected;
        t.cut = false;
        return t;
      })
    );
  };
  const cutTask = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = false;
        t.cut = t.selected || t.multiSelected;
        return t;
      })
    );
  };
  const resetClipboard = () => {
    setTasks(
      tasks.map((t) => {
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  const pasteTask = async (task: Task) => {
    const copiedTasks = tasks.filter((t) => t.copied);
    const cutTasks = tasks.filter((t) => t.cut);
    if (copiedTasks.length) {
      await pasteCopiedTask(task, copiedTasks);
    } else if (cutTasks.length) {
      await pasteCutTask(task, cutTasks);
    } else {
      resetClipboard();
    }
  };
  const pasteCopiedTask = async (target: Task, copiedTasks: Task[]) => {
    if (tasks.length + copiedTasks.length >= freePlanMaxTaskCount) {
      setTaskCountAlert();
      sendAlertLog("task_count_limit");
      return;
    }
    const {
      newTasks,
      addingTasks,
      updatingTasks,
      selectingTaskId,
    } = blPasteCopiedTask(tasks, target, copiedTasks, tempUserId);
    await updateTasks(newTasks, addingTasks, updatingTasks, selectingTaskId);
  };
  const pasteCutTask = async (target: Task, cutTasks: Task[]) => {
    if (cutTasks.some((c) => c.id === target.id)) {
      resetClipboard();
      return;
    }
    const formerParent = cutTasks[0].parent;
    if (!formerParent) return;

    const { newTasks, updatingTasks, selectingTaskId } = blPasteCutTask(
      tasks,
      cutTasks,
      formerParent,
      target,
      tempUserId
    );
    await updateTasks(newTasks, [], updatingTasks, selectingTaskId);
  };
  const outdentTasks = async (task: Task) => {
    if (isFiltering) {
      // 子供の追加は自分自身か先祖がフィルター表示対象の場合のみ
      setAddedInvisibleTaskMessage();
      return;
    }
    const formerParent = task.parent;
    if (!formerParent || formerParent.level === 1) return;

    const nextParent = formerParent.parent;
    if (!nextParent) return;

    const targetChildren = formerParent.children.filter(
      (c) => c.selected || c.multiSelected
    );
    if (!targetChildren.length) return;

    const { newTasks, updatingTasks, selectingTaskId } = blOutdentTasks(
      tasks,
      task,
      targetChildren,
      formerParent,
      nextParent,
      tempUserId
    );
    await updateTasks(newTasks, [], updatingTasks, selectingTaskId);
  };

  // done
  const toggleDone = async (task: Task) => {
    if (!user || task.children.length) return;

    task.done = !task.done;
    if (task.done) {
      task.doneBy = "";
      task.doneAt = null;
    } else {
      task.doneBy = user.name;
      task.doneAt = new Date();
    }
    const updatingTasks: UpdateTask[] = [
      {
        id: task.id,
        params: {
          done: task.done,
          doneBy: task.doneBy,
          doneAt: task.doneAt,
          updatedBy: tempUserId,
          updatedAt: new Date(),
        },
      },
    ];
    await updateTask(task, updatingTasks);
  };

  // edit
  const startEditing = (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = t.id === task.id;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
  };
  /**
   * text 編集終了時の処理
   *
   * 1. text が空でない(削除しない) OR 子供がいる(削除できない)
   *    1. 新規追加なら親兄弟も含めて更新
   *    2. 編集なら自分だけ更新
   * 2. text が空で子供もいない
   *    1. 新規追加ならローカルだけ削除
   *    2. 編集ならDBも削除
   */
  const endEditingText = async (task: Task, text: string): Promise<void> => {
    if (!user || !projectId) return;

    // 先にフォーカスを移さないと ClikcToFocus が一瞬表示されてしまう
    focusMindmap();

    if (text.trim() || task.children.length) {
      // text があるか、子供がいる場合は削除できないので更新処理
      const updatedAt = new Date();
      task.text = text.trim();
      task.selected = true;
      task.isEditing = false;
      if (task.isNew) {
        task.isNew = false;
        // isNew の場合はまだ 新規追加したタスクの親や兄弟をDBに反映してないのでここで更新
        const addingTasks: Task[] = [task];
        const updatingTasks: UpdateTask[] = [];
        if (task.parent) {
          // 親も更新
          updatingTasks.push({
            id: task.parent.id,
            params: {
              childrenIds: task.parent.childrenIds,
              updatedBy: tempUserId,
              updatedAt,
            },
          });
          // addSibling の場合は途中に追加されることもあるので、自分以降の兄弟も更新
          const youngerSiblings = task.parent.children.filter(
            (c) => c.sortNumber > task.sortNumber
          );
          for (const sibling of youngerSiblings) {
            updatingTasks.push({
              id: sibling.id,
              params: {
                sortNumber: sibling.sortNumber,
                updatedBy: tempUserId,
                updatedAt,
              },
            });
          }
        }
        try {
          await batchEditTasks(
            user.userId,
            tempUserId,
            projectId,
            addingTasks,
            updatingTasks,
            [],
            // 新規追加の場合は選択タスクを移動させていないので、ここで更新
            task.id
          );
          // 更新が確認できてからローカルを更新
          setTask(task);
          // ローカルの選択タスクも更新
          setSelectedTaskId(projectId, task.id);
        } catch (err) {
          // console.error(err);
          // 不整合が起きて追加できなかった場合
          if (err.message === firebase401Message) {
            setUnauthorizedMessage();
            history.push("/news");
          } else if (err.message === firebase404Message) {
            setTaskDeletedMessage();
            // 新規追加の場合はローカルのタスクを消す必要がある
            deleteLocalTask(task);
          }
        }
      } else {
        // 既にあった場合は自分だけを更新
        const updatingTasks: UpdateTask[] = [
          {
            id: task.id,
            params: {
              text: task.text,
              updatedBy: tempUserId,
              updatedAt,
            },
          },
        ];
        await updateTask(task, updatingTasks);
      }
    } else if (!task.children.length) {
      // 子供がない場合は削除
      if (task.isNew) {
        // 新規の場合はローカルだけを削除
        deleteLocalTask(task);
      } else {
        // 新規でない場合はDBも更新
        await deleteTask(task);
      }
    }
    // 編集後に戻り先のタスクをリセットしておかないと
    // 別の場所で削除した時に引き継がれてしまう
    setNextTaskIdAfterDeletingNewTask("");
  };

  // settings
  const startAssigning = async (task: Task) => {
    if (!computedIsCoediting) {
      setNotCollaborationProjectWarning();
      return;
    }
    if (!isAssigneeVisible) {
      setAssigningInvisibleWarning();
      return;
    }
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = t.id === task.id;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
    sendTaskSettingLog("open_assignee_setting");
  };
  const endAssigning = async (
    task: Task,
    assignee: User | null,
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.assignee = assignee;
    task.isAssigning = false;
    if (nextCategory === "DueDate") {
      task.isSettingDueDate = true;
    } else if (nextCategory === "Label") {
      task.isLabeling = true;
    }
    const updatingTasks: UpdateTask[] = [
      {
        id: task.id,
        params: {
          assigneeId: assignee ? assignee.userId : "",
          updatedBy: tempUserId,
          updatedAt: new Date(),
        },
      },
    ];
    await updateTask(task, updatingTasks);
  };
  const startSettingDueDate = async (task: Task) => {
    if (!isDueDateVisible) {
      setDueDateInvisibleWarning();
      return;
    }
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = t.id === task.id;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
    sendTaskSettingLog("open_due_date_setting");
  };
  const endSettingDueDate = async (
    task: Task,
    dueDate: Date | null,
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.dueDate = dueDate;
    task.isSettingDueDate = false;
    if (nextCategory === "Assignee") {
      if (computedIsCoediting) {
        task.isAssigning = true;
      } else {
        setNotCollaborationProjectWarning();
      }
    } else if (nextCategory === "Label") {
      task.isLabeling = true;
    }
    const updatingTasks: UpdateTask[] = [
      {
        id: task.id,
        params: {
          dueDate,
          updatedBy: tempUserId,
          updatedAt: new Date(),
        },
      },
    ];
    await updateTask(task, updatingTasks);
  };
  const startLabeling = async (task: Task) => {
    if (!isLabelVisible) {
      setLabelInvisibleWarning();
      return;
    }
    if (project && !project.labels.length) {
      setNoLabelWarning();
      return;
    }
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = t.id === task.id;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
    sendTaskSettingLog("open_label_setting");
  };
  const endLabeling = async (
    task: Task,
    labels: TaskLabel[],
    nextCategory?: SettingCategory
  ) => {
    focusMindmap();
    task.labels = labels;
    task.isLabeling = false;
    if (nextCategory === "Assignee") {
      if (computedIsCoediting) {
        task.isAssigning = true;
      } else {
        setNotCollaborationProjectWarning();
      }
    } else if (nextCategory === "DueDate") {
      task.isSettingDueDate = true;
    }
    const updatingTasks: UpdateTask[] = [
      {
        id: task.id,
        params: {
          labels,
          updatedBy: tempUserId,
          updatedAt: new Date(),
        },
      },
    ];
    await updateTask(task, updatingTasks);
  };

  const toggleIsSettingsVisible = () => {
    setIsSettingsVisible(!isSettingsVisible);
    organize();
  };

  // Select
  const move = async (
    task: Task,
    direction: "UP" | "DOWN" | "RIGHT" | "LEFT"
  ) => {
    const nextTask =
      direction === "UP"
        ? getClosestAboveTask(displayTasks, task)
        : direction === "DOWN"
        ? getClosestBelowTask(displayTasks, task)
        : direction === "RIGHT"
        ? getClosestRightTask(displayTasks, task)
        : // LEFT
          getClosestLeftTask(task);
    if (nextTask) {
      await selectTask(nextTask);
      setShiftMoveStartTask(null);
      setContextMenuTask(null);
      adjustScroll(nextTask);
    }
  };
  const shiftMove = async (task: Task, direction: "UP" | "DOWN") => {
    if (!task.parent) return;

    const nextTask =
      direction === "UP"
        ? getElderTask(task, task.parent, isFiltering)
        : getYoungerTask(task, task.parent, isFiltering);
    if (!nextTask) {
      setDifferentParentMessage();
      return;
    }

    if (shiftMoveStartTask) {
      await shiftSelectTask(shiftMoveStartTask, nextTask);
    } else {
      // 最初のノード
      setShiftMoveStartTask(task);
      await shiftSelectTask(task, nextTask);
    }
    setContextMenuTask(null);
  };
  const shiftSelectTask = async (startTask: Task, endTask: Task) => {
    setTasks(
      tasks.map((t) => {
        t.selected = t.id === endTask.id;
        if (
          t.parentId === startTask.parentId &&
          // フィルタリング中でないか、フィルタリング中なら表示されていることが条件
          (!isFiltering || t.passedFilterWithFamily) &&
          ((t.sortNumber >= startTask.sortNumber &&
            t.sortNumber <= endTask.sortNumber) ||
            (t.sortNumber <= startTask.sortNumber &&
              t.sortNumber >= endTask.sortNumber))
        ) {
          t.multiSelected = true;
        } else {
          t.multiSelected = false;
        }
        return t;
      })
    );
    await updateSelectedTaskId(endTask.id);
  };
  const selectTask = async (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.isSetting = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        return t;
      })
    );
    await updateSelectedTaskId(task.id);
  };
  const onClickTask = async (
    event: React.MouseEvent<HTMLDivElement>,
    task: Task
  ) => {
    if (event.shiftKey) {
      if (shiftMoveStartTask) {
        if (shiftMoveStartTask.parentId === task.parentId) {
          // Shift移動開始したタスクと同じ親を持つ場合にだけ可能
          await shiftSelectTask(shiftMoveStartTask, task);
        } else {
          setDifferentParentMessage();
        }
      } else {
        // Shift移動をまだ開始してない場合
        const selectedTask = tasks.find((t) => t.selected);
        if (selectedTask && selectedTask.parentId === task.parentId) {
          // 選択されているタスクと同じ親を持つ場合にだけ可能
          await shiftSelectTask(selectedTask, task);
          // Shift移動開始タスクも同時にセットしておく
          setShiftMoveStartTask(selectedTask);
        } else {
          setDifferentParentMessage();
        }
      }
    } else if (event.ctrlKey || event.metaKey) {
      await ctrlSelectTask(task);
      setShiftMoveStartTask(null);
    } else {
      await selectTask(task);
      setShiftMoveStartTask(null);
    }
    setContextMenuTask(null);
  };
  const ctrlSelectTask = async (task: Task) => {
    if (task.selected) {
      // 選択状態のタスクの場合
      const multiSelectedTask = tasks.find((t) => t.multiSelected);
      if (multiSelectedTask) {
        // 他に複数選択状態のタスクがあれば適当に選択状態を移動
        multiSelectedTask.selected = true;
        task.selected = false;
        setTasks(
          tasks.map((t) => {
            if (t.id === task.id) return task;
            if (t.id === multiSelectedTask.id) return multiSelectedTask;
            return t;
          })
        );
        await updateSelectedTaskId(multiSelectedTask.id);
      } else {
        // 複数選択状態のタスクがない場合は選択状態のタスクがなくなるとまずいので何もしない
      }
    } else {
      const selectedTask = tasks.find((t) => t.selected);
      if (!selectedTask) return;
      if (selectedTask.parentId !== task.parentId) {
        setDifferentParentMessage();
        return;
      }
      // selected を移動させる
      selectedTask.selected = false;
      selectedTask.multiSelected = true;
      task.selected = true;
      setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
      await updateSelectedTaskId(task.id);
    }
  };

  // Context Menu
  const onContextMenu = async (
    event: React.MouseEvent<HTMLDivElement>,
    task: Task
  ) => {
    event.preventDefault();
    const bounds = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    setContextMenuTop(task.top + y - 3); // translateY分の3
    setContextMenuLeft(task.left + x);
    setContextMenuTask(task);
    await selectTask(task);
  };
  const onClickContextMenuOpenSettings = () => {
    if (!contextMenuTask) return;
    startSettings(contextMenuTask);
    setContextMenuTask(null);
  };
  const startSettings = async (task: Task) => {
    setTasks(
      tasks.map((t) => {
        t.isEditing = false;
        t.isSetting = t.id === task.id;
        t.isAssigning = false;
        t.isSettingDueDate = false;
        t.isLabeling = false;
        t.selected = t.id === task.id;
        t.multiSelected = false;
        t.copied = false;
        t.cut = false;
        return t;
      })
    );
    adjustScroll(task);
    sendTaskSettingLog("open_total_settings");
  };
  const endSettings = async (
    task: Task,
    assignee: User | null,
    dueDate: Date | null,
    labels: TaskLabel[]
  ) => {
    focusMindmap();
    task.assignee = assignee;
    task.dueDate = dueDate;
    task.labels = labels;
    task.isSetting = false;

    const updatingTasks: UpdateTask[] = [
      {
        id: task.id,
        params: {
          assigneeId: assignee ? assignee.userId : "",
          dueDate,
          labels,
          updatedBy: tempUserId,
          updatedAt: new Date(),
        },
      },
    ];
    await updateTask(task, updatingTasks);
  };
  const onClickContextMenuAddChild = async () => {
    if (!contextMenuTask) return;
    await addNewChild(contextMenuTask);
  };
  const onClickContextMenuAddTaskBelow = async () => {
    if (!contextMenuTask) return;
    await addSibling(contextMenuTask, "BELOW");
  };
  const onClickContextMenuAddTaskAbove = async () => {
    if (!contextMenuTask) return;
    await addSibling(contextMenuTask, "ABOVE");
  };

  const onMindmapMouseDownLocal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    contextMenuTask && setContextMenuTask(null);
    onMindmapMouseDown(event);
  };

  return (
    <MapContext.Provider
      value={{
        focusMindmap,
        project,
        isCoediting: computedIsCoediting,
        isHelpOpen,
        setIsHelpOpen,
        members,
        firstDay,
        activeMembers,
        setActiveMembers,
        setTask,
        isSettingsVisible,
        toggleIsSettingsVisible,
        openOrFocusFilter,
        closeFilter,
        focusFilterCounter,
        filterMember,
        changeFilterMember,
        filterStatus,
        changeFilterStatus,
        filterDueDate,
        changeFilterDueDate,
        filterLabelItems,
        toggleFilterLabelItem,
        resetFilter,
        adjustScroll,
      }}
    >
      <Container
        ref={mindmapElm}
        tabIndex={mindmapTabIndex}
        isGrabbing={isGrabbingMindmap}
        isClickToFocusDisabled={isClickToFocusDisabled}
        color={color}
        onKeyDown={onKeyDown}
        onMouseDown={onMindmapMouseDownLocal}
        onMouseOut={onMindmapMouseOut}
        onMouseMove={onMouseMove}
        onMouseUp={onMindmapMouseUp}
      >
        {project &&
          displayTasks.map((t) => (
            <ComponentTask
              key={t.id}
              project={project}
              task={t}
              onClick={onClickTask}
              onContextMenu={onContextMenu}
              toggleDone={toggleDone}
              endEditingText={endEditingText}
              endSettings={endSettings}
              endAssigning={endAssigning}
              endSettingDueDate={endSettingDueDate}
              endLabeling={endLabeling}
            />
          ))}
        {contextMenuTask ? (
          <ContextMenu
            borderColor={
              palette[(contextMenuTask.topSortNumber - 1) % palette.length]
            }
            top={contextMenuTop}
            left={contextMenuLeft}
            onClickContextMenuOpenSettings={onClickContextMenuOpenSettings}
            onClickContextMenuAddChild={onClickContextMenuAddChild}
            onClickContextMenuAddTaskBelow={onClickContextMenuAddTaskBelow}
            onClickContextMenuAddTaskAbove={onClickContextMenuAddTaskAbove}
          />
        ) : (
          false
        )}
        {lines.map((l) => (
          <ComponentLine
            key={l.startTask.id + l.endTask.id}
            startX={l.startX}
            startY={l.startY}
            endX={l.endX}
            endY={l.endY}
            lineStrokeColor={
              l.startTask.isCutWithAncestors
                ? node.cut.borderColor
                : palette[(l.endTask.topSortNumber - 1) % palette.length]
            }
          />
        ))}
        {activeMembersLabels.map((l) => (
          <ComponentActiveMembersLabel key={"label-" + l.taskId} label={l} />
        ))}
        <ActiveMembersComponent />
        <Toolbox />
        {project && (
          <Filter
            isOpen={isFilterOpen}
            bottom={filterBottom}
            right={filterRight}
            labels={project.labels}
            visibility={project.visibility}
            isCoediting={computedIsCoediting}
            members={members}
            openOrFocusFilter={openOrFocusFilter}
            closeFilter={closeFilter}
            focusFilterCounter={focusFilterCounter}
            filterMember={filterMember}
            changeFilterMember={changeFilterMember}
            filterStatus={filterStatus}
            changeFilterStatus={changeFilterStatus}
            filterDueDate={filterDueDate}
            changeFilterDueDate={changeFilterDueDate}
            filterLabelItems={filterLabelItems}
            toggleFilterLabelItem={toggleFilterLabelItem}
          />
        )}
        <FilteringItems
          bottom={labelItemsBottom}
          left={labelItemsLeft}
          isSettingsVisible={isSettingsVisible}
          toggleIsSettingsVisible={toggleIsSettingsVisible}
          filterMember={filterMember}
          changeFilterMember={changeFilterMember}
          filterStatus={filterStatus}
          changeFilterStatus={changeFilterStatus}
          filterDueDate={filterDueDate}
          changeFilterDueDate={changeFilterDueDate}
          filterLabelItems={filterLabelItems}
          toggleFilterLabelItem={toggleFilterLabelItem}
          resetFilter={resetFilter}
        />
        <Help />
        <ClickToFocus className="click-to-focus">
          {c("clickToFocus")}
        </ClickToFocus>
      </Container>
    </MapContext.Provider>
  );
};

type ContainerProps = {
  isGrabbing: boolean;
  isClickToFocusDisabled: boolean;
  color: string;
};

const Container = styled.div<ContainerProps>`
  min-width: 100vw;
  min-height: 100vh;
  color: ${({ color }) => color};

  position: relative;
  cursor: ${({ isGrabbing }) => (isGrabbing ? "grabbing" : "grab")};
  outline: none;

  /* focus してない時は編集時、設定時なら none */
  div.click-to-focus {
    display: ${({ isClickToFocusDisabled }) =>
      isClickToFocusDisabled ? "none" : ""};
  }
  /* focus 時は常に none */
  :focus {
    div.click-to-focus {
      display: none;
    }
  }
`;

export default ComponentProject;
