import { TaskLabel } from "./Label";
import { User } from "../models/User";

export interface ITask {
  id: string;
  level: number;
  sortNumber: number;
  parentId: string;
  childrenIds: string[];
  text: string;
  done: boolean;
  doneBy: string;
  doneAt: Date | null;
  assigneeId: string;
  dueDate: Date | null;
  labels: TaskLabel[];

  // 共同編集用
  updatedBy?: string;
  updatedAt?: Date | null;

  // localのみ
  changeType: "" | "added" | "modified" | "removed";
}
export interface UpdateTask {
  id: string;
  params: {
    level?: number;
    sortNumber?: number;
    parentId?: string;
    childrenIds?: string[];
    text?: string;
    done?: boolean;
    doneBy?: string;
    doneAt?: Date | null;
    assigneeId?: string;
    dueDate?: Date | null;
    labels?: TaskLabel[];

    // 共同編集用
    updatedBy: string;
    updatedAt: Date | null;
  };
}

const getAllChildren = (task: Task): Task[] => {
  const children: Task[] = [];
  if (task.children.length) {
    for (const child of task.children) {
      children.push(child);
      Array.prototype.push.apply(children, getAllChildren(child));
    }
  }
  return children.filter(
    (c, i, self) => self.findIndex((cc) => cc.id === c.id) === i
  );
};

const getAllFilteredChildren = (task: Task): Task[] => {
  const filteredChildren = task.filteredChildren;
  const children: Task[] = [];
  if (filteredChildren.length) {
    for (const filteredChild of filteredChildren) {
      children.push(filteredChild);
      Array.prototype.push.apply(
        children,
        getAllFilteredChildren(filteredChild)
      );
    }
  }
  return children.filter(
    (c, i, self) => self.findIndex((cc) => cc.id === c.id) === i
  );
};

export class Task {
  id: string;
  level: number;
  sortNumber: number;
  parent: Task | null;
  children: Task[];
  text: string;
  top: number;
  left: number;
  done: boolean;
  doneBy: string;
  doneAt: Date | null;
  assignee: User | null;
  dueDate: Date | null;
  labels: TaskLabel[];
  passedMemberFilter: boolean;
  passedMemberFilterWithAncestors: boolean;
  passedMemberFilterWithDescendants: boolean;
  passedStatusFilter: boolean;
  passedDueDateFilter: boolean;
  passedDueDateFilterWithAncestors: boolean;
  passedDueDateFilterWithDescendants: boolean;
  passedLabelFilter: boolean;
  passedLabelFilterWithAncestors: boolean;
  passedLabelFilterWithDescendants: boolean;
  isNew: boolean;
  isEditing: boolean;
  isSetting: boolean;
  isAssigning: boolean;
  isSettingDueDate: boolean;
  isLabeling: boolean;
  selected: boolean;
  multiSelected: boolean;
  copied: boolean;
  cut: boolean;
  marginBetweenGrabbedYAndTop: number;
  marginBetweenGrabbedXAndLeft: number;
  elm: HTMLDivElement | null;

  constructor(
    id: string,
    level: number,
    sortNumber: number,
    parent: Task | null,
    children: Task[],
    text: string,
    done: boolean,
    doneBy: string,
    doneAt: Date | null,
    assignee: User | null,
    dueDate: Date | null,
    labels: TaskLabel[]
  ) {
    this.id = id;
    this.level = level;
    this.sortNumber = sortNumber;
    this.parent = parent;
    this.children = children;
    this.text = text;
    this.top = 0;
    this.left = 0;
    this.done = done;
    this.doneBy = doneBy;
    this.doneAt = doneAt;
    this.assignee = assignee;
    this.dueDate = dueDate;
    this.labels = labels;
    this.passedMemberFilter = false;
    this.passedMemberFilterWithAncestors = false;
    this.passedMemberFilterWithDescendants = false;
    this.passedStatusFilter = false;
    this.passedDueDateFilter = false;
    this.passedDueDateFilterWithAncestors = false;
    this.passedDueDateFilterWithDescendants = false;
    this.passedLabelFilter = false;
    this.passedLabelFilterWithAncestors = false;
    this.passedLabelFilterWithDescendants = false;
    this.isNew = false;
    this.isEditing = false;
    this.isSetting = false;
    this.isAssigning = false;
    this.isSettingDueDate = false;
    this.isLabeling = false;
    this.selected = false;
    this.multiSelected = false;
    this.copied = false;
    this.cut = false;
    this.marginBetweenGrabbedYAndTop = 0;
    this.marginBetweenGrabbedXAndLeft = 0;
    this.elm = null;
  }

  get allChildren(): Task[] {
    return getAllChildren(this);
  }
  get parentId(): string {
    return this.parent ? this.parent.id : "";
  }
  get childrenIds(): string[] {
    return this.children.map((c) => c.id);
  }
  get assigneeId(): string {
    return this.assignee ? this.assignee.userId : "";
  }
  get siblings(): Task[] {
    if (!this.parent) return [];
    return this.parent.children.filter((c) => c.id !== this.id);
  }

  // filtered
  get passedFilter(): boolean {
    return (
      this.passedMemberFilter &&
      this.passedStatusFilter &&
      this.passedDueDateFilter &&
      this.passedLabelFilter
    );
  }
  get passedFilterWithAncestors(): boolean {
    return (
      this.passedMemberFilterWithAncestors &&
      this.passedDueDateFilterWithAncestors &&
      this.passedLabelFilterWithAncestors
    );
  }
  get passedFilterWithFamily(): boolean {
    return (
      (this.passedMemberFilter ||
        this.passedMemberFilterWithAncestors ||
        this.passedMemberFilterWithDescendants) &&
      this.passedStatusFilter &&
      (this.passedDueDateFilter ||
        this.passedDueDateFilterWithAncestors ||
        this.passedDueDateFilterWithDescendants) &&
      (this.passedLabelFilter ||
        this.passedLabelFilterWithAncestors ||
        this.passedLabelFilterWithDescendants)
    );
  }
  get filteredChildren(): Task[] {
    return this.children.filter((c) => c.passedFilterWithFamily);
  }

  // done
  get doneConsideringDescendants(): boolean {
    return this.children.length
      ? this.children.every((c) => c.doneConsideringDescendants)
      : this.done;
  }

  // copied & cut
  get isCopiedWithAncestors(): boolean {
    if (this.copied) return true;
    if (this.parent) {
      return this.parent.isCopiedWithAncestors;
    }
    return false;
  }
  get isCutWithAncestors(): boolean {
    if (this.cut) return true;
    if (this.parent) {
      return this.parent.isCutWithAncestors;
    }
    return false;
  }

  // size
  setTop(top: number): void {
    this.top = top;
  }
  setLeft(left: number): void {
    this.left = left;
  }
  get offsetWidth(): number {
    return this.elm ? this.elm.offsetWidth : 0;
  }
  get offsetHeight(): number {
    return this.elm ? this.elm.offsetHeight : 0;
  }
  get bottom(): number {
    return this.top + this.offsetHeight;
  }
  // position
  get verticalCenter(): number {
    return this.top + this.offsetHeight / 2;
  }
  get horizontalCenter(): number {
    return this.left + this.offsetWidth / 2;
  }
  get right(): number {
    return this.left + this.offsetWidth;
  }
  get lineStartX(): number {
    return this.left + this.offsetWidth;
  }
  get lineStartY(): number {
    return this.top + this.offsetHeight / 2;
  }
  get lineEndX(): number {
    return this.left;
  }
  get lineEndY(): number {
    return this.top + this.offsetHeight / 2;
  }
  get topConsideringChildren(): number {
    const children = this.children;
    if (children.length) {
      const childrenHeight =
        children[children.length - 1].bottom - children[0].top;
      return children[0].top + childrenHeight / 2 - this.offsetHeight / 2;
    } else {
      return this.top;
    }
  }
  get topConsideringChildrenWithFilter(): number {
    const children = this.filteredChildren;
    if (children.length) {
      const childrenHeight =
        children[children.length - 1].bottom - children[0].top;
      return children[0].top + childrenHeight / 2 - this.offsetHeight / 2;
    } else {
      return this.top;
    }
  }
  get bottomWithDescendatns(): number {
    if (!this.children.length) {
      return this.bottom;
    }
    const allChildren = getAllChildren(this);
    const childrenBottom = Math.max(...allChildren.map((c) => c.bottom));
    return childrenBottom > this.bottom ? childrenBottom : this.bottom;
  }
  get bottomWithDescendatnsWithFilter(): number {
    const children = this.filteredChildren;
    if (!children.length) {
      return this.bottom;
    }
    const allFilteredChildren = getAllFilteredChildren(this);
    const childrenBottom = Math.max(
      ...allFilteredChildren.map((c) => c.bottom)
    );
    return childrenBottom > this.bottom ? childrenBottom : this.bottom;
  }

  includes(x: number, y: number): boolean {
    return this.left < x && x < this.right && this.top < y && y < this.bottom;
  }

  has(childId: string): boolean {
    return this.children.some((c) => c.id === childId);
  }

  hasSameParents(task: Task): boolean {
    return task.parent && this.parent && task.parent.id === this.parent.id
      ? true
      : false;
  }

  overlaps(left: number, right: number): boolean {
    if (this.right < left || right < this.left) return false;
    return true;
  }

  get topSortNumber(): number {
    if (this.level <= 2) return this.sortNumber;
    let parent = this.parent;
    for (let i = 1; i < this.level - 2; i++) {
      parent = parent ? parent.parent : null;
    }
    return parent ? parent.sortNumber : 0;
  }

  getNextSortNumber(): number {
    if (this.children.length) {
      return this.children[this.children.length - 1].sortNumber + 1;
    } else {
      return 1;
    }
  }

  toItem(tempUserId: string): ITask {
    return {
      id: this.id,
      level: this.level,
      sortNumber: this.sortNumber,
      parentId: this.parentId,
      childrenIds: this.childrenIds,
      text: this.text,
      done: this.done,
      doneBy: this.doneBy,
      doneAt: this.doneAt,
      assigneeId: this.assignee ? this.assignee.userId : "",
      dueDate: this.dueDate,
      labels: this.labels,
      changeType: "",
      updatedBy: tempUserId,
      updatedAt: new Date(),
    };
  }
}
