import React from "react";
import { useTranslation } from "react-i18next";
import { ClearRounded } from "@material-ui/icons";
import styled from "styled-components";
import { FlexRowCenter } from "../StyledComponents";
import FilteringItem from "./FilteringItem";
import useStyles from "../../hooks/useStyles";
import { User } from "../../models/User";
import { LabelItem } from "../../models/Label";
import { FilterStatus, FilterDueDate } from "../../models/Filter";
import { filterItemsZIndex } from "../../constants";

type Props = {
  bottom: number;
  left: number;
  isSettingsVisible: boolean;
  toggleIsSettingsVisible: () => void;
  filterMember: User | null;
  changeFilterMember: (member: User | null) => void;
  filterStatus: FilterStatus;
  changeFilterStatus: (filterStatus: FilterStatus) => void;
  filterDueDate: FilterDueDate;
  changeFilterDueDate: (filterDueDate: FilterDueDate) => void;
  filterLabelItems: LabelItem[];
  toggleFilterLabelItem: (item: LabelItem) => void;
  resetFilter: (resetLocalStorage: boolean) => void;
};

const FilterItems: React.FC<Props> = React.memo(
  ({
    bottom,
    left,
    isSettingsVisible,
    toggleIsSettingsVisible,
    filterMember,
    changeFilterMember,
    filterStatus,
    changeFilterStatus,
    filterDueDate,
    changeFilterDueDate,
    filterLabelItems,
    toggleFilterLabelItem,
    resetFilter,
  }) => {
    // hooks
    const { t: s } = useTranslation("filterStatus");
    const { t: d } = useTranslation("filterDueDate");
    const { t: l } = useTranslation("labels");
    const { fontSize, filter } = useStyles();

    const onClickFilterMember = () => {
      changeFilterMember(null);
    };

    const onClickFilterStatus = () => {
      changeFilterStatus("all");
    };

    const onClickFilterDueDate = () => {
      changeFilterDueDate("none");
    };

    const onClickLabelItems = (item: LabelItem) => {
      toggleFilterLabelItem(item);
    };

    const onClickReset = () => {
      resetFilter(true);
    };

    return (
      <Container fontSize={fontSize} bottom={bottom} left={left}>
        {!isSettingsVisible && (
          <FilteringItem
            color={filter.item.selected.color}
            bgColor={filter.item.selected.bgColor}
            onClick={toggleIsSettingsVisible}
          >
            <DeleteButton linecolor={filter.item.selected.color} />
            {l("onlyText")}
          </FilteringItem>
        )}
        {filterStatus !== "all" ||
        filterDueDate !== "none" ||
        filterLabelItems.length ? (
          <FilteringItem
            color={filter.item.selected.color}
            bgColor={filter.item.selected.bgColor}
            onClick={onClickReset}
          >
            <DeleteButton linecolor={filter.item.selected.color} />
            {l("reset")}
          </FilteringItem>
        ) : (
          false
        )}
        {filterStatus !== "all" && (
          <FilteringItem
            color={filter.item.selected.color}
            bgColor={filter.item.selected.bgColor}
            onClick={onClickFilterStatus}
          >
            <DeleteButton linecolor={filter.item.selected.color} />
            {s(filterStatus)}
          </FilteringItem>
        )}
        {filterMember && (
          <FilteringItem
            color={filter.item.selected.color}
            bgColor={filter.item.selected.bgColor}
            onClick={onClickFilterMember}
          >
            <DeleteButton linecolor={filter.item.selected.color} />
            {filterMember.name}
          </FilteringItem>
        )}
        {filterDueDate !== "none" && (
          <FilteringItem
            color={filter.item.selected.color}
            bgColor={filter.item.selected.bgColor}
            onClick={onClickFilterDueDate}
          >
            <DeleteButton linecolor={filter.item.selected.color} />
            {d(filterDueDate)}
          </FilteringItem>
        )}
        {filterLabelItems.map((li) => (
          <FilteringItem
            key={li.labelItemId}
            color={li.color}
            bgColor={li.backgroundColor}
            onClick={() => onClickLabelItems(li)}
          >
            <DeleteButton linecolor={li.color} />
            {li.name}
          </FilteringItem>
        ))}
      </Container>
    );
  }
);
FilterItems.displayName = "FilterItems";

type ContainerProps = {
  fontSize: number;
  bottom: number;
  left: number;
};

const Container = styled(FlexRowCenter)<ContainerProps>`
  font-size: ${({ fontSize }) => fontSize}px;
  position: fixed;
  bottom: ${({ bottom }) => bottom}px;
  left: ${({ left }) => left}px;
  z-index: ${filterItemsZIndex};

  > div {
    margin-right: 8px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

const DeleteButton = styled(ClearRounded)<{ linecolor: string }>`
  color: ${({ linecolor }) => linecolor};
  cursor: pointer;
  margin-right: 4px;
`;

export default FilterItems;
