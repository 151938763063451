import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MapContext from "./context";
import useStyles from "../../hooks/useStyles";
import {
  activeMembersTop,
  activeMembersRight,
  toolboxZIndex,
} from "../../constants";
import { FlexColumnCenter } from "../../components/StyledComponents";

const ActiveMembersConponent: React.FC = React.memo(() => {
  // context
  const { isCoediting, activeMembers } = useContext(MapContext);
  // hooks
  const { t: a } = useTranslation("activeMember");
  const { color } = useStyles();

  if (isCoediting) {
    return (
      <Container>
        <Header>{a("activeMembers")}</Header>
        {
          <Members width={40 + 20 * (activeMembers.length - 1)}>
            {activeMembers.map((m, i) => (
              <Member
                key={m.userId}
                alt={m.name}
                src={m.image ? m.image : ""}
                color={color}
                left={5 + 20 * i}
                zIndex={activeMembers.length - i}
              />
            ))}
          </Members>
        }
      </Container>
    );
  } else {
    return <></>;
  }
});
ActiveMembersConponent.displayName = "ActiveMembers";

const Container = styled(FlexColumnCenter)`
  position: fixed;
  top: ${activeMembersTop}px;
  right: ${activeMembersRight}px;
  cursor: default;
  z-index: ${toolboxZIndex};
`;

const Header = styled.div`
  line-height: 13px;
  font-size: 13px;
  margin-bottom: 2px;
`;

const Members = styled.div<{ width: number }>`
  height: 100%;
  width: ${({ width }) => width}px;

  position: relative;
  display: flex;
  align-items: center;

  user-select: none;
`;

const Member = styled.img<{ color: string; left: number; zIndex: number }>`
  width: 31px;
  height: 31px;
  position: absolute;
  border: solid 2px ${({ color }) => color};
  border-radius: 20px;
  top: 0;
  left: ${({ left }) => left}px;
  z-index: ${({ zIndex }) => zIndex};
`;

export default ActiveMembersConponent;
