import { v4 as uuidv4 } from "uuid";

export const issueId = (): string => {
  return uuidv4().replace(/-/g, "");
};

/**
 * 時刻が 00:00:00 の現在 Date を取得
 */
export const getOclockDate = (): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};
