import styled from "styled-components";

type ButtonProps = {
  color: string;
  bgColor: string;
  selected?: boolean;
};
const OutlinedButton = styled.div<ButtonProps>`
  color: ${({ color, bgColor, selected }) => (selected ? color : bgColor)};
  background-color: ${({ bgColor, selected }) =>
    selected ? bgColor : "transparent"};
  padding: 4px 8px;
  border: solid 1px ${({ bgColor }) => bgColor};
  border-radius: 8px;
  cursor: pointer;
  outline: none;

  :hover {
    color: ${({ color }) => color};
    background-color: ${({ bgColor }) => bgColor};
    box-shadow: 0 0 10px ${({ bgColor }) => bgColor};
  }
`;
export default OutlinedButton;
