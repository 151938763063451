import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import SettingContainer from "../components/SettingContainer";
import SignInWithButton from "../components/Button/SignInWithButton";
import Context from "../context";

const SigninPage: React.FC = () => {
  const { user } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.push("/news");
    }
  }, [user]);

  return (
    <Conatiner>
      <SignInWithButton provider="Google" margin="0px 0px 12px 0px" />
      <SignInWithButton provider="Twitter" margin="0px 0px 12px 0px" />
      <SignInWithButton provider="Github" margin="0px 0px 12px 0px" />
    </Conatiner>
  );
};

const Conatiner = styled(SettingContainer)`
  > button {
    margin-bottom: 12px;
  }
  > button:last-child {
    margin-bottom: 0px;
  }
`;

export default SigninPage;
