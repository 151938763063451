import React from "react";
import styled from "styled-components";
import useStyles from "../hooks/useStyles";
import { baseBorderRadius } from "../constants";

type Props = {
  index: number;
  selected: boolean;
  flexDirection?: string;
  justifyContent?: string;
  cursor?: string;
  onClick?: () => void | Promise<void>;
};
const MenuItem: React.FC<Props> = React.memo(
  ({
    children,
    index,
    selected,
    flexDirection,
    justifyContent,
    cursor,
    onClick,
  }) => {
    const { palette, menuItem } = useStyles();

    const selectedBgColor =
      menuItem.selected.mode === "designated"
        ? menuItem.selected.bgColor
        : palette[index % palette.length];
    const boxShadow = menuItem.boxShadow
      ? menuItem.boxShadow
      : `0 0 10px ${menuItem.borderColor}`;

    return (
      <Container
        selected={selected}
        color={menuItem.color}
        bgColor={menuItem.bgColor}
        selectedColor={menuItem.selected.color}
        selectedBgColor={selectedBgColor}
        borderColor={menuItem.borderColor}
        boxShadow={boxShadow}
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        cursor={cursor}
        onClick={onClick}
      >
        {children}
      </Container>
    );
  }
);
MenuItem.displayName = "MenuItem";

type ContainerProps = {
  selected: boolean;
  color: string;
  bgColor: string;
  selectedColor: string;
  selectedBgColor: string;
  borderColor: string;
  boxShadow: string;
  flexDirection?: string;
  justifyContent?: string;
  cursor?: string;
};
const Container = styled.li<ContainerProps>`
  line-height: 26px;
  color: ${({ selected, color, selectedColor }) =>
    selected ? selectedColor : color};
  background-color: ${({ selected, bgColor, selectedBgColor }) =>
    selected ? selectedBgColor : bgColor};
  opacity: 0.9;
  padding: 5px 12px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;

  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};

  list-style: none;
  user-select: none;
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};

  transition: color 100ms ease-out, background-color 100ms ease-out,
    box-shadow 100ms ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;
export default MenuItem;
