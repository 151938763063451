import { Task } from "../../models/Task";

export const sortChildren = (tasks: Task[]): void => {
  for (const task of tasks) {
    task.children.sort((a, b) => a.sortNumber - b.sortNumber);
  }
};

/**
 * ソートしない
 */
export const asignChildrenSortNumber = (parent: Task): void => {
  for (let i = 0; i < parent.children.length; i++) {
    const child = parent.children[i];
    child.sortNumber = i + 1;
  }
};

/**
 * ソートする
 * sortNumber がおかしくなった時の保険
 */
export const reasignChildrenSortNumber = (parent: Task): void => {
  parent.children.sort((a, b) => a.sortNumber - b.sortNumber);
  for (let i = 0; i < parent.children.length; i++) {
    const child = parent.children[i];
    child.sortNumber = i + 1;
  }
};
