import { User } from "../../models/User";
import { Task } from "../../models/Task";
import { Label } from "../../models/Label";
import { issueId, getOclockDate } from "../../bl/common";
import { lineWidth } from "../../constants";
import { mindmapHorizontalPadding } from "./constants";

export const setTaskLeft = (tasks: Task[]): void => {
  const maxLevel = Math.max(...tasks.map((t) => t.level));
  for (let level = 1; level <= maxLevel; level++) {
    const levelTasks = tasks.filter((t) => t.level === level);
    for (const levelTask of levelTasks) {
      if (level === 1) {
        levelTask.setLeft(mindmapHorizontalPadding);
      }
      if (!levelTask.parent) continue;
      levelTask.setLeft(levelTask.parent.right + lineWidth);
    }
  }
};

export const createSampleDate = (
  userName: string,
  member1Name: string,
  member2Name: string,
  priorityText: string,
  highText: string,
  mediumText: string,
  lowText: string,
  topText: string,
  designText: string,
  logoText: string,
  mapText: string,
  userInterfaceText: string,
  keyboardShotcutsText: string,
  mouseActionsText: string,
  policiesText: string
): {
  user: User;
  members: User[];
  labels: Label[];
  newTasks: Task[];
} => {
  // user
  const user: User = {
    userId: "user",
    language: "en",
    name: userName,
    email: "",
    image: "",
    organizaitonIds: [],
    allowEmailSearch: true,
  };

  // members
  const member1: User = {
    userId: "member1",
    language: "en",
    name: member1Name,
    email: "",
    image: "",
    organizaitonIds: [],
    allowEmailSearch: true,
  };
  const member2: User = {
    userId: "member2",
    language: "en",
    name: member2Name,
    email: "",
    image: "",
    organizaitonIds: [],
    allowEmailSearch: true,
  };
  const members = [user, member1, member2];

  // labels
  const labels: Label[] = [
    {
      labelId: "priority",
      displayOrder: 1,
      name: priorityText,
      labelItems: [
        {
          labelItemId: "low",
          sortOrder: 1,
          name: lowText,
          color: "#ffffff",
          backgroundColor: "#ffc069",
        },
        {
          labelItemId: "medium",
          sortOrder: 1,
          name: mediumText,
          color: "#ffffff",
          backgroundColor: "#ff9c6e",
        },
        {
          labelItemId: "high",
          sortOrder: 1,
          name: highText,
          color: "#ffffff",
          backgroundColor: "#ff7875",
        },
      ],
    },
  ];

  // tasks
  const top = new Task(
    issueId(),
    1,
    1,
    null,
    [],
    topText,
    false,
    "",
    null,
    null,
    null,
    []
  );

  const design = new Task(
    issueId(),
    2,
    1,
    top,
    [],
    designText,
    false,
    "",
    null,
    null,
    null,
    []
  );
  const logoDueDate = getOclockDate();
  const logo = new Task(
    issueId(),
    3,
    1,
    design,
    [],
    logoText,
    false,
    "",
    null,
    user,
    logoDueDate,
    [{ labelId: "priority", labelItemId: "high" }]
  );
  const map = new Task(
    issueId(),
    3,
    2,
    design,
    [],
    mapText,
    true,
    "",
    null,
    null,
    null,
    []
  );
  design.children = [logo, map];

  const userInterface = new Task(
    issueId(),
    2,
    2,
    top,
    [],
    userInterfaceText,
    false,
    "",
    null,
    null,
    null,
    [{ labelId: "priority", labelItemId: "medium" }]
  );
  const keyboardShotcutsDueDate = getOclockDate();
  keyboardShotcutsDueDate.setDate(keyboardShotcutsDueDate.getDate() - 3);
  const keyboardShotcuts = new Task(
    issueId(),
    3,
    1,
    userInterface,
    [],
    keyboardShotcutsText,
    false,
    "",
    null,
    member1,
    keyboardShotcutsDueDate,
    []
  );
  const mouseActions = new Task(
    issueId(),
    3,
    2,
    userInterface,
    [],
    mouseActionsText,
    true,
    "",
    null,
    null,
    null,
    []
  );
  userInterface.children = [keyboardShotcuts, mouseActions];

  const policiesDueDate = getOclockDate();
  policiesDueDate.setDate(policiesDueDate.getDate() + 3);
  const policies = new Task(
    issueId(),
    2,
    3,
    top,
    [],
    policiesText,
    false,
    "",
    null,
    member2,
    policiesDueDate,
    [{ labelId: "priority", labelItemId: "low" }]
  );

  keyboardShotcuts.selected = true;

  top.children = [design, userInterface, policies];
  const newTasks = [
    top,
    design,
    userInterface,
    logo,
    map,
    keyboardShotcuts,
    mouseActions,
    policies,
  ];
  return {
    user,
    members,
    labels,
    newTasks,
  };
};
