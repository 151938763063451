import { reasignChildrenSortNumber } from "./common";
import { UpdateTask, Task } from "../../models/Task";

export const blDeleteTask = (
  tasks: Task[], // 全体
  targetTasks: Task[], // 削除する子供たち
  deletingTaskIds: string[], // 子孫も含めた削除する全てのタスク
  parent: Task, // 親
  nextTaskId: string,
  updatedBy: string
): {
  newTasks: Task[];
  updatingTasks: UpdateTask[];
  selectingTaskId: string;
} => {
  const updatedAt = new Date();

  // 削除対象のうち最も若い sortNumber
  const minSortNumber = Math.min(...targetTasks.map((t) => t.sortNumber));

  // 削除対象のタスクを親から削除
  const targetTaskIds = targetTasks.map((t) => t.id);
  parent.children = parent.children.filter(
    (c) => !targetTaskIds.includes(c.id)
  );
  reasignChildrenSortNumber(parent);

  // 次の選択状態になるタスク
  let selectingTaskId = "";
  if (nextTaskId) {
    // まず指定がある場合
    const nextTask = tasks.find((t) => t.id === nextTaskId);
    if (nextTask) {
      nextTask.selected = true;
      selectingTaskId = nextTaskId;
    }
  }
  if (!selectingTaskId) {
    // 指定がある場合に指定タスクがないことはまず考えられないが
    // 選択状態のタスクがなくなると困るので
    // 万が一に備えて、指定タスクが見つからなかった場合もここの処理を通るようにする
    if (parent.children.length) {
      const sameSortNumberSibling = parent.children.find(
        (c) => c.sortNumber === minSortNumber
      );
      if (sameSortNumberSibling) {
        // 下にタスクがあった場合は選択状態にする
        sameSortNumberSibling.selected = true;
        selectingTaskId = sameSortNumberSibling.id;
      } else {
        // 下にタスクがなかった場合
        const elderSibling = parent.children.find(
          (c) => c.sortNumber === minSortNumber - 1
        );
        if (elderSibling) {
          // 上にタスクがあった場合は選択状態にする
          elderSibling.selected = true;
          selectingTaskId = elderSibling.id;
        } else {
          // ここは↓に処理が移るので有り得ないはずだが、↓と同じように親を選択状態にしておく
          parent.selected = true;
          selectingTaskId = parent.id;
        }
      }
    } else {
      // 親を選択状態にしておく
      parent.selected = true;
      selectingTaskId = parent.id;
    }
  }

  const updatingTasks: UpdateTask[] = [];
  updatingTasks.push({
    id: parent.id,
    params: {
      childrenIds: parent.childrenIds,
      updatedBy,
      updatedAt,
    },
  });
  // 削除したタスク以降のタスク全ての sortNumber が変更されるので
  // minSortNumber 以降のタスク全てが更新対象
  for (const child of parent.children) {
    if (child.sortNumber >= minSortNumber) {
      updatingTasks.push({
        id: child.id,
        params: {
          sortNumber: child.sortNumber,
          updatedBy,
          updatedAt,
        },
      });
    }
  }

  const newTasks = tasks
    .filter((t) => !deletingTaskIds.includes(t.id))
    .map((t) => {
      t.multiSelected = false;
      t.copied = false;
      t.cut = false;
      return t;
    });

  return {
    newTasks,
    updatingTasks,
    selectingTaskId,
  };
};
