import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import Typography from "../../../components/Typography";
import DatePicker from "./DatePicker";
import ComponentLabel from "./Label";
import Members from "./Members";
import useStyles from "../../../hooks/useStyles";
import { User } from "../../../models/User";
import { Visibility } from "../../../models/Project";
import { Task } from "../../../models/Task";
import { SettingCategory } from "../../../models/Setting";
import { Label, LabelItem, TaskLabel } from "../../../models/Label";
import { taskSettingTabIndex } from "../../../constants";

type Props = {
  user: User;
  visibility: Visibility;
  members: User[];
  isCoediting: boolean;
  task: Task;
  labels: Label[];
  endSettings: (
    task: Task,
    assignee: User | null,
    dueDate: Date | null,
    labels: TaskLabel[]
  ) => Promise<void>;
};

const TaskSettings: React.FC<Props> = React.memo(
  ({ user, visibility, members, isCoediting, task, labels, endSettings }) => {
    // ref
    const elm = useRef<HTMLDivElement>(null);
    const {
      assignee: vAssignee,
      dueDate: isDueDateVisible,
      label: vLabel,
    } = visibility;
    const membersWithoutSelf = members.filter(
      (m) => m.userId !== (user ? user.userId : "")
    );
    // hooks
    const { t: l } = useTranslation("labels");
    const { t: a } = useTranslation("assignee");
    const { t: cl } = useTranslation("calendar");
    const { t: v } = useTranslation("visibility");
    const { color, fontSize } = useStyles();
    // computed
    const isAssigneeVisible = vAssignee && isCoediting;
    const isLabelVisible = vLabel && labels.length ? true : false;
    const isAllInvisible =
      !isAssigneeVisible && !isDueDateVisible && !isLabelVisible;
    // states
    const [left, setLeft] = useState(task.left);
    const [settingCategory, setSettingCategory] = useState<SettingCategory>(
      isAssigneeVisible ? "Assignee" : isDueDateVisible ? "DueDate" : "Label"
    );
    const [selectedLabelIndex, setSelectedLabelIndex] = useState(0);
    const [editingAssignee, setEditingAssignee] = useState<User | null>(null);
    const [editingDueDate, setEditingDueDate] = useState<Date | null>(null);
    const [editingTaskLabels, setEditingTaskLabels] = useState<TaskLabel[]>([]);

    // Force Update
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
    const forceUpdate = () => {
      setForceUpdateCounter(forceUpdateCounter + 1);
    };

    useEffect(() => {
      if (elm.current) {
        const margin =
          elm.current.offsetLeft +
          elm.current.offsetWidth / 2 -
          task.horizontalCenter;
        setLeft(left - margin);
      }
    }, [task.horizontalCenter]);

    useEffect(() => {
      if (task.isSetting) {
        setEditingAssignee(task.assignee);
        setEditingDueDate(
          task.dueDate ? new Date(task.dueDate.getTime()) : null
        );
        setEditingTaskLabels(JSON.parse(JSON.stringify(task.labels)));
        setSelectedLabelIndex(0);
        focus();
      } else {
        // 確定かキャンセルをせずに他のノードを操作した場合
      }
    }, [task.isSetting]);

    const focus = () => {
      elm.current && elm.current.focus();
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      switch (event.key) {
        case "Enter":
          event.preventDefault();
          elm.current && elm.current.blur();
          return;
        case " ":
          // 画面がスクロールされるのを防ぐ
          event.preventDefault();
          return;
        case "Tab":
          // フォーカスが移動するのを防ぐ
          event.preventDefault();
          return;
        default:
          break;
      }
    };

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      focus();
    };

    const onClickLabelItem = (labelId: string, labelItem: LabelItem) => {
      if (editingTaskLabels.some((l) => l.labelId === labelId)) {
        setEditingTaskLabels(
          editingTaskLabels.map((l) => {
            if (l.labelId === labelId) {
              return {
                labelId,
                labelItemId: labelItem.labelItemId,
              };
            } else {
              return l;
            }
          })
        );
      } else {
        editingTaskLabels.push({
          labelId,
          labelItemId: labelItem.labelItemId,
        });
        setEditingTaskLabels(editingTaskLabels);
      }
      setSettingCategory("Label");
    };

    const onClickNone = (labelId: string) => {
      setEditingTaskLabels(
        editingTaskLabels.filter((l) => l.labelId !== labelId)
      );
    };

    const onChangeAssignee = (assignee: User | null) => {
      setEditingAssignee(assignee);
      setSettingCategory("Assignee");
    };

    const onChangeDueDate = (newDueDate: Date | null) => {
      setEditingDueDate(newDueDate);
      setSettingCategory("DueDate");
      forceUpdate();
    };

    const onDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    };

    const onBlur = async () => {
      await endSettings(
        task,
        editingAssignee,
        editingDueDate,
        editingTaskLabels
      );
    };

    return (
      <Container
        ref={elm}
        tabIndex={taskSettingTabIndex}
        onKeyDown={onKeyDown}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onBlur={onBlur}
      >
        {user && isCoediting && vAssignee && (
          <>
            <Title fontSize={fontSize}>{a("assignee")}</Title>
            <Members
              focused={settingCategory === "Assignee"}
              user={user}
              assigneeId={editingAssignee ? editingAssignee.userId : ""}
              members={membersWithoutSelf}
              onChangeAssignee={onChangeAssignee}
            />
          </>
        )}
        {isDueDateVisible && (
          <>
            <Title fontSize={fontSize}>{cl("dueDate")}</Title>
            <DatePicker
              selectedDate={editingDueDate}
              onChange={onChangeDueDate}
              forceUpdateCounter={forceUpdateCounter}
              color={color}
            />
          </>
        )}
        {isLabelVisible && (
          <>
            <LabelsTitle fontSize={fontSize}>{l("labels")}</LabelsTitle>
            {labels.map((l, i) => (
              <ComponentLabel
                key={l.labelId}
                focused={settingCategory === "Label"}
                selected={i === selectedLabelIndex}
                label={l}
                taskLabel={editingTaskLabels.find(
                  (nl) => nl.labelId === l.labelId
                )}
                onClickLabelItem={onClickLabelItem}
                onClickNone={onClickNone}
              />
            ))}
          </>
        )}
        {isAllInvisible && (
          <FlexColumnCenter>
            <Typography variant="small" bold margin="0px 0px 4px 0px">
              {v("noSetting")}
            </Typography>
          </FlexColumnCenter>
        )}
      </Container>
    );
  }
);
TaskSettings.displayName = "TaskSettings";

const Container = styled(FlexColumnCenter)`
  max-width: 400px;
  margin-top: 8px;
  user-select: none;
  :focus {
    outline: none;
  }
`;

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize + 2}px;
  font-weight: bold;
`;

const LabelsTitle = styled(Title)`
  margin-bottom: 4px;
`;

export default TaskSettings;
