import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../components/SettingContainer";
import Typography from "../../components/Typography";
import Text from "../../components/Input/Text";
import Radio from "../../components/Input/Radio";
import FilledButton from "../../components/Button/FilledButton";
import { FlexRowCenter } from "../../components/StyledComponents";
import { updateUserNameEmailAllow } from "../../db/users";
import useSnackbar from "../../hooks/useSnackbar";
import Context from "../../context";

const AccountSetting: React.FC = () => {
  // context
  const { user, updateLocalUser } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t } = useTranslation("account");
  const { setMessage } = useSnackbar();
  // states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [allowEmailSearch, setAllowEmailSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setAllowEmailSearch(user.allowEmailSearch);
    }
  }, [user]);

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onChangeAllowEmailSearch = (value: boolean) => {
    setAllowEmailSearch(value);
  };

  const save = async () => {
    if (!user || !name || !email) return;

    setLoading(true);
    await updateUserNameEmailAllow(user.userId, name, email, allowEmailSearch);
    await updateLocalUser();
    setMessage({
      level: "success",
      text: c("successfullyUpdated"),
    });
    setLoading(false);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="8px 0px 16px 0px">
        {t("account")}
      </Typography>
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {t("accountName")}
      </Typography>
      <Text value={name} onChange={onChangeName} margin="0px 0px 16px 0px" />
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {t("email")}
      </Typography>
      <Text
        value={email}
        onChange={onChangeEmail}
        margin="0px 0px 16px 0px"
        long
      />
      <Typography variant="medium" bold margin="0px 0px 8px 0px">
        {t("emailInvitation")}
      </Typography>
      <Radios>
        <Radio
          label={t("disallow")}
          value="false"
          checked={allowEmailSearch === false}
          onChange={() => onChangeAllowEmailSearch(false)}
        />
        <Radio
          label={t("allow")}
          value="true"
          checked={allowEmailSearch === true}
          onChange={() => onChangeAllowEmailSearch(true)}
        />
      </Radios>
      <FilledButton
        color="primary"
        disabled={!name || !email}
        loading={loading}
        onClick={save}
      >
        {c("save")}
      </FilledButton>
    </SettingContainer>
  );
};

const Radios = styled(FlexRowCenter)`
  margin-bottom: 16px;
  > button {
    margin: 0px 6px;
  }
`;

export default AccountSetting;
