import React from "react";
import styled from "styled-components";
import { lineStrokeWidth, lineZIndex } from "../../constants";

type Props = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  lineStrokeColor: string;
};

const ComponentLine: React.FC<Props> = React.memo(
  ({ startX, startY, endX, endY, lineStrokeColor }) => {
    if (endX - startX <= 0) {
      return <></>;
    }
    const width = endX - startX;
    if (startY === endY) {
      return (
        <StyledSvg
          viewBox={`0 0 ${width} ${lineStrokeWidth}`}
          width={width}
          height={lineStrokeWidth}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            top: startY - lineStrokeWidth / 2,
            left: startX,
          }}
        >
          <line
            x1="0"
            y1={lineStrokeWidth / 2}
            x2={width}
            y2={lineStrokeWidth / 2}
            stroke={lineStrokeColor}
            strokeWidth={lineStrokeWidth}
          />
        </StyledSvg>
      );
    } else if (endY - startY > 0) {
      // lower
      const top = startY - lineStrokeWidth / 2;
      const height = endY - startY + lineStrokeWidth;
      const mX = 0;
      const mY = lineStrokeWidth / 2;
      const c1stX = width * 0.5;
      const c1stY = 0;
      const c2ndX = width * 0.5;
      const c2ndY = height;
      const c3rdX = width;
      const c3rdY = height - lineStrokeWidth / 2;
      const d = `M ${mX},${mY} C ${c1stX},${c1stY} ${c2ndX},${c2ndY} ${c3rdX},${c3rdY}`;
      return (
        <StyledSvg
          viewBox={`0 0 ${width} ${height}`}
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            top,
            left: startX,
          }}
        >
          <path
            d={d}
            stroke={lineStrokeColor}
            fill="transparent"
            strokeWidth={lineStrokeWidth}
          />
        </StyledSvg>
      );
    } else {
      // upper
      const top = endY - lineStrokeWidth / 2;
      const height = startY - endY + lineStrokeWidth;
      const mX = 0;
      const mY = height - lineStrokeWidth / 2;
      const c1stX = width * 0.5;
      const c1stY = height;
      const c2ndX = width * 0.5;
      const c2ndY = 0;
      const c3rdX = width;
      const c3rdY = lineStrokeWidth / 2;
      const d = `M ${mX},${mY} C ${c1stX},${c1stY} ${c2ndX},${c2ndY} ${c3rdX},${c3rdY}`;
      return (
        <StyledSvg
          viewBox={`0 0 ${width} ${height}`}
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          style={{
            top,
            left: startX,
          }}
        >
          <path
            d={d}
            stroke={lineStrokeColor}
            fill="transparent"
            strokeWidth={lineStrokeWidth}
          />
        </StyledSvg>
      );
    }
  }
);
ComponentLine.displayName = "ComponentLine";

const StyledSvg = styled.svg`
  position: absolute;
  z-index: ${lineZIndex};
`;

export default ComponentLine;
