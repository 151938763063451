import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Mindmap from "./Mindmap";
import { FlexRowCenter } from "../../../components/StyledComponents";
import { User } from "../../../models/User";
import { ActiveMember } from "../../../models/ActiveMember";
import { ITask, Task } from "../../../models/Task";
import { Label } from "../../../models/Label";
import { createSampleDate } from "../bl";
import { taskMargin, lineWidth } from "../../../constants";
import { recMargin } from "../constants";

type Props = {
  focusTrigger: number;
  addScrollTop: (value: number) => void;
};

const FilterTutorial: React.FC<Props> = ({ focusTrigger, addScrollTop }) => {
  // refs
  const leftScreenElm = useRef<HTMLDivElement>(null);
  const rightScreenElm = useRef<HTMLDivElement>(null);
  // hooks
  const { t: s } = useTranslation("sample");
  const { t: p } = useTranslation("priority");
  // left
  const [isLeftGrabbingMindmap, setLeftIsGrabbingMindmap] = useState(false);
  const [leftGrabbedPageX, setLeftGrabbedPageX] = useState(0);
  const [leftGrabbedScrollLeft, setLeftGrabbedScrollLeft] = useState(0);
  const [leftGrabbedPageY, setLeftGrabbedPageY] = useState(0);
  const [leftGrabbedScrollTop, setLeftGrabbedScrollTop] = useState(0);
  // right
  const [isRightGrabbingMindmap, setRightIsGrabbingMindmap] = useState(false);
  const [rightGrabbedPageX, setRightGrabbedPageX] = useState(0);
  const [rightGrabbedScrollLeft, setRightGrabbedScrollLeft] = useState(0);
  const [rightGrabbedPageY, setRightGrabbedPageY] = useState(0);
  const [rightGrabbedScrollTop, setRightGrabbedScrollTop] = useState(0);
  // states
  const [leftUser, setLeftUser] = useState<User | null>(null);
  const [rightUser, setRightUser] = useState<User | null>(null);
  const [members, setMembers] = useState<User[]>([]);
  const [labels, setLabels] = useState<Label[]>([]);
  const [activeMembers, setActiveMembers] = useState<ActiveMember[]>([]);
  const [leftUpdatedTasks, setLeftUpdatedTasks] = useState<ITask[]>([]);
  const [rightUpdatedTasks, setRightUpdatedTasks] = useState<ITask[]>([]);

  useEffect(() => {
    const {
      members: newMembers,
      labels: newLabels,
      newTasks,
    } = createSampleDate(
      s("userName"),
      s("member1Name"),
      s("member2Name"),
      p("priority"),
      p("high"),
      p("medium"),
      p("low"),
      s("create"),
      s("design"),
      s("logo"),
      s("map"),
      s("ui"),
      s("keyboardShortcuts"),
      s("mouseActions"),
      s("policies")
    );
    setMembers(newMembers);
    const member1 = newMembers[0];
    const member2 = newMembers[1];
    setLeftUser(member1);
    setRightUser(member2);
    setActiveMembers([
      {
        userId: member1.userId,
        name: member1.name,
        image: "",
        currentTaskId: newTasks[2].id,
        updatedAt: new Date(),
      },
      {
        userId: member2.userId,
        name: member2.name,
        image: "",
        currentTaskId: newTasks[5].id,
        updatedAt: new Date(),
      },
    ]);
    const updatingTasks = newTasks.map((t) => {
      const iTask = t.toItem("");
      iTask.changeType = "added";
      return iTask;
    });
    setLeftUpdatedTasks(updatingTasks);
    setRightUpdatedTasks(updatingTasks);
    setLabels(newLabels);
  }, [s]);

  const resetLeftUpdatedTasks = () => {
    setLeftUpdatedTasks([]);
  };

  const resetRightUpdatedTasks = () => {
    setRightUpdatedTasks([]);
  };

  const batchEditTasks = (
    userId: string,
    tempUserId: string,
    addingTasks: Task[],
    updatingTasks: Task[],
    deletingTasks: Task[],
    currentTaskId: string
  ) => {
    const tempUpdatedTasks: ITask[] = [];
    for (const task of addingTasks) {
      const iTask = task.toItem(tempUserId);
      iTask.changeType = "added";
      tempUpdatedTasks.push(iTask);
    }
    for (const task of updatingTasks) {
      const iTask = task.toItem(tempUserId);
      iTask.changeType = "modified";
      tempUpdatedTasks.push(iTask);
    }
    for (const task of deletingTasks) {
      const iTask = task.toItem(tempUserId);
      iTask.changeType = "removed";
      tempUpdatedTasks.push(iTask);
    }
    setLeftUpdatedTasks(tempUpdatedTasks);
    setRightUpdatedTasks(tempUpdatedTasks);
    setActiveMembers(
      activeMembers.map((m) => {
        if (m.userId === userId) {
          m.currentTaskId = currentTaskId;
          m.updatedAt = new Date();
        }
        return m;
      })
    );
  };

  const updateCurrentTask = (userId: string, currentTaskId: string) => {
    setActiveMembers(
      activeMembers.map((m) => {
        if (m.userId === userId) {
          m.currentTaskId = currentTaskId;
          m.updatedAt = new Date();
        }
        return m;
      })
    );
  };

  // left
  const onLeftMindmapMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setLeftIsGrabbingMindmap(true);
    setLeftGrabbedPageX(event.pageX);
    setLeftGrabbedPageY(event.pageY);
    if (leftScreenElm.current) {
      setLeftGrabbedScrollTop(leftScreenElm.current.scrollTop);
      setLeftGrabbedScrollLeft(leftScreenElm.current.scrollLeft);
    }
  };
  const onLeftMindmapMouseMove = (pageX: number, pageY: number) => {
    if (isLeftGrabbingMindmap) {
      if (!leftScreenElm.current) return;
      leftScreenElm.current.scrollTop =
        leftGrabbedScrollTop - (pageY - leftGrabbedPageY);
      leftScreenElm.current.scrollLeft =
        leftGrabbedScrollLeft - (pageX - leftGrabbedPageX);
    }
  };
  const onLeftMindmapMouseUp = () => {
    if (isLeftGrabbingMindmap) {
      setLeftIsGrabbingMindmap(false);
    }
  };
  const onLeftMindmapMouseOut = () => {
    setLeftIsGrabbingMindmap(false);
  };
  const leftAdjustScroll = (target: Task) => {
    if (!leftScreenElm.current) return;
    // top
    if (leftScreenElm.current.scrollTop + taskMargin > target.top) {
      const margin = leftScreenElm.current.scrollTop - target.top;
      leftScreenElm.current.scrollTop =
        leftScreenElm.current.scrollTop - margin - taskMargin;
    }
    // bottom
    if (
      leftScreenElm.current.scrollTop +
        leftScreenElm.current.clientHeight -
        taskMargin <
      target.bottom
    ) {
      const margin =
        target.bottom -
        leftScreenElm.current.scrollTop -
        leftScreenElm.current.clientHeight;
      leftScreenElm.current.scrollTop =
        leftScreenElm.current.scrollTop + margin + taskMargin + 20;
    }
    // right
    // target.right + lineWidth が目標
    const rightMargin =
      target.right +
      lineWidth -
      leftScreenElm.current.scrollLeft -
      leftScreenElm.current.clientWidth;
    if (rightMargin > 0) {
      leftScreenElm.current.scrollLeft =
        leftScreenElm.current.scrollLeft + rightMargin;
    }
    // left
    // targetTask.right + lineWidth が目標
    const leftMargin =
      leftScreenElm.current.scrollLeft + lineWidth - target.left;
    if (leftMargin > 0) {
      leftScreenElm.current.scrollLeft =
        leftScreenElm.current.scrollLeft - leftMargin;
    }
  };

  // right
  const onRightMindmapMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setRightIsGrabbingMindmap(true);
    setRightGrabbedPageX(event.pageX);
    setRightGrabbedPageY(event.pageY);
    if (rightScreenElm.current) {
      setRightGrabbedScrollTop(rightScreenElm.current.scrollTop);
      setRightGrabbedScrollLeft(rightScreenElm.current.scrollLeft);
    }
  };
  const onRightMindmapMouseMove = (pageX: number, pageY: number) => {
    if (isRightGrabbingMindmap) {
      if (!rightScreenElm.current) return;
      rightScreenElm.current.scrollTop =
        rightGrabbedScrollTop - (pageY - rightGrabbedPageY);
      rightScreenElm.current.scrollLeft =
        rightGrabbedScrollLeft - (pageX - rightGrabbedPageX);
    }
  };
  const onRightMindmapMouseUp = () => {
    if (isRightGrabbingMindmap) {
      setRightIsGrabbingMindmap(false);
    }
  };
  const onRightMindmapMouseOut = () => {
    setRightIsGrabbingMindmap(false);
  };
  const rightAdjustScroll = (target: Task) => {
    if (!rightScreenElm.current) return;
    // top
    if (rightScreenElm.current.scrollTop + taskMargin > target.top) {
      const margin = rightScreenElm.current.scrollTop - target.top;
      rightScreenElm.current.scrollTop =
        rightScreenElm.current.scrollTop - margin - taskMargin;
    }
    // bottom
    if (
      rightScreenElm.current.scrollTop +
        rightScreenElm.current.clientHeight -
        taskMargin <
      target.bottom
    ) {
      const margin =
        target.bottom -
        rightScreenElm.current.scrollTop -
        rightScreenElm.current.clientHeight;
      rightScreenElm.current.scrollTop =
        rightScreenElm.current.scrollTop + margin + taskMargin + 20;
    }
    // right
    // target.right + lineWidth が目標
    const rightMargin =
      target.right +
      lineWidth -
      rightScreenElm.current.scrollLeft -
      rightScreenElm.current.clientWidth;
    if (rightMargin > 0) {
      rightScreenElm.current.scrollLeft =
        rightScreenElm.current.scrollLeft + rightMargin;
    }
    // left
    // targetTask.right + lineWidth が目標
    const leftMargin =
      rightScreenElm.current.scrollLeft + lineWidth - target.left;
    if (leftMargin > 0) {
      rightScreenElm.current.scrollLeft =
        rightScreenElm.current.scrollLeft - leftMargin;
    }
  };

  const onWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight ===
        event.currentTarget.scrollTop &&
      event.deltaY > 0
    ) {
      // スクロールが一番下かつ下にスクロール
      addScrollTop(event.deltaY);
    } else if (event.currentTarget.scrollTop === 0 && event.deltaY < 0) {
      // スクロールが一番上かつ上にスクロール
      addScrollTop(event.deltaY);
    }
  };

  return (
    <Container>
      <Screen ref={leftScreenElm} className="scroll-none" onWheel={onWheel}>
        <Mindmap
          isLeft={true}
          focusTrigger={focusTrigger}
          isGrabbingMindmap={isLeftGrabbingMindmap}
          user={leftUser}
          members={members}
          activeMembers={activeMembers}
          labels={labels}
          updatedTasks={leftUpdatedTasks}
          resetUpdatedTasks={resetLeftUpdatedTasks}
          batchEditTasks={batchEditTasks}
          updateCurrentTask={updateCurrentTask}
          onMindmapMouseDown={onLeftMindmapMouseDown}
          onMindmapMouseMove={onLeftMindmapMouseMove}
          onMindmapMouseUp={onLeftMindmapMouseUp}
          onMindmapMouseOut={onLeftMindmapMouseOut}
          adjustScroll={leftAdjustScroll}
        />
      </Screen>
      <Screen ref={rightScreenElm} className="scroll-none" onWheel={onWheel}>
        <Mindmap
          isLeft={false}
          focusTrigger={focusTrigger}
          isGrabbingMindmap={isRightGrabbingMindmap}
          user={rightUser}
          members={members}
          activeMembers={activeMembers}
          labels={labels}
          updatedTasks={rightUpdatedTasks}
          resetUpdatedTasks={resetRightUpdatedTasks}
          batchEditTasks={batchEditTasks}
          updateCurrentTask={updateCurrentTask}
          onMindmapMouseDown={onRightMindmapMouseDown}
          onMindmapMouseMove={onRightMindmapMouseMove}
          onMindmapMouseUp={onRightMindmapMouseUp}
          onMindmapMouseOut={onRightMindmapMouseOut}
          adjustScroll={rightAdjustScroll}
        />
      </Screen>
    </Container>
  );
};

const Container = styled(FlexRowCenter)``;

const Screen = styled.div`
  font-family: initial;
  height: calc(100vh - ${recMargin * 2}px);
  width: calc(50vw - ${recMargin}px);
  overflow: auto;
  pointer-events: initial;
`;

export default FilterTutorial;
