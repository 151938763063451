import React from "react";
import styled from "styled-components";
import Google from "../../images/google.png";
import Twitter from "../../images/twitter.png";
import GithubWhite from "../../images/github-white.png";
import GithubBlack from "../../images/github-black.png";
import useStyles from "../../hooks/useStyles";
import firebase from "../../firebase";
import { baseBorderRadius } from "../../constants";

type Props = {
  provider: "Google" | "Twitter" | "Github";
  margin?: string;
};

const SignInWithButton: React.FC<Props> = React.memo(({ provider, margin }) => {
  const { logo, menuItem } = useStyles();
  const boxShadow = menuItem.boxShadow
    ? menuItem.boxShadow
    : `0 0 10px ${menuItem.borderColor}`;

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider);
  };

  const signInWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    firebase.auth().signInWithPopup(provider);
  };

  const signInWithGithub = () => {
    const provider = new firebase.auth.GithubAuthProvider();
    firebase.auth().signInWithPopup(provider);
  };

  switch (provider) {
    case "Twitter":
      return (
        <Container
          color={menuItem.color}
          bgColor={logo.color}
          borderColor={menuItem.borderColor}
          boxShadow={boxShadow}
          margin={margin}
          onClick={signInWithTwitter}
        >
          <Img src={Twitter} alt="Twitter" height="25" width="25" />
          Sign in with Twitter
        </Container>
      );
    case "Github":
      return (
        <Container
          color={menuItem.color}
          bgColor={logo.color}
          borderColor={menuItem.borderColor}
          boxShadow={boxShadow}
          margin={margin}
          onClick={signInWithGithub}
        >
          <Img
            src={menuItem.color === "white" ? GithubWhite : GithubBlack}
            alt="Github"
            height="25"
            width="25"
          />
          Sign in with Github
        </Container>
      );
    case "Google":
    default:
      return (
        <Container
          color={menuItem.color}
          bgColor={logo.color}
          borderColor={menuItem.borderColor}
          boxShadow={boxShadow}
          margin={margin}
          onClick={signInWithGoogle}
        >
          <Img src={Google} alt="Google" height="25" width="25" />
          Sign in with Google
        </Container>
      );
  }
});
SignInWithButton.displayName = "SignInWithButton";

type ContainerProps = {
  color: string;
  bgColor: string;
  borderColor: string;
  boxShadow: string;
  margin?: string;
};
const Container = styled.button<ContainerProps>`
  font-family: "Kanit", sans-serif;
  font-size: 1.375rem;

  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};

  padding: 4px 24px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  margin: ${({ margin }) => margin};

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  user-select: none;
  cursor: pointer;
  pointer-events: initial;

  transition: color 100ms ease-out, background-color 100ms ease-out,
    box-shadow 100ms ease-out;

  :hover {
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

const Img = styled.img`
  margin-right: 8px;
`;

export default SignInWithButton;
