import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  FlexColumnCenter,
  FlexRowCenter,
} from "../../components/StyledComponents";
import ThemeItem from "../../components/ThemeItem";
import useStyles from "../../hooks/useStyles";
import { baseBorderRadius } from "../../constants";

const SelectTheme: React.FC = React.memo(() => {
  const { t: th } = useTranslation("theme");

  const { themes, color, top, filter } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnter = () => {
    setIsOpen(true);
  };
  const onMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <Container>
      <Select
        isOpen={isOpen}
        color={top.color}
        hoverColor={top.hoverColor}
        onMouseEnter={onMouseEnter}
      >
        {th("theme")}
      </Select>
      {isOpen && (
        <Options
          color={color}
          bgColor={filter.bgColor}
          borderColor={filter.borderColor}
          onMouseLeave={onMouseLeave}
        >
          {themes.map((t) => (
            <ThemeItem key={t.name} themeItem={t} />
          ))}
        </Options>
      )}
    </Container>
  );
});
SelectTheme.displayName = "SelectTheme";

const Container = styled.div`
  width: 125px;
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  transition: display 200ms ease-out;
`;

type SelectProps = {
  isOpen: boolean;
  color: string;
  hoverColor: string;
};
const Select = styled(FlexRowCenter)<SelectProps>`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ isOpen, color }) => (isOpen ? "transparent" : color)};
  padding: 4px 12px;
  margin-right: 10px;
  cursor: pointer;

  :hover {
    color: ${({ isOpen, hoverColor }) => (isOpen ? "transparent" : hoverColor)};
  }
`;

type OptionsProps = {
  bgColor: string;
  borderColor: string;
};
const Options = styled(FlexColumnCenter)<OptionsProps>`
  min-width: 250px;
  background-color: ${({ bgColor }) => bgColor};
  padding: 12px;
  border: solid 2px ${({ borderColor }) => borderColor};
  border-radius: ${baseBorderRadius}px;
  position: absolute;
  bottom: 0px;
  right: 10px;
  align-items: stretch;

  > div {
    margin-bottom: 12px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
`;

export default SelectTheme;
