import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ProjectList from "./ProjectList";
import SettingMenu from "./SettingMenu";
import Top from "./top";
import TermsOfService from "./terms-of-service";
import PrivacyPolicy from "./privacy-policy";
import CookiePolicy from "./cookie-policy";
import News from "./news";
import ProjectComponent from "./projects";
import Header from "./Header";
import AddProject from "./projects/add";
import BasicSettings from "./projects/settings/basic";
import LabelSettings from "./projects/settings/labels";
import MemberSettings from "./projects/settings/members";
import CalendarSetting from "./projects/settings/calendar";
import VisibilitySettings from "./projects/settings/visibility";
import Account from "./settings/account";
// import Teams from "./settings/teams";
// import Team from "./settings/teams/teamId";
// import Subscription from "./settings/subscriptions";
import ThemeSetting from "./settings/theme";
import Language from "./settings/language";
import Contact from "./settings/contact";
import SettingsTermsOfService from "./settings/terms-of-service";
import SettingsPrivacyPolicy from "./settings/privacy-policy";
import SettingsCookiePolicy from "./settings/cookie-policy";
import Signin from "./signin";
import YesNoDialog from "../components/Dialog/YesNoDialog";
import { deleteProject } from "../db/projects";
import Context from "../context";
import useStyles from "../hooks/useStyles";
import useSnackbar from "../hooks/useSnackbar";
import useAnalytics from "../hooks/useAnalytics";
import { Project } from "../models/Project";
import { Task } from "../models/Task";
import { firebase401Message, taskMargin, lineWidth } from "../constants";

const ComponentDevice: React.FC = () => {
  // context
  const { deleteLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: p } = useTranslation("project");
  const history = useHistory();
  const { background } = useStyles();
  const { setUnauthorizedMessage } = useSnackbar();
  const { sendProjectLog } = useAnalytics();
  // refs
  const screenElm = useRef<HTMLDivElement>(null);
  // drag
  const [isGrabbingMindmap, setIsGrabbingMindmap] = useState(false);
  const [grabbedPageX, setGrabbedPageX] = useState(0);
  const [grabbedScrollLeft, setGrabbedScrollLeft] = useState(0);
  const [grabbedPageY, setGrabbedPageY] = useState(0);
  const [grabbedScrollTop, setGrabbedScrollTop] = useState(0);

  // setMindmapSize の後のスクロール幅セット
  const setScroll = (top: number, left: number) => {
    if (!screenElm.current) return;
    screenElm.current.scrollTop = top;
    screenElm.current.scrollLeft = left;
  };

  // ドラッグ&ドロップのスクロール
  const onMindmapMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsGrabbingMindmap(true);
    setGrabbedPageX(event.pageX);
    setGrabbedPageY(event.pageY);
    if (screenElm.current) {
      setGrabbedScrollTop(screenElm.current.scrollTop);
      setGrabbedScrollLeft(screenElm.current.scrollLeft);
    }
  };

  const onMindmapMouseMove = (pageX: number, pageY: number) => {
    if (isGrabbingMindmap) {
      if (!screenElm.current) return;
      screenElm.current.scrollTop = grabbedScrollTop - (pageY - grabbedPageY);
      screenElm.current.scrollLeft = grabbedScrollLeft - (pageX - grabbedPageX);
    }
  };

  const onMindmapMouseUp = () => {
    if (isGrabbingMindmap) {
      setIsGrabbingMindmap(false);
    }
  };

  const onMindmapMouseOut = () => {
    setIsGrabbingMindmap(false);
  };

  // ノード移動後のスクロール移動
  const adjustScroll = (target: Task) => {
    if (!screenElm.current) return;
    // top
    if (screenElm.current.scrollTop + taskMargin > target.top) {
      const margin = screenElm.current.scrollTop - target.top;
      screenElm.current.scrollTop =
        screenElm.current.scrollTop - margin - taskMargin;
    }
    // bottom
    if (
      screenElm.current.scrollTop +
        screenElm.current.clientHeight -
        taskMargin <
      target.bottom
    ) {
      const margin =
        target.bottom -
        screenElm.current.scrollTop -
        screenElm.current.clientHeight;
      screenElm.current.scrollTop =
        screenElm.current.scrollTop + margin + taskMargin + 20;
    }
    // right
    // target.right + lineWidth が目標
    const rightMargin =
      target.right +
      lineWidth -
      screenElm.current.scrollLeft -
      screenElm.current.clientWidth;
    if (rightMargin > 0) {
      screenElm.current.scrollLeft = screenElm.current.scrollLeft + rightMargin;
    }
    // left
    // targetTask.right + lineWidth が目標
    const leftMargin = screenElm.current.scrollLeft + lineWidth - target.left;
    if (leftMargin > 0) {
      screenElm.current.scrollLeft = screenElm.current.scrollLeft - leftMargin;
    }
  };

  // delete project
  const [deletingProject, setDeletingProject] = useState<Project | null>(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const onClickDeleteProject = async (project: Project) => {
    setDeletingProject(project);
    setIsConfirmDeleteDialogOpen(true);
    sendProjectLog("delete");
  };
  const onClickYesForDelete = async () => {
    if (!deletingProject) return;
    try {
      await deleteProject(deletingProject.projectId);
      deleteLocalProject(deletingProject.projectId);
      history.push("/");
    } catch (err) {
      if (err.message === firebase401Message) {
        setUnauthorizedMessage();
      }
    } finally {
      setIsConfirmDeleteDialogOpen(false);
    }
  };
  const onClickNoForDelete = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Top />} />
        <Route path="/terms-of-service" render={() => <TermsOfService />} />
        <Route path="/privacy-policy" render={() => <PrivacyPolicy />} />
        <Route path="/cookie-policy" render={() => <CookiePolicy />} />
        <Container
          ref={screenElm}
          className="scroll-none"
          background={background}
        >
          <Header />
          <ProjectList onClickDeleteProject={onClickDeleteProject} />
          <Switch>
            <Route exact path="/news" render={() => <News />} />
            <Route exact path="/projects/add" render={() => <AddProject />} />
            <Route
              exact
              path="/projects/:projectId"
              render={() => (
                <ProjectComponent
                  setScroll={setScroll}
                  isGrabbingMindmap={isGrabbingMindmap}
                  onMindmapMouseDown={onMindmapMouseDown}
                  onMindmapMouseMove={onMindmapMouseMove}
                  onMindmapMouseUp={onMindmapMouseUp}
                  onMindmapMouseOut={onMindmapMouseOut}
                  adjustScroll={adjustScroll}
                />
              )}
            />
            <Route
              path={`/projects/:projectId/settings/basic`}
              render={() => <BasicSettings />}
            />
            <Route
              path={`/projects/:projectId/settings/labels`}
              render={() => <LabelSettings />}
            />
            <Route
              path={`/projects/:projectId/settings/members`}
              render={() => <MemberSettings />}
            />
            <Route
              path={`/projects/:projectId/settings/calendar`}
              render={() => <CalendarSetting />}
            />
            <Route
              path={`/projects/:projectId/settings/visibility`}
              render={() => <VisibilitySettings />}
            />
            <Route path="/settings/account" render={() => <Account />} />
            <Route path="/settings/theme" render={() => <ThemeSetting />} />
            <Route path="/settings/language" render={() => <Language />} />
            <Route exact path="/settings/contact" render={() => <Contact />} />
            <Route
              path="/settings/terms-of-service"
              render={() => <SettingsTermsOfService />}
            />
            <Route
              path="/settings/privacy-policy"
              render={() => <SettingsPrivacyPolicy />}
            />
            <Route
              path="/settings/cookie-policy"
              render={() => <SettingsCookiePolicy />}
            />
            <Route path="/signin" render={() => <Signin />} />
            {/* <Route exact path="/settings/teams" render={() => <Teams />} />
            <Route
              exact
              path="/settings/teams/:teamId"
              render={() => <Team />}
            />
            <Route
              exact
              path="/settings/subscriptions"
              render={() => <Subscription />}
            /> */}
          </Switch>
          <SettingMenu />
          {deletingProject && (
            <YesNoDialog
              isOpen={isConfirmDeleteDialogOpen}
              setIsOpen={setIsConfirmDeleteDialogOpen}
              color="secondary"
              title={c("confirmDeletion")}
              message={p("areYouSureDelete").replace(
                "{name}",
                deletingProject.name
              )}
              onClickYes={onClickYesForDelete}
              onClickNo={onClickNoForDelete}
            />
          )}
        </Container>
      </Switch>
    </Router>
  );
};

const Container = styled.div<{ background: string }>`
  height: 100vh;
  width: 100vw;
  overflow: auto;

  background: ${({ background }) => background};
`;

export default ComponentDevice;
