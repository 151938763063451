import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import ComponentLabel from "./Label";
import useStyles from "../../../hooks/useStyles";
import { Task } from "../../../models/Task";
import { Label, LabelItem, TaskLabel } from "../../../models/Label";
import { SettingCategory } from "../../../models/Setting";
import { taskSettingTabIndex } from "../../../constants";

type Props = {
  task: Task;
  labels: Label[];
  endLabeling: (
    task: Task,
    labels: TaskLabel[],
    category?: SettingCategory
  ) => Promise<void>;
};

const Labeling: React.FC<Props> = React.memo(
  ({ task, labels, endLabeling }) => {
    // ref
    const elm = useRef<HTMLDivElement>(null);
    // hooks
    const { t: l } = useTranslation("labels");
    const { fontSize } = useStyles();
    // states
    const [left, setLeft] = useState(task.left);
    const [selectedLabelIndex, setSelectedLabelIndex] = useState(0);
    const [editingTaskLabels, setEditingTaskLabels] = useState<TaskLabel[]>([]);

    useEffect(() => {
      if (elm.current) {
        const margin =
          elm.current.offsetLeft +
          elm.current.offsetWidth / 2 -
          task.horizontalCenter;
        setLeft(left - margin);
      }
    }, [task.horizontalCenter]);

    useEffect(() => {
      if (task.isLabeling) {
        setEditingTaskLabels(JSON.parse(JSON.stringify(task.labels)));
        setSelectedLabelIndex(0);
        focus();
      }
    }, [task.isLabeling]);

    const focus = () => {
      elm.current && elm.current.focus();
    };

    const onClickLabelItem = (labelId: string, labelItem: LabelItem) => {
      if (editingTaskLabels.some((l) => l.labelId === labelId)) {
        setEditingTaskLabels(
          editingTaskLabels.map((l) => {
            if (l.labelId === labelId) {
              return {
                labelId,
                labelItemId: labelItem.labelItemId,
              };
            } else {
              return l;
            }
          })
        );
      } else {
        editingTaskLabels.push({
          labelId,
          labelItemId: labelItem.labelItemId,
        });
        setEditingTaskLabels(editingTaskLabels);
      }
    };

    const onClickNone = (labelId: string) => {
      setEditingTaskLabels(
        editingTaskLabels.filter((l) => l.labelId !== labelId)
      );
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          moveToAboveLabel();
          break;
        case "ArrowDown":
          event.preventDefault();
          moveToBelowLabel();
          break;
        case "ArrowRight":
          event.preventDefault();
          moveToRightLabelItem();
          break;
        case "ArrowLeft":
          event.preventDefault();
          moveToLeftLabelItem();
          break;
        case "Enter":
          event.preventDefault();
          end();
          return;
        case "a":
          event.preventDefault();
          await endLabeling(task, editingTaskLabels, "Assignee");
          return;
        case "d":
          event.preventDefault();
          await endLabeling(task, editingTaskLabels, "DueDate");
          return;
        case "l":
          event.preventDefault();
          return;
        case " ":
          // 画面がスクロールされるのを防ぐ
          event.preventDefault();
          return;
        case "Tab":
          // フォーカスが移動するのを防ぐ
          event.preventDefault();
          return;
        default:
          break;
      }
    };

    const moveToAboveLabel = () => {
      if (selectedLabelIndex === 0) {
        setSelectedLabelIndex(labels.length - 1);
      } else {
        setSelectedLabelIndex(selectedLabelIndex - 1);
      }
    };

    const moveToBelowLabel = () => {
      if (selectedLabelIndex === labels.length - 1) {
        setSelectedLabelIndex(0);
      } else {
        setSelectedLabelIndex(selectedLabelIndex + 1);
      }
    };

    const moveToRightLabelItem = () => {
      const selectedLabel = labels[selectedLabelIndex];
      const taskLabel = editingTaskLabels.find(
        (l) => l.labelId === selectedLabel.labelId
      );

      if (taskLabel) {
        const currentLabelItemIndex = selectedLabel.labelItems.findIndex(
          (i) => i.labelItemId === taskLabel.labelItemId
        );
        if (currentLabelItemIndex === selectedLabel.labelItems.length - 1) {
          // 最後の labelItem だったら なし
          const newTaskLabels = editingTaskLabels.filter(
            (nl) => nl.labelId !== selectedLabel.labelId
          );
          setEditingTaskLabels(newTaskLabels);
        } else {
          // それ以外は右隣に入れ替える
          const nextLabelItem =
            selectedLabel.labelItems[currentLabelItemIndex + 1];
          const newTaskLabels = editingTaskLabels.map((t) => {
            if (t.labelId === selectedLabel.labelId) {
              t.labelItemId = nextLabelItem.labelItemId;
            }
            return t;
          });
          setEditingTaskLabels(newTaskLabels);
        }
      } else {
        // 設定なしの場合は最初のラベルアイテム
        const nextLabelItem = selectedLabel.labelItems[0];
        const newTaskLabel: TaskLabel = {
          labelId: selectedLabel.labelId,
          labelItemId: nextLabelItem.labelItemId,
        };
        setEditingTaskLabels(editingTaskLabels.concat([newTaskLabel]));
      }
    };

    const moveToLeftLabelItem = () => {
      const selectedLabel = labels[selectedLabelIndex];
      const taskLabel = editingTaskLabels.find(
        (l) => l.labelId === selectedLabel.labelId
      );

      if (taskLabel) {
        const currentLabelItemIndex = selectedLabel.labelItems.findIndex(
          (i) => i.labelItemId === taskLabel.labelItemId
        );
        if (currentLabelItemIndex === 0) {
          // 最初の labelItem だったら なし
          const newTaskLabels = editingTaskLabels.filter(
            (nl) => nl.labelId !== selectedLabel.labelId
          );
          setEditingTaskLabels(newTaskLabels);
        } else {
          // それ以外は左隣に入れ替える
          const nextLabelItem =
            selectedLabel.labelItems[currentLabelItemIndex - 1];
          const newTaskLabels = editingTaskLabels.map((t) => {
            if (t.labelId === selectedLabel.labelId) {
              t.labelItemId = nextLabelItem.labelItemId;
            }
            return t;
          });
          setEditingTaskLabels(newTaskLabels);
        }
      } else {
        // 設定なしの場合は最後のラベルアイテム
        const nextLabelItem =
          selectedLabel.labelItems[selectedLabel.labelItems.length - 1];
        const newTaskLabel: TaskLabel = {
          labelId: selectedLabel.labelId,
          labelItemId: nextLabelItem.labelItemId,
        };
        setEditingTaskLabels(task.labels.concat([newTaskLabel]));
      }
    };

    const end = () => {
      elm.current && elm.current.blur();
    };

    const onBlur = async () => {
      await endLabeling(task, editingTaskLabels);
    };

    return (
      <Container
        ref={elm}
        tabIndex={taskSettingTabIndex}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      >
        <LabelsTitle fontSize={fontSize}>{l("labels")}</LabelsTitle>
        {labels.map((l, i) => (
          <ComponentLabel
            key={l.labelId}
            focused={true}
            selected={i === selectedLabelIndex}
            label={l}
            taskLabel={editingTaskLabels.find((nl) => nl.labelId === l.labelId)}
            onClickLabelItem={onClickLabelItem}
            onClickNone={onClickNone}
          />
        ))}
      </Container>
    );
  }
);
Labeling.displayName = "Labeling";

const Container = styled(FlexColumnCenter)`
  max-width: 400px;
  margin-top: 8px;
  user-select: none;
  :focus {
    outline: none;
  }
`;

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize + 2}px;
  font-weight: bold;
`;

const LabelsTitle = styled(Title)`
  margin-bottom: 4px;
`;

export default Labeling;
