import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../components/SettingContainer";
import Typography from "../../components/Typography";
import Radio from "../../components/Input/Radio";
import Context from "../../context";
import useAnalytics from "../../hooks/useAnalytics";
import { Language } from "../../models/Language";
import { languages } from "../../constants";

const LanguageSetting: React.FC = () => {
  // context
  const { language, setLanguage } = useContext(Context);
  // hooks
  const { t } = useTranslation("language");
  const { sendSettingLog } = useAnalytics();

  const onChange = async (language: Language) => {
    setLanguage(language);
    sendSettingLog("update_language");
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="8px 0px 16px 0px">
        {t("language")}
      </Typography>
      <Languages>
        {languages.map((l) => (
          <Radio
            key={l}
            label={t(l)}
            value={l}
            checked={language === l}
            onChange={() => onChange(l)}
          >
            {t(l)}
          </Radio>
        ))}
      </Languages>
    </SettingContainer>
  );
};

const Languages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    margin-bottom: 8px;
  }
`;

export default LanguageSetting;
