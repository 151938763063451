import { issueId } from "../../bl/common";
import { reasignChildrenSortNumber } from "./common";
import { Task } from "../../models/Task";
import { taskMargin, lineWidth } from "../../constants";

export const blAddChild = (
  tasks: Task[],
  task: Task
): {
  newTasks: Task[];
} => {
  const newTasks = tasks.map((t) => {
    t.copied = false;
    t.cut = false;
    return t;
  });
  const newChild = createNewTask(
    task.level + 1,
    task.getNextSortNumber(),
    task
  );

  // 選択を移動
  task.selected = false;
  newChild.selected = true;
  // 編集中にする
  newChild.isEditing = true;

  // 左上から飛んでくるようなアニメーションを防ぐために初期位置を入れておく
  newChild.top = task.children.length
    ? task.children[task.children.length - 1].bottom + taskMargin
    : task.top;
  newChild.left = task.right + lineWidth;

  newTasks.push(newChild);
  task.children.push(newChild);
  return {
    newTasks,
  };
};

const createNewTask = (
  level: number,
  sortNumber: number,
  parent: Task
): Task => {
  const task = new Task(
    issueId(),
    level,
    sortNumber,
    parent,
    [],
    "",
    false,
    "",
    null,
    null,
    null,
    []
  );
  task.isNew = true;
  return task;
};

export const blAddSibling = (
  tasks: Task[],
  task: Task,
  parent: Task,
  direction: "ABOVE" | "BELOW"
): {
  newTasks: Task[];
} => {
  const newTasks = tasks.map((t) => {
    t.copied = false;
    t.cut = false;
    return t;
  });
  const newSortNumber =
    direction === "ABOVE" ? task.sortNumber : task.sortNumber + 1;
  const newTask = createNewTask(task.level, newSortNumber, parent);

  // 選択を移動
  task.selected = false;
  newTask.selected = true;
  // 編集中にする
  newTask.isEditing = true;

  // 左上から飛んでくるようなアニメーションを防ぐために位置を最初に入れておく
  newTask.top = direction === "ABOVE" ? task.top : task.bottom + taskMargin;
  newTask.left = task.left;

  const siblings = newTasks.filter(
    (t) => t.parentId === task.parentId && t.sortNumber >= newSortNumber
  );
  for (const sibling of siblings) {
    sibling.sortNumber++;
  }
  parent.children.push(newTask);
  // sortNumber 振り直し
  reasignChildrenSortNumber(parent);
  // 最後に追加しないと sortNumber がインクリメントされる
  newTasks.push(newTask);
  return {
    newTasks,
  };
};
