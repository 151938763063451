import React from "react";
import SettingContainer from "../../components/SettingContainer";
import TermsOfServiceComponent from "../../components/TermsOfService";

const TermsOfService: React.FC = () => {
  return (
    <SettingContainer>
      <TermsOfServiceComponent />
    </SettingContainer>
  );
};

export default TermsOfService;
