import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import SettingContainer from "../../../components/SettingContainer";
import FilledButton from "../../../components/Button/FilledButton";
import Typography from "../../../components/Typography";
import YesNoDialog from "../../../components/Dialog/YesNoDialog";
import OkDialog from "../../../components/Dialog/OkDialog";
import Owner from "../../../components/MemberManagement/Owner";
import Admins from "../../../components/MemberManagement/Admins";
import Members from "../../../components/MemberManagement/Members";
import { getUsers, getUserById, getUserByEmail } from "../../../db/users";
import {
  switchAdminToMember,
  switchMemberToAdmin,
  removeProjectAdmin,
  addProjectMember,
  removeProjectMember,
} from "../../../db/projects";
import Context from "../../../context";
import useSnackbar from "../../../hooks/useSnackbar";
import useAnalytics from "../../../hooks/useAnalytics";
import { User } from "../../../models/User";
import { Project } from "../../../models/Project";
import { Auth } from "../../../models/Auth";
import { firebase401Message } from "../../../constants";

const ProjectMemberSetting: React.FC = () => {
  const match = useRouteMatch<{ projectId: string }>({
    path: "/projects/:projectId/settings/members",
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params && match.params.projectId;

  // context
  const { user, projects, updateLocalProject } = useContext(Context);
  // hooks
  const { t: c } = useTranslation("common");
  const { t: m } = useTranslation("member");
  const { t: p } = useTranslation("project");
  const { setUnauthorizedMessage } = useSnackbar();
  const { sendProjectLog } = useAnalytics();
  const history = useHistory();
  // states
  const [project, setProject] = useState<Project | null>(null);
  const [owner, setOwner] = useState<User | null>(null);
  const [admins, setAdmins] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  // Add member
  const [email, setEmail] = useState("");
  const [foundUser, setFoundUser] = useState<User | null>(null);
  const [confirmAddMessage, setConfirmAddMessage] = useState("");
  const [isConfirmAddDialogOpen, setIsConfirmAddDialogOpen] = useState(false);
  // Remove member
  const [removingMember, setRemovingMember] = useState<User | null>(null);
  const [confirmRemoveMessage, setConfirmRemoveMessage] = useState("");
  const [isRemoveAdminDialogOpen, setIsRemoveAdminDialogOpen] = useState(false);
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] = useState(
    false
  );
  // OK
  const [isOkDialogOpen, setIsOkDialogOpen] = useState(false);
  const [okTitle, setOkTitle] = useState("");
  const [okMessage, setOkMessage] = useState("");
  // computed
  const canEditMembers =
    user &&
    project &&
    (project.ownerId === user.userId || project.adminIds.includes(user.userId))
      ? true
      : false;

  useEffect(() => {
    if (!projectId || !projects.length) return;
    const currentProject = projects.find((p) => p.projectId === projectId);
    if (currentProject) {
      setProject(currentProject);
      (async () => {
        const promises = [
          loadOwner(currentProject.ownerId),
          loadAdmins(currentProject.adminIds),
          loadMembers(currentProject.memberIds),
        ];
        await Promise.all(promises);
      })();
    }
  }, [projects, projectId]);

  const loadOwner = async (ownerId: string) => {
    setOwner(await getUserById(ownerId));
  };

  const loadAdmins = async (adminIds: string[]) => {
    if (adminIds.length) {
      setAdmins(await getUsers(adminIds));
    } else {
      setAdmins([]);
    }
  };

  const loadMembers = async (memberIds: string[]) => {
    if (memberIds.length) {
      setMembers(await getUsers(memberIds));
    } else {
      setMembers([]);
    }
  };

  // Change auth
  const changeAuth = async (member: User, from: Auth, to: Auth) => {
    if (!project || !canEditMembers) return;
    switch (from) {
      case "admin":
        switch (to) {
          case "member":
            try {
              await switchAdminToMember(project.projectId, member.userId);
              await updateLocalProject(project.projectId);
            } catch (err) {
              if (err.message === firebase401Message) {
                setUnauthorizedMessage();
              }
            }
            break;
          case "notmember":
            setRemovingMember(member);
            setConfirmRemoveMessage(
              m("areYouSureRemove").replace("{name}", member.name)
            );
            setIsRemoveAdminDialogOpen(true);
            break;
          default:
            break;
        }
        break;
      case "member":
        switch (to) {
          case "admin":
            try {
              await switchMemberToAdmin(project.projectId, member.userId);
              await updateLocalProject(project.projectId);
            } catch (err) {
              if (err.message === firebase401Message) {
                setUnauthorizedMessage();
              }
            }
            break;
          case "notmember":
            setRemovingMember(member);
            setConfirmRemoveMessage(
              m("areYouSureRemove").replace("{name}", member.name)
            );
            setIsRemoveMemberDialogOpen(true);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // Add member
  const onClickAddMember = async () => {
    if (!project || !canEditMembers) return;
    // 存在確認
    const tempFoundUser = await getUserByEmail(email);
    if (tempFoundUser) {
      if (project.memberIds.includes(tempFoundUser.userId)) {
        // 登録済み
        setOkTitle(m("registeredUser"));
        setOkMessage(m("alreadyRegistered"));
        setIsOkDialogOpen(true);
      } else {
        // 登録確認
        setFoundUser(tempFoundUser);
        setIsConfirmAddDialogOpen(true);
        setConfirmAddMessage(
          m("areYouSureAdd").replace("{name}", tempFoundUser.name)
        );
      }
    } else {
      // Not Found
      setOkTitle(m("notFound"));
      setOkMessage(m("notFoundMessage"));
      setIsOkDialogOpen(true);
    }
  };
  const onClickYesForAddMember = async () => {
    if (!project || !canEditMembers) return;
    if (foundUser) {
      try {
        await addProjectMember(project.projectId, foundUser.userId);
        await updateLocalProject(project.projectId);
      } catch (err) {
        if (err.message === firebase401Message) {
          setUnauthorizedMessage();
        }
      }
    }
    setEmail("");
    setIsConfirmAddDialogOpen(false);
    sendProjectLog("add_member");
  };
  const onClickNoForAddMember = () => {
    setIsConfirmAddDialogOpen(false);
  };

  // Remove admin
  const onClickYesForRemoveAdmin = async () => {
    if (!project || !canEditMembers) return;
    if (removingMember) {
      try {
        await removeProjectAdmin(project.projectId, removingMember.userId);
        await updateLocalProject(project.projectId);
        sendProjectLog("remove_member");
      } catch (err) {
        if (err.message === firebase401Message) {
          setUnauthorizedMessage();
        }
      }
    }
    setIsRemoveAdminDialogOpen(false);
  };
  const onClickNoForRemoveAdmin = () => {
    setIsRemoveAdminDialogOpen(false);
  };

  // Remove member
  const onClickYesForRemoveMember = async () => {
    if (!project || !canEditMembers) return;
    if (removingMember) {
      try {
        await removeProjectMember(project.projectId, removingMember.userId);
        await updateLocalProject(project.projectId);
        sendProjectLog("remove_member");
      } catch (err) {
        if (err.message === firebase401Message) {
          setUnauthorizedMessage();
        }
      }
    }
    setIsRemoveMemberDialogOpen(false);
  };
  const onClickNoForRemoveMember = () => {
    setIsRemoveMemberDialogOpen(false);
  };

  const onClickCancel = () => {
    projectId && history.push(`/projects/${projectId}`);
  };

  return (
    <SettingContainer>
      <Typography variant="large" bold margin="16px 0px 16px 0px">
        {project ? project.name : ""}
      </Typography>
      <Owner owner={owner} />
      <Admins
        admins={admins}
        canEditMembers={canEditMembers}
        changeAuth={changeAuth}
      />
      <Members
        members={members}
        changeAuth={changeAuth}
        canAddMember={true}
        canEditMembers={canEditMembers}
        email={email}
        onChangeEmail={onChangeEmail}
        onClickAddMember={onClickAddMember}
      />
      <FilledButton color="default" onClick={onClickCancel}>
        {p("backToProject")}
      </FilledButton>
      <YesNoDialog
        isOpen={isConfirmAddDialogOpen}
        setIsOpen={setIsConfirmAddDialogOpen}
        color="primary"
        title={m("newMember")}
        message={confirmAddMessage}
        onClickYes={onClickYesForAddMember}
        onClickNo={onClickNoForAddMember}
      />
      <YesNoDialog
        isOpen={isRemoveAdminDialogOpen}
        setIsOpen={setIsRemoveAdminDialogOpen}
        color="secondary"
        title={c("confirmRemoval")}
        message={confirmRemoveMessage}
        onClickYes={onClickYesForRemoveAdmin}
        onClickNo={onClickNoForRemoveAdmin}
      />
      <YesNoDialog
        isOpen={isRemoveMemberDialogOpen}
        setIsOpen={setIsRemoveMemberDialogOpen}
        color="secondary"
        title={c("confirmRemoval")}
        message={confirmRemoveMessage}
        onClickYes={onClickYesForRemoveMember}
        onClickNo={onClickNoForRemoveMember}
      />
      <OkDialog
        isOpen={isOkDialogOpen}
        setIsOpen={setIsOkDialogOpen}
        title={okTitle}
        message={okMessage}
      />
    </SettingContainer>
  );
};

export default ProjectMemberSetting;
