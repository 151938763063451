import React from "react";
import styled from "styled-components";

type Props = {
  variant?: "small" | "medium" | "large";
  bold?: boolean;
  margin?: string;
};

const Typography: React.FC<Props> = React.memo(
  ({ children, variant, margin, bold }) => {
    const getFontSize = () => {
      switch (variant) {
        case "medium":
          return 18;
        case "large":
          return 22;
        case "small":
        default:
          return 16;
      }
    };

    return (
      <Container fontSize={getFontSize()} margin={margin} bold={bold}>
        {children}
      </Container>
    );
  }
);
Typography.displayName = "Typography";

type ContainerProps = {
  fontSize: number;
  bold?: boolean;
  margin?: string;
};
const Container = styled.div<ContainerProps>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ bold }) => (bold ? "bold" : "")};
  margin: ${({ margin }) => margin};
`;

export default Typography;
