import { useContext } from "react";
import { Language } from "../models/Language";
import { Command } from "../models/Command";
import Context from "../context";
import firebase from "../firebase";

const analytics = firebase.analytics();

type TutorialType = "Edit" | "Move" | "Setting" | "Filter";
type TaskSettingType =
  | "open_assignee_setting"
  | "open_due_date_setting"
  | "open_label_setting"
  | "open_total_settings";
type ProjectType =
  | "add"
  | "delete"
  | "update_project_name"
  | "update_labels"
  | "add_member"
  | "remove_member"
  | "update_calendar"
  | "update_visibility";
type FilterType = "open";
type SettingType = "update_theme" | "update_language";
type AlertType =
  | "project_count_limit"
  | "task_count_limit"
  | "label_count_limit";

const useAnalytics = (): {
  setLanguageProperty: (language: Language) => void;
  sendLoginLog: () => void;
  sendLandingLog: () => void;
  sendTutorialCommandLog: (command: Command) => void;
  sendTutorialCompletedLog: (type: TutorialType) => void;
  sendTaskSettingLog: (type: TaskSettingType) => void;
  sendFilterLog: (type: FilterType) => void;
  sendProjectLog: (type: ProjectType) => void;
  sendSettingLog: (type: SettingType) => void;
  sendAlertLog: (type: AlertType) => void;
} => {
  const { user } = useContext(Context);

  const disabled = false;

  const setLanguageProperty = (language: Language) => {
    if (disabled) return;
    analytics.setUserProperties({ language });
  };

  const sendLoginLog = () => {
    if (disabled) return;
    if (!user) return;
    analytics.logEvent("login", { method: "" });
  };

  const sendLandingLog = () => {
    if (disabled) return;
    analytics.logEvent("landing");
  };

  const sendTutorialCommandLog = (command: Command) => {
    if (disabled) return;
    analytics.logEvent("tutorial_command", { command });
  };

  const sendTutorialCompletedLog = (type: TutorialType) => {
    if (disabled) return;
    analytics.logEvent("tutorial_completed", { type });
  };

  const sendTaskSettingLog = (type: TaskSettingType) => {
    if (disabled) return;
    if (!user) return;
    analytics.logEvent("task_setting", { type });
  };

  const sendFilterLog = (type: FilterType) => {
    if (disabled) return;
    if (!user) return;
    analytics.logEvent("filter", { type });
  };

  const sendProjectLog = (type: ProjectType) => {
    if (disabled) return;
    if (!user) return;
    analytics.logEvent("project", { type });
  };

  const sendSettingLog = (type: SettingType) => {
    if (disabled) return;
    if (!user) return;
    analytics.logEvent("setting", { type });
  };

  const sendAlertLog = (type: AlertType) => {
    if (disabled) return;
    if (!user) return;
    analytics.logEvent("limit_alert", { type });
  };

  return {
    setLanguageProperty,
    sendLoginLog,
    sendLandingLog,
    sendTutorialCommandLog,
    sendTutorialCompletedLog,
    sendTaskSettingLog,
    sendFilterLog,
    sendProjectLog,
    sendSettingLog,
    sendAlertLog,
  };
};

export default useAnalytics;
