import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexColumnCenter } from "../../../components/StyledComponents";
import DatePicker from "./DatePicker";
import { getOclockDate } from "../../../bl/common";
import useStyles from "../../../hooks/useStyles";
import { Task } from "../../../models/Task";
import { SettingCategory } from "../../../models/Setting";
import { taskSettingTabIndex } from "../../../constants";

type Props = {
  task: Task;
  endSettingDueDate: (
    task: Task,
    dueDate: Date | null,
    category?: SettingCategory
  ) => Promise<void>;
};

const SettingDueDate: React.FC<Props> = React.memo(
  ({ task, endSettingDueDate }) => {
    // ref
    const elm = useRef<HTMLDivElement>(null);
    // hooks
    const { t: cl } = useTranslation("calendar");
    const { color, fontSize } = useStyles();
    // states
    const [left, setLeft] = useState(task.left);
    const [editingDueDate, setEditingDueDate] = useState<Date | null>(null);

    // Force Update
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
    const forceUpdate = () => {
      setForceUpdateCounter(forceUpdateCounter + 1);
    };

    useEffect(() => {
      if (elm.current) {
        const margin =
          elm.current.offsetLeft +
          elm.current.offsetWidth / 2 -
          task.horizontalCenter;
        setLeft(left - margin);
      }
    }, [task.horizontalCenter]);

    useEffect(() => {
      if (task.isSettingDueDate) {
        setEditingDueDate(
          task.dueDate ? new Date(task.dueDate.getTime()) : null
        );
        focus();
      }
    }, [task.isSettingDueDate]);

    const focus = () => {
      elm.current && elm.current.focus();
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          if (editingDueDate) {
            editingDueDate.setDate(editingDueDate.getDate() - 7);
            onChangeDueDate(editingDueDate);
          } else {
            // 入ってなかった場合は今日から移動
            const now = getOclockDate();
            now.setDate(now.getDate() - 7);
            onChangeDueDate(now);
          }
          break;
        case "ArrowDown":
          event.preventDefault();
          if (editingDueDate) {
            editingDueDate.setDate(editingDueDate.getDate() + 7);
            onChangeDueDate(editingDueDate);
          } else {
            // 入ってなかった場合は今日から移動
            const now = getOclockDate();
            now.setDate(now.getDate() + 7);
            onChangeDueDate(now);
          }
          break;
        case "ArrowRight":
          event.preventDefault();
          if (editingDueDate) {
            editingDueDate.setDate(editingDueDate.getDate() + 1);
            onChangeDueDate(editingDueDate);
          } else {
            // 入ってなかった場合は今日から移動
            const now = getOclockDate();
            now.setDate(now.getDate() + 1);
            onChangeDueDate(now);
          }
          break;
        case "ArrowLeft":
          event.preventDefault();
          if (editingDueDate) {
            editingDueDate.setDate(editingDueDate.getDate() - 1);
            onChangeDueDate(editingDueDate);
          } else {
            // 入ってなかった場合は今日から移動
            const now = getOclockDate();
            now.setDate(now.getDate() - 1);
            onChangeDueDate(now);
          }
          break;
        case "Enter":
          event.preventDefault();
          end();
          return;
        case "a":
          event.preventDefault();
          await endSettingDueDate(task, editingDueDate, "Assignee");
          return;
        case "d":
          event.preventDefault();
          return;
        case "l":
          event.preventDefault();
          await endSettingDueDate(task, editingDueDate, "Label");
          return;
        case " ":
          // 画面がスクロールされるのを防ぐ
          event.preventDefault();
          setEditingDueDate(null);
          return;
        case "Tab":
          // フォーカスが移動するのを防ぐ
          event.preventDefault();
          return;
        default:
          break;
      }
    };

    const onChangeDueDate = (newDueDate: Date | null) => {
      setEditingDueDate(newDueDate);
      forceUpdate();
    };

    const end = () => {
      elm.current && elm.current.blur();
    };

    const onBlur = async () => {
      await endSettingDueDate(task, editingDueDate);
    };

    return (
      <Container
        ref={elm}
        tabIndex={taskSettingTabIndex}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      >
        <Title fontSize={fontSize}>{cl("dueDate")}</Title>
        <DatePicker
          selectedDate={editingDueDate}
          onChange={onChangeDueDate}
          forceUpdateCounter={forceUpdateCounter}
          color={color}
        />
        <div>Space : {cl("resetDueDate")}</div>
      </Container>
    );
  }
);
SettingDueDate.displayName = "SettingDueDate";

const Container = styled(FlexColumnCenter)`
  max-width: 400px;
  margin-top: 8px;
  user-select: none;
  :focus {
    outline: none;
  }
`;

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize + 2}px;
  font-weight: bold;
`;

export default SettingDueDate;
