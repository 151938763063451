import React from "react";
import styled from "styled-components";
import { Palette, ArrowUpward, ArrowDownward, Clear } from "@material-ui/icons";
import useStyles from "../../../../hooks/useStyles";

type Props = {
  icon: "palette" | "delete" | "up" | "down";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Icon: React.FC<Props> = React.memo(({ icon, disabled, onClick }) => {
  const { icon: iconTheme } = useStyles();

  const getIcon = () => {
    switch (icon) {
      case "palette":
        return <Palette />;
      case "delete":
        return <Clear />;
      case "up":
        return <ArrowUpward />;
      case "down":
        return <ArrowDownward />;
      default:
        return <></>;
    }
  };

  return (
    <Container
      color={iconTheme.color}
      hoverColor={iconTheme.hoverColor}
      disabledColor="transparent"
      disabled={disabled}
      onClick={onClick}
    >
      {getIcon()}
    </Container>
  );
});
Icon.displayName = "Icon";

type ContainerProps = {
  color: string;
  hoverColor: string;
  disabledColor: string;
  disabled?: boolean;
};
const Container = styled.button<ContainerProps>`
  color: ${({ color, disabledColor, disabled }) =>
    disabled ? disabledColor : color};
  background-color: transparent;
  border: none;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  display: flex;
  align-items: center;

  transition: color 0.1s ease-out;

  :hover {
    color: ${({ hoverColor, disabledColor, disabled }) =>
      disabled ? disabledColor : hoverColor};
  }
  :focus {
    outline: none;
  }
`;

export default Icon;
