import React from "react";
import Snackbar, { SnackbarCloseReason } from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarMessage } from "../models/Snackbar";
import { baseBorderRadius } from "../constants";

// eslint-disable-next-line
const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    borderRadius: baseBorderRadius,
    zIndex: 9999,
  },
}));

type Props = {
  message: SnackbarMessage;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const MessageSnackbar: React.FC<Props> = React.memo(
  ({ message, isOpen, setIsOpen }) => {
    const classes = useStyles();

    const handleClose = (
      // eslint-disable-next-line
      event: React.SyntheticEvent<any, Event>,
      reason: SnackbarCloseReason
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setIsOpen(false);
    };

    return (
      <div className={classes.root}>
        <Snackbar
          open={isOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            className={classes.alert}
            onClose={handleClose}
            severity={message.level}
          >
            {message.text}
          </Alert>
        </Snackbar>
      </div>
    );
  }
);
MessageSnackbar.displayName = "MessageSnackbar";

export default MessageSnackbar;
